import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Comment} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/comment_pb';
import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {
  RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole,
  RelatedFeaturesGroup,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

import {type TimelineEntry, TimelineEntryType} from './common';

const AUTHOR_PROPERTY = 'User';

/**
 * Component for rendering timeline entry description with navigation attached
 * to link element in feature timeline.
 */
@Component({
  selector: 'timeline-entry-description',
  templateUrl: './timeline_entry_description.ng.html',
  styleUrls: ['./timeline_entry_description.scss'],
})
export class TimelineEntryDescription {
  @Input() entry!: TimelineEntry;
  @Output() readonly navigate = new EventEmitter();

  // Alias to the enum.
  readonly TimelineEntryType = TimelineEntryType;

  /**
   * Formats the comment for display.
   */
  formatComment(data: TimelineEntry): string {
    if (data.type !== TimelineEntryType.COMMENT) {
      return '';
    }
    return `Comment added by ${(data.record as Comment)?.user?.name || 'Unknown'}`;
  }

  /**
   * Formats the image group for display.
   */
  formatImageGroup(data: TimelineEntry): string {
    if (data.type !== TimelineEntryType.IMAGE) {
      return '';
    }
    const imageCount = this.getChildImageCount(data.record as Feature) ?? 0;

    // TODO(halinab): change the plural handling once we introduce i18n.
    return `${imageCount} ${imageCount === 1 ? 'image' : 'images'}`;
  }

  /**
   * Formats the related feature for display.
   */
  formatFeature(data: TimelineEntry): string {
    if (data.type !== TimelineEntryType.RELATED_FEATURE) {
      return '';
    }
    return (data.record as Feature)?.name || '';
  }

  /**
   * Formats the action verb (added/updated/removed) for display.
   */
  formatActionVerb(): string {
    return this.entry.action.toLowerCase();
  }

  /**
   * Gets the user associated with the update.
   */
  getAuthor(data: TimelineEntry): string {
    if (data.type !== TimelineEntryType.IMAGE) {
      return '';
    }
    const property = (data.record as Feature)?.properties.find(
      (property: Property) => property.key === AUTHOR_PROPERTY,
    );
    return property?.propertyValue.case === 'value' ? property?.propertyValue.value : 'Unknown';
  }

  /**
   * Triggers navigation event.
   */
  goToFeature() {
    this.navigate.emit();
  }

  /**
   * Gets the child image count of a feature.
   */
  private getChildImageCount(feature: Feature): number {
    const imagesGroup = feature.relatedFeaturesGroups.find((group: RelatedFeaturesGroup) => {
      return group.role === RelatedFeatureRole.CHILD_IMAGE;
    });
    return imagesGroup?.relatedFeatures.length || 0;
  }
}

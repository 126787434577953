import {Component} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';

/**
 * The selected date range.
 */
export interface DateRange {
  startDate: Date;
  endDate: Date;
}

// TODO(aegorov): Consider to replace this custom component with the standard
//  one:
//  https://material.angular.io/components/datepicker/overview#date-range-picker-overview

/**
 * Used for selecting a date range.
 */
@Component({
  selector: 'date-range-picker',
  templateUrl: './date_range_picker.ng.html',
  styleUrls: ['./date_range_picker.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangePicker,
    },
  ],
})
export class DateRangePicker implements ControlValueAccessor {
  hasError = false;
  private startDate: Date | null = null;
  private endDate: Date | null = null;

  startDateControl = new UntypedFormControl(new Date());
  endDateControl = new UntypedFormControl(new Date());

  onChange = (range: DateRange) => {
    range;
  };

  writeValue(range: DateRange) {
    if (range) {
      this.startDate = range.startDate;
      this.endDate = range.endDate;
      this.startDateControl.setValue(this.startDate);
      this.endDateControl.setValue(this.endDate);
    }
  }

  registerOnChange(fn: (range: DateRange) => void) {
    this.onChange = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled;
    // Do nothing, we don't need disabled state support for this component.
  }

  registerOnTouched() {
    // Do nothing, we don't need touch support for this component.
  }

  updateDates(startDate: string, endDate: string) {
    if (!startDate || !endDate) {
      return;
    }
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
    this.hasError = !isValidDateRange(this.startDate, this.endDate);
    if (!this.hasError) {
      this.onChange({startDate: this.startDate, endDate: this.endDate});
    }
  }

  openPicker(picker: MatDatepicker<Date>) {
    picker.open();
  }
}

function isValidDateRange(startDate: Date, endDate: Date) {
  return startDate.valueOf() <= endDate.valueOf();
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {BreadcrumbNavigationModule} from '../breadcrumb_navigation/breadcrumb_navigation_module';
import {LightboxModule} from '../gallery/lightbox/lightbox_module';
import {HeaderModule} from '../header/header_module';
import {ImageStudioModule} from '../image_studio/image_studio_module';
import {ImageThumbnailModule} from '../image_thumbnail/image_thumbnail_module';
import {InspectionStatusModule} from '../inspection_status/inspection_status_module';
import {MetadataModule} from '../metadata/metadata_module';
import {PipesModule} from '../pipes/pipes_module';
import {SidepanelLayoutModule} from '../sidepanel_layout/sidepanel_layout_module';
import {SolarInsightsModule} from '../solar_insights/solar_insights_module';
import {TagsModule} from '../tags/tags_module';
import {TimelineModule} from '../timeline/timeline_module';
import {FeatureDetails} from './feature_details';

@NgModule({
  declarations: [FeatureDetails],
  imports: [
    BreadcrumbNavigationModule,
    CommonModule,
    HeaderModule,
    ImageStudioModule,
    ImageThumbnailModule,
    InspectionStatusModule,
    LightboxModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule,
    MetadataModule,
    RouterModule,
    PipesModule,
    SidepanelLayoutModule,
    SolarInsightsModule,
    TagsModule,
    TimelineModule,
  ],
})
export class FeatureDetailsModule {}

@if (form) {
  <mat-card class="mat-elevation-z2 dense annotation-card" [formGroup]="form">
    <mat-card-content class="annotation-card-content">
      <div class="form-info-header" [ngStyle]="header.style">
        {{ header.text }}
      </div>
      <mat-form-field class="search-input" appearance="outline" subscriptSizing="dynamic">
        <input
          #searchText
          matInput
          type="text"
          spellcheck="false"
          placeholder="Search labels"
          aria-label="Search labels"
          i18n-aria-label />
      </mat-form-field>
      <div #labelsFilter class="labels-container">
        <mat-radio-group
          class="labels-radio-group"
          aria-label="Apply labels"
          i18n-aria-label
          [formControl]="form.controls.label">
          @for (label of labels | labelSearch: searchText.value; track label) {
            <mat-radio-button class="label-option" [value]="label" (click)="selectLabel(label)">
              <span innerHtml="{{ label.label | highlight: searchText.value }}"></span>
            </mat-radio-button>
          }
        </mat-radio-group>
        @if ((labels | labelSearch: searchText.value).length === 0) {
          <div class="missing-result">No labels matching filter.</div>
        }
      </div>
      <mat-form-field
        class="comment-input"
        appearance="outline"
        floatLabel="auto"
        subscriptSizing="dynamic">
        <textarea
          matInput
          [formControl]="form.controls.comment"
          [placeholder]="placeholderText"
          class="comment-text-area"
          aria-label="Comment form"
          i18n-aria-label
          rows="2">
        </textarea>
      </mat-form-field>
      @for (err of form.errors | keyvalue; track err) {
        <mat-error>
          {{ err.value.value }}
        </mat-error>
      }
      <div class="action-container">
        <button
          mat-flat-button
          color="primary"
          class="action-button"
          type="button"
          (click)="deleteAnnotation()">
          Clear
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="form.invalid"
          class="action-button"
          type="button"
          (click)="apply()">
          Apply
        </button>
      </div>
    </mat-card-content>
  </mat-card>
}

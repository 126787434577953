/**
 * An Interface for the swipes configuration.
 */
export interface SwipeConfig {
  // Maximum duration in milliseconds between a touch start event and a touch
  // end event to be qualified as a swipe event.
  maxDurationInMs: number;
  // Minimum length between a touch start event and a touch end event on either
  // horizontal or vertical axis to be qualified as a swipe event.
  minLength: number;
  // A swipe event consists of a start touch event and an end touch event.
  // These two events form a bounding box. This parameter sets a minimum ratio
  // of the bounding box's width and height. The bounding box should be
  // stretched enough either horizontally or vertically to be qualified as a
  // swipe event.
  minRatio: number;
}

/** The default swipe configuration. */
export const DEFAULT_SWIPE_CONFIG: SwipeConfig = {
  maxDurationInMs: 1000,
  minLength: 30,
  minRatio: 3,
};

// Copyright 2021-2024 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { Message } from "../../message.js";
import { proto3 } from "../../proto3.js";
import { ScalarType } from "../../scalar.js";
import { protoInt64 } from "../../proto-int64.js";
/**
 * Wrapper message for `double`.
 *
 * The JSON representation for `DoubleValue` is JSON number.
 *
 * @generated from message google.protobuf.DoubleValue
 */
export class DoubleValue extends Message {
  constructor(data) {
    super();
    /**
     * The double value.
     *
     * @generated from field: double value = 1;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.DOUBLE, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.DOUBLE, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.DoubleValue from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new DoubleValue().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new DoubleValue().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new DoubleValue().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(DoubleValue, a, b);
  }
}
DoubleValue.runtime = proto3;
DoubleValue.typeName = "google.protobuf.DoubleValue";
DoubleValue.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */
}]);
DoubleValue.fieldWrapper = {
  wrapField(value) {
    return new DoubleValue({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `float`.
 *
 * The JSON representation for `FloatValue` is JSON number.
 *
 * @generated from message google.protobuf.FloatValue
 */
export class FloatValue extends Message {
  constructor(data) {
    super();
    /**
     * The float value.
     *
     * @generated from field: float value = 1;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.FLOAT, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.FLOAT, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.FloatValue from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new FloatValue().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new FloatValue().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new FloatValue().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(FloatValue, a, b);
  }
}
FloatValue.runtime = proto3;
FloatValue.typeName = "google.protobuf.FloatValue";
FloatValue.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}]);
FloatValue.fieldWrapper = {
  wrapField(value) {
    return new FloatValue({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `int64`.
 *
 * The JSON representation for `Int64Value` is JSON string.
 *
 * @generated from message google.protobuf.Int64Value
 */
export class Int64Value extends Message {
  constructor(data) {
    super();
    /**
     * The int64 value.
     *
     * @generated from field: int64 value = 1;
     */
    this.value = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.INT64, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.INT64, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.Int64Value from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new Int64Value().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Int64Value().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Int64Value().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Int64Value, a, b);
  }
}
Int64Value.runtime = proto3;
Int64Value.typeName = "google.protobuf.Int64Value";
Int64Value.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 3 /* ScalarType.INT64 */
}]);
Int64Value.fieldWrapper = {
  wrapField(value) {
    return new Int64Value({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `uint64`.
 *
 * The JSON representation for `UInt64Value` is JSON string.
 *
 * @generated from message google.protobuf.UInt64Value
 */
export class UInt64Value extends Message {
  constructor(data) {
    super();
    /**
     * The uint64 value.
     *
     * @generated from field: uint64 value = 1;
     */
    this.value = protoInt64.zero;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.UINT64, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.UINT64, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.UInt64Value from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new UInt64Value().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UInt64Value().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UInt64Value().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UInt64Value, a, b);
  }
}
UInt64Value.runtime = proto3;
UInt64Value.typeName = "google.protobuf.UInt64Value";
UInt64Value.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 4 /* ScalarType.UINT64 */
}]);
UInt64Value.fieldWrapper = {
  wrapField(value) {
    return new UInt64Value({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `int32`.
 *
 * The JSON representation for `Int32Value` is JSON number.
 *
 * @generated from message google.protobuf.Int32Value
 */
export class Int32Value extends Message {
  constructor(data) {
    super();
    /**
     * The int32 value.
     *
     * @generated from field: int32 value = 1;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.INT32, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.INT32, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.Int32Value from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new Int32Value().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new Int32Value().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new Int32Value().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(Int32Value, a, b);
  }
}
Int32Value.runtime = proto3;
Int32Value.typeName = "google.protobuf.Int32Value";
Int32Value.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
Int32Value.fieldWrapper = {
  wrapField(value) {
    return new Int32Value({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `uint32`.
 *
 * The JSON representation for `UInt32Value` is JSON number.
 *
 * @generated from message google.protobuf.UInt32Value
 */
export class UInt32Value extends Message {
  constructor(data) {
    super();
    /**
     * The uint32 value.
     *
     * @generated from field: uint32 value = 1;
     */
    this.value = 0;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.UINT32, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.UINT32, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.UInt32Value from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new UInt32Value().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new UInt32Value().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new UInt32Value().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(UInt32Value, a, b);
  }
}
UInt32Value.runtime = proto3;
UInt32Value.typeName = "google.protobuf.UInt32Value";
UInt32Value.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 13 /* ScalarType.UINT32 */
}]);
UInt32Value.fieldWrapper = {
  wrapField(value) {
    return new UInt32Value({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `bool`.
 *
 * The JSON representation for `BoolValue` is JSON `true` and `false`.
 *
 * @generated from message google.protobuf.BoolValue
 */
export class BoolValue extends Message {
  constructor(data) {
    super();
    /**
     * The bool value.
     *
     * @generated from field: bool value = 1;
     */
    this.value = false;
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.BOOL, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.BOOL, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.BoolValue from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new BoolValue().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BoolValue().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BoolValue().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BoolValue, a, b);
  }
}
BoolValue.runtime = proto3;
BoolValue.typeName = "google.protobuf.BoolValue";
BoolValue.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
BoolValue.fieldWrapper = {
  wrapField(value) {
    return new BoolValue({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `string`.
 *
 * The JSON representation for `StringValue` is JSON string.
 *
 * @generated from message google.protobuf.StringValue
 */
export class StringValue extends Message {
  constructor(data) {
    super();
    /**
     * The string value.
     *
     * @generated from field: string value = 1;
     */
    this.value = "";
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.STRING, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.STRING, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.StringValue from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new StringValue().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new StringValue().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new StringValue().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(StringValue, a, b);
  }
}
StringValue.runtime = proto3;
StringValue.typeName = "google.protobuf.StringValue";
StringValue.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
StringValue.fieldWrapper = {
  wrapField(value) {
    return new StringValue({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
/**
 * Wrapper message for `bytes`.
 *
 * The JSON representation for `BytesValue` is JSON string.
 *
 * @generated from message google.protobuf.BytesValue
 */
export class BytesValue extends Message {
  constructor(data) {
    super();
    /**
     * The bytes value.
     *
     * @generated from field: bytes value = 1;
     */
    this.value = new Uint8Array(0);
    proto3.util.initPartial(data, this);
  }
  toJson(options) {
    return proto3.json.writeScalar(ScalarType.BYTES, this.value, true);
  }
  fromJson(json, options) {
    try {
      this.value = proto3.json.readScalar(ScalarType.BYTES, json);
    } catch (e) {
      let m = `cannot decode message google.protobuf.BytesValue from JSON"`;
      if (e instanceof Error && e.message.length > 0) {
        m += `: ${e.message}`;
      }
      throw new Error(m);
    }
    return this;
  }
  static fromBinary(bytes, options) {
    return new BytesValue().fromBinary(bytes, options);
  }
  static fromJson(jsonValue, options) {
    return new BytesValue().fromJson(jsonValue, options);
  }
  static fromJsonString(jsonString, options) {
    return new BytesValue().fromJsonString(jsonString, options);
  }
  static equals(a, b) {
    return proto3.util.equals(BytesValue, a, b);
  }
}
BytesValue.runtime = proto3;
BytesValue.typeName = "google.protobuf.BytesValue";
BytesValue.fields = proto3.util.newFieldList(() => [{
  no: 1,
  name: "value",
  kind: "scalar",
  T: 12 /* ScalarType.BYTES */
}]);
BytesValue.fieldWrapper = {
  wrapField(value) {
    return new BytesValue({
      value
    });
  },
  unwrapField(value) {
    return value.value;
  }
};
<ng-container class="container">
  <div class="filter-header">Show map results</div>
  @for (option of options; track option) {
    <div class="filter-container">
      <div class="legend-item">
        <img src="{{ option.iconSrc }}" class="legend-icon" alt="{{ option.alt }}" />
        <span class="layer-name">{{ option.label }}</span>
      </div>
      <mat-checkbox
        class="image-association-filter-checkbox"
        [(ngModel)]="option.selected"
        (change)="onSelectedOptionsChanged()">
      </mat-checkbox>
    </div>
  }
</ng-container>

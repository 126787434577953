<div class="share-overlay mat-elevation-z6">
  <div class="content">{{ value }}</div>
  <button
    mat-icon-button
    color="primary"
    matTooltip="Copy to clipboard"
    [cdkCopyToClipboard]="value"
    (cdkCopyToClipboardCopied)="onCopy()">
    <mat-icon>content_copy</mat-icon>
  </button>
</div>

<header></header>
<div main class="container">
  <div class="layer-config">Configure layer</div>
  <div class="content">
    <mat-selection-list class="sidepanel" [multiple]="false" [hideSingleSelectionIndicator]="true">
      @for (layer of layers; track layer) {
        <mat-list-option
          class="layer-option"
          [selected]="layer === selectedLayer"
          (click)="selectLayer(layer)">
          <span class="name">
            {{ layer.name }}
          </span>
        </mat-list-option>
      }
    </mat-selection-list>
    <div class="main">
      @if (selectedLayer) {
        <layer-config [layer]="selectedLayer"> </layer-config>
      }
    </div>
  </div>
</div>

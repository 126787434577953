<h1 mat-dialog-title class="header-text">Offline Mode</h1>
<mat-dialog-content>
  <div class="content">You are in a location with limited or no internet connection.</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-flat-button class="button" color="primary" (click)="goToPendingUploads()">
    Go to pending uploads
  </button>
  <button mat-button mat-dialog-close class="button">Ignore</button>
</mat-dialog-actions>

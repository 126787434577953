import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';

import {DeleteImageConfirmationDialogModule} from '../delete_image_confirmation_dialog/delete_image_confirmation_dialog_module';
import {HeaderModule} from '../header/header_module';
import {DialogsModule} from './dialogs/dialogs_module';
import {PendingUploadPage} from './pending_upload';
import {UploadGroup} from './upload_group';

@NgModule({
  declarations: [PendingUploadPage, UploadGroup],
  imports: [
    CommonModule,
    DeleteImageConfirmationDialogModule,
    DialogsModule,
    HeaderModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
  exports: [PendingUploadPage],
})
export class PendingUploadModule {}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/sunroofservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { LatLng } from "../../../../google/type/latlng_pb.js";
import { Building } from "./sunroof_pb.js";

/**
 * Request message for FindClosestBuilding.
 *
 * @generated from message tapestry.gridaware.api.v1.FindClosestBuildingRequest
 */
export const FindClosestBuildingRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.FindClosestBuildingRequest", () => [{
  no: 1,
  name: "location",
  kind: "message",
  T: LatLng
}]);

/**
 * Response message for FindClosestBuilding.
 *
 * @generated from message tapestry.gridaware.api.v1.FindClosestBuildingResponse
 */
export const FindClosestBuildingResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.FindClosestBuildingResponse", () => [{
  no: 1,
  name: "closest_building",
  kind: "message",
  T: Building
}]);
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  first,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Layer} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {QUERY_PARAMS} from '../constants/paths';
import {FilterMap, FilterUpdate, LayerFilters} from '../typings/filter';
import {VisibilityByLayerUpdate} from '../typings/map';
import {waitFor} from '../utils/rxjs';
import {ConfigService} from './config_service';
import {LayersFilterService} from './layers_filter_service';
import {LayersService} from './layers_service';
import {MapService} from './map_service';

/**
 * The number of places after the decimal to truncate the lattitude/longitude stored in the url.
 * This level of precition maps roughly to a particular suburban cul-de-sac.
 * @see https://xkcd.com/2170/.
 */
const URL_LOCATION_PRECISION = 3;

/**
 * Service for handling updating deeplinks.
 */
@Injectable({
  providedIn: 'root',
})
export class DeeplinkService implements OnDestroy {
  private currentFilterStateByLayerId = new Map<string, LayerFilters>();
  private readonly destroyed = new Subject<void>();
  private readonly layersReady = new ReplaySubject<void>(1);
  visibilityByLayerId = new Map<string, boolean>();

  constructor(
    private readonly configService: ConfigService,
    private readonly layersFilterService: LayersFilterService,
    private readonly layersService: LayersService,
    private readonly mapService: MapService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly zone: NgZone,
  ) {
    if (this.configService.filterDeeplinksEnabled) {
      this.loadInitialFilterStateAndLayers();
      this.listenForFilterUpdates();
      this.listenForLayerVisibilityUpdates();
      this.listenForLocationUpdates();
    }
  }

  private loadInitialFilterStateAndLayers() {
    combineLatest([
      this.layersService.onLayersMetadataChanged(),
      this.mapService.onLayerVisibilityChanged(),
      this.layersFilterService.layerFiltersInitialState,
    ])
      .pipe(first(), takeUntil(this.destroyed))
      .subscribe({
        next: ([, visibilityByLayerUpdate, layerFiltersArr]: [
          Layer[],
          VisibilityByLayerUpdate,
          LayerFilters[],
        ]) => {
          for (const layerFilters of layerFiltersArr) {
            this.updateFilterState(layerFilters);
          }
          this.visibilityByLayerId = visibilityByLayerUpdate.visibilityByLayerId;
          this.updateUrlWithFilterChanges(this.visibilityByLayerId);
          this.layersReady.next();
        },
      });
  }

  private listenForFilterUpdates() {
    this.layersFilterService
      .layerFiltersUpdated()
      .pipe(
        waitFor(this.layersReady),
        switchMap((filterUpdate: FilterUpdate) =>
          combineLatest([
            this.fetchLayerFiltersUpdates(filterUpdate.layerId),
            of(!!filterUpdate.userInitiated),
          ]),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe(([updates, userInitiated]: [LayerFilters, boolean]) => {
        this.updateFilterState(updates);
        if (userInitiated) {
          this.removeQueryParam(QUERY_PARAMS.VIEW_ID);
          this.updateUrlWithFilterChanges(this.visibilityByLayerId);
        }
      });
  }

  private listenForLayerVisibilityUpdates() {
    this.mapService
      .onLayerVisibilityChanged()
      .pipe(waitFor(this.layersReady), takeUntil(this.destroyed))
      .subscribe((visibilityByLayerUpdate: VisibilityByLayerUpdate) => {
        this.visibilityByLayerId = visibilityByLayerUpdate.visibilityByLayerId;
        if (visibilityByLayerUpdate.userInitiated) {
          this.removeQueryParam(QUERY_PARAMS.VIEW_ID);
          this.updateUrlWithFilterChanges(this.visibilityByLayerId);
        }
      });
  }

  private listenForLocationUpdates() {
    this.mapService.locationChange.pipe(takeUntil(this.destroyed)).subscribe(([center, zoom]) => {
      this.updateUrlWithLocationChanges(center, zoom);
    });
  }

  private removeQueryParam(queryParam: string) {
    this.router.navigate([], {
      queryParams: {
        [queryParam]: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private fetchLayerFiltersUpdates(layerId: string): Observable<LayerFilters> {
    return combineLatest([
      this.layersFilterService.getFilterMap(layerId).pipe(first()),
      this.layersFilterService.includeInactive(layerId).pipe(first()),
    ]).pipe(
      map(([filters, includeInactiveResults]: [FilterMap, boolean]) => {
        return {
          layerId,
          filters,
          includeInactiveResults,
        };
      }),
    );
  }

  updateFilterState(newFilterStateUpdate: LayerFilters) {
    const {layerId} = newFilterStateUpdate;
    this.currentFilterStateByLayerId.set(layerId, newFilterStateUpdate);
  }

  updateUrlWithLocationChanges(center: google.maps.LatLng, zoom: number) {
    const lat = center.lat().toFixed(URL_LOCATION_PRECISION);
    const lng = center.lng().toFixed(URL_LOCATION_PRECISION);
    this.zone.run(() =>
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {[QUERY_PARAMS.LOCATION]: `${lat},${lng},${zoom}`},
        queryParamsHandling: 'merge',
      }),
    );
  }

  updateUrlWithFilterChanges(currentVisibilityByLayerId: Map<string, Boolean>) {
    let paramString = '';
    for (const [layerId, isVisible] of currentVisibilityByLayerId) {
      if (isVisible) {
        paramString += layerId + '!';
        const filterState = this.currentFilterStateByLayerId.get(layerId);
        if (filterState) {
          if (JSON.stringify(filterState?.filters) !== '{}') {
            for (const [filterName, values] of Object.entries(filterState.filters)) {
              paramString += filterName + '-';
              for (const value of values) {
                paramString += value + '*';
              }
              // Replace trailing plus sign with comma
              paramString = paramString.slice(0, -1) + ',';
            }
            // Remove trailing comma.
            paramString = paramString.slice(0, -1);
          }
          const includeInactiveString = filterState.includeInactiveResults ? '1' : '0';
          paramString += '~' + includeInactiveString + '.';
        }
      }
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {[QUERY_PARAMS.FILTER_STATE]: paramString},
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
  }
}

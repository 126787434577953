/**
 * @fileoverview This is a service for the app root
 */
import {Observable, Subject} from 'rxjs';

import {Injectable} from '@angular/core';

/**
 * Service that emits an event when the app component is about to reload.
 * This gives an opportunity for components/services to save their state.
 */
@Injectable()
export class AppService {
  windowUnloadEventSubject: Subject<void>;
  constructor() {
    this.windowUnloadEventSubject = new Subject<void>();
  }

  getWindowUnloadEvent(): Observable<void> {
    return this.windowUnloadEventSubject.asObservable();
  }

  emitUnloadEvent() {
    this.windowUnloadEventSubject.next();
  }
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

/**
 * Data that is passed to the delete view dialog.
 */
export interface DeleteViewDialogData {
  viewName: string;
}

/**
 * Component for rendering the dialog displayed on view deletion.
 */
@Component({
  selector: 'delete-view-dialog',
  templateUrl: './delete_view_dialog.ng.html',
  styleUrls: ['./delete_view_dialog.scss'],
})
export class DeleteViewDialog {
  filterViewName: string;

  constructor(
    readonly dialogRef: MatDialogRef<DeleteViewDialog>,
    @Inject(MAT_DIALOG_DATA) data: DeleteViewDialogData,
  ) {
    this.filterViewName = data.viewName;
  }
}

import {EMPTY, Observable, Subject} from 'rxjs';
import {finalize, first, switchMap, takeUntil, tap} from 'rxjs/operators';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {
  FilterView,
  LayerFilterView,
  PropertyFilter,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filter_view_pb';

import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {AuthService} from '../services/auth_service';
import {DialogService} from '../services/dialog_service';
import {FilterViewsService} from '../services/filter_views_service';
import {LayersService} from '../services/layers_service';
import {LocalStorageService} from '../services/local_storage_service';
import {DIALOG_WIDTH, MAX_DIALOG_WIDTH, MIN_DIALOG_WIDTH} from '../styles/constants';
import {CreateViewDialog, CreateViewDialogResult} from './create_view_dialog';
import {DeleteViewDialog} from './delete_view_dialog';

const TOAST_DURATION_MS = 2500;
const CREATE_DIALOG_WIDTH = '860px';

interface Location {
  origin: string;
}

/**
 * Component for rendering filter views panel.
 */
@Component({
  selector: 'views',
  templateUrl: './views.ng.html',
  styleUrls: ['./views.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Views implements OnInit, OnDestroy, OnChanges {
  // The most recent state of the current filter view.
  @Input() currentView!: FilterView;

  // If any of the saved filter views was selected,
  // will be set to selected view ID.
  @Input() selectedViewId!: string;

  @Output() readonly onClearFilters = new EventEmitter();

  // Indicates whether the updated state is being loaded at the moment.
  loading = false;

  // Number of applied layers in the current filter selection.
  layersCount: number = 0;

  // Number of applied filters in the current filter selection.
  filtersCount: number = 0;

  // Data to be displayed in the saved views section.
  savedViews: FilterView[] = [];

  // Whether the views panel should be expanded / collapsed.
  isPanelExpanded = false;

  showOverlay = false;
  overlayFor = '';

  private currentUserName: string = '';

  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private readonly dialogService: DialogService,
    private readonly filterViewsService: FilterViewsService,
    private readonly layersService: LayersService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.currentUserName = this.authService.getUserName();
    this.isPanelExpanded = this.shouldShowViewsPanel();
    this.loadSavedViews();
    this.layersCount = this.getSelectedLayersCount(this.currentView);
    this.filtersCount = this.getSelectedFiltersCount(this.currentView);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('currentView' in changes) {
      this.layersCount = this.getSelectedLayersCount(this.currentView);
      this.filtersCount = this.getSelectedFiltersCount(this.currentView);
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private loadSavedViews() {
    this.loading = true;
    this.filterViewsService
      .listFilterViews()
      .pipe(
        first(),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: (views: FilterView[]) => {
          this.savedViews = views;
        },
        error: () => {
          this.openSnackBar('Could not load saved filter views.');
        },
      });
  }

  goToSavedView(savedView: FilterView) {
    this.sendFilterViewSelectedEvent(savedView);
    this.router.navigate([ROUTE.MAP], {
      queryParams: {[QUERY_PARAMS.VIEW_ID]: savedView.id},
    });
  }

  getViewLink(viewId: string, windowLocation: Location = window.location): string {
    const url = new URL(windowLocation.origin);
    const params = new URLSearchParams();
    params.set(QUERY_PARAMS.VIEW_ID, viewId);
    url.hash = `${ROUTE.MAP}?${params}`;
    return this.sanitizer.sanitize(SecurityContext.URL, url.toString()) || '';
  }

  canCreateView(): boolean {
    return this.layersCount + this.filtersCount > 0;
  }

  canDeleteView(view: FilterView): boolean {
    return view.user?.name === this.currentUserName;
  }

  /**
   * Opens view creation dialog and refreshes saved filter views.
   */
  openCreateViewDialog(event: Event) {
    const dialogData = {
      layerNamesById: this.getLayerNamesByIds(this.currentView),
      view: this.currentView,
    };

    // Stop propagation in order to prevent mat-expansion panel from
    // toggling when "Create View" button in panel header is clicked.
    event.stopPropagation();

    this.dialogService
      .render<CreateViewDialog, CreateViewDialogResult>(CreateViewDialog, {
        data: dialogData,
        minWidth: MIN_DIALOG_WIDTH,
        width: CREATE_DIALOG_WIDTH,
      })
      .pipe(
        tap(() => {
          this.loading = true;
          this.changeDetectorRef.detectChanges();
        }),
        switchMap((result: CreateViewDialogResult): Observable<FilterView | null> => {
          return result.viewToCreate
            ? this.filterViewsService.createFilterView(result.viewToCreate)
            : EMPTY;
        }),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: (newFilterView: FilterView | null) => {
          if (newFilterView) {
            this.savedViews = this.savedViews.concat(newFilterView);
            this.goToSavedView(newFilterView);
          }
        },
        error: () => {
          this.openSnackBar('Could not create filter view.');
        },
      });
  }

  /**
   * Opens view deletion dialog and refreshes saved filter views.
   */
  openDeleteViewDialog(view: FilterView) {
    const dialogData = {
      viewName: view.displayName,
    };

    this.dialogService
      .render<DeleteViewDialog, boolean>(DeleteViewDialog, {
        data: dialogData,
        maxWidth: MAX_DIALOG_WIDTH,
        minWidth: MIN_DIALOG_WIDTH,
        width: DIALOG_WIDTH,
      })
      .pipe(
        tap(() => {
          this.loading = true;
          this.changeDetectorRef.detectChanges();
        }),
        switchMap((removalApproved: boolean): Observable<void> => {
          return removalApproved ? this.filterViewsService.deleteFilterView(view.id) : EMPTY;
        }),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: () => {
          this.savedViews = this.savedViews.filter((savedView) => savedView.id !== view.id);
        },
        error: () => {
          this.openSnackBar('Could not remove filter view.');
        },
      });
  }

  clearAllFilters(event: Event) {
    // Stop propagation in order to prevent mat-expansion panel from
    // toggling when "Clear all" button in panel header is clicked.
    event.stopPropagation();
    this.onClearFilters.emit();
  }

  /**
   * Saves the view panel open state to persist in-between sessions.
   */
  updateOpenState(isOpen: boolean) {
    this.localStorageService.writeFilterViewsPanelOpen(isOpen);
  }

  /**
   * The views panel will be expanded in the following cases:
   * - panel wasn't explicitly closed by the user
   * - active view is selected.
   */
  private shouldShowViewsPanel(): boolean {
    return this.localStorageService.readFilterViewsPanelOpen() || this.selectedViewId !== '';
  }

  private getSelectedLayersCount(view: FilterView): number {
    return Object.entries(view.filtersByLayerId || {}).length;
  }

  private getSelectedFiltersCount(view: FilterView): number {
    return Object.values(view.filtersByLayerId || {}).flatMap(
      (layerFilters: LayerFilterView): PropertyFilter[] =>
        Object.values(layerFilters.propertyFilters || {}),
    ).length;
  }

  private getLayerNamesByIds(view: FilterView): Map<string, string> {
    return new Map<string, string>(
      Object.keys(view.filtersByLayerId || {}).map((layerId: string) => [
        layerId,
        this.layersService.getLayerName(layerId) || '',
      ]),
    );
  }

  private sendFilterViewSelectedEvent(filterView: FilterView) {
    this.analyticsService.sendEvent(EventActionType.FILTER_VIEW_SELECTED, {
      event_category: EventCategoryType.FILTER_VIEW,
      event_label: `Filter view name: ${filterView.displayName}`,
    });
  }

  private openSnackBar(message: string) {
    this.snackBar.open(message, '', {duration: TOAST_DURATION_MS});
  }
}

<mat-form-field class="input" appearance="outline">
  <mat-label> Location of image </mat-label>
  <input
    #autocomplete
    matInput
    type="text"
    [disabled]="!mapReady"
    [value]="formattedLocation"
    (change)="updateFormattedLocation($event.target.value)"
    required />
</mat-form-field>
@if (locationDescriptionVisible) {
  <mat-form-field class="input" appearance="outline">
    <mat-label> Location description </mat-label>
    <input
      matInput
      type="text"
      [disabled]="!mapReady"
      [value]="locationDescription"
      (change)="updateLocationDescription($event.target.value)" />
  </mat-form-field>
}
<div class="checkbox">
  <mat-checkbox
    matCheckbox
    #checkbox
    [disabled]="!mapReady"
    [checked]="!featureSelectionEnabled"
    (change)="toggleFeatureSelection(checkbox.checked)">
    I don't know which feature I photographed
  </mat-checkbox>
</div>
<search
  [layerIds]="getActiveLayers()"
  placeholder="Search by address or asset"
  [showHelp]="false"
  [showSearchIcon]="false"
  [isGlobalSearch]="false"
  (featureSelected)="onFeatureSelected($event)"></search>
<div class="map-wrapper">
  <div #map class="map"></div>
  @if (fetchingCount > 0) {
    <mat-progress-spinner class="map-spinner" mode="indeterminate" color="primary" diameter="24">
    </mat-progress-spinner>
  }
</div>
@if (!featureSelectionEnabled) {
  <div class="drag-help-text">Drag red pin to the location where you took your image.</div>
}
@if (!checkbox.checked) {
  <div class="feature-container">
    <div class="label">Associated feature</div>
    <div class="value-container">
      @if (selectedMarker) {
        <div class="feature-text">
          <div>{{ selectedMarker.featureMetadata.feature.name }}</div>
          <a [routerLink]="createMapFeaturePath()" target="_blank">
            <mat-icon class="open-icon" color="primary">open_in_new</mat-icon>
          </a>
        </div>
      } @else {
        <div class="no-text">Click on a feature to associate</div>
      }
    </div>
  </div>
}

@if (!image) {
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="48" class="spinner">
  </mat-progress-spinner>
} @else {
  <div class="image-studio-details-container">
    <div class="panel">
      <div class="panel-header first-header">Image</div>
      @if (takenOn) {
        <div class="title">Image taken {{ takenOn | date: 'medium' }}</div>
      }
      @if (formattedAddress) {
        <div class="address-container">
          <mat-icon color="primary">location_on</mat-icon>
          <a
            class="address"
            href="https://www.google.com/maps/search/?api=1&query={{ formattedAddress }}"
            target="_blank"
            aria-label="Open in Google Maps"
            i18n-aria-label="Open in Google Maps"
            [matTooltip]="'Open in Google Maps'">
            {{ formattedAddress }}
          </a>
        </div>
      }
      @if (editorMode === EDITOR_MODE.OFF && !isNewUpload) {
        <div class="bottom-buttons">
          <!-- The ng-containers on these are to prevent whitespace around them that un-centers the border between them-->
          <span class="top-panel-button details-button" (click)="toggleMoreDetails()">
            <ng-container>{{ showMoreDetails ? 'Fewer details' : 'More details' }}</ng-container>
          </span>
          @if (contextualImageGroup | async; as imageGroup) {
            <span
              class="top-panel-button inspection-form-button"
              (click)="openInspectionForm(imageGroup)">
              <ng-container>Inspection form</ng-container>
            </span>
          }
        </div>
      }
    </div>
    @if (!isNewUpload) {
      @if (editorMode === EDITOR_MODE.OFF && showMoreDetails) {
        <div class="panel">
          <div class="panel-header">Additional information</div>
          @if (image.name) {
            <div class="property">
              <span class="property-name">Name: </span>
              <span class="property-value">{{ image.name }}</span>
            </div>
          }
          <div class="property">
            <span class="property-name">Asset: </span>
            @if (relatedAssetId !== '') {
              <span>
                <span class="property-value">View associated asset</span>
                <button
                  mat-icon-button
                  class="asset-nav-button icon-button icon"
                  color="primary"
                  (click)="navigateToAsset()"
                  aria-label="Open"
                  i18n-aria-label="Open"
                  [matTooltip]="'Open'"
                  [matTooltipPosition]="'above'">
                  <mat-icon class="icon">open_in_new</mat-icon>
                </button>
              </span>
            } @else {
              <span class="property-value">No associated asset</span>
            }
          </div>
          @if (image.description) {
            <div class="property">
              <span class="property-name">Description:</span>
              <span class="property-value">{{ image.description }}</span>
            </div>
          }
          <div class="property">
            <metadata [properties]="properties"></metadata>
          </div>
        </div>
      }
      @if (editorMode === EDITOR_MODE.OFF) {
        <div class="panel">
          <div class="panel-header">Tags</div>
          <tags
            [tags]="tags"
            [layerId]="layerId"
            [allowEditing]="false"
            (tagsUpdated)="onTagsUpdated($event)">
          </tags>
        </div>
      }
    }
  </div>
}

import {Injectable} from '@angular/core';

/**
 * Service for map properties
 */
@Injectable({providedIn: 'root'})
export class MapPropertiesService {
  INACTIVE_MARKER_Z_INDEX = 100;
  ACTIVE_MARKER_Z_INDEX = 101;
  LOCATION_PIN_Z_INDEX = 103;
  // The minimum zoom that the map can have.
  MIN_ZOOM_LEVEL = 8;
  // The zoom level to use if a saved zoom is not found.
  INITIAL_ZOOM_LEVEL = 8;
  // The zoom level to use when repositioning the map.
  MAP_REPOSITION_ZOOM_LEVEL = 19;
  // The zoom level to use when prompting user for area selection on the map.
  MAP_AREA_SELECTION_ZOOM = 13;
  // The minimum zoom that the map can have while in cache area selection mode.
  MIN_AREA_SELECTION_ZOOM_LEVEL = 10;

  /**
   * Additional styles to apply to the map if obfuscation is needed.
   */
  OBFUSCATION_STYLES: google.maps.MapTypeStyle[] = [
    {
      elementType: 'labels',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'administrative.neighborhood',
      stylers: [{visibility: 'off'}],
    },
  ];
}

@if (!openingInDialog) {
  <header></header>
} @else {
  <div class="dialog-top-section">
    <div class="dialog-header">
      <h1 class="dialog-heading">{{ getHeader() }}</h1>
      @if (!loading && !complete && !editing && availableFormTemplates.length > 1) {
        <mat-form-field data-test-id="form-template" appearance="outline">
          <mat-select
            class="template-select"
            [(value)]="formTemplate"
            (selectionChange)="showNewForm()">
            @for (template of availableFormTemplates; track template) {
              <mat-option [value]="template">
                {{ template.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
    <button
      class="close-button"
      mat-icon-button
      mat-dialog-close
      aria-label="Close"
      i18n-aria-label
      matTooltip="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
}
<div class="container" [ngClass]="{'upload-form-opened-in-dialog': openingInDialog}">
  <div class="content-container">
    @if (!openingInDialog) {
      <div class="header">
        <div class="title">
          @if (complete) {
            <button
              mat-icon-button
              aria-label="Back"
              i18n-aria-label
              [matTooltip]="'Back'"
              (click)="reset()">
              <mat-icon class="back-arrow">arrow_back</mat-icon>
            </button>
          }
          {{ getHeader() }}
        </div>
      </div>
    }
    @if (loading) {
      <div class="spinner-overlay">
        <mat-progress-spinner mode="indeterminate" color="primary" diameter="48" class="spinner">
        </mat-progress-spinner>
      </div>
    }
    @if (!loading && !complete && editing != null) {
      <div>
        @if (!openingInDialog) {
          @if (!editing && availableFormTemplates.length > 1) {
            <mat-form-field data-test-id="form-template" appearance="outline">
              <mat-label>Select a template</mat-label>
              <mat-select
                class="template-select"
                [(value)]="formTemplate"
                (selectionChange)="showNewForm()">
                @for (template of availableFormTemplates; track template) {
                  <mat-option [value]="template">
                    {{ template.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          @if (!editing && !loading && !complete) {
            <div class="subheader">You can add an image directly from your computer or device.</div>
          }
        }
        @if (shouldShowUploadForm()) {
          @if (openingInDialog) {
            <upload-form
              [formTemplate]="formTemplate"
              [initialFormData]="formResponses"
              [initialMapMetadata]="initialMapMetadata"
              [offline]="offline"
              [editing]="editing"
              [layerId]="layerId"
              [mapSelectionLayerId]="mapSelectionLayerId"
              [preserveState]="preserveState"
              (submit)="triggerUpload($event)">
            </upload-form>
          } @else {
            <old-upload-form
              [formTemplate]="formTemplate"
              [initialFormData]="formResponses"
              [initialMapMetadata]="initialMapMetadata"
              [offline]="offline"
              [editing]="editing"
              [layerId]="layerId"
              [mapSelectionLayerId]="mapSelectionLayerId"
              [preserveState]="preserveState"
              (submit)="triggerUpload($event)">
            </old-upload-form>
          }
        }
        @if (!shouldShowUploadForm()) {
          <div class="spinner-overlay">
            <mat-progress-spinner
              mode="indeterminate"
              color="primary"
              diameter="48"
              class="spinner">
            </mat-progress-spinner>
          </div>
        }
      </div>
    }
    @if (!loading && complete) {
      <div class="complete-overlay">
        <upload-progress (reset)="reset()"></upload-progress>
      </div>
    }
  </div>
</div>

<mat-chip-listbox class="filter-chips-container" [selectable]="false">
  @for (filter of filters | keyvalue; track filter) {
    @if (!hiddenFilters.includes(filter.key)) {
      <mat-chip-option
        appearance="input"
        [removable]="true"
        [matTooltip]="filter | filterChipLabel"
        (click)="changeFilter(filter.key, filter.value)"
        (removed)="removeFilter(filter.key)">
        <div class="chip-input-container">
          <div class="input-name-value">{{ filter | filterChipLabel }}</div>
          <mat-icon matChipRemove>cancel</mat-icon>
        </div>
      </mat-chip-option>
    }
  }
</mat-chip-listbox>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

import {ResetFormDialog} from './reset_form_dialog';

@NgModule({
  declarations: [ResetFormDialog],
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  exports: [ResetFormDialog],
})
export class DialogsModule {}

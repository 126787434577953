import {PromiseClient} from '@connectrpc/connect';
import {Observable, ReplaySubject} from 'rxjs';

import {Injectable} from '@angular/core';

import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';
import {TagService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tagservice_connect';
import {GetTagsByLayerIdResponse} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tagservice_pb';

import {ApiService} from './api_service';

/**
 * Service for retrieving tags.
 */
@Injectable()
export class TagsService {
  private readonly client: PromiseClient<typeof TagService>;

  private readonly tagsByLayerId = new Map<string, ReplaySubject<string[]>>();

  constructor(private readonly apiService: ApiService) {
    this.client = apiService.createTagServiceBEClient();
  }

  /**
   * Gets all available tags.
   */
  getTags(layerId: string, forceUpdate = false): Observable<string[]> {
    return this.getTagsByLayerId(layerId, forceUpdate);
  }

  private getTagsByLayerId(layerId: string, forceUpdate: boolean): Observable<string[]> {
    if (!forceUpdate && this.tagsByLayerId.has(layerId)) {
      return this.tagsByLayerId.get(layerId)!.asObservable();
    }

    if (!this.tagsByLayerId.has(layerId)) {
      this.tagsByLayerId.set(layerId, new ReplaySubject<string[]>(1));
    }

    this.apiService
      .withCallOptions((options) => this.client.getTagsByLayerId({layerId}, options))
      .then(
        (response: GetTagsByLayerIdResponse) => {
          this.tagsByLayerId.get(layerId)!.next(response.tags.map((t: Tag) => t.name));
        },
        (error: Error) => {
          throw new Error(`Could not get tags: ${error.message}`);
        },
      );

    return this.tagsByLayerId.get(layerId)!.asObservable();
  }
}

/**
 * @fileoverview Search module.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

// import {TapestryPipesModule} from '../../../common/frontend/pipes/tapestry_pipes_module';
import {PipesModule} from '../pipes/pipes_module';
// import {FeaturesService} from '../services/features_service';
import {Search} from './search';

@NgModule({
  declarations: [Search],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatTooltipModule,
    PipesModule,
    ReactiveFormsModule,
    // TapestryPipesModule,
  ],
  exports: [Search],
  // providers: [FeaturesService],
})
export class SearchModule {}

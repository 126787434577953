import {TrustedResourceUrl, trustedResourceUrl} from 'safevalues';
import {safeServiceWorkerContainer} from 'safevalues/dom';

import {Injectable} from '@angular/core';

import {ServiceWorkerMessageType} from '../constants/communication';
import {ConfigService} from './config_service';

const SERVICE_WORKER_URL = trustedResourceUrl`/service_worker.js`;

const SERVICE_WORKER_SCOPE = '/';

/**
 * A service for registering a service worker.
 */
@Injectable({providedIn: 'root'})
export class ServiceWorkerService {
  constructor(private readonly configService: ConfigService) {}

  register(): Promise<boolean> {
    if (!(this.configService.serviceWorkerEnabled && this.isServiceWorkerSupported())) {
      return Promise.resolve(false);
    }

    const swContainer = window.navigator.serviceWorker;
    this.setupServiceWorkerMessaging(swContainer);
    return this.registerServiceWorker(swContainer, SERVICE_WORKER_URL, SERVICE_WORKER_SCOPE);
  }

  private setupServiceWorkerMessaging(swContainer: ServiceWorkerContainer) {
    swContainer.addEventListener('message', (event: MessageEvent) => {
      switch (event.data?.type) {
        // Service worker may request firebase config after it was restarted
        // by the browser.
        case ServiceWorkerMessageType.REQUEST_FIREBASE_CONFIG:
          this.postFirebaseConfig(swContainer);
          break;
        default:
          break;
      }
    });
  }

  private async registerServiceWorker(
    swContainer: ServiceWorkerContainer,
    serviceWorkerUrl: TrustedResourceUrl,
    scope: string,
  ): Promise<boolean> {
    try {
      await safeServiceWorkerContainer.register(swContainer, serviceWorkerUrl, {
        scope,
      });
      await swContainer.ready;
      if (swContainer.controller) {
        // Update service worker with the latest config.
        this.postFirebaseConfig(swContainer);
      } else {
        // Mitigate https://web.dev/service-worker-lifecycle/#shift-reload
        // by soft reloading after a hard refresh.
        window.setTimeout(() => {
          window.location.reload();
        }, 2500); // Timeout is arbitrary.
      }
      return Promise.resolve(true);
    } catch (error) {
      console.error('a problem occurred registering the service worker', error);
      return Promise.resolve(false);
    }
  }

  private postFirebaseConfig(swContainer: ServiceWorkerContainer) {
    swContainer.controller?.postMessage({
      type: ServiceWorkerMessageType.FIREBASE_CONFIG,
      data: this.configService.firebaseConfig,
      enabled: true,
    });
  }

  isServiceWorkerSupported(): boolean {
    const isSupported = 'serviceWorker' in window.navigator;
    if (!isSupported) {
      console.warn('service worker is not supported by the browser');
    }
    return isSupported;
  }

  notifyServiceWorkerOfCacheModeChange(isOffline: boolean) {
    const controller = navigator.serviceWorker.controller;
    if (controller) {
      controller.postMessage({
        type: isOffline
          ? ServiceWorkerMessageType.CHANGE_TO_CACHE_FIRST
          : ServiceWorkerMessageType.CHANGE_TO_NETWORK_FIRST,
      });
    }
  }
}

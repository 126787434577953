import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

import {UserType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/user_pb';

import {ColorScheme} from '../typings/common';
import {LocalStorageService} from './local_storage_service';

const FAVICON_PATH = '/assets/favicon.svg';
const DARK_FAVICON_PATH = '/assets/favicon_dark.svg';

/**
 * Manages access for user preferences, both saved in local storage or in a
 * database.
 */
@Injectable()
export class UserPreferencesService {
  private currentUserType: UserType | null = null;
  private userTypes: UserType[] = [];
  private readonly userType = new ReplaySubject<UserType | null>(1);
  private readonly colorScheme = new BehaviorSubject<ColorScheme>(ColorScheme.LIGHT);

  constructor(
    private readonly localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    const savedColorScheme = this.localStorageService.readColorScheme();
    if (savedColorScheme) {
      this.setColorScheme(
        savedColorScheme === ColorScheme.DARK ? ColorScheme.DARK : ColorScheme.LIGHT,
      );
    }
  }

  setUserTypes(userTypes: UserType[]) {
    this.userTypes = userTypes;
    const storedTypeCode = this.localStorageService.readUserType();
    this.setSelectedUserTypeCode(storedTypeCode);
  }

  getSelectedUserType(): Observable<UserType | null> {
    return this.userType.asObservable();
  }

  getSelectedUserTypeString(): string {
    return this.currentUserType ? this.currentUserType.code : '';
  }

  setSelectedUserTypeCode(code: string | null): void {
    this.currentUserType = null;
    if (code === null) {
      return;
    }
    for (const userType of this.userTypes) {
      if (userType.code === code) {
        this.currentUserType = userType;
        this.localStorageService.writeUserType(code);
        break;
      }
    }
    this.userType.next(this.currentUserType);
  }

  setColorScheme(colorScheme: ColorScheme) {
    this.colorScheme.next(colorScheme);

    this.localStorageService.writeColorScheme(colorScheme.toString());

    if (colorScheme === ColorScheme.DARK) {
      this.document.documentElement.classList.add('dark-theme');
    } else {
      this.document.documentElement.classList.remove('dark-theme');
    }
    this.updateFavicon();
  }

  getColorScheme(): Observable<ColorScheme> {
    return this.colorScheme.asObservable();
  }

  // Update the favicon based on a user's system settings.
  // TODO(b/350748351): Dynamically update the favicon
  // if a user changes their system settings. Currently they
  // have to reload the page to see the new favicon.
  private updateFavicon() {
    const faviconLink: HTMLLinkElement | null = this.document.querySelector(
      'link[type="image/svg+xml"]',
    );

    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (prefersDarkScheme && faviconLink) {
      faviconLink.href = DARK_FAVICON_PATH;
    } else if (!prefersDarkScheme && faviconLink) {
      faviconLink.href = FAVICON_PATH;
    }
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {DateRangePickerModule} from '../date_range_picker/date_range_picker_module';
import {MultiselectAutocompleteModule} from '../multiselect_autocomplete/multiselect_autocomplete_module';
import {PipesModule} from '../pipes/pipes_module';
import {FilterChipLabelPipe} from './filter_chip_label_pipe';
import {FilterChips} from './filter_chips';
import {FilterForm} from './filter_form';
import {FilterNameLabelPipe} from './filter_name_label_pipe';

@NgModule({
  declarations: [FilterForm, FilterChips, FilterChipLabelPipe, FilterNameLabelPipe],
  exports: [FilterForm, FilterChips],
  imports: [
    CommonModule,
    DateRangePickerModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatChipsModule,
    MatTooltipModule,
    MatIconModule,
    MultiselectAutocompleteModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class FilterModule {}

class ViewState {
  constructor(props, state) {
    this._viewportProps = this.applyConstraints(props);
    this._state = state;
  }
  getViewportProps() {
    return this._viewportProps;
  }
  getState() {
    return this._state;
  }
}
export { ViewState as default };
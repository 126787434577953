import {EMPTY, Subject, combineLatest, of} from 'rxjs';
import {expand, finalize, first, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';

import {Component, OnDestroy} from '@angular/core';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {QueryFeaturesResponse} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_pb';

import {SILVER_THEME} from '../constants/map_theme';
import {QueryFeaturesPagination} from '../services/features_service';
import {FilterMap} from '../typings/filter';
import {MapComponent, MapContentChange, MapLayerFilters} from './map_component';

const MAX_FETCH_COUNT_PER_LAYER = 10;
const DEFAULT_PAGINATION: QueryFeaturesPagination = {
  pageSize: 1000,
  pageToken: '',
};

interface FetchStatus {
  // Tracks the number of fetches for a given layer to stop fetching
  // once a layer has fetched the MAX_FETCH_COUNT_PER_LAYER.
  fetchCount: number;

  // Provides a way to cancel fetching for a given layer.
  cancelFetchForLayer: Subject<void>;
}

/**
 * This subclass overrides the base MapComponent to use DeckGL for rendering.
 */
@Component({
  selector: 'app-deckgl-map',
  templateUrl: 'map_component.ng.html',
  styleUrls: ['map_component.scss'],
})
export class MapDeckGLComponent extends MapComponent implements OnDestroy {
  fetchStatusByLayerId = new Map<string, FetchStatus>();

  override initMapListeners() {
    super.initMapListeners();
    this.zoomChanged.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.onZoomChanged(this.map);
    });

    this.deckGLService.init();
    this.deckGLService.multiMarkerByFeatureId = this.multiMarkerByFeatureId;

    this.deckGLService.multiMarkerClickedFeature
      .pipe(takeUntil(this.destroyed))
      .subscribe((feature: Feature | null) => {
        if (!feature) {
          return;
        }
        let multiMarker = this.multiMarkerByFeatureId.get(feature.id);
        // Search for multiMarker by location if not found by feature.
        if (!multiMarker) {
          const locationKey = this.createLocationKeyFromFeature(feature);
          multiMarker = this.multiMarkerByLocation.get(locationKey);
        }
        if (!multiMarker) {
          return;
        }

        this.deckGLService.multiMarkerOpenedWindow.next({
          feature,
          infoWindowVCRef: this.infoWindowVCRef,
          multiMarker,
          map: this.mapService.map,
        });
        this.deckGLService.selectFeature(feature.id);
      });
  }

  private onZoomChanged(map: google.maps.Map) {
    if (!map) {
      return;
    }
    this.deckGLService.updateVisibleLayers(map.getZoom() || 0);
  }

  // TODO(b/327200857): Support dark theme.
  override styles = SILVER_THEME;

  override ngOnDestroy() {
    this.stopWatchingPosition();
    this.destroyed.next();
    this.mapService.mapDestroyed();

    // Cancel all pending requests.
    this.fetchStatusByLayerId.forEach((status) => {
      status.cancelFetchForLayer.next();
    });
    this.loaderService.determinateLoadingProgress.next(100);
    this.loaderService.showIndeterminateProgress.next(false);

    this.deckGLService.destroy();
  }

  override fetchAndRenderFeatures(layerId: string): Subject<MapContentChange> {
    const subject = new Subject<MapContentChange>();

    // Cancel existing fetches for this layer before starting new ones.
    let status = this.fetchStatusByLayerId.get(layerId);
    if (status) {
      status.cancelFetchForLayer.next();
    }

    // Reset the count for each new fetchAndRenderFeatures call.
    status = {
      cancelFetchForLayer: new Subject<void>(),
      fetchCount: 0,
    };
    this.fetchStatusByLayerId.set(layerId, status);
    let mapLayerFilters: MapLayerFilters;
    const pagination = DEFAULT_PAGINATION;

    // Show progress even if it's zero.
    if (+MAX_FETCH_COUNT_PER_LAYER !== 1) {
      this.loaderService.determinateLoadingProgress.next(0);
    } else {
      this.loaderService.showIndeterminateProgress.next(true);
    }

    // Progress is the number of completed fetches versus all fetches
    // for all actively fetching layers.
    const maxFetchCount = +MAX_FETCH_COUNT_PER_LAYER * this.fetchStatusByLayerId.size;

    subject
      .pipe(
        mergeMap(({layerId}: MapContentChange) => {
          return combineLatest([
            this.layersFilterService.getFilterMap(layerId).pipe(first()),
            this.layersFilterService.includeInactive(layerId).pipe(first()),
          ]).pipe(
            map(([filters, includeInactiveResults]: [FilterMap, boolean]) => {
              return {
                layerId,
                filters,
                includeInactiveResults,
                globalSearch: false,
              };
            }),
          );
        }),
        switchMap(({layerId, filters, includeInactiveResults}: MapLayerFilters) => {
          const bounds = this.map.getBounds()!;
          mapLayerFilters = {
            layerId,
            bounds,
            filters,
            includeInactiveResults,
            globalSearch: false,
          };
          return combineLatest([
            of(layerId),
            this.featureService.queryFeaturesInBoundsWithPagination(
              layerId,
              bounds,
              pagination,
              filters,
              includeInactiveResults,
            ),
          ]);
        }),
        expand(([layerId, response]: [string, QueryFeaturesResponse]) => {
          const token = response.nextPageToken;

          // Update progress for fetches across all layers.
          const totalFetchCount = this.getTotalFetchCount() + 1;
          const layerFetchProgress = Math.round((totalFetchCount / maxFetchCount) * 100);
          this.loaderService.determinateLoadingProgress.next(layerFetchProgress);
          const status = this.fetchStatusByLayerId.get(layerId);

          // Stop fetching if no next token or total fetch count will be exceeded.
          if (!status || !token || totalFetchCount + 1 >= maxFetchCount) {
            this.loaderService.determinateLoadingProgress.next(100);
            pagination.pageToken = '';
            return EMPTY;
          }

          // Continue fetching the next page for this layer.
          const {bounds, includeInactiveResults, filters} = mapLayerFilters;
          pagination.pageToken = token;
          status.fetchCount++;
          return combineLatest([
            of(layerId),
            this.featureService.queryFeaturesInBoundsWithPagination(
              layerId,
              bounds!,
              pagination,
              filters,
              includeInactiveResults,
            ),
          ]);
        }),
        finalize(() => {
          this.loaderService.determinateLoadingProgress.next(100);
        }),
        takeUntil(this.destroyed),
        takeUntil(status.cancelFetchForLayer),
      )
      .subscribe(([layerId, response]: [string, QueryFeaturesResponse]) => {
        if (!layerId || !response) {
          return;
        }
        const features = response.features.filter(
          (feature: Feature) => feature.id !== this.deletedFeatureId,
        );
        if (!this.layerVisibilityById.get(layerId)) {
          return;
        }
        const filteredFeatures = this.filterOutRenderedFeatures(features);
        const featuresToRender = this.separateDuplicateLocations(filteredFeatures);
        if (featuresToRender.duplicateLocations.length > 0) {
          this.renderDuplicateLocationFeatures(featuresToRender.duplicateLocations, layerId);
        }
        if (featuresToRender.singleLocations.length > 0) {
          this.renderSingleLocationFeatures(featuresToRender.singleLocations, layerId);
        }
        this.selectPendingFeature();
      });
    return subject;
  }

  protected override renderSingleLocationFeatures(features: Feature[], layerId: string) {
    if (features.length === 0) {
      return;
    }
    this.deckGLService.renderSingleLocationFeatures(features, layerId);
  }

  protected override renderDuplicateLocationFeatures(features: Feature[], layerId: string) {
    // Create multi-markers but do not add to map; instead use DeckGL.
    const multiMarkers = super.createMultiMarkers(features, layerId, false);

    if (multiMarkers.length > 0) {
      this.deckGLService.renderDuplicateLocationFeatures(multiMarkers, layerId);
    }
  }

  protected override removeLayers(layerIds: string[]) {
    if (layerIds.length === 0) {
      return;
    }
    super.removeLayers(layerIds);
    this.deckGLService.removeLayers(layerIds, false);
    this.fetchStatusByLayerId.forEach((status, layerId) => {
      if (layerIds.includes(layerId)) {
        status.cancelFetchForLayer.next();
        this.fetchStatusByLayerId.delete(layerId);
      }
    });
  }

  protected override selectFeature(featureId: string) {
    if (featureId) {
      // Select feature but do not updateMarkerSelectedState.
      super.selectFeature(featureId, false);
      this.deckGLService.selectFeature(featureId);
    }
  }

  protected override deselectFeature() {
    super.deselectFeature();
    this.deckGLService.deselectFeature();
  }

  private getTotalFetchCount(): number {
    let totalFetchCount = 0;
    for (const status of this.fetchStatusByLayerId.values()) {
      totalFetchCount += status.fetchCount;
    }
    return totalFetchCount;
  }
}

import {Observable, Subscriber, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {
  IDB_ASSETS_KEY_PATH,
  IDB_ASSETS_STORE_INDEX_KEY_PATH,
  IDB_ASSETS_STORE_INDEX_NAME,
  IDB_ASSETS_STORE_NAME,
  IDB_NAME,
  IDB_SHARED_STATE_STORE_NAME,
  IDB_UPLOADS_STORE_NAME,
  IDB_VERSION,
} from '../constants/storage';
import {openIndexedDb} from '../core/storage/indexeddb';

/**
 * Service for IndexedDB operations.
 */
@Injectable({providedIn: 'root'})
export class IndexedDBService {
  private idbName: string = IDB_NAME;
  private factory: IDBFactory | null = null;

  setFactory(override: IDBFactory | null = null) {
    this.factory = override;
  }

  getFactory(): IDBFactory {
    return this.factory || window.indexedDB;
  }

  openDb(): Observable<IDBDatabase> {
    return new Observable((obs) => {
      openIndexedDb(
        this.idbName,
        IDB_VERSION,
        [
          {name: IDB_UPLOADS_STORE_NAME},
          {
            name: IDB_ASSETS_STORE_NAME,
            keyPath: IDB_ASSETS_KEY_PATH,
            indexes: [
              {
                name: IDB_ASSETS_STORE_INDEX_NAME,
                keyPath: IDB_ASSETS_STORE_INDEX_KEY_PATH,
              },
            ],
            recreate: true,
          },
        ],
        [IDB_SHARED_STATE_STORE_NAME],
        {},
        this.getFactory(),
      )
        .then((db) => {
          obs.next(db);
          obs.complete();
        })
        .catch((error) => obs.error(error));
    });
  }

  addItem<T>(storeName: string, item: T): Observable<IDBValidKey> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.add(item);
    });
  }

  addItems<T>(storeName: string, items: T[]): Observable<number> {
    let itemsWrittenCount = 0;
    return this.openDb().pipe(
      mergeMap((db: IDBDatabase): Observable<number> => {
        return new Observable((subscriber: Subscriber<number>) => {
          const transaction = db.transaction(storeName, 'readwrite');
          transaction.onerror = () => {
            subscriber.error('There was an error in the object store transaction');
          };
          transaction.oncomplete = () => {
            subscriber.next(itemsWrittenCount);
            subscriber.complete();
          };
          const objectStore = transaction.objectStore(storeName);
          for (const item of items) {
            const request = objectStore.add(item);
            request.onsuccess = () => {
              itemsWrittenCount++;
            };
            request.onerror = () => {
              console.error(`Failed to write item to IndexedDB: ${item}`);
            };
          }
        });
      }),
    );
  }

  putItem<T>(storeName: string, item: T, key: string | number): Observable<IDBValidKey> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.put(item, key);
    });
  }

  deleteItem(storeName: string, key: string | number): Observable<void> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.delete(key);
    });
  }

  deleteAllItems(storeName: string): Observable<void> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.clear();
    });
  }

  getItem(storeName: string, key: string | number): Observable<unknown> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.get(key);
    });
  }

  getItemByIndexedField(
    storeName: string,
    fieldName: string,
    value: string | number,
  ): Observable<unknown> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.index(fieldName).get(value);
    });
  }

  getAllItems(storeName: string): Observable<unknown> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.getAll();
    });
  }

  getAllItemKeys(storeName: string): Observable<IDBValidKey[]> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.getAllKeys();
    });
  }

  getItemKeysStartingWith(
    storeName: string,
    searchTerm: string,
    max: number = 15,
  ): Observable<string[]> {
    const range = IDBKeyRange.bound(searchTerm, searchTerm + '\uffff');
    return this.getItemKeysByRange(storeName, range, max);
  }

  getItemKeysByRange(
    storeName: string,
    range: IDBKeyRange,
    max: number = 15,
  ): Observable<string[]> {
    const result: string[] = [];
    return this.openDb().pipe(
      mergeMap((db: IDBDatabase): Observable<string[]> => {
        return new Observable((subscriber: Subscriber<string[]>) => {
          const transaction = db.transaction(storeName, 'readonly');
          transaction.onerror = () => {
            subscriber.error('There was an error in the object store transaction');
          };
          transaction.oncomplete = () => {
            subscriber.next(result);
            subscriber.complete();
          };
          const objectStore = transaction.objectStore(storeName);
          const request = objectStore.openCursor(range, 'prev');
          request.onsuccess = (event) => {
            const cursor = (event.target as IDBRequest).result;
            if (cursor && max >= 0) {
              result.push(cursor.key);
              cursor.continue();
              max--;
            }
          };
        });
      }),
    );
  }

  countAllItems(storeName: string): Observable<number> {
    return this.operation(storeName, (objectStore: IDBObjectStore) => {
      return objectStore.count();
    });
  }

  /**
   * Returns the number of available bytes.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate.
   */
  availableSpaceBytes(): Observable<number | null> {
    if (!navigator?.storage?.estimate) {
      console.warn('navigator.storage.estimate is not supported by the browser');
      // If the estimate function doesn't exist, return null. If there isn't
      // enough space, the indexedDB write will fail.
      return of(null);
    }
    return new Observable((subscriber: Subscriber<number>) => {
      navigator.storage.estimate().then((estimate: StorageEstimate) => {
        const availableBytes = (estimate.quota || 0) - (estimate.usage || 0);
        subscriber.next(availableBytes);
        subscriber.complete();
      });
    });
  }

  /**
   * Allows to override target DB name for testing.
   * Should only be used in tests.
   */
  overrideDBNameForTest(name: string) {
    this.idbName = name;
  }

  private operation<T>(
    storeName: string,
    callback: (arg: IDBObjectStore) => IDBRequest<T>,
  ): Observable<T> {
    return this.openDb().pipe(
      mergeMap((db: IDBDatabase) => {
        return this.handleObjectStoreTransaction<T>(db, storeName, callback);
      }),
      catchError((errorMessage: string) => {
        console.error(errorMessage);
        throw new Error(errorMessage);
      }),
    );
  }

  private handleObjectStoreTransaction<T>(
    db: IDBDatabase,
    storeName: string,
    callback: (arg: IDBObjectStore) => IDBRequest<T>,
  ): Observable<T> {
    return new Observable((subscriber: Subscriber<T>) => {
      const transaction = db.transaction(storeName, 'readwrite');
      transaction.onerror = () => {
        subscriber.error('There was an error in the object store transaction');
      };
      const objectStore = transaction.objectStore(storeName);
      const objectStoreReq: IDBRequest = callback(objectStore);
      objectStoreReq.onsuccess = () => {
        subscriber.next(objectStoreReq.result);
        subscriber.complete();
      };
      objectStoreReq.onerror = () => {
        subscriber.error('There was an error during the object store action');
      };
    });
  }
}

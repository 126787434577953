// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/filterviewservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateFilterViewRequest, DeleteFilterViewRequest, DeleteFilterViewResponse, GetFilterViewRequest, ListFilterViewsRequest, ListFilterViewsResponse, SearchFilterViewsRequest, SearchFilterViewsResponse } from "./filterviewservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { FilterView } from "./filter_view_pb.js";

/**
 * @generated from service tapestry.gridaware.api.v1.FilterViewService
 */
export const FilterViewService = {
  typeName: "tapestry.gridaware.api.v1.FilterViewService",
  methods: {
    /**
     * ListFilterViews returns all filter views that match target criteria.
     *
     * @generated from rpc tapestry.gridaware.api.v1.FilterViewService.ListFilterViews
     */
    listFilterViews: {
      name: "ListFilterViews",
      I: ListFilterViewsRequest,
      O: ListFilterViewsResponse,
      kind: MethodKind.Unary
    },
    /**
     * GetFilterView fetches a filter view by its unique identifier (UUID).
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     *
     * @generated from rpc tapestry.gridaware.api.v1.FilterViewService.GetFilterView
     */
    getFilterView: {
      name: "GetFilterView",
      I: GetFilterViewRequest,
      O: FilterView,
      kind: MethodKind.Unary
    },
    /**
     * CreateFilterView adds a new filter view.
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     *
     * @generated from rpc tapestry.gridaware.api.v1.FilterViewService.CreateFilterView
     */
    createFilterView: {
      name: "CreateFilterView",
      I: CreateFilterViewRequest,
      O: FilterView,
      kind: MethodKind.Unary
    },
    /**
     * DeleteFilterView deletes an existing filter view by its unique identifier
     * (UUID).
     *
     * @generated from rpc tapestry.gridaware.api.v1.FilterViewService.DeleteFilterView
     */
    deleteFilterView: {
      name: "DeleteFilterView",
      I: DeleteFilterViewRequest,
      O: DeleteFilterViewResponse,
      kind: MethodKind.Unary
    },
    /**
     * SearchFilterViews returns all filter views that match search terms.
     *
     * @generated from rpc tapestry.gridaware.api.v1.FilterViewService.SearchFilterViews
     */
    searchFilterViews: {
      name: "SearchFilterViews",
      I: SearchFilterViewsRequest,
      O: SearchFilterViewsResponse,
      kind: MethodKind.Unary
    }
  }
};
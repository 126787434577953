import {KeyValue} from '@angular/common';
import {Inject, Pipe, PipeTransform} from '@angular/core';

import {isDateField} from '../utils/properties';
import {FILTER_NAMES_MAP} from './filter_config';
import {getFilterNameByKey} from './filter_helpers';

/**
 * Pipe for formatting filter chip label.
 */
@Pipe({name: 'filterChipLabel'})
export class FilterChipLabelPipe implements PipeTransform {
  constructor(
    @Inject(FILTER_NAMES_MAP)
    private readonly filterNamesMap: Map<string, string>,
  ) {}

  transform(filter: KeyValue<string, Set<string>>): string {
    const {key, value} = filter;
    const selectedOptions = Array.from(value);

    let dateFilterValues = '';
    if (isDateField(key)) {
      const dates = selectedOptions.sort((a, b) => Number(new Date(a)) - Number(new Date(b)));
      dateFilterValues = `${dates[0]} to ${dates.pop()}`;
    }

    const filterName = getFilterNameByKey(this.filterNamesMap, key);
    const filterValues =
      dateFilterValues || selectedOptions.map((option) => option.trim()).join(', ');

    return `${filterName}: ${filterValues}`;
  }
}

import {Pipe, PipeTransform} from '@angular/core';

import {LabelInfo} from '../typings/annotations';

/** Filters labels that match the search criteria. */
@Pipe({name: 'labelSearch'})
export class LabelFilterPipe implements PipeTransform {
  transform(labels: LabelInfo[], searchText: string): LabelInfo[] {
    if (searchText === null || labels === null) {
      return labels;
    }
    return labels.filter((label) => label.label.toLowerCase().includes(searchText.toLowerCase()));
  }
}

<div class="fields-container">
  <mat-form-field class="field">
    <mat-label>Start date</mat-label>
    <input
      matInput
      readonly
      subscriptSizing="dynamic"
      [matDatepicker]="startDate"
      [formControl]="startDateControl"
      (click)="openPicker(startDate)"
      aria-label="Start date"
      i18n-aria-label
      placeholder="Start date" />
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate (closed)="updateDates(startDateControl.value, endDateControl.value)">
    </mat-datepicker>
  </mat-form-field>
  <mat-form-field class="endDate field">
    <mat-label>End date</mat-label>
    <input
      matInput
      readonly
      subscriptSizing="dynamic"
      [matDatepicker]="endDate"
      [formControl]="endDateControl"
      (click)="openPicker(endDate)"
      aria-label="End date"
      i18n-aria-label
      placeholder="End date" />
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate (closed)="updateDates(startDateControl.value, endDateControl.value)">
    </mat-datepicker>
  </mat-form-field>
</div>
@if (hasError) {
  <div class="error">End date must be after start date</div>
}

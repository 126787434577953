import {Observable, ReplaySubject, forkJoin} from 'rxjs';
import {combineLatestWith, defaultIfEmpty, map, takeUntil} from 'rxjs/operators';

import {Platform} from '@angular/cdk/platform';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {RelatedFeaturesGroup_RelatedFeatureRole} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

import {FilterField} from '../constants/filters';
import {SortByOption} from '../constants/gallery';
import {AnnotationsService} from '../services/annotations_service';
import {ConfigService} from '../services/config_service';
import {GalleryService} from '../services/gallery_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {Annotation, AnnotationType, PendingAnnotatedImage} from '../typings/annotations';
import {FilterMap} from '../typings/filter';
import {dateToDateStringWithMonthLevelFormat} from '../utils/date';
import {
  ImageUrlOptions,
  THUMBNAIL_SIZE_PX,
  buildImageUrlWithOptions,
  getRelatedFeatures,
  getTakenOn,
} from '../utils/image';

/**
 * Collection of required data corresponding to an image group for rendering in asset timeline.
 */
interface ImageGroupOnAssetTimeline {
  displayLabel: string;
  images: Image[];
  groupStartIndex: number;
  groupEndIndex: number;
}

/**
 * Each filter label string has a corresponding state.
 */
interface FilterState {
  selected: boolean;
  count: number;
}

const ITEM_SIZE_IN_ASSET_TIMELINE = 86;
const IMG_HEIGHT_IN_ASSET_TIMELINE = 66;
const UNGROUPED_IMAGES_ID = 'ungrouped_images';
const UNGROUPED_IMAGES_LABEL_IN_SORT_BY_IMAGE_GROUP = 'Other Images';
const UNGROUPED_IMAGES_LABEL_IN_SORT_BY_CAPTURED_TIME = 'No captured time';

/**
 * Renders images in a virtualized carousel with feature flagged
 * controls for filtering and markers for identifying images with defects.
 */
@Component({
  selector: 'image-carousel',
  templateUrl: './image_carousel.ng.html',
  styleUrls: ['./image_carousel.scss'],
})
export class ImageCarousel implements OnDestroy {
  @ViewChild('viewport') viewport!: CdkVirtualScrollViewport;

  /** All images in the carousel. When filtering, 'filteredImages' is used. */
  @Input() images: Image[] = [];

  // The currently selected image index.
  @Input() selectedImageIndex = 0;

  // URL param options supported by go/fife-urls#url-options and go/gmrs.
  // Sizes requested may be larger than container for better image quality.
  @Input() imageUrlOptions: ImageUrlOptions = {
    height: THUMBNAIL_SIZE_PX,
    width: THUMBNAIL_SIZE_PX,
  };

  // The width in px of each carousel item.
  @Input() itemSize = 141;

  // Height of each carousel item.
  @Input() heightPx = 100;

  @Output() private readonly changedImageIndex = new EventEmitter<number>();

  // If true, a different view is rendered for mobile.
  readonly isMobile: boolean = false;

  // If true then apply filters.
  protected showAllImages = true;

  // Contains all image ids that have a defect (used to show defect marker).
  protected defects = new Set<string>();

  // Contains all image ids that have a particular annotation filter label.
  // This is helpful for faster lookup when filtering images.
  protected labelsById = new Map<string, Set<string>>();

  // Each filter label string has a corresponding FilterState.
  protected filters = new Map<string, FilterState>();

  // A copy of the images.
  protected filteredImages: Image[] = [];

  // Id <-> URL map of images to serve.
  protected imageUrlsById = new Map<string, string>();

  // List of all image groups in the carousel for rendering in asset timeline.
  protected imageGroups: ImageGroupOnAssetTimeline[] = [];

  protected assetTimelineEnabled = false;

  /**
   * The asset timeline will only be shown if feature flag is enabled and layer corresponding to
   * the feature opened in the studio supports asset timeline.
   */
  protected showAssetTimeline = false;

  protected studioFilteringEnabled = false;

  protected sortByOptionsValues = Object.values(SortByOption);

  protected updateIsInProgress = false;

  private timeout!: NodeJS.Timeout;

  private readonly destroyed = new ReplaySubject<void>(1);

  constructor(
    readonly galleryService: GalleryService,
    private readonly annotationsService: AnnotationsService,
    private readonly configService: ConfigService,
    private readonly layersFilterService: LayersFilterService,
    platform: Platform,
  ) {
    this.isMobile = platform.ANDROID || platform.IOS;
  }

  ngOnInit() {
    this.assetTimelineEnabled = this.configService.assetTimelineEnabled;
    this.showAssetTimeline =
      this.assetTimelineEnabled && this.galleryService.checkLayerSupportsAssetTimeline();
    if (this.assetTimelineEnabled) {
      this.itemSize = ITEM_SIZE_IN_ASSET_TIMELINE;
      this.heightPx = IMG_HEIGHT_IN_ASSET_TIMELINE;
    }
    this.studioFilteringEnabled = this.configService.studioFilteringEnabled;
    if (this.studioFilteringEnabled) {
      this.initFiltering();
    }
    this.filteredImages = [...this.images];
    const urls = this.filteredImages.map(
      (image: Image): Observable<[string, string]> =>
        buildImageUrlWithOptions(image, this.imageUrlOptions).pipe(
          map((url: string): [string, string] => [image.id, url]),
        ),
    );
    forkJoin(urls)
      .pipe(defaultIfEmpty([]), takeUntil(this.destroyed))
      .subscribe((values: [string, string][]) => {
        this.imageUrlsById = new Map(values);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedImageIndex']) {
      this.scrollToOffscreenImage();
    }
    if (changes['images'] && this.images) {
      this.filteredImages = [...this.images];
      if (this.updateIsInProgress) {
        this.filterImages();
        this.updateIsInProgress = false;
      }
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    clearTimeout(this.timeout);
  }

  protected getSelectedSortByOption() {
    return this.galleryService.getSelectedSortByOption();
  }

  protected isCurrentSortByOptionSelected(option: SortByOption) {
    return this.galleryService.getSelectedSortByOption() === option;
  }

  protected onSortByOptionClicked(option: SortByOption) {
    if (this.isCurrentSortByOptionSelected(option)) {
      return;
    }
    this.updateIsInProgress = true;
    this.galleryService.updateImagesBasedOnSortByOption(option);
  }

  protected selectImage(index: number) {
    if (index > this.filteredImages.length - 1) {
      index = 0;
    } else if (index < 0) {
      index = this.filteredImages.length - 1;
    }
    this.changedImageIndex.emit(index);
    this.selectedImageIndex = index;
    const image = this.filteredImages[index];
    if (image) {
      this.galleryService.selectImage(image, image.url);
    }
  }

  protected previousImage() {
    this.selectImage(this.selectedImageIndex - 1);
  }

  protected nextImage() {
    this.selectImage(this.selectedImageIndex + 1);
  }

  protected toggleSelection(filterName: string) {
    const filter = this.filters.get(filterName);
    if (!filter) {
      return;
    }
    filter.selected = !filter.selected;
    this.showAllImages = this.shouldShowAllImages();
    this.filterImages();
  }

  protected toggleShowAllImages() {
    this.showAllImages = !this.showAllImages;

    this.filters.forEach((filter) => {
      filter.selected = !this.showAllImages;
    });
    this.filterImages();
  }

  protected filterImages() {
    let selectedImage = this.images[this.selectedImageIndex];
    if (this.showAllImages) {
      this.filteredImages = [...this.images];
    } else {
      this.filteredImages = this.images.filter((image) => {
        const labels = this.labelsById.get(image.id) || [];
        let isFiltered = false;
        for (const label of labels) {
          if (this.filters.get(label)?.selected) {
            isFiltered = true;
          }
        }

        return image && isFiltered;
      });
    }

    if (this.showAssetTimeline) {
      this.segregateFilteredImagesIntoGroups();
    }

    // Update index since it may change when filtering. If the previously
    // selected image is filtered out then default to index 0.
    const updatedIndex = this.filteredImages.findIndex(
      (filteredImage) => filteredImage.id === selectedImage.id,
    );
    this.selectedImageIndex = updatedIndex > -1 ? updatedIndex : 0;
    selectedImage = this.filteredImages[this.selectedImageIndex];
    this.galleryService.selectImage(selectedImage, selectedImage.url);
  }

  /**
   * Scrolls to the selected image if it is off screen.
   */
  private scrollToOffscreenImage() {
    if (this.viewport?.measureScrollOffset === undefined) {
      return;
    }

    const startOffset = this.viewport.measureScrollOffset('start');
    const viewportSize = this.viewport.getViewportSize();
    const imageOffset = this.selectedImageIndex * this.itemSize;

    // Scroll to the selected image if it's not visible.
    if (imageOffset < startOffset || imageOffset > startOffset + viewportSize - this.itemSize) {
      this.viewport.scrollToOffset(imageOffset);
    }
  }

  private initFiltering() {
    this.annotationsService
      .getAnnotations(this.images.map((image) => image.id))
      .pipe(
        combineLatestWith(this.layersFilterService.getFilterMap(this.galleryService.layerId)),
        takeUntil(this.destroyed),
      )
      .subscribe(([images, filters]: [PendingAnnotatedImage[], FilterMap]) => {
        this.filteredImages = [...this.images];
        for (const image of images) {
          image.annotations.forEach((annotation: Annotation) => {
            // Detect defects even if no filters exist.
            if (annotation.info.label?.type === AnnotationType.DEFECT) {
              this.defects.add(image.id);
            }

            // Add annotation label to the set of labels for the current image.
            const annotationLabel = annotation.info.label?.label;
            if (annotationLabel) {
              let imageLabels = this.labelsById.get(image.id);
              if (!imageLabels) {
                imageLabels = new Set<string>();
                this.labelsById.set(image.id, imageLabels);
              }
              imageLabels.add(annotationLabel);
            }
          });
        }

        const includeSet = filters[FilterField.ANNOTATION_INCLUDE];

        // Add an option for each distinct annotation label. The option will be
        // selected only if its corresponding label is one of the filtered
        // labels.
        for (const labelSet of this.labelsById.values()) {
          labelSet.forEach((label) => {
            const filterState = this.filters.get(label) || {
              selected: includeSet?.has(label),
              count: 0,
            };
            filterState.count++;
            this.filters.set(label, filterState);
          });
        }

        this.showAllImages = this.shouldShowAllImages();
        this.filterImages();
      });
  }

  private shouldShowAllImages() {
    const someFilterSelected = Array.from(this.filters.values()).some(
      (filterState) => filterState.selected,
    );
    return !someFilterSelected;
  }

  private segregateFilteredImagesIntoGroups() {
    switch (this.galleryService.getSelectedSortByOption()) {
      case SortByOption.CAPTURED_TIME:
        this.imageGroups = this.getCapturedTimeGroups();
        break;
      case SortByOption.IMAGE_GROUP:
        this.imageGroups = this.getContextualDefectGroups();
        break;
      default:
        console.error('Invalid sort option.');
        throw new Error();
    }
  }

  private getContextualDefectGroups(): ImageGroupOnAssetTimeline[] {
    const imageGroupById = new Map<string, ImageGroupOnAssetTimeline>();
    for (const [imageIndex, image] of this.filteredImages.entries()) {
      const [contextualDefect] = getRelatedFeatures(
        image,
        RelatedFeaturesGroup_RelatedFeatureRole.CONTEXTUAL_DEFECT,
      );
      if (contextualDefect) {
        const contextualDefectId = contextualDefect.id;
        if (!imageGroupById.has(contextualDefectId)) {
          imageGroupById.set(contextualDefectId, {
            displayLabel: this.galleryService.imageGroupLabelById.get(contextualDefectId)!,
            images: [],
            groupStartIndex: imageIndex,
            groupEndIndex: imageIndex,
          });
        }
        imageGroupById.get(contextualDefectId)!.images.push(image);
        imageGroupById.get(contextualDefectId)!.groupEndIndex = imageIndex;
      } else {
        if (!imageGroupById.has(UNGROUPED_IMAGES_ID)) {
          imageGroupById.set(UNGROUPED_IMAGES_ID, {
            displayLabel: UNGROUPED_IMAGES_LABEL_IN_SORT_BY_IMAGE_GROUP,
            images: [],
            groupStartIndex: imageIndex,
            groupEndIndex: imageIndex,
          });
        }
        imageGroupById.get(UNGROUPED_IMAGES_ID)!.images.push(image);
        imageGroupById.get(UNGROUPED_IMAGES_ID)!.groupEndIndex = imageIndex;
      }
    }
    return Array.from(imageGroupById.values());
  }

  private getCapturedTimeGroups(): ImageGroupOnAssetTimeline[] {
    const captureTimeGroupByLabel = new Map<string, ImageGroupOnAssetTimeline>();
    for (const [imageIndex, image] of this.filteredImages.entries()) {
      const takenOnTimestamp = getTakenOn(image);
      if (takenOnTimestamp) {
        const groupLabel = dateToDateStringWithMonthLevelFormat(takenOnTimestamp!);
        if (!captureTimeGroupByLabel.has(groupLabel)) {
          captureTimeGroupByLabel.set(groupLabel, {
            displayLabel: groupLabel,
            images: [],
            groupStartIndex: imageIndex,
            groupEndIndex: imageIndex,
          });
        }
        captureTimeGroupByLabel.get(groupLabel)!.images.push(image);
        captureTimeGroupByLabel.get(groupLabel)!.groupEndIndex = imageIndex;
      } else {
        if (!captureTimeGroupByLabel.has(UNGROUPED_IMAGES_ID)) {
          captureTimeGroupByLabel.set(UNGROUPED_IMAGES_ID, {
            displayLabel: UNGROUPED_IMAGES_LABEL_IN_SORT_BY_CAPTURED_TIME,
            images: [],
            groupStartIndex: imageIndex,
            groupEndIndex: imageIndex,
          });
        }
        captureTimeGroupByLabel.get(UNGROUPED_IMAGES_ID)!.images.push(image);
        captureTimeGroupByLabel.get(UNGROUPED_IMAGES_ID)!.groupEndIndex = imageIndex;
      }
    }
    return Array.from(captureTimeGroupByLabel.values());
  }
}

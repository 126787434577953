@if (image) {
  @if (!isThumbnail && !is360Image) {
    <div
      #imageContainer
      class="image-container"
      (mousedown)="dragStart($event)"
      (mouseup)="dragEnd()"
      (mousemove)="drag($event)"
      (mouseenter)="dragIn($event)"
      (wheel)="wheelEvent.next($event)"
      (dblclick)="zoomIn()">
      <ng-container>
        <!-- The image is being set using the background-image style on this div -->
        <div #image class="image" [ngStyle]="style"></div>
        <div
          #imageSvg
          class="image annotation-image-overlay"
          [ngClass]="{hidden: !isAnnotationsLayerOn}"
          [ngStyle]="annotationOverlayStyle"></div>
        <svg
          #annotationsOverlay
          class="svg-panel"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 700 700"
          preserveAspectRatio="xMinYMin meet">
          <style>
            rect {
              fill: transparent;
              stroke-width: 5;
              stroke: #ff3600;
            }
            .label-container {
              display: flex;
              flex-flow: row wrap;
              align-items: center;
            }
            .label-chip {
              align-items: center;
              background-color: white;
              border: 1px solid #dadce0;
              border-radius: 16px;
              cursor: default;
              display: inline-flex;
              font-size: 10px;
              font-weight: 700;
              justify-content: center;
              margin: 4px;
              padding: 4px 8px;
            }
            .annotation-label-container {
              background-color: white;
              border-radius: 8px;
              color: black;
              display: inline-block;
              font-family: Roboto, Arial, sans-serif;
              max-width: 170px;
              max-height: 550px;
              overflow: hidden;
            }
            .comment-container {
              display: -webkit-inline-box;
              font-size: 12px;
              font-weight: 300;
              margin: 4px 4px 6px;
              max-height: 96px;
              max-width: 150px;
              overflow: hidden;
              padding: 4px;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 7;
            }
            .user-container {
              display: -webkit-inline-box;
              color: gray;
              font-size: 10px;
              font-weight: 1400;
              margin-left: 4px;
              max-height: 40px;
              max-width: 150px;
              overflow: hidden;
              padding-left: 4px;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 7;
            }
          </style>
        </svg>
        <div class="annotation-actions">
          <button
            mat-flat-button
            class="action-button"
            color="primary"
            aria-label="Annotations toggle"
            i18n-aria-label
            [disabled]="!hasAnnotations"
            (click)="toggleAnnotations()">
            {{ isAnnotationsLayerOn ? 'Annotations Off' : 'Annotations On' }}
          </button>
          <mat-divider class="actions-divider" [vertical]="true"> </mat-divider>
          <button
            mat-flat-button
            class="action-button"
            color="primary"
            aria-label="Add or edit annotations"
            i18n-aria-label
            (click)="onEdit.next()">
            @if (!hasAnnotations) {
              <mat-icon>add_circle</mat-icon>
            }
            {{ hasAnnotations ? 'Add / Edit annotations' : 'Add annotations' }}
          </button>
        </div>
        <div class="zoom-actions">
          <button
            mat-icon-button
            class="zoom-action"
            (click)="zoomOut()"
            [disabled]="zoomLevel <= minZoomLevel"
            aria-label="Zoom out"
            i18n-aria-label
            matTooltip="Zoom out">
            <mat-icon>remove</mat-icon>
          </button>
          <button
            mat-icon-button
            class="zoom-action"
            (click)="resetZoomState()"
            [disabled]="zoomLevel <= minZoomLevel"
            aria-label="Reset zoom"
            i18n-aria-label
            matTooltip="Reset zoom">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button
            mat-icon-button
            class="zoom-action"
            (click)="zoomIn()"
            [disabled]="zoomLevel >= maxZoomLevel"
            aria-label="Zoom in"
            i18n-aria-label
            matTooltip="Zoom in">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </ng-container>
      <button
        mat-icon-button
        class="image-button nav-arrow left"
        (click)="previousPressed.emit()"
        aria-label="View previous photo"
        i18n-aria-label
        matTooltip="Previous">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button
        mat-icon-button
        class="image-button nav-arrow right"
        (click)="nextPressed.emit()"
        aria-label="View next photo"
        i18n-aria-label
        matTooltip="Next">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  }
  <!--  <asset-details-vr-photos *ngIf="!isThumbnail && is360Image" [imageUrl]="url360">-->
  <!--  </asset-details-vr-photos>-->
  <!-- Thumbnail-specific -->
  @if (isThumbnail) {
    <div #imageContainer class="image-container" (click)="imagePressed.emit()">
      <!-- The image is being set using the background-image style on this div -->
      <div #image class="image" [ngStyle]="style"></div>
      <div class="edit-label-container" (click)="$event.stopPropagation()">
        <div class="left-group">
          <mat-icon class="label" [matTooltip]="'Annotation count'"> label_outline </mat-icon>
          <div>
            {{
              !thumbnailMetadata || thumbnailMetadata === null
                ? '-'
                : thumbnailMetadata.annotationCount
            }}
          </div>
          <mat-divider [vertical]="true" class="divider"></mat-divider>
        </div>
        <button
          mat-icon-button
          class="edit"
          (click)="onEdit.next()"
          aria-label="Edit"
          i18n-aria-label>
          Edit
        </button>
      </div>
      @if (thumbnailMetadata) {
        <div class="image-count-container" (click)="$event.stopPropagation()">
          <mat-icon class="arrow" [matTooltip]="'Previous image'" (click)="previousPressed.emit()">
            arrow_left
          </mat-icon>
          @if (thumbnailMetadata && thumbnailMetadata.totalImageCount) {
            <div class="image-count">
              <!-- The one is being added to the index of the image so that 0/4 shows up as 1/4 and so
            on. -->
              {{ thumbnailMetadata!.selectedImageIndex + 1 }} /
              {{ thumbnailMetadata!.totalImageCount || 1 }}
            </div>
          }
          <mat-icon class="arrow" [matTooltip]="'Next image'" (click)="nextPressed.emit()">
            arrow_right
          </mat-icon>
        </div>
      }
    </div>
  }
  <!-- End thumbnail-specific -->
}

@if (loading) {
  <div class="loading">
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="20"></mat-progress-spinner>
  </div>
} @else {
  <mat-form-field class="input-textarea" appearance="fill" floatLabel="auto">
    <textarea
      matInput
      [(ngModel)]="commentText"
      placeholder="Add a comment"
      [maxlength]="commentMaxLength"
      rows="1">
    </textarea>
  </mat-form-field>
  <div class="comment-actions">
    <button class="cancel-button" mat-button (click)="clearComment()">Cancel</button>
    <button color="primary" mat-button (click)="leaveComment()">Comment</button>
  </div>
}

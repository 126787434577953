<div class="banner-container" [ngClass]="{warning: isWarning(), announcement: !isWarning()}">
  <div class="banner-message">
    @if (isWarning()) {
      <mat-icon class="banner-icon"> warning </mat-icon>
    }
    <span class="banner-text">{{ message }}</span>
  </div>
  <div class="banner-actions">
    @if (isWarning()) {
      <button
        mat-icon-button
        class="dismiss-button"
        aria-label="Dismiss banner"
        i18n-aria-label
        (click)="dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    }
    @if (hasLink()) {
      <div>
        <a class="action-link" role="button" (click)="navigateTo(link)">
          {{ linkLabel }}
        </a>
      </div>
    }
  </div>
</div>

import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

/**
 * Snackbar's config.
 */
declare interface SnackbarData {
  message: string;
  category: MessageCategory;
  linkLabel: string;
  link: string;
}

/**
 * Category of message conveyed in the snack bar.
 */
export enum MessageCategory {
  SUCCESS,
  FAILURE,
  INFO,
}

/**
 * Snackbar with configurable action link.
 */
@Component({
  selector: 'navigation-snackbar',
  templateUrl: './navigation_snackbar.ng.html',
  styleUrls: ['./navigation_snackbar.scss'],
})
export class NavigationSnackBar {
  // Alias to the enum.
  readonly Category = MessageCategory;

  constructor(
    private readonly router: Router,
    readonly snackBarRef: MatSnackBarRef<NavigationSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) readonly snackbarData: SnackbarData,
  ) {}

  navigateTo(link: string) {
    this.router.navigate([link]);
    this.snackBarRef.dismiss();
  }
}

// Angular modules.
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

// Component.
import {ImageThumbnail} from './image_thumbnail';

@NgModule({
  declarations: [ImageThumbnail],
  imports: [CommonModule, MatIconModule],
  exports: [ImageThumbnail],
})
export class ImageThumbnailModule {}

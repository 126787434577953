import {Subject} from 'rxjs';

import {Injectable, NgZone, OnDestroy} from '@angular/core';

/**
 * Service that listens to global keyboard events that consumers
 * can subscribe to.
 */
@Injectable({providedIn: 'root'})
export class KeyboardService implements OnDestroy {
  private readonly keydownSubject = new Subject<KeyboardEvent>();
  private readonly keyupSubject = new Subject<KeyboardEvent>();

  keydown = this.keydownSubject.asObservable();
  keyup = this.keyupSubject.asObservable();

  constructor(private readonly ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('keydown', this.onKeydown.bind(this));
      document.addEventListener('keyup', this.onKeyup.bind(this));
    });
  }

  private onKeydown(event: KeyboardEvent) {
    this.ngZone.run(() => {
      this.keydownSubject.next(event);
    });
  }

  private onKeyup(event: KeyboardEvent) {
    this.ngZone.run(() => {
      this.keyupSubject.next(event);
    });
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.onKeydown.bind(this));
    document.removeEventListener('keyup', this.onKeyup.bind(this));
  }
}

<div class="views">
  <mat-expansion-panel
    class="mat-elevation-z0"
    [expanded]="isPanelExpanded"
    [hideToggle]="savedViews.length === 0"
    [ngClass]="{'disable-toggle': savedViews.length === 0}"
    (opened)="updateOpenState(true)"
    (closed)="updateOpenState(false)">
    <mat-expansion-panel-header>
      <mat-panel-title class="title">Views</mat-panel-title>
      <mat-panel-description class="text">
        <div class="info">
          <div class="layers-info">{{ layersCount }} layer{{ layersCount === 1 ? '' : 's' }}</div>
          <div class="filters-info">
            {{ filtersCount }} filter{{ filtersCount === 1 ? '' : 's' }}
          </div>
        </div>
        <button
          mat-stroked-button
          color="accent"
          class="action-button clear-button"
          [disabled]="!canCreateView()"
          (click)="clearAllFilters($event)">
          Reset
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    @if (loading) {
      <div class="spinner-container">
        <mat-progress-spinner class="spinner" mode="indeterminate" color="primary" diameter="24">
        </mat-progress-spinner>
      </div>
    }
    <ng-container>
      <div class="filter-control-container">
        <button
          mat-flat-button
          class="action-button create-button"
          color="primary"
          [disabled]="!canCreateView()"
          (click)="openCreateViewDialog($event)">
          <mat-icon>add_circle_outline</mat-icon>
          Create view
        </button>
        <span class="views-count">
          <ng-container [ngPlural]="savedViews.length">
            <ng-template ngPluralCase="=1"> {{ savedViews.length }} view </ng-template>
            <ng-template ngPluralCase="other"> {{ savedViews.length }} views </ng-template>
          </ng-container>
        </span>
      </div>
      @if (loading || savedViews.length > 0) {
        <mat-list>
          @for (savedView of savedViews; track savedView) {
            <mat-list-item
              class="saved-view-item"
              [class.selected-view]="savedView.id === selectedViewId">
              <a matListItemTitle class="view-link" (click)="goToSavedView(savedView)">
                {{ savedView.displayName }}
              </a>
              <button
                matListItemMeta
                mat-icon-button
                class="menu-button"
                matTooltip="Menu"
                [matMenuTriggerFor]="menu">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="showOverlay = !showOverlay; overlayFor = savedView.id"
                  type="button"
                  cdkOverlayOrigin
                  #origin="cdkOverlayOrigin">
                  <span>Share</span>
                </button>
                <!-- Share link overlay connected to the 'Share' button. -->
                <!-- View ID check is required to make sure overlay only opens for the item share was called on. -->
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="origin"
                  [cdkConnectedOverlayOpen]="showOverlay && overlayFor == savedView.id"
                  (overlayOutsideClick)="showOverlay = false">
                  <share-card [value]="getViewLink(savedView.id)"></share-card>
                </ng-template>
                <button
                  mat-menu-item
                  [disabled]="!canDeleteView(savedView)"
                  (click)="openDeleteViewDialog(savedView)">
                  <span>Delete</span>
                </button>
              </mat-menu>
            </mat-list-item>
          }
        </mat-list>
      }
    </ng-container>
  </mat-expansion-panel>
</div>

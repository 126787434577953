import {EMPTY, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';

import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';

import {FEATURE_PARAM_KEY, LAYER_PARAM_KEY, ROUTE} from '../constants/paths';
import {MapService} from '../services/map_service';

/**
 * Component that allows to switch from map to table view.
 */
@Component({
  selector: 'map-table-toggle',
  templateUrl: './map_table_toggle.ng.html',
  styleUrls: ['./map_table_toggle.scss'],
})
export class MapTableToggle implements OnDestroy {
  private featureId = '';
  private layerId = '';
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly mapService: MapService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        // Navigation has already finished on initial load, trigger
        // to run once (for the case when user goes directly to e.g.
        // ../map/<layerID>/<featureID>).
        startWith(undefined),
        switchMap(() => this.route.firstChild?.paramMap ?? EMPTY),
        distinctUntilChanged(),
        map((paramMap: ParamMap): string[] => [
          paramMap.get(FEATURE_PARAM_KEY) || '',
          paramMap.get(LAYER_PARAM_KEY) || '',
        ]),
        takeUntil(this.destroyed),
      )
      .subscribe(([featureId, layerId]) => {
        this.featureId = featureId;
        this.layerId = layerId;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  navigateToMap() {
    if (this.featureId && this.layerId) {
      this.mapService.setShouldRepositionMap(true);
      this.router.navigate([ROUTE.MAP, this.layerId, this.featureId]);
    } else {
      this.router.navigateByUrl(ROUTE.MAP);
    }
  }

  navigateToTable() {
    if (this.featureId && this.layerId) {
      this.router.navigate([ROUTE.TABLE, this.layerId, this.featureId]);
    } else {
      this.router.navigateByUrl(ROUTE.TABLE);
    }
  }

  isOnMapPage(): boolean {
    return this.router.url.startsWith(ROUTE.MAP);
  }

  isOnTablePage(): boolean {
    return this.router.url.startsWith(ROUTE.TABLE);
  }
}

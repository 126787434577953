@if (layerType === layerTypes.IMAGES) {
  <div class="container">
    <div class="legend-item">
      <img src="assets/images/image_marker.svg" class="image-icon" alt />
      <span class="layer-name">Image</span>
    </div>
    <div class="legend-item">
      <img src="assets/images/assoc-layer-icon.svg" class="image-icon" alt />
      <span class="layer-name">Link to tagged asset</span>
    </div>
  </div>
}
@if (layerName === 'Districts') {
  <div class="container">
    <div class="legend-item">
      <img src="assets/images/district-layer-icon.svg" class="legend-icon" alt />
      <span class="layer-name">Boundary</span>
    </div>
  </div>
}

@switch (entry.type) {
  @case (TimelineEntryType.COMMENT) {
    <div class="non-feature-entry">
      {{ formatComment(entry) }}
    </div>
  }
  @case (TimelineEntryType.IMAGE) {
    <div class="non-feature-entry">
      <!-- The following line is not wrapped since doing so introduces the extra space in link. -->
      <!-- TODO(halinab): investigate why. -->
      <a role="button" class="timeline-nav" (click)="goToFeature()">{{
        formatImageGroup(entry)
      }}</a>
      uploaded by {{ getAuthor(entry) }}
    </div>
  }
  @case (TimelineEntryType.RELATED_FEATURE) {
    <div class="feature-entry">
      <!-- The following line is not wrapped since doing so introduces the extra space in link. -->
      <!-- TODO(halinab): investigate why. -->
      <a role="button" class="timeline-nav" (click)="goToFeature()">{{ formatFeature(entry) }}</a>
      {{ formatActionVerb() }}
    </div>
  }
  @default {
    <div>Related feature updated</div>
  }
}

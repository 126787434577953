<h1 mat-dialog-title class="header-text">Update location</h1>
<mat-dialog-content>
  <div class="subheader-text">
    We could not determine the location for this image group. Please select an asset to continue
    uploading images.
  </div>
  <div class="divider"></div>
  <feature-selection-map
    [locationDescriptionVisible]="locationDescriptionVisible"
    [layerIds]="data.layerIds"
    (featureSelected)="updateFeatureSelection($event)"
    (locationPinChanged)="updateLocationOfPin($event)">
  </feature-selection-map>
</mat-dialog-content>
<mat-dialog-actions class="actions upload-all" align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="uploadAll()" [disabled]="!selectedFeature && !locationOfPin">
    Upload all
  </button>
</mat-dialog-actions>

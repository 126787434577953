import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {RouterModule} from '@angular/router';

import {AnnotationFiltersModule} from '../annotation_filters/annotation_filters_module';
import {MatchingResultModule} from '../matching_result/matching_result_module';
import {PipesModule} from '../pipes/pipes_module';
import {PropertyFiltersModule} from '../property_filters/property_filters_module';
import {LayerFilters} from './layer_filters';

@NgModule({
  declarations: [LayerFilters],
  exports: [LayerFilters],
  imports: [
    AnnotationFiltersModule,
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    PipesModule,
    PropertyFiltersModule,
    RouterModule,
    MatchingResultModule,
  ],
})
export class LayerFiltersModule {}

import {Observable, Subject, Subscription} from 'rxjs';

import {ComponentRef, Injectable, ViewContainerRef} from '@angular/core';

import {FileViewer} from '../file_viewer/file_viewer';

/**
 * Service for injecting the file viewer into the app root and communicating
 * events.
 */
@Injectable({providedIn: 'root'})
export class FileViewerService {
  private root: ViewContainerRef | null = null;
  private componentRef: ComponentRef<FileViewer> | null = null;
  private fileDeletionUpdates = new Subject<File[]>();
  private subscriptions: Subscription[] = [];

  setRoot(root: ViewContainerRef) {
    this.root = root;
  }

  open(files: File[], activeIndex = 0) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.fileDeletionUpdates) {
      this.fileDeletionUpdates.complete();
    }
    this.fileDeletionUpdates = new Subject<File[]>();
    this.componentRef = this.root!.createComponent(FileViewer);
    this.componentRef.instance.files = files;
    this.componentRef.instance.index = activeIndex;
    this.attachEventListeners(this.componentRef);
  }

  onFileDeleted(): Observable<File[]> {
    return this.fileDeletionUpdates.asObservable();
  }

  private attachEventListeners(compRef: ComponentRef<FileViewer>) {
    this.subscriptions.push(
      compRef.instance.fileViewerClosed.subscribe(() => {
        for (const subscription of this.subscriptions) {
          subscription.unsubscribe();
        }
        this.root!.remove(this.root!.indexOf(compRef.hostView));
        this.fileDeletionUpdates.complete();
        this.subscriptions = [];
      }),
    );
    this.subscriptions.push(
      compRef.instance.fileDeleted.subscribe((files: File[]) => {
        this.fileDeletionUpdates.next(files);
      }),
    );
  }
}

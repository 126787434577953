import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, Subject, forkJoin, merge, of} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {ROUTE} from '../../constants/paths';
import {InspectionStatus, PropertyKey} from '../../constants/properties';
import {FeaturesService} from '../../services/features_service';
import {LocalStorageService} from '../../services/local_storage_service';
import {PendingUploadService} from '../../services/pending_upload_service';
import {
  FieldType,
  GlobalFormPropertyName,
  PendingFile,
  type UploadFormTemplate,
} from '../../typings/upload';
import {
  existingPropertiesToFormProperties,
  getAllRelatedFeatures,
  getPropertyValue,
  hasAnyProperty,
  setOrAddPropertyValue,
} from '../../utils/feature';
import {
  IMAGE_WIDTH_PX,
  buildImageUrlWithOptions,
  buildPendingImageID,
  defaultImageUrlOptions,
} from '../../utils/image';
import {type InitialMapMetadata} from '../feature_selection_map/feature_selection_map';
import {type UploadFormResponses} from './forms';

/**
 * Reusable upload form component.
 */
@Component({
  selector: 'old-upload-form',
  templateUrl: './old_upload_form.ng.html',
  styleUrls: ['./old_upload_form.scss'],
})
export class OldUploadForm {
  // The template that should be used for building the form.
  @Input() formTemplate!: UploadFormTemplate;
  // The initial location for the map's center and asset to select.
  @Input() initialMapMetadata: InitialMapMetadata | null = null;
  // Whether the site is in offline mode.
  @Input() offline = true;
  // Whether the page should initiate in editing mode.
  @Input() editing = false;
  // Initial form data for editing.
  @Input() initialFormData: UploadFormResponses | null = null;
  @Input() layerId = '';
  @Input() mapSelectionLayerId = '';
  @Input() locationDescription = '';
  // Whether the pending state (unsubmitted files and annotations) should be
  // cleared up or restored on load. Defaults to clear state unless explicitly
  // instructed otherwise.
  @Input() preserveState = false;
  // URL param options supported by go/fife-urls#url-options and go/gmrs.
  // Sizes requested may be larger than container for better image quality.
  @Input() imageUrlOptions = {...defaultImageUrlOptions, width: IMAGE_WIDTH_PX};

  @Output() submit = new EventEmitter<UploadFormResponses>();

  @ViewChild('photoSelector', {static: false}) photoSelector!: ElementRef;
  @ViewChild(NgForm, {static: true}) form!: NgForm;

  readonly FieldType = FieldType;

  // The location of the map pin if one exists.
  selectedMapLocation: LatLng | null = null;

  pendingFiles: PendingFile[] = [];
  tags = new Set<string>();
  existingImages: Image[] = [];
  imageIds: string[] = [];
  imagesToDelete: Image[] = [];
  externalId = '';
  location: LatLng | null = null;
  formattedLocation = '';
  relatedFeature: Feature | null = null;
  properties: Record<string, string> = {};
  // Used in the template.
  globalPropertyName = GlobalFormPropertyName;
  // Holds the form's various checkboxes. Consider using this pattern for all
  // form elements.
  formGroupByPropertyName = new Map<string, UntypedFormGroup>();
  existingProperties: Property[] = [];
  // Features that are related to the primary associated feature that match form
  // specified key-value properties.
  propertyLookupFeatures: Feature[] = [];
  // The field name associated with the PROPERTY_LOOKUP field type. Assumes
  // only a single PROPERTY_LOOKUP field.
  propertiesToLookupPropertyName = '';
  // Form-specified key-value properties to search.
  propertiesToLookup: Property[] = [];
  // The pipe that handles fetching related features and setting
  // propertyLookupFeatures that match specified propertiesToLookup for a given
  // form.
  private readonly fetchAndRenderFeatures = new Subject<Array<Observable<Feature | null>>>();
  // Keeps track of the layer ID for each related feature which is needed for
  // navigation to said layer. Assumes that a feature is only part of a single
  // layer.
  private readonly layerIdByFeatureId = new Map<string, string>();

  // Id <-> URL map of images to serve.
  imageUrlsById = new Map<string, string>();
  propertyLookupFeaturesAreLoading = false;
  private readonly destroyed = new Subject<void>();

  trackByKey = (index: number, property: Property): string => {
    return property.key;
  };

  constructor(
    private readonly featureService: FeaturesService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly localStorageService: LocalStorageService,
    private readonly pendingUploadService: PendingUploadService,
  ) {}

  ngOnInit() {
    this.initFetchAndRenderFeatures();
    this.existingImages = this.initialFormData?.existingImages
      ? [...this.initialFormData.existingImages]
      : [];
    this.externalId = this.initialFormData?.externalId || '';
    this.formGroupByPropertyName = this.createCheckboxFormGroups();
    this.setExistingCheckboxFormGroups(
      this.formGroupByPropertyName,
      this.initialFormData?.extraProperties || [],
    );
    this.tags = this.getTags();
    this.properties = this.getFormData();
    this.setPropertiesToLookup();
    this.assignRelatedAsset();
    this.pendingUploadService
      .getAllPendingImages()
      .pipe(takeUntil(this.destroyed))
      .subscribe((pendingFiles: PendingFile[]) => {
        this.pendingFiles = pendingFiles;
      });
    this.imageIds = this.existingImages.map((image) => image.id);

    const urls = this.existingImages.map(
      (image: Image): Observable<[string, string]> =>
        buildImageUrlWithOptions(image, this.imageUrlOptions).pipe(
          map((url: string): [string, string] => [image.id, url]),
        ),
    );
    forkJoin(urls)
      .pipe(defaultIfEmpty([]), takeUntil(this.destroyed))
      .subscribe((values: [string, string][]) => {
        this.imageUrlsById = new Map(values);
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * Saves form pending state and prevents form from submitting when enter key
   * is pressed.
   */
  onFormKeyPress(event: KeyboardEvent) {
    this.pendingUploadService.setPendingDataState(this.properties);
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  deletePendingFile(pendingFile: PendingFile) {
    this.pendingUploadService.deletePendingImage(pendingFile.id);
  }

  queueImageDeletion(image: Image) {
    if (this.imagesToDelete) {
      this.imagesToDelete.push(image);
    }
    if (this.existingImages) {
      this.existingImages = this.existingImages.filter(
        (currentImage: Image) => currentImage.id !== image.id,
      );
    }
  }

  isFormValid(edit: boolean): boolean {
    if (this.offline) {
      return !!this.form.valid && this.pendingFiles.length > 0;
    }
    const valid = !!this.form.valid && this.areRequiredFieldsFound();
    return edit ? valid : valid && this.pendingFiles.length > 0;
  }

  // Returns true if map and tags are specified as required and found.
  // Limitations: right now we only support a single map and tag component on
  // each form; therefore, filtering by the FieldType that matches and
  // verifying its required property is adequate. This solution will not scale
  // to multiple map or tag components on a single form and will need to be
  // reworked.
  areRequiredFieldsFound(): boolean {
    const requiredByFieldType = new Map<FieldType, boolean>([
      [FieldType.MAP, false],
      [FieldType.TAGS, false],
    ]);
    for (const sections of this.formTemplate.sections) {
      for (const field of sections.fields) {
        if (!requiredByFieldType.has(field.type)) {
          continue;
        }
        requiredByFieldType.set(field.type, field.required || false);
      }
    }
    if (
      requiredByFieldType.get(FieldType.MAP) &&
      !this.relatedFeature &&
      !this.location?.latitude &&
      !this.location?.longitude
    ) {
      return false;
    }
    if (requiredByFieldType.get(FieldType.TAGS) && this.tags.size === 0) {
      return false;
    }
    return true;
  }

  /**
   * Simulate a click on the actual file selector element.
   */
  selectPhoto() {
    this.photoSelector.nativeElement.value = '';
    this.photoSelector.nativeElement.click();
  }

  /**
   * Retrieves the selected file(s).
   */
  getFile(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = target.files;
    if (!fileList) {
      return;
    }
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        const imageId = buildPendingImageID();
        const dataUrl = fileReader.result as string;
        const pendingImageInfo = {
          file,
          url: dataUrl,
          id: imageId,
        };
        this.pendingUploadService.addPendingImage(imageId, pendingImageInfo);
      });
      fileReader.addEventListener('error', () => {
        alert(`Could not upload image ${file.name}`);
      });
      fileReader.readAsDataURL(file);
    }
  }

  onTagsUpdated(newTags: Set<string>) {
    this.tags = newTags;
    this.pendingUploadService.setPendingTags(newTags);
  }

  onMaterialIdsUpdated(newMaterialIds: string[]) {
    this.updateProperty(PropertyKey.MATERIAL_IDS, newMaterialIds.join(', '));
  }

  submitForm() {
    this.writePreviousTagsOfForm();
    const response: UploadFormResponses = {
      pendingFiles: this.pendingFiles,
      tags: this.tags,
      location: this.location!,
      asset: this.relatedFeature,
      externalId: this.externalId,
      extraProperties: this.buildExtraProperties(),
      existingImages: this.existingImages,
      imagesToDelete: this.imagesToDelete,
    };
    this.submit.next(response);
    this.pendingUploadService.clearPendingState();
  }

  /**
   * Saves the currently entered tags as the previous tags of this form.
   * If this form is a new upload, all entered tags a saved.
   * If this form is opened for editing, we exclude the initial tags.
   * If this form is inspected, the entered tags are not saved.
   */
  writePreviousTagsOfForm() {
    if (!this.editing) {
      this.localStorageService.writePreviousTagsOfForm(this.formTemplate.name, [...this.tags]);
      return;
    }
    if (!this.isFormInspected()) {
      const initialTags = this.initialFormData?.tags || new Set();
      const previousTags = new Set([...this.tags].filter((tag: string) => !initialTags.has(tag)));
      this.localStorageService.writePreviousTagsOfForm(this.formTemplate.name, [...previousTags]);
    }
  }

  buildExtraProperties(): Property[] {
    const properties: Property[] = [];
    properties.push(
      new Property({
        key: GlobalFormPropertyName.FORM_TYPE,
        propertyValue: {case: 'value', value: this.formTemplate.name},
      }),
    );

    for (const [propertyKey, propertyValue] of Object.entries(this.properties)) {
      properties.push(
        new Property({
          key: propertyKey,
          propertyValue: {case: 'value', value: propertyValue},
        }),
      );
    }
    properties.push(...this.formGroupToProperties(this.formGroupByPropertyName));
    if (this.propertiesToLookup.length > 0) {
      const lookupProperties = this.mergePropertyLookupText(this.propertiesToLookup);
      const lookupFeatureNames = this.propertyLookupFeatures.map(
        (feature: Feature) => feature.name,
      );
      const propertyValue = `${lookupProperties.join(', ')}: ${lookupFeatureNames.join(', ')} `;
      properties.push(
        new Property({
          key: this.propertiesToLookupPropertyName,
          propertyValue: {case: 'value', value: propertyValue},
        }),
      );
    }
    return setOrAddPropertyValue(
      GlobalFormPropertyName.INSPECTION_STATUS,
      InspectionStatus.INSPECTED,
      properties,
    );
  }

  initFetchAndRenderFeatures() {
    this.fetchAndRenderFeatures
      .pipe(
        switchMap((features$: Array<Observable<Feature | null>>) => {
          this.propertyLookupFeaturesAreLoading = true;
          return merge(...features$).pipe(
            catchError((error: Error) => {
              console.error(`An error occurred fetching feature: ${error}`);
              return of(null);
            }),
            finalize(() => {
              this.propertyLookupFeaturesAreLoading = false;
            }),
          );
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((feature: Feature | null) => {
        if (!feature || !hasAnyProperty(feature, this.propertiesToLookup)) {
          return;
        }
        this.propertyLookupFeatures.push(feature);
      });
  }

  private createCheckboxFormGroups(): Map<string, UntypedFormGroup> {
    const formGroupByPropertyName = new Map<string, UntypedFormGroup>();
    for (const section of this.formTemplate.sections) {
      for (const field of section.fields) {
        if (field.type === FieldType.CHECKBOX) {
          if (!field.options) {
            console.error('Field options missing from FieldType.CHECKBOX');
            continue;
          }
          const options = field.options.reduce(
            (previous: Record<string, boolean>, current: string) => {
              previous[current] = false;
              return previous;
            },
            {},
          );
          formGroupByPropertyName.set(field.propertyName, this.formBuilder.group(options));
        }
      }
    }
    return formGroupByPropertyName;
  }

  private setExistingCheckboxFormGroups(
    formGroupByPropertyName: Map<string, UntypedFormGroup>,
    existingProperties: Property[],
  ) {
    for (const property of existingProperties) {
      if (!formGroupByPropertyName.has(property.key) || property.propertyValue.case !== 'value') {
        continue;
      }
      formGroupByPropertyName.get(property.key)!.patchValue({[property.propertyValue.value]: true});
    }
  }

  private formGroupToProperties(
    formGroupByPropertyName: Map<string, UntypedFormGroup>,
  ): Property[] {
    const properties: Property[] = [];
    for (const [propertyName, formGroup] of formGroupByPropertyName) {
      for (const [value, isTrue] of Object.entries(formGroup.value)) {
        if (!isTrue) {
          continue;
        }
        properties.push(
          new Property({
            key: propertyName,
            propertyValue: {case: 'value', value},
          }),
        );
      }
    }
    return properties;
  }

  private assignRelatedAsset() {
    const initialAsset = this.initialFormData?.asset ? this.initialFormData.asset.clone() : null;
    const unsavedAsset = this.preserveState ? this.pendingUploadService.getPendingAsset() : null;
    const initialLocation = this.initialFormData?.location
      ? this.initialFormData.location.clone()
      : null;
    const unsavedLocation = this.preserveState
      ? this.pendingUploadService.getPendingLocation()
      : null;
    this.relatedFeature = unsavedAsset || initialAsset;
    this.location = unsavedLocation || initialLocation;
    this.externalId = this.initialFormData?.externalId || '';

    if (this.relatedFeature || this.location) {
      let mapLocation = null;
      if (this.relatedFeature?.geometry?.geometry.case === 'point') {
        mapLocation = this.relatedFeature
          ? this.relatedFeature.geometry?.geometry.value.location
          : this.location;
      }
      if (mapLocation) {
        this.initialMapMetadata = {
          featureId: this.relatedFeature?.id || '',
          center: mapLocation,
        };
      }
    }
  }

  private getFormData(): Record<string, string> {
    const initialData = this.getInitialFormData();
    const unsavedData = this.pendingUploadService.getPendingDataState();
    if (!this.preserveState || Object.keys(unsavedData).length === 0) {
      return initialData;
    }
    return unsavedData;
  }

  private getInitialFormData(): Record<string, string> {
    return this.initialFormData?.extraProperties
      ? existingPropertiesToFormProperties(this.initialFormData.extraProperties, this.formTemplate)
      : {};
  }

  private getTags(): Set<string> {
    const initialTags = this.getInitialFormTags();
    const unsavedTags = this.pendingUploadService.getPendingTags();
    if (!this.preserveState || unsavedTags === null) {
      return initialTags;
    }
    return unsavedTags;
  }

  /**
   * Returns tags of the form.
   * If this a new upload, the tags are loaded from the local storage.
   * If this form is inspected, the tags are from the response (image group).
   * If this form is not inspected, the tags are combined: from the response
   * and from the local storage.
   */
  getInitialFormTags(): Set<string> {
    const previousTags = this.getPreviousTagsOfForm();
    if (!this.editing) {
      return previousTags;
    }
    if (this.isFormInspected()) {
      return new Set(this.initialFormData?.tags || []);
    }
    return new Set([...(this.initialFormData?.tags || []), ...previousTags]);
  }

  private getPreviousTagsOfForm(): Set<string> {
    const templateInitialTags = this.formTemplate.initialTags;
    const defaultTagsOfForm = this.localStorageService.readDefaultTagsOfForms(
      this.formTemplate.name,
    );

    const tagsAreEqual =
      templateInitialTags.length === defaultTagsOfForm?.length &&
      templateInitialTags.every((tag: string) => defaultTagsOfForm.includes(tag));

    if (!tagsAreEqual) {
      this.localStorageService.writeDefaultTagsOfForm(this.formTemplate.name, templateInitialTags);
      return new Set(templateInitialTags);
    }
    const previousTagsOfForm = this.localStorageService.readPreviousTagsOfForms(
      this.formTemplate.name,
    );
    return new Set(previousTagsOfForm || this.formTemplate.initialTags);
  }

  private isFormInspected() {
    const inspectionStatus = getPropertyValue(
      GlobalFormPropertyName.INSPECTION_STATUS,
      this.initialFormData?.extraProperties || [],
    );
    return inspectionStatus === InspectionStatus.INSPECTED;
  }

  setPropertyLookupFeatures(feature: Feature | null) {
    this.propertyLookupFeatures = [];
    if (!feature || this.propertiesToLookup.length === 0) {
      return;
    }
    const relatedFeatures = getAllRelatedFeatures(feature);
    const features$: Array<Observable<Feature | null>> = [];
    for (const relatedFeature of relatedFeatures) {
      this.layerIdByFeatureId.set(relatedFeature.id, relatedFeature.layerId);
      features$.push(
        this.featureService
          .getFeature(relatedFeature.layerId, relatedFeature.id, false)
          .pipe(take(1)),
      );
    }
    this.fetchAndRenderFeatures.next(features$);
  }

  // Assumes that there is only a single property-lookup section for a given
  // form.
  setPropertiesToLookup() {
    this.propertiesToLookup = [];
    for (const sections of this.formTemplate.sections) {
      for (const field of sections.fields) {
        if (field.type === FieldType.PROPERTY_LOOKUP) {
          this.propertiesToLookup.push(...field.propertiesToLookup!);
          this.propertiesToLookupPropertyName = field.propertyName;
          return;
        }
      }
    }
  }

  displayPropertyLookup(properties: Property[]): string {
    if (properties.length === 0) {
      return '';
    }
    const propertyKeyValues = this.mergePropertyLookupText(properties);
    return `We found the following related features with properties: ${propertyKeyValues.join(
      ', ',
    )}`;
  }

  mergePropertyLookupText(properties: Property[]): string[] {
    // May need to potentially support property types that are not strings at
    // some point (eg, dates).
    const valuesByKey = new Map<string, string[]>();
    for (const property of properties) {
      if (property.propertyValue.case !== 'value') {
        continue;
      }
      const propertyValues = valuesByKey.get(property.key) || [];
      propertyValues.push(property.propertyValue.value);
      valuesByKey.set(property.key, propertyValues);
    }
    const propertyKeyValues: string[] = [];
    for (const [key, values] of valuesByKey) {
      propertyKeyValues.push(`"${key}:  ${values.join(', ')}"`);
    }
    return propertyKeyValues;
  }

  updateProperty(propertyName: string, propertyValue: string) {
    this.properties = {...this.properties, [propertyName]: propertyValue};
    this.pendingUploadService.setPendingDataState(this.properties);
  }

  createMapFeaturePath(feature: Feature): string {
    return `${ROUTE.MAP}/${this.layerIdByFeatureId.get(feature.id)}/${feature.id}`;
  }

  onFeatureSelected(selectedFeature: Feature) {
    this.relatedFeature = selectedFeature;
    this.setPropertyLookupFeatures(selectedFeature);
    this.externalId = selectedFeature.externalId;
    this.pendingUploadService.setPendingAsset(selectedFeature);
  }

  onLocationPinChanged(newLocation: LatLng) {
    if (
      this.location?.latitude !== newLocation?.latitude ||
      this.location?.longitude !== newLocation?.longitude
    ) {
      this.pendingUploadService.setPendingLocation(newLocation);
      this.location = newLocation;
    }
  }
}

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {FilterField} from '../constants/filters';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {AssetImageSourceOption} from '../typings/asset_filter';
import {FilterMap} from '../typings/filter';

/**
 * Represents Asset Image filter checkbox.
 */
export interface AssetImageOption {
  name: string;
  selected: boolean;
  label: string;
  iconSrc: string;
  alt: string;
}

/**
 * Component that allows asset filtering based on the types of images associated with the asset.
 */
@Component({
  selector: 'asset-image-filters',
  templateUrl: './asset_image_filters.ng.html',
  styleUrls: ['./asset_image_filters.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetImageFilters implements OnInit, OnDestroy {
  @Input() layerId = '';
  @Output() readonly editingChange = new EventEmitter<boolean>();
  loading = true;
  editing = false;
  private filterMap: FilterMap = {};
  private readonly optionsByFilterName = new BehaviorSubject<string[]>([]);

  options: AssetImageOption[] = [
    {
      name: AssetImageSourceOption.NO_IMAGES,
      selected: true,
      label: 'Without images',
      iconSrc: 'assets/images/asset_no_images.svg',
      alt: 'Asset without images',
    },
    {
      name: AssetImageSourceOption.USER_IMAGES_ONLY,
      selected: true,
      label: 'With User images',
      iconSrc: 'assets/images/asset_user_images.svg',
      alt: 'Asset with only user created images',
    },
    {
      name: AssetImageSourceOption.TAPESTRY_IMAGES_ONLY,
      selected: true,
      label: 'With Sensor Collected images',
      iconSrc: 'assets/images/asset_sov_images.svg',
      alt: 'Asset with sensor collected images',
    },
    {
      name: AssetImageSourceOption.USER_AND_TAPESTRY_IMAGES,
      selected: true,
      label: 'With User and Sensor Collected images',
      iconSrc: 'assets/images/asset_sov_user_images.svg',
      alt: 'Asset with both user and sensor created images',
    },
  ];

  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly configService: ConfigService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly layersFilterService: LayersFilterService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    // Update the UI based on the current image associated filters.
    this.layersFilterService
      .getFilterMap(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((filters: FilterMap) => {
        this.filterMap = structuredClone(filters);
        this.setInitFilter(this.filterMap[FilterField.IMAGE_ASSOCIATION_FILTER]);
        this.changeDetectionRef.markForCheck();
      });
  }

  private setInitFilter(imageFilters: Set<string>) {
    // When image filters are reset, then select all options.
    // This works as "no filters - show everything".
    if (imageFilters === undefined) {
      this.options.forEach((option: AssetImageOption) => {
        option.selected = true;
      });
      return;
    }
    if (imageFilters.size > 0) {
      this.options
        .filter((option: AssetImageOption) => !imageFilters.has(option.name))
        .forEach((option: AssetImageOption) => {
          option.selected = false;
        });
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onSelectedOptionsChanged() {
    this.analyticsService.sendEvent(EventActionType.IMAGE_ASSOCIATION_FILTER_ADD, {
      event_category: EventCategoryType.MAP,
      event_label: FilterField.IMAGE_ASSOCIATION_FILTER,
    });
    this.cancelEditing();

    this.filterMap[FilterField.IMAGE_ASSOCIATION_FILTER] = this.composeFilter();
    this.layersFilterService.updateFilterMap(this.layerId, this.filterMap, true);
  }

  private composeFilter() {
    return new Set<string>(
      this.options
        .filter((option: AssetImageOption) => option.selected)
        .map((option: AssetImageOption) => option.name),
    );
  }

  setEditing(editing: boolean) {
    this.editing = editing;
  }

  cancelEditing() {
    this.setEditing(false);
    this.optionsByFilterName.next([]);
  }
}

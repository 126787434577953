@if (loading) {
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="36" class="spinner">
  </mat-progress-spinner>
} @else {
  <breadcrumb-navigation></breadcrumb-navigation>
  <ng-container>
    <image-studio-details [selectedImage]="selectedImage" [isNewUpload]="isNewUpload">
    </image-studio-details>
    <annotation-selector></annotation-selector>
  </ng-container>
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {BreadcrumbNavigationModule} from '../../breadcrumb_navigation/breadcrumb_navigation_module';
import {AnnotationSelectorModule} from '../../image_studio/annotation_selector/annotation_selector_module';
import {ImageStudioDetailsModule} from '../../image_studio/image_studio_details/image_studio_details_module';
import {ImageThumbnailModule} from '../../image_thumbnail/image_thumbnail_module';
import {GalleryInfo} from './gallery_info';

@NgModule({
  declarations: [GalleryInfo],
  imports: [
    AnnotationSelectorModule,
    BreadcrumbNavigationModule,
    CommonModule,
    FormsModule,
    ImageStudioDetailsModule,
    ImageThumbnailModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule,
    RouterModule,
  ],
  exports: [GalleryInfo],
})
export class GalleryInfoModule {}

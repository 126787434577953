import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';

import {Banner} from './banner';
import {BannerContentDirective} from './banner_content_directive';

@NgModule({
  declarations: [Banner, BannerContentDirective],
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule],
  exports: [Banner, BannerContentDirective],
})
export class BannerModule {}

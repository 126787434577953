// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/commentservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCommentRequest, AddCommentResponse, DeleteCommentRequest, DeleteCommentResponse, GetCommentsRequest, GetCommentsResponse } from "./commentservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service tapestry.gridaware.api.v1.CommentService
 */
export const CommentService = {
  typeName: "tapestry.gridaware.api.v1.CommentService",
  methods: {
    /**
     * GetComments lists all comments for a feature.
     *
     * @generated from rpc tapestry.gridaware.api.v1.CommentService.GetComments
     */
    getComments: {
      name: "GetComments",
      I: GetCommentsRequest,
      O: GetCommentsResponse,
      kind: MethodKind.Unary
    },
    /**
     * AddComment adds a new comment to a feature.
     *
     * @generated from rpc tapestry.gridaware.api.v1.CommentService.AddComment
     */
    addComment: {
      name: "AddComment",
      I: AddCommentRequest,
      O: AddCommentResponse,
      kind: MethodKind.Unary
    },
    /**
     * DeleteComment deletes a comment.
     *
     * @generated from rpc tapestry.gridaware.api.v1.CommentService.DeleteComment
     */
    deleteComment: {
      name: "DeleteComment",
      I: DeleteCommentRequest,
      O: DeleteCommentResponse,
      kind: MethodKind.Unary
    }
  }
};
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';

import {MultiselectAutocompleteModule} from '../multiselect_autocomplete/multiselect_autocomplete_module';
import {LayersFilterService} from '../services/layers_filter_service';
import {TagsService} from '../services/tags_service';
import {TagFilters} from './tag_filters';

@NgModule({
  declarations: [TagFilters],
  imports: [
    CommonModule,
    MultiselectAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
  ],
  exports: [TagFilters],
  providers: [LayersFilterService, TagsService],
})
export class TagFiltersModule {}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {ImageThumbnailModule} from '../image_thumbnail/image_thumbnail_module';
import {MapTableToggleModule} from '../map_table_toggle/map_table_toggle_module';
import {MapService} from '../services/map_service';
import {SidepanelLayoutModule} from '../sidepanel_layout/sidepanel_layout_module';
import {SolarTableModule} from '../solar_table/solar_table_module';
import {DataTable} from './data_table';
import {Table} from './table';
import {TableDialog} from './table_dialog';

@NgModule({
  declarations: [TableDialog, DataTable, Table],
  imports: [
    CommonModule,
    FormsModule,
    ImageThumbnailModule,
    MapTableToggleModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule,
    SidepanelLayoutModule,
    SolarTableModule,
  ],
  providers: [MapService],
  exports: [TableDialog, DataTable, Table],
})
export class TableModule {}

import {NgModule} from '@angular/core';

import {HighlightListPipe} from './highlight_list_pipe';
import {HighlightPipe} from './highlight_pipe';
import {OrderByPipe} from './order_by_pipe';
import {RelativeDatePipe} from './relative_date_pipe';
import {RelativeDateTimePipe} from './relative_datetime_pipe';
import {SelectionsSearchPipe} from './selections_search_pipe';
import {StringifyPipe} from './stringify_pipe';

@NgModule({
  declarations: [
    HighlightListPipe,
    HighlightPipe,
    OrderByPipe,
    RelativeDatePipe,
    RelativeDateTimePipe,
    SelectionsSearchPipe,
    StringifyPipe,
  ],
  exports: [
    HighlightListPipe,
    HighlightPipe,
    OrderByPipe,
    RelativeDatePipe,
    RelativeDateTimePipe,
    SelectionsSearchPipe,
    StringifyPipe,
  ],
})
export class PipesModule {}

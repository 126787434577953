import {BehaviorSubject, Observable, Subject, combineLatest} from 'rxjs';
import {first, switchMap, takeUntil, tap} from 'rxjs/operators';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {LayerStyle} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {SolarInsight} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/solar_insight_pb';

import {SOLAR_INSIGHTS_LAYER_ID} from '../constants/layer';
import {AnalyticsService, EventActionType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {FeaturesCountService} from '../services/features_count_service';
import {QueryFeaturesCount} from '../services/features_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {NetworkService} from '../services/network_service';
import {SolarInsightsService} from '../services/solar_insights_service';
import {FilterEditingStateEvent, FilterMap} from '../typings/filter';

/**
 * This component displays the filters and filter modification controls for
 * a single layer within the sidepanel.
 */
@Component({
  selector: 'layer-filters',
  templateUrl: './layer_filters.ng.html',
  styleUrls: ['./layer_filters.scss'],
})
export class LayerFilters implements OnInit, OnDestroy {
  @Input() layerId = '';
  @Input() layerStyle: LayerStyle | null = null;
  @Output()
  readonly editingChange = new EventEmitter<FilterEditingStateEvent>();

  canIncludeInactives = true;
  editing = false;
  totalFeatureCount = new BehaviorSubject<number | null>(null);
  queryFeatureCount: QueryFeaturesCount | null = null;
  includeInactiveResults = false;

  destroyed = new Subject<void>();
  resetFiltersRequest = new Subject<void>();

  constructor(
    readonly networkService: NetworkService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly featuresCountService: FeaturesCountService,
    private readonly layersFilterService: LayersFilterService,
    private readonly analyticsService: AnalyticsService,
    private readonly solarInsightsService: SolarInsightsService,
    private readonly configService: ConfigService,
  ) {}

  ngOnInit() {
    this.canIncludeInactives = !this.layerStyle?.includeInactivesDisabled;

    // Do not show includeInactives for "Solar Insights" / ICP layer and use
    // a different count.
    if (this.configService.solar2Enabled && this.layerId === SOLAR_INSIGHTS_LAYER_ID) {
      this.canIncludeInactives = false;
      this.initFeedersFilterListener();
      this.initQueryCountListenerForSolar2();
      return;
    }

    // Get initial value for 'Include Inactive'.
    const includeInactive = this.layersFilterService.includeInactive(this.layerId).pipe(first());

    combineLatest([includeInactive, this.getQueryCount()])
      .pipe(takeUntil(this.destroyed))
      .subscribe(([includeInactive, queryFeaturesCount]) => {
        this.includeInactiveResults = includeInactive;
        this.queryFeatureCount = queryFeaturesCount;
        this.setTotalFeatureCount();
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.resetFiltersRequest.next();
    this.resetFiltersRequest.complete();
  }

  private initFeedersFilterListener() {
    this.layersFilterService
      .getFilterMap(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((filterMap: FilterMap) => {
        this.updateFilteredFeeders(filterMap);
      });
  }

  private updateFilteredFeeders(filterMap: FilterMap) {
    this.solarInsightsService.updateFilteredFeeders(filterMap);
  }

  private initQueryCountListenerForSolar2() {
    this.solarInsightsService
      .getSelectedFeeders()
      .pipe(takeUntil(this.destroyed))
      .subscribe((selectedFeeders: SolarInsight[] | null) => {
        const count = selectedFeeders?.length ?? 0;
        this.queryFeatureCount = {active: count, inactive: count};
        this.setTotalFeatureCount();
      });
  }

  // Get features count on the first load.
  // Refresh features count on filter changes.
  private getQueryCount(): Observable<QueryFeaturesCount> {
    let shouldForceFetchFeaturesCount = false;
    return this.layersFilterService.getFilterMap(this.layerId).pipe(
      switchMap((filterMap: FilterMap): Observable<QueryFeaturesCount> => {
        this.totalFeatureCount.next(null);
        return this.featuresCountService.getFeaturesCountByLayerId(
          this.layerId,
          filterMap,
          shouldForceFetchFeaturesCount,
        );
      }),
      tap(() => {
        shouldForceFetchFeaturesCount = true;
      }),
      takeUntil(this.destroyed),
    );
  }

  setIncludeInactive(isOn: boolean) {
    const userInitiated = true;
    this.includeInactiveResults = isOn;
    this.layersFilterService.updateIncludeInactive(this.layerId, isOn, userInitiated);
    this.setTotalFeatureCount();
  }

  resetFilters() {
    this.analyticsService.sendEvent(EventActionType.RESET_FILTERS, {});
    this.layersFilterService.updateFilterMap(this.layerId, {}, true);
    this.resetFiltersRequest.next();
    this.editingChange.emit({
      layerId: this.layerId,
      editing: false,
      isReset: true,
    });
    if (this.configService.solar2Enabled && this.layerId === SOLAR_INSIGHTS_LAYER_ID) {
      this.solarInsightsService.reset();
    }
  }

  private setTotalFeatureCount() {
    if (!this.queryFeatureCount) {
      return;
    }
    const featureCount = this.featuresCountService.getTotalCount(
      this.queryFeatureCount,
      this.includeInactiveResults,
    );

    this.totalFeatureCount.next(featureCount);
    this.featuresCountService.setTotalCountForLayer(this.layerId, featureCount);
  }

  onEditingChanged(event: FilterEditingStateEvent) {
    this.editing = event.editing;
    this.editingChange.emit(event);
  }
}

import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {DateRange} from '../date_range_picker/date_range_picker';
import {type Filter} from '../typings/filter';
import {buildFormattedDateRange} from '../utils/date';
import {isDateField} from '../utils/properties';
import {extractDateRange} from './filter_helpers';

/**
 * This component displays the filters and filter modification controls
 * for a single layer within the sidepanel.
 */
@Component({
  selector: 'filter-form',
  templateUrl: './filter_form.ng.html',
  styleUrls: ['./filter_form.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterForm implements OnInit, OnDestroy {
  /**
   * The list of filter names to display in the drop-down. Should be the list of
   * property names except those names which are already used for filtering.
   */
  @Input() filterNames: string[] = [];

  /**
   * The list of options for the currently selected property name.
   */
  @Input()
  set optionsByFilterName(options: string[] | null) {
    if (!options?.length) {
      return;
    }
    this.selectionChangedFetching = false;
    this.options = options.sort();
  }

  /**
   * Whether to include inactive features in the data. This flag is set by the
   * user with the checkbox, which is rendered outside of this component.
   * PropertyFiltersForm needs to know the value of this flag in order to fetch
   * all possible options.
   */
  @Input() includeInactiveResults = false;

  /**
   * The filter which the user wants to modify. This input field is filled in
   * with the current filter settings when the user clicks on the filter chip,
   * then the form is shown.
   */
  @Input() changingFilter: Filter | null = null;

  /**
   * Emits filter name when user change it.
   */
  @Output() readonly filterNameValueChanged = new EventEmitter<string>();

  /**
   * Emits new property filter that the user wants to be applied to the map.
   */
  @Output() readonly filterApplied = new EventEmitter<Filter>();

  /**
   * Emits whenever the user closes the form without applying a new filter.
   */
  @Output() readonly canceled = new EventEmitter<void>();

  /**
   * Options for the currently selected filter name.
   */
  options: string[] = [];
  /**
   * The options that have been selected.
   */
  selectedOptions: string[] = [];
  filterNameControl = new UntypedFormControl();
  multiselectOptionsControl = new UntypedFormControl();
  dateRangeControl = new UntypedFormControl();
  filterForm = new UntypedFormGroup({
    filterNameControl: this.filterNameControl,
    valueControl: this.multiselectOptionsControl,
    dateRangeControl: this.dateRangeControl,
  });
  dateRangeField = false;
  selectionChangedFetching = false;
  unsubscribe$ = new Subject<void>();

  constructor(readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.filterNameControl.valueChanges
      .pipe(
        filter((filterName: string | null) => !!filterName),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.dateRangeField = isDateField(this.filterNameControl.value);
        if (!this.dateRangeField) {
          this.selectionChangedFetching = true;
          this.filterNameValueChanged.emit(this.filterNameControl.value);
        }
        if (this.changingFilter !== null) {
          this.filterNameControl.disable({emitEvent: false});
          if (this.dateRangeField) {
            this.dateRangeControl.setValue(
              extractDateRange([...this.changingFilter.selectedValues]),
            );
          } else {
            this.selectedOptions = [...this.changingFilter.selectedValues];
            this.multiselectOptionsControl.setValue([...this.changingFilter.selectedValues]);
          }
        }
      });

    if (this.changingFilter !== null) {
      this.filterNameControl.setValue(this.changingFilter.name);
    }

    this.multiselectOptionsControl.valueChanges
      .pipe(
        filter((selectedOptions: string[] | null) => !!selectedOptions),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((selectedOptions: string[] | null) => {
        this.setSelectedOptions(selectedOptions!);
      });

    this.dateRangeControl.valueChanges
      .pipe(
        filter((selectedDateRange: DateRange | null) => !!selectedDateRange),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((selectedDateRange: DateRange | null) => {
        this.setSelectedOptions(
          buildFormattedDateRange(selectedDateRange!.startDate, selectedDateRange!.endDate),
        );
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFilterNames(): string[] {
    if (this.changingFilter === null) {
      return this.filterNames;
    }
    return [this.changingFilter.name, ...this.filterNames];
  }

  applySelectedOptions() {
    this.filterApplied.emit({
      name: this.filterNameControl.value,
      selectedValues: new Set(this.selectedOptions),
    });
    this.clearForm();
  }

  cancel() {
    this.clearForm();
    this.canceled.emit();
  }

  clearForm() {
    this.selectedOptions = [];
    this.filterForm.reset();
    this.dateRangeField = false;
  }

  setSelectedOptions(selectedOptions: string[]) {
    this.selectedOptions = selectedOptions;
    this.changeDetectorRef.detectChanges();
  }

  getSelectAllOptionName(): string {
    return this.filterNameControl.value || 'Select all';
  }
}

<div class="container">
  @if (loading) {
    <div class="loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  }
  <div class="property">Feeder ID: {{ feederId }}</div>
  @for (stat of solarStats; track stat) {
    <div class="property">
      <div class="left">{{ formatKey(stat.key) }}</div>
      @if (!loading) {
        <div class="right">
          <span class="value">{{ stat.value | number: '1.0-0' }}</span>
          <span class="unit">{{ stat.unit }}</span>
        </div>
      }
    </div>
  }
</div>

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/userservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { UserType } from "./user_pb.js";

/**
 * @generated from message tapestry.gridaware.api.v1.ListUserTypesRequest
 */
export const ListUserTypesRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.ListUserTypesRequest", []);

/**
 * @generated from message tapestry.gridaware.api.v1.ListUserTypesResponse
 */
export const ListUserTypesResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.ListUserTypesResponse", () => [{
  no: 1,
  name: "user_types",
  kind: "message",
  T: UserType,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.CheckAccessRequest
 */
export const CheckAccessRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.CheckAccessRequest", []);

/**
 * @generated from message tapestry.gridaware.api.v1.CheckAccessResponse
 */
export const CheckAccessResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.CheckAccessResponse", []);

/**
 * Retrieves a User resource identified by the supplied information.
 *
 * @generated from message tapestry.gridaware.api.v1.GetUserRequest
 */
export const GetUserRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetUserRequest", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "identifier"
}, {
  no: 2,
  name: "email",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "identifier"
}]);
import {JsonObject} from '@bufbuild/protobuf/dist/cjs/json-format';
import {color, interpolateRgbBasis, max, min, scaleSequential} from 'd3';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Sort} from '@angular/material/sort';

import {
  SolarInsight,
  SolarInsights,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/solar_insight_pb';

import {FEEDER_NAME} from '../constants/asset';
import {ICP_LAYER_ID} from '../constants/layer';
import {LayersFilterService} from '../services/layers_filter_service';
import {FilterMap} from '../typings/filter';

/**
 * Color scale from yellow "low", orange "medium", and red "high."
 */
export const COLOR_SCALE = ['#FCE541', '#E9AD42', '#E35C33'];

/**
 * Human readable labels for feeder metrics.
 */
export enum FeederMetrics {
  FEEDER_CODE = 'Feeder code',
  PERCENT_RESIDENTIAL = 'Percent of residential rooftops',
  PERCENT_COMMERCIAL = 'Percent of commercial rooftops',
  PERCENT_OTHER = 'Percent of other rooftops',
  MAX_CONNECTED_CAPACITY = 'Max potential capacity (total kW)',
  AVG_MAX_KW_RESIDENTIAL = 'Average residential system size (kW)',
  AVG_MAX_KW_COMMERCIAL = 'Average commercial system size (kW)',
  MAX_YEARLY_KWH_POTENTIAL_AC = 'Yearly max energy potential (kWh AC)',
  MAX_M2_PANEL_AREA = 'Maximum panel area (m<sup>2</sup>)',
  TOTAL_M2_ROOF_AREA = 'Total potential panel area (m<sup>2</sup>)',
  AVG_ROOF_MAX_SUNSHINE_HRS_PER_M2 = 'Average roof max sunshine hours per m<sup>2</sup>',
  NE_ONLY_KW_CONNECTED_CAPACITY = 'NorthEast average kW potential capacity',
  NE_ONLY_AVG_KW_RESIDENTIAL = 'NorthEast average kW residential',
  NE_ONLY_AVG_KW_COMMERCIAL = 'NorthEast average kW commercial',
  NE_ONLY_YEARLY_KWH_POTENTIAL_AC = 'NorthEast yearly max energy potential (kWh AC)',
  CARBON_OFFSET_FACTOR_PER_MWH = 'Carbon offset factor (kg CO<sup>2</sup>e per MWh)',
  DIVIDER = 'DIVIDER',
}

/**
 * A service to support the solar insights feeder selection components.
 * The feeders are stored as a textproto file here:
 * googlex/refinery/viaduct/proto/gridaware:solar_insights_data
 */
@Injectable()
export class SolarInsightsService {
  /**
   * Emits whenever the Solar Insights range filter is reset.
   */
  readonly resetRangeFilter = new Subject<void>();

  /**
   * Feeders that have been selected (e.g. with the range-filter).
   */
  readonly selectedFeeders = new ReplaySubject<SolarInsight[] | null>(1);

  /**
   * All feeders that have Solar Insight data.
   */
  readonly feeders = new ReplaySubject<SolarInsight[]>(1);
  private readonly cachedTableColumns = new ReplaySubject<string[]>();
  readonly sortedByColumn = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });

  private readonly colorByFeeder = new Map<string, string>();
  private readonly globalFilters: FilterMap = {};

  private allFeeders: SolarInsight[] = [];

  constructor(
    private readonly layersFilterService: LayersFilterService,
    private readonly http: HttpClient,
  ) {
    this.http.get('/assets/solar_insights/vts_feeders.json').subscribe((data) => {
      const solarInsights = SolarInsights.fromJson(data as JsonObject);
      this.allFeeders = solarInsights.solarInsights;
      this.feeders.next(this.allFeeders);
    });
    this.setColorForConnectedCapacity();
  }

  getFeederByCode(feederCode: string): SolarInsight | undefined {
    return this.getAllFeeders().find((feeder) =>
      feeder.aggregationType.value?.feederCode === feederCode ? feeder : null,
    );
  }

  getColorByCode(feederCode: string): string | null {
    return this.colorByFeeder.get(feederCode) || null;
  }

  getAllFeeders(): SolarInsight[] {
    this.feeders.next(this.allFeeders);
    return this.allFeeders;
  }

  setSelectedFeeders(feeders: SolarInsight[]) {
    this.selectedFeeders.next(feeders);
  }

  getSelectedFeeders(): Observable<SolarInsight[] | null> {
    return this.selectedFeeders.asObservable();
  }

  getAllCachedFeeders(): Observable<SolarInsight[]> {
    return this.feeders.asObservable();
  }

  updateFilteredFeeders(filterMap: FilterMap) {
    const feederIds = Object.values(filterMap).length
      ? Array.from(filterMap[FEEDER_NAME].values())
      : null;
    this.setFeedersByIds(feederIds);
  }

  setFeedersByIds(feederIds: string[] | null) {
    const allFeeders = this.getAllFeeders();
    let filteredFeeders: SolarInsight[] = [];

    if (feederIds === null) {
      filteredFeeders = allFeeders;
    } else {
      filteredFeeders = allFeeders.filter((feeder) =>
        feederIds.includes(feeder.aggregationType.value?.feederCode || ''),
      );
    }

    this.setSelectedFeeders(filteredFeeders);
  }

  // Apply filter that only displays ICPs that belong to feeders that
  // have available data.
  filterByFeederNames() {
    const filteredFeeders = this.getAllFeeders();

    const feederNames = [
      ...filteredFeeders.map((feeder) => {
        return feeder?.aggregationType.value?.feederCode || '';
      }),
      ...Array.from(this?.globalFilters[FEEDER_NAME]?.values() || []),
    ];

    const feederNamesSet: FilterMap = {
      [FEEDER_NAME]: new Set(feederNames),
    };

    this.setSelectedFeeders(filteredFeeders);
    this.layersFilterService.updateFilterMap(ICP_LAYER_ID, feederNamesSet, true);
  }

  // Reset filters and UI to default state (i.e. show all feeders).
  reset() {
    this.selectedFeeders.next(this.getAllFeeders());
    this.resetRangeFilter.next();
  }

  // Assign a color for each feeder based on max kW connected capacity.
  private setColorForConnectedCapacity() {
    this.getAllCachedFeeders().subscribe((insights: SolarInsight[]) => {
      const metrics: number[] = insights.map(
        (insight: SolarInsight) => insight.maxKwConnectedCapacity,
      );
      const minMetric = min(metrics) || Number.MIN_VALUE;
      const maxMetric = max(metrics) || Number.MAX_VALUE;
      const colorScale = scaleSequential(interpolateRgbBasis(COLOR_SCALE)).domain([
        minMetric,
        maxMetric,
      ]);

      insights.forEach((insight: SolarInsight) => {
        const maxCapacity = insight.maxKwConnectedCapacity;
        const feederCode = insight.aggregationType.value?.feederCode;
        const scaledColor = colorScale(maxCapacity);
        const d3Color = color(scaledColor);
        if (feederCode && d3Color) {
          this.colorByFeeder.set(feederCode, d3Color.hex());
        }
      });
    });
  }

  // Saves selected table columns for persistence.
  setTableColumns(columns: string[]) {
    this.cachedTableColumns.next(columns);
  }

  getTableColumns(): Observable<string[]> {
    return this.cachedTableColumns.asObservable();
  }
}

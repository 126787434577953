import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';

import {UserType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/user_pb';

import {UserPreferencesService} from '../services/user_preferences_service';
import {UsersService} from '../services/users_service';

/**
 * Component for a dialog where a user can set their user type.
 */
@Component({
  templateUrl: './user_types_dialog.ng.html',
  styleUrls: ['./user_types_dialog.scss'],
})
export class UserTypesDialog implements OnDestroy, OnInit {
  selectedUserType: UserType | null = null;
  userTypes: UserType[] = [];
  private readonly destroyed = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<UserTypesDialog>,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly usersService: UsersService,
  ) {}

  ngOnInit() {
    this.usersService
      .getUserTypes()
      .pipe(takeUntil(this.destroyed))
      .subscribe((userTypes: UserType[]) => {
        this.userTypes = userTypes;
      });

    this.userPreferencesService
      .getSelectedUserType()
      .pipe(takeUntil(this.destroyed))
      .subscribe((userType: UserType | null) => {
        this.selectedUserType = userType;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onUserTypeSelect(change: MatSelectChange) {
    this.userPreferencesService.setSelectedUserTypeCode(change.value);
    this.dialogRef.close();
  }
}

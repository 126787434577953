// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/sunroofservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { FindClosestBuildingRequest, FindClosestBuildingResponse } from "./sunroofservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Provides an API for interacting with go/sunroof data.
 *
 * @generated from service tapestry.gridaware.api.v1.SunroofService
 */
export const SunroofService = {
  typeName: "tapestry.gridaware.api.v1.SunroofService",
  methods: {
    /**
     * Locates the closest building to a query point.
     *
     * @generated from rpc tapestry.gridaware.api.v1.SunroofService.FindClosestBuilding
     */
    findClosestBuilding: {
      name: "FindClosestBuilding",
      I: FindClosestBuildingRequest,
      O: FindClosestBuildingResponse,
      kind: MethodKind.Unary
    }
  }
};
// taze: gapi from //third_party/javascript/typings/gapi_auth2
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ROUTE} from '../constants/paths';
import {AuthProviderType, AuthService} from '../services/auth_service';
import {ConfigService} from '../services/config_service';

/**
 * Maximum number of SSO options to be displayed on login page.
 */
export const MAX_SSO_OPTIONS = 3;

/**
 * Login component class.
 */
@Component({
  templateUrl: './login_component.ng.html',
  styleUrls: ['./login_component.scss'],
})
export class LoginComponent implements OnInit {
  returnUrl: string = ROUTE.MAP;
  showGoogleLogin = false;
  showOAuthLogin = false;
  oAuthLoginOptionLabels: string[] = [];

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
  ) {}

  ngOnInit() {
    if (this.configService.authOverrideEnabled) {
      // Show 'Sign in with Google' option even though the auth is bypassed.
      this.showGoogleLogin = true;
      return;
    }
    this.showGoogleLogin = this.configService.googleLoginEnabled;
    this.showOAuthLogin = this.authService.oAuthEnabled();
    this.oAuthLoginOptionLabels = this.parseOauthLoginLabels();
    const user = this.authService.currentUserIfPresent();
    if (user) {
      // User is already authenticated.
      this.redirect();
    }
  }

  /**
   * Navigates to the Google login flow.
   */
  startGoogleSignIn() {
    if (this.configService.authOverrideEnabled) {
      this.redirect();
      return;
    }
    this.router.navigateByUrl(ROUTE.LOADING, {
      state: {signIn: true, authType: AuthProviderType.GOOGLE},
    });
  }

  /**
   * Navigates to the login flow for the customer single sign-on
   * system configured in Google Cloud Identity Platform.
   * @param providerIndex index of the oAuth provider.
   */
  startOAuthSignIn(providerIndex: number) {
    this.router.navigateByUrl(ROUTE.LOADING, {
      state: {
        signIn: true,
        authType: AuthProviderType.OAUTH,
        providerIndex: providerIndex,
      },
    });
  }

  parseOauthLoginLabels(): string[] {
    const oauthConfigEntries = this.configService.firebaseProviderConfig;
    const optionsToShow = Math.min(oauthConfigEntries.length, MAX_SSO_OPTIONS);
    return oauthConfigEntries
      .slice(0, optionsToShow)
      .map((config, index) => this.getLoginLabel(config.label, config.id, index === 0));
  }

  getLoginLabel(label: string, providerId: string, isPrimary: boolean): string {
    if (isPrimary) {
      return 'Sign in';
    }
    if (!label || label.length === 0) {
      // Try to correct the config data errors if any.
      return `Sign in with ${providerId ? providerId : 'other provider'}`;
    }
    return label;
  }

  private redirect() {
    const continueUrl = this.authService.getUrl() || this.returnUrl;
    this.router.navigateByUrl(continueUrl);
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

import {TagsModule} from '../../tags/tags_module';
import {FeatureSelectionMapModule} from '../../upload/feature_selection_map/feature_selection_map_module';
import {FeatureSelectionMapDialog} from './feature_selection_map_dialog';
import {OfflineDialog} from './offline_dialog';

@NgModule({
  declarations: [FeatureSelectionMapDialog, OfflineDialog],
  imports: [CommonModule, FeatureSelectionMapModule, MatButtonModule, MatDialogModule, TagsModule],
  exports: [FeatureSelectionMapDialog, OfflineDialog],
})
export class DialogsModule {}

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import {TagsService} from '../services/tags_service';
import {setsAreEqual} from '../utils/collections';

// A delay applied to initially opening the input. Without this, the width of
// the dropdown is incorrect.
const OPEN_DELAY_MS = 200;

/**
 * Component for tags displaying and editing.
 */
@Component({
  selector: 'tags',
  templateUrl: './tags.ng.html',
  styleUrls: ['./tags.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Tags implements OnInit, AfterViewInit, OnDestroy {
  @Input() tags = new Set<string>();
  @Input() layerId = '';
  @Input() opened = false;
  @Input() allowEditing = true;
  @Output() tagsUpdated = new EventEmitter<Set<string>>();
  // Emits when editing begins or ends.  A value of true signals that editing
  // has started while false signals that editing has ended.
  @Output() editingChanged = new EventEmitter<boolean>();

  updatedTags = new Set<string>();
  editing = false;
  allTags: string[] = [];
  destroyed = new Subject<void>();

  constructor(private readonly tagsService: TagsService) {}

  ngOnInit() {
    this.updatedTags = new Set(this.tags);

    // Keep track of all tags.
    if (!this.layerId) {
      // layer ID may absent in case of offline mode
      console.warn("No layerId, won't load tags from BE.");
      return;
    }
    this.tagsService
      .getTags(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((allTags: string[]) => {
        this.allTags = allTags;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngAfterViewInit() {
    if (this.opened) {
      // Wrap in setTimeout so that the width of the dropdown is correct.
      setTimeout(() => {
        this.startEditing();
        this.opened = false;
      }, OPEN_DELAY_MS);
    }
  }

  startEditing() {
    this.editing = true;
    this.editingChanged.next(true);
  }

  stopEditing() {
    this.editing = false;
    this.editingChanged.next(false);
  }

  onSelectedOptionsChanged(options: string[]) {
    this.updatedTags = new Set(options);
  }

  onApplied() {
    if (!setsAreEqual(this.tags, this.updatedTags)) {
      this.tags = new Set(this.updatedTags);
      this.tagsUpdated.emit(new Set(this.updatedTags));
    }
    this.stopEditing();
  }

  onCancel() {
    this.updatedTags = new Set(this.tags);
    this.stopEditing();
  }

  getTags(): string[] {
    return [...this.tags];
  }
}

import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  CdkConnectedOverlay,
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';
import {DatePipe} from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import {
  CorrectedImageAnnotation,
  ImageAnnotation,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';

import {ANNOTATION_NAME_BY_LABEL} from '../constants/annotations';
import {DEFAULT_TEXT_ANNOTATION_COLOR} from '../styles/constants';
import {type AnnotationInfo} from '../typings/annotations';
import {type LabelSelection} from './label_selector';

const DEFAULT_OFFSET_Y_PX = -8;
const DEFAULT_PANEL_WIDTH_PX = '244';
const DEFAULT_LABEL_COLOR = '#3c4043';

/**
 * Component for rendering annotation input panel.
 */
@Component({
  selector: 'label-panel',
  templateUrl: './label_panel.ng.html',
  styleUrls: ['./label_panel.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('smoothPanel', [
      state(
        'false',
        style({
          opacity: 0,
          transform: 'scale(1, 0.8)',
        }),
      ),
      transition(
        'false => true',
        animate(
          '120ms cubic-bezier(0, 0, 0.2, 1)',
          style({
            opacity: 1,
            transform: 'scale(1, 1)',
          }),
        ),
      ),
      transition('true => false', animate('100ms linear', style({opacity: 0}))),
    ]),
  ],
})
export class LabelPanel implements OnInit, AfterViewInit {
  @Input() x = 0;
  @Input() y = 0;
  // This will be null in the case where the annotation is new.
  @Input() annotationInfo: AnnotationInfo | null = null;
  @Input() hiddenInput = true;
  @Input() editable = false;
  @Input() color = DEFAULT_LABEL_COLOR;
  @Input() textColor = DEFAULT_TEXT_ANNOTATION_COLOR;
  @Input() outOfFocus = false;
  @Input() focused = false;
  @Output() readonly onComplete = new EventEmitter<AnnotationInfo>();
  @Output() readonly onClear = new EventEmitter<void>();
  @Output() readonly onSelect = new EventEmitter<void>();
  @Output() readonly onClose = new EventEmitter<void>();
  @Output() readonly onCorrectAnnotation = new EventEmitter<LabelSelection>();
  @Output() readonly onReady = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay!: CdkConnectedOverlay;

  formattedDate = 'N/A';
  formattedCorrectedAnnotationDate = 'N/A';
  annotationNameByLabel = ANNOTATION_NAME_BY_LABEL; // Used in the template.
  /**
   * Offset the overlay so that it covers the trigger icon.
   */
  offsetY = DEFAULT_OFFSET_Y_PX;
  panelWidth = DEFAULT_PANEL_WIDTH_PX;
  showOverlay = false;
  correctAnnotationSelectorVisible = false;
  corrected: CorrectedImageAnnotation | null = null;
  positionStrategy!: FlexibleConnectedPositionStrategy;
  private readonly panelPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  constructor(
    readonly datepipe: DatePipe,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
  ) {}

  ngOnInit() {
    if (!this.annotationInfo) {
      // This is a new annotation. Show the label selector.
      this.showOverlay = true;
    }
    if (this.annotationInfo?.updatedAt) {
      this.formattedDate =
        this.datepipe.transform(this.annotationInfo.updatedAt.toDate(), 'dd-MMM-yyyy') || '';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['x'] || changes['y']) {
      this.updatePanelPosition();
    }
    if (changes['annotationInfo'] && this.annotationInfo?.sourceAnnotation) {
      this.corrected = this.annotationInfo.sourceAnnotation.corrected || null;
      const correctedDate = this.corrected?.createdAt || null;
      const formattedDate = correctedDate
        ? this.datepipe.transform(correctedDate.toDate(), 'dd-MMM-yyyy')
        : null;
      this.formattedCorrectedAnnotationDate = formattedDate || 'N/A';
    }
  }

  ngAfterViewInit() {
    this.onReady.emit();
  }

  closeIfNotNewAnnotation() {
    if (this.annotationInfo) {
      this.closePanel();
    }
  }

  apply(selection: LabelSelection) {
    // onComplete shouldn't be emitting AnnotationInfo but rather LabelSelection
    // as label and comment are the only things that are being used downstream.
    // There was an issue where the user wasn't being saved when only label and
    // comment were being emitted (see b/542951008).
    // TODO: b/319520313 - emit LabelSelection from onComplete.
    const emptyAnnotationInfo = {
      label: null,
      comment: '',
      updatedAt: undefined,
      createdAt: undefined,
      isMachineGenerated: false,
      machineModelVersion: '',
      machineConfidenceScore: 0,
      sourceAnnotation: new ImageAnnotation(),
    };
    this.annotationInfo = {
      ...(this.annotationInfo || emptyAnnotationInfo),
      label: selection.label,
      comment: selection.comment,
    };
    this.onComplete.emit(this.annotationInfo);
    this.closePanel();
  }

  deleteAnnotation() {
    this.onClear.emit();
  }

  updatePanelPosition() {
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo({x: this.x, y: this.y + DEFAULT_OFFSET_Y_PX})
      .withPositions(this.panelPositions)
      .withGrowAfterOpen(true)
      .withPush(true);
  }

  handleKeyPressEvents(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.annotationInfo ? this.closePanel() : this.onClear.emit();
    }
  }

  closePanel() {
    this.showOverlay = false;
    this.correctAnnotationSelectorVisible = false;
    this.onClose.emit();
  }

  showPanel() {
    this.showOverlay = true;
    this.selectLabel();
  }

  selectLabel() {
    this.onSelect.emit();
  }

  applyCorrectedAnnotation(selection: LabelSelection) {
    this.onCorrectAnnotation.emit(selection);
    this.closePanel();
  }

  openCorrectAnnotationSelector() {
    this.showOverlay = false;

    setTimeout(() => {
      this.correctAnnotationSelectorVisible = true;
      // Update the overlay position when correct annotation selector is rendered.
      // Note: without this, the overlay size was not updated.
      this.cdkConnectedOverlay?.overlayRef?.updatePosition();
      this.showOverlay = true;
    }, 100);
  }
}

<ng-container>
  <div class="range-filter-section">
    <div class="range-filter-title">{{ sliderFilterTitle }}</div>
    <div class="range-filter">
      <mat-slider
        color="primary"
        [min]="minAll.value"
        [max]="maxAll.value"
        [disabled]="sliderDisabled">
        <input
          [value]="minReflection | async"
          (change)="updateSliderMin($event)"
          matSliderStartThumb />
        <input
          [value]="maxReflection | async"
          (change)="updateSliderMax($event)"
          matSliderEndThumb />
      </mat-slider>
    </div>
    <div class="range-color-legend" [ngStyle]="getLegendStyle()"></div>
    <div class="range-inputs">
      <mat-form-field appearance="outline">
        <mat-label>Minimum</mat-label>
        <input
          matInput
          type="number"
          [value]="minReflection | async"
          (change)="updateSliderMin($event)" />
        <span matTextSuffix>kW</span>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Maximum</mat-label>
        <input
          matInput
          type="number"
          [value]="maxReflection | async"
          (change)="updateSliderMax($event)" />
        <span matTextSuffix>kW</span>
      </mat-form-field>
    </div>
  </div>
</ng-container>

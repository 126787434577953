import {
  Selection as D3Selection, // eslint-disable-next-line node/no-unpublished-import
} from 'd3';
import {Observable, Subscriber} from 'rxjs';

import {formatDate} from '@angular/common';

import {Annotation, AnnotationInfo, AnnotationShape} from '../typings/annotations';

// SVG namespace.
const SVG_NS = 'http://www.w3.org/2000/svg';

// XHTML namespace.
const XHTML_NS = 'http://www.w3.org/1999/xhtml';

// Credentials mode for CORS image request.
const CREDENTIALS_MODE = 'same-origin';

/**
 * Coordinates identifying specific overlay location.
 */
interface Position {
  x: number;
  y: number;
}

type SvgSelection = D3Selection<SVGElement, unknown, HTMLElement | null, undefined>;

type SvgRectSelection = D3Selection<SVGRectElement, unknown, HTMLElement | null, undefined>;

/**
 * Draws original image into HTML5 canvas.
 */
export function drawImageIntoCanvas(
  originalImageUrl: string,
  canvas: HTMLCanvasElement,
): Observable<HTMLImageElement> {
  return new Observable((subscriber: Subscriber<HTMLImageElement>) => {
    getImageDataUrl(originalImageUrl)
      .then((dataUrl: string) => {
        const image = new Image();
        image.src = dataUrl;
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          canvas.innerText = image.alt;
          canvas.getContext('2d')?.drawImage(image, 0, 0);
          subscriber.next(image);
          subscriber.complete();
        };
      })
      .catch((error: Error) => {
        subscriber.error(`error drawing image: ${error}`);
      });
  });
}

function getImageDataUrl(originalImageUrl: string): Promise<string> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (originalImageUrl.startsWith('data:')) {
        // Already a Data Url.
        resolve(originalImageUrl);
        return;
      }
      const response = await fetch(originalImageUrl, {
        credentials: CREDENTIALS_MODE,
      });
      const blob = await response.blob();
      readBlobAsDataUrl(blob, (dataUrl: string) => {
        resolve(dataUrl);
      });
    } catch (error) {
      reject(`error fetching image: ${error}`);
    }
  });
}

/**
 * Draws the annotations SVG state into HTML5 canvas.
 */
export function drawSvgIntoCanvas(
  svg: SVGSVGElement,
  width: number,
  height: number,
  canvas: HTMLCanvasElement,
): Observable<void> {
  return new Observable((subscriber: Subscriber<void>) => {
    const svgDataUrl = svgToDataUrl(svg, width, height);
    const svgImage = new Image();
    svgImage.src = svgDataUrl;
    svgImage.onload = () => {
      canvas.getContext('2d')?.drawImage(svgImage, 0, 0);
      subscriber.next();
      subscriber.complete();
    };
  });
}

/**
 * Retrieves the image element by it's URL.
 */
export function getImage(url: string): Observable<HTMLImageElement> {
  return new Observable((subscriber: Subscriber<HTMLImageElement>) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      subscriber.next(image);
      subscriber.complete();
    };
  });
}

/**
 * Initializes SVG panel with shapes accoding to provided annotations info.
 */
export function setUpAnnotationsSvg(mainSvg: SVGSVGElement, annotations: Annotation[]) {
  for (const annotation of annotations) {
    const shape = annotation.shapeData;
    const topLeft = {x: shape.left, y: shape.top};
    const bottomRight = {
      x: shape.left + shape.width,
      y: shape.top + shape.height,
    };
    const id = buildShapeID();
    const el = createShape(id, topLeft, bottomRight, annotation.shapeType);
    if (el !== null) {
      mainSvg?.appendChild(el);
    }

    const bottomLeft = {x: shape.left, y: shape.top + shape.height};
    const labelEl = createLabelPanel(bottomLeft, annotation.info);
    mainSvg?.appendChild(labelEl);
  }
}

/**
 * Creates the annotation panel containing labels and comment as SVG's
 * foreign object. The use of foreign object allows to utilize such features
 * as the text wrap, variable size containers - features otherwise missing
 * in SVG.
 */
export function createLabelPanel(topLeft: Position, annotationInfo: AnnotationInfo): Element {
  const htmlContent = document.createElementNS(SVG_NS, 'foreignObject');
  htmlContent.setAttribute('x', `${topLeft.x}`);
  htmlContent.setAttribute('y', `${topLeft.y}`);
  htmlContent.setAttribute('width', '450');
  htmlContent.setAttribute('height', '550');
  const annotationContainer = document.createElementNS(XHTML_NS, 'div');
  annotationContainer.classList.add('annotation-label-container');
  const labelContainer = document.createElementNS(XHTML_NS, 'div');
  labelContainer.classList.add('label-container');

  if (!annotationInfo.label) {
    console.error('annotation label is missing');
  }
  const labelDiv = document.createElementNS(XHTML_NS, 'div');
  labelDiv.classList.add('label-chip');
  labelDiv.innerText = annotationInfo.label?.label || 'N/A';
  labelContainer.appendChild(labelDiv);

  annotationContainer.appendChild(labelContainer);
  if (annotationInfo.comment) {
    const commentContainer = document.createElementNS(XHTML_NS, 'div');
    commentContainer.classList.add('comment-container');
    commentContainer.innerText = annotationInfo.comment;
    annotationContainer.appendChild(commentContainer);
  }

  if (annotationInfo.isMachineGenerated) {
    const mgContainer = document.createElementNS(XHTML_NS, 'div');
    mgContainer.classList.add('user-container');
    mgContainer.innerText = 'Machine-generated';
    annotationContainer.appendChild(mgContainer);
    if (annotationInfo.machineModelVersion) {
      const modelContainer = document.createElementNS(XHTML_NS, 'div');
      modelContainer.classList.add('user-container');
      modelContainer.innerText = 'Model version: ' + annotationInfo.machineModelVersion;
      annotationContainer.appendChild(modelContainer);
    }
  } else if (annotationInfo.user?.name) {
    const userContainer = document.createElementNS(XHTML_NS, 'div');
    userContainer.classList.add('user-container');
    userContainer.innerText = 'Created by: ' + annotationInfo.user.name;
    annotationContainer.appendChild(userContainer);
  }
  if (annotationInfo.updatedAt) {
    const updatedAtContainer = document.createElementNS(XHTML_NS, 'div');
    updatedAtContainer.classList.add('user-container');
    // TODO: revisit dates formatting.
    updatedAtContainer.innerText =
      'Last modified: ' + formatDate(annotationInfo.updatedAt.toDate(), 'dd-MMM-yyyy', 'en-US');
    annotationContainer.appendChild(updatedAtContainer);
  }
  htmlContent.appendChild(annotationContainer);
  return htmlContent;
}

/**
 * Creates an SVG shape and positions it according to input.
 */
export function createShape(
  id: string,
  pointA: Position,
  pointB: Position,
  type: AnnotationShape,
): Element | null {
  const shape = buildShape(id, type);
  if (shape === null) {
    console.error('error building shape');
    return null;
  }
  redrawShape(shape, pointA, pointB, type);

  return shape;
}

/**
 * Repositions and resizes the provided SVG shape according to input.
 */
export function redrawShape(
  el: Element,
  pointA: Position,
  pointB: Position,
  shapeType: AnnotationShape,
) {
  switch (shapeType) {
    case AnnotationShape.RECTANGLE: {
      const topLeft: Position = {
        x: Math.min(pointA.x, pointB.x),
        y: Math.min(pointA.y, pointB.y),
      };
      const bottomRight: Position = {
        x: Math.max(pointA.x, pointB.x),
        y: Math.max(pointA.y, pointB.y),
      };
      redrawRectangle(el, topLeft, bottomRight);
      break;
    }
    default:
      // TODO(halinab): implementation for arrow and other shapes.
      break;
  }
}

/**
 * Creates SVG rectangle shape and positions it according to input.
 */
export function createRectShape(
  parent: SvgSelection,
  id: string,
  pointA: Position,
  pointB: Position,
): SvgRectSelection {
  const shape = parent.append('rect').attr('id', id);
  redrawRectShape(shape, pointA, pointB);
  return shape;
}

/**
 * Repositions and resizes the provided SVG shape according to input.
 */
export function redrawRectShape(el: SvgRectSelection, pointA: Position, pointB: Position) {
  const topLeft: Position = {
    x: Math.min(pointA.x, pointB.x),
    y: Math.min(pointA.y, pointB.y),
  };
  const bottomRight: Position = {
    x: Math.max(pointA.x, pointB.x),
    y: Math.max(pointA.y, pointB.y),
  };
  el.attr('x', `${topLeft.x}`)
    .attr('y', `${topLeft.y}`)
    .attr('width', `${bottomRight.x - topLeft.x}`)
    .attr('height', `${bottomRight.y - topLeft.y}`);
}

/**
 * Repositions provided SVG shape according to input.
 */
export function moveRectShape(
  el: SvgRectSelection,
  start: Position,
  end: Position,
  boundaryTopLeft: Position | null = null,
  boundaryBottomRight: Position | null = null,
) {
  let newPosition = {x: end.x - start.x, y: end.y - start.y};
  if (boundaryTopLeft !== null && boundaryBottomRight !== null) {
    const minXYBoundary = boundaryTopLeft;
    const maxXYBoundary = {
      x: boundaryBottomRight.x - Number(el.attr('width')),
      y: boundaryBottomRight.y - Number(el.attr('height')),
    };
    newPosition = fitInBounds(newPosition, minXYBoundary, maxXYBoundary);
  }
  el.attr('x', `${newPosition.x}`).attr('y', `${newPosition.y}`);
}

/**
 * Repositions provided SVG shape according to input.
 */
export function moveShape(
  el: Element,
  start: Position,
  end: Position,
  shapeType: AnnotationShape,
  boundaryTopLeft: Position | null = null,
  boundaryBottomRight: Position | null = null,
) {
  switch (shapeType) {
    case AnnotationShape.RECTANGLE: {
      const newPosition = {x: end.x - start.x, y: end.y - start.y};
      moveRectangle(el, newPosition, boundaryTopLeft, boundaryBottomRight);
      break;
    }
    default:
      // TODO(halinab): implementation for arrow and other shapes.
      break;
  }
}

/**
 * Builds SVG shape of given type.
 */
export function buildShape(id: string, shapeType: AnnotationShape): Element | null {
  switch (shapeType) {
    case AnnotationShape.RECTANGLE:
      return buildRectangleSvg(id);
    default:
      // TODO(halinab): implementation for arrow and other shapes.
      return null;
  }
}

/**
 * Builds SVG <rect> shape.
 */
export function buildRectangleSvg(id: string): Element {
  const rectangle = document.createElementNS(SVG_NS, 'rect');
  rectangle.setAttribute('id', id);
  return rectangle;
}

/**
 * Re-draws the rectangle at the appropriate location
 * with the provided width and height.
 */
export function redrawRectangle(
  rectangle: Element,
  topLeft: Position,
  bottomRight: Position,
): Element {
  moveRectangle(rectangle, topLeft);
  resizeRectangle(rectangle, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
  return rectangle;
}

/**
 * Changes x/y for the SVG <rect>.
 * If the boundary parameters are provided, also tries to fit the rectangle
 * in given bounds.
 */
export function moveRectangle(
  el: Element,
  topLeft: Position,
  boundaryTopLeft: Position | null = null,
  boundaryBottomRight: Position | null = null,
): Element {
  if (boundaryTopLeft !== null && boundaryBottomRight !== null) {
    const minXYBoundary = boundaryTopLeft;
    const maxXYBoundary = {
      x: boundaryBottomRight.x - Number(el.getAttribute('width')),
      y: boundaryBottomRight.y - Number(el.getAttribute('height')),
    };
    topLeft = fitInBounds(topLeft, minXYBoundary, maxXYBoundary);
  }
  el.setAttribute('x', `${topLeft.x}`);
  el.setAttribute('y', `${topLeft.y}`);
  return el;
}

/**
 * Changes width/height for the SVG <rect>.
 */
export function resizeRectangle(el: Element, width: number, height: number): Element {
  el.setAttribute('width', `${width}`);
  el.setAttribute('height', `${height}`);
  return el;
}

/**
 * Builds a random SVG shape element ID.
 */
export function buildShapeID() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substring(2, 10);
}

function fitInBounds(requested: Position, min: Position, max: Position) {
  const x = Math.min(max.x, Math.max(min.x, requested.x));
  const y = Math.min(max.y, Math.max(min.y, requested.y));
  return {x, y};
}

// Reads the Blob as DataURL using the FileReader API.
function readBlobAsDataUrl(blob: Blob, onComplete: (result: string) => void) {
  const reader = new FileReader();
  reader.onloadend = () => {
    onComplete(`${reader.result}`);
  };
  reader.readAsDataURL(blob);
}

/**
 * Transforms SVG state to data URL, which allows to define an image as a
 * Base64 encoded string of characters.
 */
export function svgToDataUrl(svg: SVGSVGElement, width: number, height: number): string {
  const svgToExport = svg.cloneNode(true) as SVGSVGElement;
  svgToExport.setAttribute('display', 'inline');
  svgToExport.setAttribute('width', `${width}`);
  svgToExport.setAttribute('height', `${height}`);

  const annotationData = new XMLSerializer().serializeToString(svgToExport);
  // Remove any characters outside the Latin1 range.
  const decoded = decodeURIComponent(encodeURIComponent(annotationData));
  const dataUrl = 'data:image/svg+xml;base64,' + btoa(decoded);
  return dataUrl;
}

/**
 * Checks annotations for equality. Returns true if 2 annotations are the
 * same.
 */
export function equals(annotationA: Annotation, annotationB: Annotation): boolean {
  if (annotationA === annotationB) {
    return true;
  }

  return (
    annotationA.shapeType === annotationB.shapeType &&
    annotationA.shapeData.height === annotationB.shapeData.height &&
    annotationA.shapeData.left === annotationB.shapeData.left &&
    annotationA.shapeData.top === annotationB.shapeData.top &&
    annotationA.shapeData.width === annotationB.shapeData.width &&
    annotationA.info.isMachineGenerated === annotationB.info.isMachineGenerated &&
    annotationA.info.machineModelVersion === annotationB.info.machineModelVersion &&
    annotationA.info.comment === annotationB.info.comment &&
    annotationA.info?.label?.value === annotationB.info?.label?.value &&
    annotationA.info?.user?.name === annotationB.info?.user?.name
  );
}

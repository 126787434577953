import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';

import {UserPreferencesService} from '../services/user_preferences_service';
import {UserTypesDialog} from './user_types_dialog';

@NgModule({
  declarations: [UserTypesDialog],
  imports: [CommonModule, FormsModule, MatDialogModule, MatSelectModule],
  exports: [UserTypesDialog],
  providers: [UserPreferencesService],
})
export class UserTypesModule {}

import {PromiseClient} from '@connectrpc/connect';
import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, combineLatest, defer, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Building} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/sunroof_pb';
import {SunroofService as SunroofServiceApi} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/sunroofservice_connect';
import {
  FindClosestBuildingRequest,
  FindClosestBuildingResponse,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/sunroofservice_pb';

import {ApiService} from './api_service';
import {LayersService} from './layers_service';

const DEFAULT_SUNROOF_URL =
  'https://storage.googleapis.com/solar_tiles_all_2018q2/tile_{x}_{y}_{z}.png';
const DEFAULT_SUNROOF_LAYER_NAME = 'Sunroof';
enum TileConfig {
  WIDTH = 256,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HEIGHT = 256,
  MIN_ZOOM = 1,
  MAX_ZOOM = 21,
}

/**
 * Solar properties.
 */
export enum SolarProperty {
  CARBON_OFFSET_FACTOR = 'Carbon offset factor',
  MAXIMUM_NUMBER_PANELS = 'Maximum number of panels',
  MAXIMUM_PANEL_AREA = 'Maximum Panel area',
  MAXIMUM_SUNSHINE_HOURS_PER_YEAR = 'Maximum sunshine hours per year',
}

/**
 * Solar stats.
 */
export interface SolarStats {
  carbonOffsetFactor: number;
  maximumNumberPanels: number;
  maximumPanelArea: number;
  maximumSunshineHoursPerYear: number;
}

const EMPTY_STATS: SolarStats = {
  carbonOffsetFactor: 0,
  maximumNumberPanels: 0,
  maximumPanelArea: 0,
  maximumSunshineHoursPerYear: 0,
};

/**
 * Service for fetching data from the Sunroof API.
 */
@Injectable({providedIn: 'root'})
export class SunroofService {
  private readonly client!: PromiseClient<typeof SunroofServiceApi>;

  constructor(
    private readonly apiService: ApiService,
    private readonly layersService: LayersService,
  ) {
    this.client = this.apiService.createSunroofServiceBEClient();
  }

  createImageTiles(layerId: string): google.maps.ImageMapType {
    const name = this.layersService.getLayerName(layerId) || DEFAULT_SUNROOF_LAYER_NAME;
    return new google.maps.ImageMapType({
      name,
      getTileUrl: (coord, zoom) => {
        const url = DEFAULT_SUNROOF_URL.replace('{x}', coord.x.toString())
          .replace('{y}', coord.y.toString())
          .replace('{z}', zoom.toString());
        return url;
      },
      tileSize: new google.maps.Size(TileConfig.WIDTH, TileConfig.HEIGHT),
      minZoom: TileConfig.MIN_ZOOM,
      maxZoom: TileConfig.MAX_ZOOM,
    });
  }

  /**
   * Calculate aggregate roof data using a feature's geometry.
   */
  getAggregateRoofData(feature: Feature): Observable<SolarStats> {
    if (!feature.geometry) {
      return of(EMPTY_STATS);
    }
    if (feature?.geometry?.geometry?.case === 'polygon') {
      const locations = feature?.geometry?.geometry?.value?.loops[0]?.points
        ?.map((point) => point.location)
        ?.map((location) => {
          return {
            lat: location?.latitude ?? 0,
            lng: location?.longitude ?? 0,
          };
        });
      return this.aggregateRoofData(locations);
    } else if (feature?.geometry?.geometry?.case === 'point') {
      const point = feature?.geometry?.geometry?.value;
      return this.aggregateRoofData([
        {
          lat: point?.location?.latitude ?? 0,
          lng: point?.location?.longitude ?? 0,
        },
      ]);
    }
    return of(EMPTY_STATS);
  }

  getSolarProperty(property: string): SolarProperty | null {
    switch (property) {
      case 'CARBON_OFFSET_FACTOR':
        return SolarProperty.CARBON_OFFSET_FACTOR;
      case 'MAXIMUM_NUMBER_PANELS':
        return SolarProperty.MAXIMUM_NUMBER_PANELS;
      case 'MAXIMUM_PANEL_AREA':
        return SolarProperty.MAXIMUM_PANEL_AREA;
      case 'MAXIMUM_SUNSHINE_HOURS_PER_YEAR':
        return SolarProperty.MAXIMUM_SUNSHINE_HOURS_PER_YEAR;
      default:
        break;
    }
    return null;
  }

  /**
   * Given a location, return Sunroof data for the nearest roof data.
   */
  getRoofData(location: google.maps.LatLngLiteral): Observable<Building> {
    const request = new FindClosestBuildingRequest({
      location: new LatLng({
        latitude: location.lat,
        longitude: location.lng,
      }),
    });
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.findClosestBuilding(request, options))
        .then((response: FindClosestBuildingResponse) => {
          if (!response.closestBuilding) {
            throw new Error('No sunroof data available.');
          }
          return response.closestBuilding!;
        }),
    );
  }

  private aggregateRoofData(
    locations: google.maps.LatLngLiteral[] | undefined,
  ): Observable<SolarStats> {
    if (!locations?.length) {
      return of(EMPTY_STATS);
    }
    const requests = locations.map((location) =>
      this.getRoofData(location).pipe(
        catchError(() => {
          return of(new Building());
        }),
      ),
    );
    return combineLatest(requests).pipe(
      map((results: Building[]) => {
        const stats = {...EMPTY_STATS};
        results.forEach((result: Building) => {
          const solarPotential = result.solarPotential;
          if (solarPotential) {
            stats.carbonOffsetFactor += solarPotential.carbonOffsetFactorKgPerMwh;
            stats.maximumNumberPanels += solarPotential.maxArrayPanelsCount;
            stats.maximumPanelArea += solarPotential.maxArrayAreaMeters2;
            stats.maximumSunshineHoursPerYear += solarPotential.maxSunshineHoursPerYear;
          }
        });
        return stats;
      }),
    );
  }
}

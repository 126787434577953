import {Metadata} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb.js';
import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {isSensitiveKey} from './properties';

/**
 * Returns the value of a key (case insensitve). If one is not found, returns
 * empty string.
 */
export function getMetadataValue(key: string, metadata: Metadata[]): string {
  const foundItem = metadata.find((item: Metadata) => item.key.toLowerCase() === key.toLowerCase());
  return foundItem ? foundItem.value : '';
}

/**
 * Sets or updates (if key exists already) a metadata value.
 */
export function setOrUpdateMetadataValue(key: string, value: string, metadata: Metadata[]) {
  for (const m of metadata) {
    if (m.key === key) {
      m.value = value;
      return;
    }
  }
  metadata.push(new Metadata({key, value}));
}

/**
 * Iterates over metadata and returns a list of keys.
 */
export function getMetadataKeys(metadata: Metadata[]): string[] {
  return metadata.map((item) => item.key).filter((key) => !!key);
}

/**
 * Converts Metadata to Property[].
 */
export function metadataToProperties(metadata: Metadata[]): Property[] {
  return metadata.map(
    (metadata: Metadata) =>
      new Property({
        key: metadata.key,
        propertyValue: {
          case: 'value',
          value: metadata.value,
        },
      }),
  );
}

/**
 * Removes sensitive metadata.
 */
export function removeSensitiveMetadata(metadata: Metadata[]): Metadata[] {
  return metadata.filter((metadata: Metadata) => !isSensitiveKey(metadata.key));
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MapLayersLegendComponent} from './map_layers_legend_component';

@NgModule({
  declarations: [MapLayersLegendComponent],
  imports: [CommonModule],
  exports: [MapLayersLegendComponent],
})
export class MapLayersLegendModule {}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {HeaderModule} from '../header/header_module';
import {ImageStudioModule} from '../image_studio/image_studio_module';
import {ImageThumbnailModule} from '../image_thumbnail/image_thumbnail_module';
import {SidepanelLayoutModule} from '../sidepanel_layout/sidepanel_layout_module';
import {GalleryPage} from './gallery';

@NgModule({
  declarations: [GalleryPage],
  imports: [
    CommonModule,
    HeaderModule,
    ImageStudioModule,
    ImageThumbnailModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule,
    RouterModule,
    SidepanelLayoutModule,
  ],
})
export class GalleryModule {}

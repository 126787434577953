import {PromiseClient} from '@connectrpc/connect';
import {Observable, ReplaySubject, defer} from 'rxjs';

import {Injectable} from '@angular/core';

import {Comment} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/comment_pb';
import {CommentService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/commentservice_connect';
import {
  AddCommentResponse,
  GetCommentsResponse,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/commentservice_pb';

import {ApiService} from './api_service';
import {HandleError, HttpErrorHandler} from './http_error_handler_service';

/**
 * Service for retrieving and adding comments.
 */
@Injectable()
export class CommentsService {
  private readonly client: PromiseClient<typeof CommentService>;

  private readonly commentsByFeatureId = new Map<string, ReplaySubject<Comment[]>>();

  private readonly handleError: HandleError;
  addCommentErrorMessage: object = {
    error: 'An error occurred when adding the comment.',
  };

  constructor(
    private readonly apiService: ApiService,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.client = apiService.createCommentServiceBEClient();
    this.handleError = httpErrorHandler.createHandleError('GetCommentResponsesService');
  }

  /**
   * Get the comments associated with a featureId.
   * @param forceFetch - whether to force a fetch even if comments are stored.
   */
  getComments(featureId: string, forceFetch: boolean): Observable<Comment[]> {
    if (!forceFetch && this.commentsByFeatureId.has(featureId)) {
      return this.commentsByFeatureId.get(featureId)!.asObservable();
    }

    if (!this.commentsByFeatureId.has(featureId)) {
      this.commentsByFeatureId.set(featureId, new ReplaySubject<Comment[]>(1));
    }

    const replaySubject = this.commentsByFeatureId.get(featureId);

    this.apiService
      .withCallOptions((options) => this.client.getComments({assetIds: [featureId]}, options))
      .then(
        (response: GetCommentsResponse) => {
          replaySubject!.next(response.comments);
        },
        () => {
          this.handleError('getCommentResponses', []);
        },
      );

    return replaySubject!.asObservable();
  }

  createComment(content: string, featureId: string): Observable<Comment | null> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) =>
          this.client.addComment({assetId: featureId, content: {body: content}}, options),
        )
        .then(
          (response: AddCommentResponse) => {
            return response.comment || null;
          },
          (error: Error) => {
            throw new Error(`Could not add comment: ${error.message}`);
          },
        ),
    );
  }

  deleteComment(commentId: string): Observable<null> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.deleteComment({commentId}, options))
        .then(
          () => {
            return null;
          },
          (error: Error) => {
            throw new Error(`Could not delete comment: ${error.message}`);
          },
        ),
    );
  }
}

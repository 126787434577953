/**
 * @fileoverview Contains functionality for manipulating IndexedDB storage.
 */

/**
 * The callbacks that can be registered with Indexed DB.
 */
export interface IndexedDbCallbacks {
  error?: (msg: string) => void;
  opened?: (db: IDBDatabase) => void;
  upgraded?: (db: IDBDatabase, version: number | null) => void;
}

/**
 * Configuration of IndexedDB object store.
 */
export interface IndexedDbObjectStore {
  name: string;
  keyPath?: string;
  indexes?: IndexedDbObjectStoreIndex[];
  recreate?: boolean;
}

/**
 * Configuration of IndexedDB object store index.
 */
export interface IndexedDbObjectStoreIndex {
  name: string;
  keyPath: string;
}

/**
 * Opens Indexed DB. In case it doesn't exist, creates it with provided
 * configuration.
 */
export function openIndexedDb(
  dbName: string,
  dbVersion: number = 1,
  dbStores: IndexedDbObjectStore[] = [],
  deprecatedDbStoreNames: string[] = [],
  dbCallbacks: IndexedDbCallbacks = {},
  idbFactory: IDBFactory | null = null,
): Promise<IDBDatabase> {
  return new Promise<IDBDatabase>((resolve, reject) => {
    if (!idbFactory && !indexedDB) {
      reject(
        "Your browser doesn't support a stable version of IndexedDB. " +
          'Offline mode will not be available. Please make sure that your ' +
          'browser uses the latest version of Chrome',
      );
    }
    const {error, opened, upgraded} = dbCallbacks;
    const openRequest = (idbFactory || indexedDB).open(dbName, dbVersion);

    openRequest.onerror = () => {
      if (error) {
        error(`There was an error opening the Indexed DB: ${openRequest.error}`);
      }
      reject(openRequest.error);
    };

    openRequest.onsuccess = () => {
      const db = openRequest.result;
      if (opened) {
        opened(db);
      }
      db.onerror = (event: Event) => {
        if (error) {
          error(`Indexed DB error: ${(event.target as IDBRequest).error}`);
        }
      };
      resolve(db);
    };

    openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;

      // Clean up deprecated stores.
      for (const storeName of deprecatedDbStoreNames) {
        deleteStore(storeName, db);
      }
      // Create or re-create requested stores.
      for (const store of dbStores) {
        if (store.recreate) {
          deleteStore(store.name, db);
        }
        if (store.recreate || !db.objectStoreNames.contains(store.name)) {
          const storeParams = store.keyPath ? {keyPath: store.keyPath} : {autoIncrement: true};
          const objectStore = db.createObjectStore(store.name, storeParams);
          for (const index of store.indexes || []) {
            objectStore.createIndex(index.name, index.keyPath, {unique: false});
          }
        }
      }
      if (upgraded) {
        upgraded(db, event.newVersion);
      }
    };
  });
}

/**
 * Attempts to delete the Indexed DB with provided name.
 */
export async function deleteIndexedDb(name: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const request = self.indexedDB.deleteDatabase(name);
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = () => {
      reject();
    };
  });
}

function deleteStore(name: string, db: IDBDatabase) {
  if (db.objectStoreNames.contains(name)) {
    db.deleteObjectStore(name);
  }
}

import {ROUTE} from '../constants/paths';

const TABLE_PATH_PATTERN = new RegExp(`^${ROUTE.TABLE}[/?].*`);

/**
 * Returns true if the path starts with /table.
 */
export function isTablePath(path: string): boolean {
  return TABLE_PATH_PATTERN.test(path);
}

/**
 * Returns true if the path starts with the routePath(ROUTE.ASSETS or
 * ROUTE.AUTOTOP) and contains layer (ROUTE.TABLE or ROUTE.MAP`).
 */
export function isPathContainsLayer(routePath: string, layer: string, path: string): boolean {
  return new RegExp(`^${routePath}${layer}[/?].*`).test(path);
}

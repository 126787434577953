import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {BannerModule} from '../banner/banner_module';
import {FeedbackService} from '../services/feedback_service';
// import {OfflineAssetsDialogsModule} from '../offline_assets_dialogs/dialogs_module';
// import {FeedbackService} from '../services/feedback_service';
import {UserPreferencesService} from '../services/user_preferences_service';
import {Header} from './header';

@NgModule({
  declarations: [Header],
  imports: [
    BannerModule,
    CommonModule,
    // OfflineAssetsDialogsModule,
    FormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    RouterModule,
  ],
  providers: [UserPreferencesService, FeedbackService],
  exports: [Header],
})
export class HeaderModule {}

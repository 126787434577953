import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

/**
 * Component for Confirmation Page on Image Upload form.
 */
@Component({
  selector: 'asset-confirmation',
  templateUrl: './asset_confirmation.ng.html',
  styleUrls: ['./asset_confirmation.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssetConfirmation {
  @Input() featureProperties: Property[] = [];
  @Input() header = '';
  @Input() isImageBlock = false;
  @Input() images: Image[] = [];
  @Output() readonly assetBlockEdit = new EventEmitter<string>();

  editFeature() {
    this.assetBlockEdit.emit(this.header);
  }
}

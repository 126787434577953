<div #satellite class="satellite"></div>
<button
  mat-icon-button
  class="close"
  (click)="goToMap()"
  aria-label="Back"
  i18n-aria-label
  matTooltip="Back">
  <mat-icon>arrow_back</mat-icon>
</button>
<div class="panel results-panel">
  <div class="header-title">Sunroof results</div>
  @if (address$ | async; as address) {
    <div>
      <mat-icon color="primary">location_on</mat-icon>
      <a
        class="address"
        href="https://www.google.com/maps/search/?api=1&query={{ address }}"
        target="_blank">
        {{ address }}
      </a>
    </div>
  }
</div>
<div class="border"></div>
<div class="panel">
  <div class="details-title">Details</div>
  <metadata [properties]="properties"></metadata>
</div>

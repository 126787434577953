<div class="solar-table container">
  @if (
    {
      featuresLoading: featuresLoading | async,
      countLoading: countLoading | async
    };
    as info
  ) {
    <div class="table-container">
      <mat-accordion displayMode="flat" togglePosition="before">
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header class="table-expansion-header">
            <h1 class="header">
              <span class="layer-name">{{ layerName || 'Loading...' }}</span>
              <div class="additional-actions">
                <button
                  class="icon"
                  color="primary"
                  aria-label="Open column menu"
                  mat-icon-button
                  disableRipple
                  i18n-aria-label
                  [matTooltip]="'Open column menu'"
                  [matMenuTriggerFor]="columnSelection"
                  (click)="$event.stopPropagation(); onColumnMenuOpen()">
                  <mat-icon>view_column</mat-icon>
                </button>
                <mat-menu #columnSelection="matMenu" xPosition="before">
                  <mat-selection-list
                    class="columns-selection"
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="selectedColumnNamesForDropdown"
                    (ngModelChange)="updateSelectedColumnNames($event)">
                    @for (column of sortedColumnNamesForDropdown; track column) {
                      <mat-list-option
                        [value]="column"
                        class="select-option"
                        checkboxPosition="before">
                        {{ column }}
                      </mat-list-option>
                    }
                  </mat-selection-list>
                </mat-menu>
              </div>
            </h1>
          </mat-expansion-panel-header>
          @if (info.featuresLoading || !layerName) {
            <mat-progress-spinner
              mode="indeterminate"
              color="primary"
              diameter="36"
              class="spinner">
            </mat-progress-spinner>
          } @else {
            <mat-table
              [dataSource]="tableData"
              class="table"
              matSort
              (matSortChange)="onSort($event)"
              (contentChanged)="onContentChanged.next()">
              @for (name of selectedColumnNames; track name) {
                <ng-container [matColumnDef]="name">
                  @if (isSortable(name)) {
                    <mat-header-cell class="table-header" mat-sort-header *matHeaderCellDef>
                      <div [innerHTML]="name"></div>
                    </mat-header-cell>
                  } @else {
                    <mat-header-cell class="not-sortable-table-header" *matHeaderCellDef>
                      {{ name }}
                    </mat-header-cell>
                  }
                  <mat-cell mat-cell *matCellDef="let data">
                    <div>
                      {{
                        name === FEEDER_CODE
                          ? getCell(name, data)
                          : (getCell(name, data) | number: '1.0-0')
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
              }
              <ng-container [matColumnDef]="editTagsColumnId">
                <mat-cell mat-cell *matCellDef="let feature" class="edit-tag">
                  <mat-checkbox
                    [checked]="isSelectedForBulkEditTags(feature)"
                    (click)="$event.stopPropagation()"
                    (change)="toggleFeatureFromTagEdit($event.checked, feature)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="selectedColumnNames"></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: selectedColumnNames"
                (click)="onRowClick(row)"
                class="table-row">
              </mat-row>
            </mat-table>
          }
          <mat-paginator
            [pageSizeOptions]="itemsPerPageOptions"
            [pageIndex]="page.pageIndex"
            [pageSize]="page.pageSize"
            [length]="getCountForPaginator()"
            [disabled]="info.featuresLoading">
          </mat-paginator>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  }
</div>

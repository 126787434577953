import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/**
 * Component for rendering the dialog displayed on form reset attempt.
 */
@Component({
  selector: 'reset-form-dialog',
  templateUrl: './reset_form_dialog.ng.html',
  styleUrls: ['./reset_form_dialog.scss'],
})
export class ResetFormDialog {
  constructor(readonly dialogRef: MatDialogRef<ResetFormDialog>) {}
}

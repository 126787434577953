<div class="table-page container">
  @if (
    {
      featuresLoading: featuresLoading | async,
      countLoading: countLoading | async,
      exportLoading: exportLoading | async
    };
    as info
  ) {
    <div class="table-container">
      <mat-accordion displayMode="flat" togglePosition="before">
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header class="table-expansion-header">
            <h1 class="header">
              <span class="layer-name">{{ layerName || 'Loading...' }}</span>
              <div class="layer-select">
                <button
                  mat-stroked-button
                  [matMenuTriggerFor]="exportToPdfControls"
                  [disabled]="info.featuresLoading || info.exportLoading || info.countLoading"
                  (click)="$event.stopPropagation()"
                  class="export-menu default-export-button">
                  Export
                  @if (!info.exportLoading) {
                    <mat-icon iconPositionEnd> arrow_drop_down </mat-icon>
                  } @else {
                    <mat-progress-spinner
                      mode="indeterminate"
                      color="primary"
                      diameter="18"
                      class="export-spinner">
                    </mat-progress-spinner>
                  }
                </button>
                <div class="mobile-export">
                  @if (info.exportLoading) {
                    <mat-progress-spinner
                      mode="indeterminate"
                      color="primary"
                      diameter="18"
                      class="export-spinner">
                    </mat-progress-spinner>
                  }
                  <button
                    mat-icon-button
                    class="mobile-export-button"
                    color="primary"
                    aria-label="Export"
                    disableRipple
                    i18n-aria-label
                    matTooltip="Open export menu"
                    [matMenuTriggerFor]="exportToPdfControls"
                    [disabled]="info.featuresLoading || info.exportLoading || info.countLoading"
                    (click)="$event.stopPropagation()">
                    <mat-icon class="material-symbols-outlined">download</mat-icon>
                  </button>
                </div>
                <mat-menu #exportToPdfControls="matMenu" xPosition="before">
                  <button mat-menu-item (click)="exportTableToCsv()">Table only (CSV)</button>
                </mat-menu>
                <mat-divider [vertical]="true" class="divider"></mat-divider>
                <div class="additional-actions">
                  <mat-slide-toggle
                    (change)="toggleEditTags($event.checked)"
                    [(ngModel)]="editingTags"
                    [disabled]="info.featuresLoading || false"
                    i18n-aria-label
                    aria-label="Bulk edit tags"
                    labelPosition="before"
                    class="bulk-edit"
                    color="primary"
                    (click)="$event.stopPropagation()">
                    <span class="toggle-label">Bulk edit tags</span>
                  </mat-slide-toggle>
                  <button
                    class="icon column-menu-trigger"
                    color="primary"
                    aria-label="Open column menu"
                    mat-icon-button
                    disableRipple
                    i18n-aria-label
                    [matTooltip]="'Open column menu'"
                    [matMenuTriggerFor]="columnSelection"
                    (click)="onColumnMenuOpen(); $event.stopPropagation()">
                    <mat-icon>view_column</mat-icon>
                  </button>
                  <mat-menu #columnSelection="matMenu" xPosition="before">
                    <mat-selection-list
                      class="columns-selection"
                      (click)="$event.stopPropagation()"
                      [(ngModel)]="selectedColumnNamesForDropdown"
                      (ngModelChange)="updateSelectedColumnNames($event)">
                      @for (column of sortedColumnNamesForDropdown; track column) {
                        <mat-list-option
                          [value]="column"
                          class="select-option"
                          checkboxPosition="before">
                          {{ column }}
                        </mat-list-option>
                      }
                    </mat-selection-list>
                  </mat-menu>
                </div>
              </div>
            </h1>
          </mat-expansion-panel-header>
          @if (info.featuresLoading || !layerName) {
            <mat-progress-spinner
              mode="indeterminate"
              color="primary"
              diameter="36"
              class="spinner">
            </mat-progress-spinner>
          } @else {
            <mat-table
              [dataSource]="tableData"
              class="table"
              matSort
              (matSortChange)="onSort($event)"
              (contentChanged)="onContentChanged.next()">
              @for (name of allColumnNames; track name) {
                <ng-container [matColumnDef]="name">
                  @if (isSortable(name)) {
                    <mat-header-cell class="table-header" mat-sort-header *matHeaderCellDef>
                      {{ name }}
                    </mat-header-cell>
                  } @else {
                    <mat-header-cell
                      class="table-header not-sortable-table-header"
                      *matHeaderCellDef>
                      {{ name }}
                    </mat-header-cell>
                  }
                  <ng-template #notSortable>
                    <mat-header-cell
                      class="table-header not-sortable-table-header"
                      *matHeaderCellDef>
                      {{ name }}
                    </mat-header-cell>
                  </ng-template>
                  <mat-cell mat-cell *matCellDef="let data">
                    @if (name === staticFeatureColumn.THUMBNAIL) {
                      <div class="thumbnail-container">
                        @if (getFirstImage(data) | async; as image) {
                          <image-thumbnail
                            class="thumbnail"
                            [image]="image"
                            [imageUrlOptions]="thumbnailImageUrlOptions">
                          </image-thumbnail>
                        }
                      </div>
                    } @else {
                      {{ getCell(name, data) }}
                    }
                    <ng-template #noImage>{{ getCell(name, data) }}</ng-template>
                  </mat-cell>
                </ng-container>
              }
              <ng-container [matColumnDef]="editTagsColumnId">
                <mat-header-cell *matHeaderCellDef class="edit-all-container">
                  <button
                    mat-flat-button
                    [disabled]="featureToTagEditById.size === 0"
                    (click)="bulkEditTags()"
                    color="primary"
                    class="edit-all">
                    Edit all ({{ featureToTagEditById.size }})
                  </button>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let feature" class="edit-tag">
                  <mat-checkbox
                    [checked]="isSelectedForBulkEditTags(feature)"
                    (click)="$event.stopPropagation()"
                    (change)="toggleFeatureFromTagEdit($event.checked, feature)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="selectedColumnNames"></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: selectedColumnNames"
                class="table-row"
                (click)="onRowClick(row)"
                [ngClass]="{selected: row.id === selectedFeatureId}">
              </mat-row>
            </mat-table>
          }
          <mat-paginator
            [pageSizeOptions]="itemsPerPageOptions"
            [pageIndex]="page.pageIndex"
            [pageSize]="page.pageSize"
            [length]="getCountForPaginator()"
            [disabled]="info.featuresLoading">
          </mat-paginator>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  }
</div>

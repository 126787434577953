// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/image.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";
import { Metadata } from "./common_pb.js";
import { Tag } from "./tag_pb.js";
import { RelatedFeaturesGroup } from "./related_feature_pb.js";
import { LatLng } from "../../../../google/type/latlng_pb.js";

/**
 * @generated from enum tapestry.gridaware.api.v1.ImageUploadError
 */
export const ImageUploadError = proto3.makeEnum("tapestry.gridaware.api.v1.ImageUploadError", [{
  no: 0,
  name: "UNSPECIFIED"
}, {
  no: 452,
  name: "MISSING_LOCATION"
}, {
  no: 453,
  name: "UNSUPPORTED_BLOB"
}]);

/**
 * An Image is a frontend-friendly object for representing an uploaded image.
 *
 * @generated from message tapestry.gridaware.api.v1.Image
 */
export const Image = proto3.makeMessageType("tapestry.gridaware.api.v1.Image", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "asset_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 4,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "original_file_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "state",
  kind: "enum",
  T: proto3.getEnumType(Image_ImageState)
}, {
  no: 6,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "user",
  kind: "message",
  T: User
}, {
  no: 8,
  name: "location",
  kind: "message",
  T: ImageLocation
}, {
  no: 9,
  name: "uploaded_at",
  kind: "message",
  T: Timestamp
}, {
  no: 18,
  name: "captured_at",
  kind: "message",
  T: Timestamp
}, {
  no: 10,
  name: "exif_metadata",
  kind: "message",
  T: ExifMetadata
}, {
  no: 11,
  name: "metadata",
  kind: "message",
  T: Metadata,
  repeated: true
}, {
  no: 13,
  name: "tags",
  kind: "message",
  T: Tag,
  repeated: true
}, {
  no: 14,
  name: "related_features_groups",
  kind: "message",
  T: RelatedFeaturesGroup,
  repeated: true
}, {
  no: 15,
  name: "external_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 16,
  name: "blob_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "data_source"
}, {
  no: 17,
  name: "external_image_id",
  kind: "message",
  T: ExternalImageId,
  oneof: "data_source"
}, {
  no: 19,
  name: "gcs_uri",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "data_source"
}]);

/**
 * A lifecycle state for an image.
 *
 * @generated from enum tapestry.gridaware.api.v1.Image.ImageState
 */
export const Image_ImageState = proto3.makeEnum("tapestry.gridaware.api.v1.Image.ImageState", [{
  no: 0,
  name: "STATE_UNSPECIFIED"
}, {
  no: 1,
  name: "ACTIVE"
}, {
  no: 2,
  name: "SOFT_DELETED"
}, {
  no: 3,
  name: "PENDING"
}, {
  no: 4,
  name: "INACTIVE"
}]);

/**
 * An ID for an image that's hosted in an external system.
 *
 * @generated from message tapestry.gridaware.api.v1.ExternalImageId
 */
export const ExternalImageId = proto3.makeMessageType("tapestry.gridaware.api.v1.ExternalImageId", () => [{
  no: 1,
  name: "gumi",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "id"
}]);

/**
 * A header image for a feature.
 *
 * @generated from message tapestry.gridaware.api.v1.HeaderImage
 */
export const HeaderImage = proto3.makeMessageType("tapestry.gridaware.api.v1.HeaderImage", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "exif_metadata",
  kind: "message",
  T: ExifMetadata
}]);

/**
 * Information about an image's location, including where the image is
 * "located" as well as location accuracy and bearing, when relevant.
 *
 * @generated from message tapestry.gridaware.api.v1.ImageLocation
 */
export const ImageLocation = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageLocation", () => [{
  no: 1,
  name: "location",
  kind: "message",
  T: LatLng
}, {
  no: 2,
  name: "bearing",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 3,
  name: "accuracy",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);

/**
 * A subset of image EXIF data that's relevant to our application. Refer to your
 * favorite search engine for more information about EXIF data.
 *
 * @generated from message tapestry.gridaware.api.v1.ExifMetadata
 */
export const ExifMetadata = proto3.makeMessageType("tapestry.gridaware.api.v1.ExifMetadata", () => [{
  no: 1,
  name: "orientation",
  kind: "enum",
  T: proto3.getEnumType(ExifMetadata_Orientation)
}, {
  no: 2,
  name: "image_projection",
  kind: "enum",
  T: proto3.getEnumType(ExifMetadata_Projection)
}]);

/**
 * The orientation (rotation) of an image.
 *
 * @generated from enum tapestry.gridaware.api.v1.ExifMetadata.Orientation
 */
export const ExifMetadata_Orientation = proto3.makeEnum("tapestry.gridaware.api.v1.ExifMetadata.Orientation", [{
  no: 0,
  name: "ORIENTATION_UNSPECIFIED"
}, {
  no: 1,
  name: "DEFAULT"
}, {
  no: 2,
  name: "ROTATED_90_CW"
}, {
  no: 3,
  name: "ROTATED_180_CW"
}, {
  no: 4,
  name: "ROTATED_270_CW"
}]);

/**
 * The projection of an image -- namely, identifies whether it's a flat or
 * 360 photo.
 *
 * @generated from enum tapestry.gridaware.api.v1.ExifMetadata.Projection
 */
export const ExifMetadata_Projection = proto3.makeEnum("tapestry.gridaware.api.v1.ExifMetadata.Projection", [{
  no: 0,
  name: "IMAGE_PROJECTION_UNSPECIFIED"
}, {
  no: 1,
  name: "RECTILINEAR"
}, {
  no: 2,
  name: "SPHERICAL_PANO"
}]);

/**
 * Image metadata to be used when creating an image uploaded through scotty.
 * The values of this proto are being set by the client.
 *
 * @generated from message tapestry.gridaware.api.v1.ImageMetadata
 */
export const ImageMetadata = proto3.makeMessageType("tapestry.gridaware.api.v1.ImageMetadata", () => [{
  no: 1,
  name: "original_file_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "description",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "image_location",
  kind: "message",
  T: ImageLocation
}, {
  no: 5,
  name: "tags",
  kind: "message",
  T: Tag,
  repeated: true
}, {
  no: 6,
  name: "captured_at",
  kind: "message",
  T: Timestamp
}]);
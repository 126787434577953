/**
 * How the image layer looks in the path.
 */
export const IMAGE_LAYER_IN_PATH = 'photo';
/**
 * Used in the routing module to set up the routes.
 */
export const LAYER_PARAM_KEY = 'layer_key';

/**
 * Used in the routing module to set up the routes.
 */
export const FEATURE_PARAM_KEY = 'feature_id';

export const IMAGE_PARAM_KEY = 'image_id';

/**
 * Used for Solar Insights for url path param.
 */
export const FEEDER_NAME = 'feeder_name';

/**
 * Navigation routes.
 */
export enum ROUTE {
  AUTOTOP = '/autotop',
  ASSETS = '/assets',
  GALLERY = '/gallery',
  LAYER_CONFIG = '/settings',
  LIGHTBOX = '/lightbox',
  LOADING = '/login/loading',
  LOGIN = '/login',
  MAP = '/map',
  OFFLINE = '/offline',
  PHOTO_DETAILS = '/lightbox/photo',
  PHOTO_UPLOAD = '/upload',
  PENDING_UPLOAD = '/upload/pending',
  TABLE = '/table',
  TABLE_V2 = '/tablev2',
  // The following routes are dynamic children routes.
  ASSET_DETAILS_PREFIX = 'assets',
  IMAGE_DETAILS_PREFIX = 'images',
  FEATURE_DETAILS_PREFIX = 'features',
  FEEDER_DETAILS_MAP = '/map/solar',
  FEEDER_DETAILS_TABLE = '/solar/details/table',
}

/** Constants for URL query params. */
export const QUERY_PARAMS = {
  LATITUDE: 'lat',
  LONGITUDE: 'lon',
  ASSET_ID: 'assetId',
  IMAGE_ID: 'imageId',
  LAYER_ID: 'layerId',
  FEATURE_ID: 'featureId',
  IMAGE_INDEX: 'imageIndex',
  VIEW_ID: 'viewId',
  LAYER_TYPE: 'layerType',
  SOURCE_URL: 'sourceUrl',
  // Indicates whether the page should render in edit mode (if applicable).
  EDIT: 'edit',
  // Signals that the application should be a in a debug mode. This may
  // result in obfuscation changes such as hiding map labels.
  DEBUG: 'debug',
  // External ID (i.e. client ID) of the asset.
  EXT_ASSET_ID: 'extAssetId',
  // Used to signal that the pending state (unsubmitted changes) made in upload
  // page should be preserved on page navigation. Use case: annotating a newly
  // uploaded image before saving.
  PRESERVE_STATE: 'preserveState',
  // Indicates whether the updates should be saved within receiving component.
  // In components supporting this logic can be set to false to indicate that
  // parent component will be performing the save as part of its separate flow.
  SAVE_ON_SUBMIT: 'saveOnSubmit',
  // ID of the pending upload. Used in edit scenario for pending uploads.
  PENDING_UPLOAD_ID: 'pendingUpload',
  // Indicates whether upon finishing any edits on current page user should be
  // taken back to the originating url.
  // Main component supporting this logic is ImageStudio.
  RETURN_ON_EXIT: 'returnOnExit',
  // Boolean that overrides all other settings to show or hide the carousel in
  // the image studio. It can be useful to initially hide the carousel
  // for faster annotation editing.
  HIDE_CAROUSEL: 'hideCarousel',
  // String which encodes the current filter state. For information on
  // how this is encoded see go/gridaware-deep-links-to-filter-state.
  FILTER_STATE: 'fs',
  // The current map location, as expressed by a lattitude, longitude, and zoom level.
  LOCATION: 'loc',
};

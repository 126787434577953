import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

// import {AssetDetailsVrPhotosModule} from '../../../asset_details_vr_photos/asset_details_vr_photos_module';
import {Lightbox} from './lightbox';

@NgModule({
  declarations: [Lightbox],
  imports: [
    // AssetDetailsVrPhotosModule,
    CommonModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [Lightbox],
})
export class LightboxModule {}

// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/userservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CheckAccessRequest, CheckAccessResponse, GetUserRequest, ListUserTypesRequest, ListUserTypesResponse } from "./userservice_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * @generated from service tapestry.gridaware.api.v1.UserService
 */
export const UserService = {
  typeName: "tapestry.gridaware.api.v1.UserService",
  methods: {
    /**
     * ListUserTypes fetches all available user types.
     *
     * @generated from rpc tapestry.gridaware.api.v1.UserService.ListUserTypes
     */
    listUserTypes: {
      name: "ListUserTypes",
      I: ListUserTypesRequest,
      O: ListUserTypesResponse,
      kind: MethodKind.Unary
    },
    /**
     * Checks whether a user has access to GridAware.
     * This RPC has no input or output because it uses the JWT token from the call
     * context, and throws an error if the user is not authorized.
     *
     * @generated from rpc tapestry.gridaware.api.v1.UserService.CheckAccess
     */
    checkAccess: {
      name: "CheckAccess",
      I: CheckAccessRequest,
      O: CheckAccessResponse,
      kind: MethodKind.Unary
    },
    /**
     * Returns a User resource for the user identified by the incoming JWT token.
     * Returns NOT_FOUND if the user does not exist in storage, yet.
     * buf:lint:ignore RPC_REQUEST_STANDARD_NAME
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     *
     * @generated from rpc tapestry.gridaware.api.v1.UserService.GetRequestingUser
     */
    getRequestingUser: {
      name: "GetRequestingUser",
      I: Empty,
      O: User,
      kind: MethodKind.Unary
    },
    /**
     * Returns a single User resource.
     * buf:lint:ignore RPC_RESPONSE_STANDARD_NAME
     * buf:lint:ignore RPC_REQUEST_RESPONSE_UNIQUE
     *
     * @generated from rpc tapestry.gridaware.api.v1.UserService.GetUser
     */
    getUser: {
      name: "GetUser",
      I: GetUserRequest,
      O: User,
      kind: MethodKind.Unary
    }
  }
};
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

import {QUERY_PARAMS, ROUTE} from '../../constants/paths';
import {AnnotationsService} from '../../services/annotations_service';
import {NetworkService} from '../../services/network_service';
import {UploadService} from '../../services/upload_service';
import {PendingAnnotatedImage} from '../../typings/annotations';

/**
 * Image thumbnail box and additional metadata component.
 */
@Component({
  selector: 'image-box',
  templateUrl: './image_box.ng.html',
  styleUrls: ['./image_box.scss'],
})
export class ImageBox {
  // Image ID.
  @Input() id = '';

  // Image URL.
  @Input() url = '';

  // Image name.
  @Input() name = '';

  // Indicates whether the provided image is newly uploaded, i.e. doesn't yet
  // exists in our system.
  @Input() isNewUpload = false;

  // Whether the site is in offline mode.
  offline = false;

  // Whether the upload form is opened in a dialog.
  uploadFormOpenedInDialog = false;

  // Callback on image deletion.
  @Output() readonly onDelete = new EventEmitter();

  // Number of annotations applied to this image.
  annotationCount = 0;

  // Updated URL of an image with reflected annotations state.
  annotatedImageUrl = '';

  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly annotationsService: AnnotationsService,
    private readonly networkService: NetworkService,
    private readonly router: Router,
    private readonly uploadService: UploadService,
  ) {}

  ngOnInit() {
    this.uploadFormOpenedInDialog = this.uploadService.isUploadFormOpenedInDialog();
    this.listenForImageAnnotationStateChanges();
    this.networkService
      .getOffline$()
      .pipe(takeUntil(this.destroyed))
      .subscribe((isOffline: boolean) => {
        this.offline = isOffline;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  // Navigates to the image annotation page.
  goToImageAnnotation() {
    if (this.uploadFormOpenedInDialog) {
      this.uploadService.setUploadDialogInterrupted(true);
    }
    const queryParams: {[key: string]: string | boolean} = {
      [QUERY_PARAMS.IMAGE_ID]: this.id,
      [QUERY_PARAMS.SOURCE_URL]: this.setPreservedStateForUrl(this.router.url),
      [QUERY_PARAMS.EDIT]: true,
      [QUERY_PARAMS.RETURN_ON_EXIT]: true,
    };
    this.router.navigate([ROUTE.LIGHTBOX], {queryParams});
  }

  // Queries the image for deletion.
  deleteImage() {
    this.onDelete.emit();
  }

  // Updates URL by adding query parameter indicating that state should be
  // preserved.
  private setPreservedStateForUrl(url: string): string {
    const urlTree = this.router.parseUrl(url);
    if (!this.uploadFormOpenedInDialog) {
      urlTree.queryParams[QUERY_PARAMS.PRESERVE_STATE] = 'true';
    }
    return urlTree.toString();
  }

  private listenForImageAnnotationStateChanges() {
    this.annotationsService
      .getPendingAnnotations(this.id)
      .pipe(takeUntil(this.destroyed))
      .subscribe((annotationsData: PendingAnnotatedImage) => {
        this.annotationCount = annotationsData.annotations.length;
        this.annotatedImageUrl = annotationsData.updatedImadeURL;
      });
  }
}

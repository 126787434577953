import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import {ImageAnnotationLabel} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';

import {LABELS} from '../constants/annotations';
import {type LabelInfo} from '../typings/annotations';

/**
 * The emitted label selection.
 */
export interface LabelSelection {
  label: LabelInfo;
  comment: string;
}

/**
 * Settings for the overlay header.
 */
export interface Header {
  text: string;
  style: {
    color: string;
  };
}

interface Form {
  label: FormControl<LabelInfo | null>;
  comment: FormControl<string | null>;
}

/**
 * Component for rendering selection of a label and comment.
 */
@Component({
  selector: 'label-selector',
  templateUrl: './label_selector.ng.html',
  styleUrls: ['./label_selector.scss'],
})
export class LabelSelector implements OnInit, AfterViewInit {
  @Input() header: Header = {text: 'Apply label', style: {color: 'var(--on-surface)'}};
  @Input() existingLabel: LabelInfo | null = null;
  @Input() existingComment = '';
  @Output() readonly onApply = new EventEmitter<LabelSelection>();
  @Output() readonly onDelete = new EventEmitter<void>();

  @ViewChild('searchText', {read: ViewContainerRef})
  searchInput!: ViewContainerRef;

  // All possible labels that can be applied.
  readonly labels = LABELS.sort((a, b) => a.label.localeCompare(b.label));
  form: FormGroup<Form> | null = null;
  placeholderText = 'Comment (optional)';

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group<Form>(
      {
        label: new FormControl(this.existingLabel, [Validators.required]),
        comment: new FormControl(this.existingComment),
      },
      {updateOn: 'change', validators: [selectorValidator]},
    );
    this.form.controls.label.valueChanges.subscribe((info: LabelInfo | null) => {
      this.placeholderText =
        info?.value === ImageAnnotationLabel.OTHER_LABEL
          ? 'Comment (required)'
          : 'Comment (optional)';
    });
  }

  ngAfterViewInit() {
    // Prevents ExpressionChangedAfterItHasBeenCheckedError.
    setTimeout(() => {
      this.searchInput?.element.nativeElement.focus();
    });
  }

  apply() {
    this.onApply.emit({
      label: this.form!.value.label!,
      comment: this.form!.value.comment || '',
    });
    this.form!.reset();
  }

  deleteAnnotation() {
    this.form!.reset();
    this.onDelete.emit();
  }

  selectLabel(label: LabelInfo) {
    // This is a hack to select the radio button when clicking anywhere in its
    // div which extends the width of the modal. Targeting the mdc element
    // selectors to accomplish this is not recommended as they could change
    // without notice.
    this.form!.controls.label.setValue(label);
  }
}

const COMMENT_MAX_LENGTH = 150;

// The recommended way to handle form validation with dependency across controls
// https://angular.io/guide/form-validation#adding-cross-validation-to-reactive-forms
const selectorValidator: ValidatorFn = (
  control: AbstractControl<FormGroup<Form>>,
): ValidationErrors | null => {
  const label: AbstractControl<LabelInfo | null> | null = control.get('label');
  const comment: AbstractControl<string | null> | null = control.get('comment');
  if (label?.value?.value === ImageAnnotationLabel.OTHER_LABEL && !comment?.value) {
    return {commentRequired: {value: 'comment required for "Other" label'}};
  }
  if (comment?.value && comment.value.length > COMMENT_MAX_LENGTH) {
    return {
      maxLength: {
        value: `${comment.value.length} is greater than comment max character length of ${COMMENT_MAX_LENGTH}`,
      },
    };
  }
  return null;
};

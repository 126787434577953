<div class="breadcrumb-panel">
  <div class="breadcrumb-navigation">
    <button
      class="button-root"
      mat-button
      (click)="navigateToRoot()"
      [matTooltip]="navigateToRootTooltip()">
      {{ rootPath === '/table' ? 'Table' : 'Map' }}
    </button>
    @if (title) {
      <mat-icon>chevron_right</mat-icon>
      <button
        class="button-title"
        mat-button
        (click)="navigateToFeature()"
        [matTooltip]="navigateToFeatureTooltip()">
        {{ title }}
      </button>
    }
    @if (showImagesCrumb) {
      <span class="images-breadcrumb mat-mdc-button">
        <mat-icon>chevron_right</mat-icon>
        <span class="breadcrumb"> Images </span>
      </span>
    }
  </div>
</div>

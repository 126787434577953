import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, ReplaySubject, Subject, firstValueFrom, of} from 'rxjs';
import {
  catchError,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Layer} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {ROUTE} from '../../constants/paths';
import {IconSelectedFn, MarkersFactory} from '../../factories/markers_factory';
import {SelectedFeature} from '../../search/search';
import {FeaturesService} from '../../services/features_service';
import {GoogleMapsService, LOCATION_NOT_AVAILABLE_TEXT} from '../../services/google_maps_service';
import {MapService} from '../../services/map_service';
import {Marker} from '../../typings/map';

// Need this so that the minifier does minify these properties in OnChanges.
declare interface PropertyChanges extends SimpleChanges {
  initialMapMetadata: SimpleChange;
}

/**
 * Metadata that is used to set the initial map state. Metadata will be provided
 * in the case of an edit or navigating to the upload page from an existing
 * asset.
 */
export interface InitialMapMetadata {
  // The ID of the initially selected feature on the map.
  featureId: string;
  // The initial map center.
  center: LatLng;
}

// Indicates the maximum length of time (in milliseconds) the device is
// allowed to take in order to return a position.
const GEOLOCATION_TIMEOUT_MS = 5000;
// Indicates the maximum age (in milliseconds) of a possible cached position
// that is acceptable to return.
const GEOLOCATION_MAX_STALENESS_MS = 10000;
// Other marker z-index values are in map_properties_service.ts
const LOCATION_PIN_Z_INDEX = 101;
const ERROR_TOAST_DURATION = 1000;
const INITIAL_ZOOM_LEVEL = 14;
const FOCUSED_ZOOM_LEVEL = 17;

/**
 * Component for rendering an image.
 */
@Component({
  selector: 'feature-selection-map',
  templateUrl: 'feature_selection_map.ng.html',
  styleUrls: ['feature_selection_map.scss'],
})
export class FeatureSelectionMap implements AfterViewInit, OnChanges, OnDestroy {
  // The initial asset, asset-selection-enabled bool, and location for the map's
  // center.
  @Input() initialMapMetadata: InitialMapMetadata | null = null;
  @Input() locationDescriptionVisible = true;
  // The layer IDs of the layer to populate the map with.
  @Input() layerIds: string[] = [];

  // The Input-Output pairs that are below allow for [()] <-- two-way binding.
  // The 'Change' suffix is required by angular.
  // The human-readable location of a pin or marker.
  @Input() formattedLocation = '';
  @Output() formattedLocationChange = new EventEmitter<string>();
  @Input() locationDescription = '';
  @Output() locationDescriptionChange = new EventEmitter<string>();

  @Output() featureSelected = new EventEmitter<Feature | null>();
  @Output() locationPinChanged = new EventEmitter<LatLng | null>();
  @Output() manualLocationChanged = new EventEmitter<boolean>();
  @Output() accuracyChanged = new EventEmitter<number>();

  @ViewChild('autocomplete', {static: false}) autocompleteInput!: ElementRef;
  autocomplete!: google.maps.places.Autocomplete;

  @ViewChild('map', {static: false}) mapElement!: ElementRef;
  map!: google.maps.Map;

  @ViewChild('checkbox', {static: false, read: MatCheckbox})
  checkbox!: MatCheckbox;

  // The feature that is selected on the map.
  selectedFeatureId = '';
  // The marker that has been selected.
  selectedMarker: Marker | null = null;
  // Whether the user is choosing the location manually. If true, don't update
  // the pin based on the browser's user agent location.
  manualLocation = false;
  latLng!: google.maps.LatLng;
  // The inferred image location accuracy, in meters.
  accuracy = 0;
  markerByFeatureId = new Map<string, Marker>();
  featureSelectionEnabled = true;
  // Shows the progress spinner when fetching.
  fetchingCount = 0;
  // The pipe used to fetch and render features based on the map's bounds.
  fetchAndRenderFeaturesSubjects = new Map<string, Subject<google.maps.LatLngBounds>>();
  marker: google.maps.Marker | null = null;
  locationPin: google.maps.Marker | null = null;
  // The ID of the geolocation position watcher. This ID will be returned from
  // navigator.geolocation.watchPosition(success, error, options) and used to
  // clear the watcher when the component is destroyed.
  watchPositionId = 0;
  // Set to true when the map idles. This will be set to false afterwards.
  mapReady = false;
  mapReadyUpdates = new ReplaySubject<void>();
  destroyed = new Subject<void>();
  activeLayers: Observable<Layer[]> | null = null;

  private readonly iconSelectedFn: IconSelectedFn = (marker: Marker, feature: Feature) => {
    let point = null;
    if (feature.geometry?.geometry.case === 'point') {
      point = feature.geometry.geometry.value;
    }
    const location = point?.location;
    const lat = location?.latitude || 0;
    const lng = location?.longitude || 0;
    if (!lat && !lng) {
      console.warn('latitude and longitude missing for feature with ID: ', feature.id);
      return;
    }
    this.deselectFeature();
    this.setMapLocation(location!, false);
    this.setFormattedLocation(new google.maps.LatLng(lat, lng));
    this.setSelectedMarker(marker);
    this.zoomInOnFeatureSelection();
    this.featureSelected.emit(feature);
  };

  constructor(
    private readonly featuresService: FeaturesService,
    private readonly googleMapsService: GoogleMapsService,
    private readonly markersFactory: MarkersFactory,
    private readonly mapService: MapService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {}

  async ngOnChanges(propertyChanges: PropertyChanges) {
    if (propertyChanges?.initialMapMetadata?.currentValue) {
      await firstValueFrom(this.mapReadyUpdates.pipe(first(), takeUntil(this.destroyed)));
      this.setManualLocation(true);
      const {center, featureId} = this.initialMapMetadata!;
      this.selectedFeatureId = featureId;
      this.featureSelectionEnabled = !!featureId;
      this.setMapLocation(center, false);
      if (featureId) {
        this.clearLocationPin();
        return;
      }
      this.toggleFeatureSelection(true);
    }
  }

  ngAfterViewInit() {
    this.initMap();
    this.bindAutocomplete();
    this.startGeolocationPolling();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    navigator.geolocation.clearWatch(this.watchPositionId);
  }

  onFeatureSelected(selectedFeature: SelectedFeature) {
    if (selectedFeature.feature.geometry?.geometry.case !== 'point') {
      return;
    }
    const latLng = selectedFeature.feature.geometry?.geometry.value.location;
    if (!latLng) {
      return;
    }
    this.setMapLocation(latLng, false);
    this.zoomInOnFeatureSelection();
    this.selectedFeatureId = selectedFeature.feature.id;
    this.featureSelected.emit(selectedFeature.feature);
  }

  getActiveLayers(): Observable<string[]> {
    return of(this.layerIds);
  }

  /**
   * Initializes the Google Maps element.
   */
  private initMap() {
    this.latLng = this.mapService.getSavedCenter();
    const mapProperties: google.maps.MapOptions = {
      // Without center set, sometimes the map will fail to load.
      center: this.latLng,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      rotateControl: false,
      streetViewControl: false,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{visibility: 'off'}],
        },
      ],
      zoom: INITIAL_ZOOM_LEVEL,
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

    this.map.addListener('dragstart', () => {
      this.setManualLocation(true);
    });

    this.map.addListener('idle', () => {
      this.setMapReady();
      if (!this.map.getBounds() || !this.featureSelectionEnabled) {
        return;
      }
      this.fetchAndRenderAllFeatures();
    });

    this.map.addListener('click', () => {
      this.deselectFeature();
    });
  }

  private fetchAndRenderAllFeatures() {
    for (const layerId of this.layerIds) {
      this.fetchAndRenderFeatures$(layerId).next(this.map.getBounds()!);
    }
  }

  private fetchAndRenderFeatures$(layerId: string): Subject<google.maps.LatLngBounds> {
    if (this.fetchAndRenderFeaturesSubjects.has(layerId)) {
      return this.fetchAndRenderFeaturesSubjects.get(layerId)!;
    }
    const subject = new Subject<google.maps.LatLngBounds>();
    this.fetchAndRenderFeaturesSubjects.set(layerId, subject);
    subject
      .pipe(
        tap(() => {
          this.fetchingCount++;
        }),
        switchMap((bounds: google.maps.LatLngBounds) => {
          return this.featuresService.queryFeaturesInBounds(layerId, bounds, {}, false).pipe(
            first(),
            finalize(() => {
              this.fetchingCount--;
            }),
            catchError((error: Error) => {
              this.snackBar.open('Could not load features.', '', {
                duration: ERROR_TOAST_DURATION,
              });
              console.error(`Could not load features: ${error.message}`);
              return of([] as Feature[]);
            }),
            takeWhile(() => this.featureSelectionEnabled),
          );
        }),
        map((features: Feature[]): Feature[] => {
          return features.filter((feature: Feature) => {
            return (
              !this.markerByFeatureId.has(feature.id) && feature.geometry?.geometry.case === 'point'
            );
          });
        }),
        map((features: Feature[]): Marker[] => {
          return this.markersFactory.createFeatureMarkers(features, layerId, this.iconSelectedFn);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: (markers: Marker[]) => {
          for (const marker of markers) {
            marker.marker.setMap(this.map);
            this.markerByFeatureId.set(marker.featureMetadata.feature.id, marker);
          }
          if (this.selectedFeatureId) {
            const marker = this.markerByFeatureId.get(this.selectedFeatureId);
            if (marker) {
              google.maps.event.trigger(marker.marker, 'click');
              this.selectedFeatureId = '';
            }
          }
        },
        error: () => {
          this.snackBar.open('Could not load features.', '', {
            duration: ERROR_TOAST_DURATION,
          });
          this.fetchingCount = 0;
        },
      });
    return subject;
  }

  /**
   * Gets the current browser geolocation data and sets up a watcher in case
   * updated location information comes in.
   */
  private startGeolocationPolling() {
    const geolocationOptions = {
      enableHighAccuracy: true,
      maximumAge: GEOLOCATION_MAX_STALENESS_MS,
      timeout: GEOLOCATION_TIMEOUT_MS,
    };
    this.watchPositionId = navigator.geolocation.watchPosition(
      (position: GeolocationPosition) => {
        if (this.manualLocation) {
          return;
        }
        this.setLocationFromGeolocation(position);
      },
      () => {
        // Silently fail
      },
      geolocationOptions,
    );
  }

  /**
   * Uses geolocation position to update location and accuracy.
   * @param position - The position containing the coordinates for the user's
   * location.
   */
  private setLocationFromGeolocation(position: GeolocationPosition) {
    if (this.manualLocation) {
      return;
    }
    const coords = position.coords;
    this.accuracy = coords.accuracy;
    this.accuracyChanged.emit(this.accuracy);
    const location = new LatLng({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
    this.setMapLocation(location, true);
    this.setAutocompleteBounds();
  }

  private setMapLocation(location: LatLng, dropPin: boolean) {
    this.latLng = new google.maps.LatLng(location.latitude, location.longitude);
    this.setFormattedLocation(this.latLng);
    this.updateMapCenter(this.latLng);
    if (dropPin) {
      this.dropLocationPin(this.latLng);
    }
  }

  private deselectFeature() {
    if (this.selectedMarker?.marker instanceof google.maps.Marker) {
      this.mapService.updateMarkerSelectedState(this.selectedMarker.marker, false);
    }
    if (this.selectedMarker) {
      this.selectedMarker = null;
      this.featureSelected.emit(null);
    }
    if (this.featureSelectionEnabled) {
      this.clearLocationPin();
    }
  }

  private setSelectedMarker(marker: Marker) {
    if (marker.marker instanceof google.maps.Marker) {
      this.mapService.updateMarkerSelectedState(marker.marker, true);
    }
    this.selectedMarker = marker;
  }

  private zoomInOnFeatureSelection() {
    this.map.setZoom(FOCUSED_ZOOM_LEVEL);
  }

  toggleFeatureSelection(disableFeatureSelection: boolean) {
    this.featureSelectionEnabled = !disableFeatureSelection;
    if (!this.locationPin && disableFeatureSelection) {
      this.dropLocationPin(this.latLng);
    }
    this.locationPin?.setDraggable(disableFeatureSelection);
    this.emitLocationPinChanged();
    if (this.featureSelectionEnabled && this.map.getBounds()) {
      this.fetchAndRenderAllFeatures();
    }
    if (!this.featureSelectionEnabled) {
      this.deselectFeature();
      this.removeFeatureMarkersFromMap();
    }
  }

  private removeFeatureMarkersFromMap() {
    for (const marker of this.markerByFeatureId.values()) {
      marker.marker.setMap(null);
    }
    this.markerByFeatureId.clear();
  }

  /**
   * Bind autocomplete input.
   */
  private bindAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.nativeElement);
    if (this.latLng) {
      this.setAutocompleteBounds();
    }
    this.autocomplete.addListener('place_changed', () => {
      const location = this.autocomplete.getPlace()?.geometry?.location;
      if (location) {
        this.setManualLocation(true);
        this.setMapLocation(
          new LatLng({latitude: location.lat(), longitude: location.lng()}),
          true,
        );
        this.setAutocompleteBounds();
      }
    });
  }

  /**
   * Sets the preferred area within which to return Place results to the current
   * latLng. Results are biased towards, but not restricted to, this area.
   */
  private setAutocompleteBounds() {
    if (!this.autocomplete) {
      return;
    }
    const circle = new google.maps.Circle({
      center: this.latLng,
      radius: this.accuracy || 0,
    });
    this.autocomplete.setBounds(circle.getBounds() || undefined);
  }

  private dropLocationPin(position: google.maps.LatLng) {
    if (positionsAreEqual(this.locationPin?.getPosition() || undefined, position || undefined)) {
      return;
    }
    this.clearLocationPin();
    this.locationPin = new google.maps.Marker({
      draggable: !this.featureSelectionEnabled,
      position,
      map: this.map,
      zIndex: LOCATION_PIN_Z_INDEX,
    });
    this.emitLocationPinChanged();
    this.locationPin.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.latLng = event.latLng;
      }
      this.emitLocationPinChanged();
      this.setManualLocation(true);
      this.setFormattedLocation(this.latLng);
    });
  }

  private clearLocationPin() {
    if (!this.locationPin) {
      return;
    }
    this.locationPin.setMap(null);
    this.locationPin = null;
    this.emitLocationPinChanged();
  }

  private emitLocationPinChanged() {
    const locationOrNull = this.featureSelectionEnabled ? null : googleMapsToApiLatLng(this.latLng);
    this.locationPinChanged.emit(locationOrNull);
  }

  private setManualLocation(isManualLocation: boolean) {
    this.manualLocation = isManualLocation;
    this.manualLocationChanged.emit(isManualLocation);
  }

  private async setFormattedLocation(location: google.maps.LatLng) {
    const formattedLocation = await firstValueFrom(
      this.googleMapsService.getAddressFromLatLng(location.toJSON()),
    );
    this.updateFormattedLocation(formattedLocation);
  }

  /**
   * Updates the map center on the embedded map and moves the marker to
   * track the new center.
   * @param latLng - The new coordinates to use for the map center.
   */
  private updateMapCenter(latLng: google.maps.LatLng) {
    this.map.setCenter(latLng);
  }

  private setMapReady() {
    if (this.mapReady) {
      return;
    }
    this.mapReady = true;
    this.mapReadyUpdates.next();
  }

  updateFormattedLocation(value: string) {
    if (this.formattedLocation && value === LOCATION_NOT_AVAILABLE_TEXT) {
      return;
    }
    this.formattedLocation = value;
    this.formattedLocationChange.emit(value);
  }

  updateLocationDescription(value: string) {
    this.locationDescription = value;
    this.locationDescriptionChange.emit(value);
  }

  createMapFeaturePath(): string {
    const id = this.selectedMarker!.featureMetadata.feature.id;
    return this.router.serializeUrl(
      this.router.createUrlTree([ROUTE.MAP, this.selectedMarker!.featureMetadata.layerId, id]),
    );
  }
}

function positionsAreEqual(
  positionA: google.maps.LatLng | undefined,
  positionB: google.maps.LatLng | undefined,
) {
  return positionA && positionB
    ? JSON.stringify(positionA.toJSON()) === JSON.stringify(positionB.toJSON())
    : false;
}

function googleMapsToApiLatLng(latLng: google.maps.LatLng): LatLng {
  return new LatLng({latitude: latLng.lat(), longitude: latLng.lng()});
}

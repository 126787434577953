// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/filterviewservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { FilterView } from "./filter_view_pb.js";

/**
 * @generated from message tapestry.gridaware.api.v1.ListFilterViewsRequest
 */
export const ListFilterViewsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.ListFilterViewsRequest", () => [{
  no: 1,
  name: "ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.ListFilterViewsResponse
 */
export const ListFilterViewsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.ListFilterViewsResponse", () => [{
  no: 1,
  name: "filter_views",
  kind: "message",
  T: FilterView,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetFilterViewRequest
 */
export const GetFilterViewRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetFilterViewRequest", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.CreateFilterViewRequest
 */
export const CreateFilterViewRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.CreateFilterViewRequest", () => [{
  no: 2,
  name: "filter_view",
  kind: "message",
  T: FilterView
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.DeleteFilterViewRequest
 */
export const DeleteFilterViewRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.DeleteFilterViewRequest", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.DeleteFilterViewResponse
 */
export const DeleteFilterViewResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.DeleteFilterViewResponse", []);

/**
 * @generated from message tapestry.gridaware.api.v1.SearchFilterViewsRequest
 */
export const SearchFilterViewsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.SearchFilterViewsRequest", () => [{
  no: 1,
  name: "display_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.SearchFilterViewsResponse
 */
export const SearchFilterViewsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.SearchFilterViewsResponse", () => [{
  no: 1,
  name: "filter_views",
  kind: "message",
  T: FilterView,
  repeated: true
}]);
<div class="customization">
  <div class="settings-header">Customize layer</div>
  <div class="divider" aria-hidden="true"></div>
  <div class="row">
    <div class="subheader-container">
      <div class="subheader">Icon</div>
      <div class="subheader-text">Select an icon shape</div>
    </div>
    <mat-form-field class="settings-select" appearance="fill" subscriptSizing="dynamic">
      <mat-label>Select a shape</mat-label>
      <mat-select
        [(ngModel)]="uploadForm.selectedIcon"
        [disabled]="assetLayerSelected || !layerHasPointMarker"
        [compareWith]="compareSelectedIcons">
        @if (uploadForm.selectedIcon.svgUrl) {
          <mat-select-trigger>
            <div class="icon-container">
              <img class="icon-image" [src]="uploadForm.selectedIcon.svgUrl" alt="icon" />
              <div class="icon-name">
                {{ uploadForm.selectedIcon.label }}
              </div>
            </div>
          </mat-select-trigger>
        }
        @for (icon of icons; track icon) {
          <mat-option [value]="icon">
            <div class="icon-container">
              <img class="icon-image" [src]="icon.svgUrl" alt="icon" />
              <div class="icon-name">
                {{ icon.label }}
              </div>
            </div>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="subheader-container">
      <div class="subheader">Hover</div>
      <div class="subheader-text">Select a property to use for the hover text (i.e. full name)</div>
    </div>
    <mat-form-field class="settings-select" appearance="fill" subscriptSizing="dynamic">
      <mat-label>Select a property</mat-label>
      <mat-select
        [(ngModel)]="uploadForm.selectedHoverPropertyKey"
        [disabled]="!layerHasPointMarker"
        [panelWidth]="propertiesSelectPanelWidth">
        @for (propertyKey of allLayerPropertyKeys; track propertyKey) {
          <mat-option class="property-select-option" [value]="propertyKey">
            {{ propertyKey }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="subheader-container">
      <div class="subheader">Prioritize properties</div>
      <div class="subheader-text">Select properties to show at the top of the details panel</div>
    </div>
    <mat-form-field class="settings-select" appearance="fill" subscriptSizing="dynamic">
      <mat-label>Select properties</mat-label>
      <mat-select
        [multiple]="true"
        [(ngModel)]="uploadForm.priorityPropertyKeys"
        [panelWidth]="propertiesSelectPanelWidth">
        @for (propertyKey of allLayerPropertyKeys; track propertyKey) {
          <mat-option class="property-select-option" [value]="propertyKey">
            {{ propertyKey }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
@if (!assetLayerSelected && layerHasPointMarker) {
  <div class="apply-color">
    <div class="settings-header">Set icon colors</div>
    <div class="divider" aria-hidden="true"></div>
    <div class="radio-container">
      <mat-radio-group [(ngModel)]="selectedRadio">
        <mat-radio-button class="radio radio-first" [value]="byLayerRadio">
          {{ byLayerRadio }}
        </mat-radio-button>
        <mat-radio-button class="radio" [value]="byLabelRadio">{{ byLabelRadio }}</mat-radio-button>
      </mat-radio-group>
      <div class="divider"></div>
    </div>
    @if (selectedRadio === byLayerRadio) {
      <div class="row">
        <div class="subheader-container">
          <div class="subheader">Select an icon color</div>
          <div class="subheader-text">All icons for this layer will use the same color</div>
        </div>
      </div>
    }
    @if (selectedRadio === byLabelRadio) {
      <div class="row">
        <div class="subheader-container">
          <div class="subheader">Select a property</div>
          <div class="subheader-text">
            Icon labels and color coding will be based on the selected property.
          </div>
        </div>
        <mat-form-field class="settings-select" appearance="fill" subscriptSizing="dynamic">
          <mat-label>Select a property</mat-label>
          <mat-select
            [formControl]="selectedPropertyKey"
            [panelWidth]="propertiesSelectPanelWidth"
            required>
            @for (propertyKey of allLayerPropertyKeys; track propertyKey) {
              <mat-option class="property-select-option" [value]="propertyKey">
                {{ propertyKey }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    <!--         TODO (reubenn): Move color palette to its own component -->
    @if (selectedRadio === byLayerRadio) {
      <div class="color-palette-container">
        <div class="subheader-text color-palette-text">Select a color</div>
        <mat-form-field
          class="settings-select color-select"
          appearance="fill"
          subscriptSizing="dynamic">
          <mat-label>Select color</mat-label>
          <mat-select [(ngModel)]="uploadForm.selectedColor">
            <mat-select-trigger>
              {{ displayColors([uploadForm.selectedColor]) }}
            </mat-select-trigger>
            @for (color of colors; track color) {
              <mat-option [value]="color.hex">
                <mat-icon class="color-icon" [style.color]="color.hex">circle</mat-icon>
                <span class="color-label">{{ color.label }}</span>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (selectedRadio === byLabelRadio) {
      <div class="color-config-container">
        @if (!propertyValuesLoading) {
          <table mat-table [dataSource]="propertyColors" class="table">
            <!-- Color Column -->
            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef class="header-column">Color</th>
              <td
                mat-cell
                *matCellDef="let color"
                class="table-cell"
                [ngClass]="{'undesignated-color': isDefaultColor(color)}">
                <span class="property-color-box">
                  <mat-icon class="property-color-icon" [style.color]="color.hex">circle</mat-icon>
                  <span class="property-color-label">{{ color.label }}</span>
                </span>
              </td>
            </ng-container>
            <!-- Property value Column -->
            <ng-container matColumnDef="propertyValue">
              <th mat-header-cell *matHeaderCellDef class="header-column">Property value</th>
              <td
                mat-cell
                *matCellDef="let color"
                class="table-cell"
                [ngClass]="{'undesignated-color': isDefaultColor(color)}">
                <span class="property-value-box">
                  @if (!isDefaultColor(color)) {
                    <mat-form-field
                      appearance="outline"
                      class="property-value color-config-select"
                      subscriptSizing="dynamic">
                      <mat-select
                        placeholder="Select property value"
                        i18n-placeholder
                        [ngModel]="uploadForm.colorsPerPropertyValue.get(color.hex)"
                        (ngModelChange)="assignPropertyColor(color.hex, $event)"
                        class="property-select">
                        <mat-option class="property-select-option">Unassigned</mat-option>
                        @for (propertyValue of propertyValues; track propertyValue) {
                          <mat-option class="property-select-option" [value]="propertyValue">
                            {{ propertyValue }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }
                  @if (isDefaultColor(color)) {
                    <div class="default-property-value">Undesignated keys</div>
                  }
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let color; columns: displayedColumns"></tr>
          </table>
        }
        @if (propertyValuesLoading) {
          <div class="spinner-container">
            <mat-progress-spinner
              mode="indeterminate"
              color="primary"
              diameter="24"
              class="spinner">
            </mat-progress-spinner>
          </div>
        }
      </div>
    }
  </div>
}
<div class="actions">
  <button class="save" color="primary" mat-flat-button [disabled]="!saveEnabled()" (click)="save()">
    Save
  </button>
  <button mat-flat-button (click)="setExistingLayerStyles()">Cancel</button>
</div>
<!-- TODO (reubenn): Move loading overlay its own component -->
@if (loading) {
  <div class="overlay">
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="48"></mat-progress-spinner>
  </div>
}

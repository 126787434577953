import {Component, OnInit} from '@angular/core';

import {BrowserCapabilityDetectionService} from '../services/browser_capability_detection_service';
import {ConfigService} from '../services/config_service';

/**
 * Map route component that's used to conditionally load different
 * versions of the map.
 */
@Component({
  selector: 'map-route',
  template: `
    @if (!mapPaginationEnabled) {
      <app-map></app-map>
    } @else {
      <app-deckgl-map></app-deckgl-map>
    }
  `,
})
export class MapRouteComponent implements OnInit {
  // If enabled and supported, then load more than 2000 results in the map.
  mapPaginationEnabled = false;

  constructor(
    private readonly configService: ConfigService,
    private readonly browserCapabilityDetectionService: BrowserCapabilityDetectionService,
  ) {}

  ngOnInit() {
    // Only use map pagination if the feature flag is enabled and
    // the client supports WebGL.
    this.mapPaginationEnabled =
      this.configService.mapPaginationEnabled &&
      this.browserCapabilityDetectionService.isWebGLAvailable();
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import {BreadcrumbNavigation} from './breadcrumb_navigation';

@NgModule({
  declarations: [BreadcrumbNavigation],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule],
  exports: [BreadcrumbNavigation],
  providers: [],
})
export class BreadcrumbNavigationModule {}

// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/imageservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddGCSImageRequest, AddGCSImageResponse, AddImageRequest, AddImageResponse, CorrectImageAnnotationRequest, CorrectImageAnnotationResponse, GetImageAnnotationsRequest, GetImageAnnotationsResponse, GetImagesRequest, GetImagesResponse, QueryImagesRequest, QueryImagesResponse, ReadImageContentRequest, ReadImageContentResponse, SaveImageAnnotationsRequest, SaveImageAnnotationsResponse, UpdateImageRequest, UpdateImageResponse } from "./imageservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service tapestry.gridaware.api.v1.ImageService
 */
export const ImageService = {
  typeName: "tapestry.gridaware.api.v1.ImageService",
  methods: {
    /**
     * GetImages gets a list of images by ID.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.GetImages
     */
    getImages: {
      name: "GetImages",
      I: GetImagesRequest,
      O: GetImagesResponse,
      kind: MethodKind.Unary
    },
    /**
     * QueryImages searches for images meeting certain parameters.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.QueryImages
     */
    queryImages: {
      name: "QueryImages",
      I: QueryImagesRequest,
      O: QueryImagesResponse,
      kind: MethodKind.Unary
    },
    /**
     * AddGCSImage adds a new gcs image.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.AddGCSImage
     */
    addGCSImage: {
      name: "AddGCSImage",
      I: AddGCSImageRequest,
      O: AddGCSImageResponse,
      kind: MethodKind.Unary
    },
    /**
     * AddImage adds a new image.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.AddImage
     */
    addImage: {
      name: "AddImage",
      I: AddImageRequest,
      O: AddImageResponse,
      kind: MethodKind.Unary
    },
    /**
     * UpdateImage updates an image.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.UpdateImage
     */
    updateImage: {
      name: "UpdateImage",
      I: UpdateImageRequest,
      O: UpdateImageResponse,
      kind: MethodKind.Unary
    },
    /**
     * ReadImageContent reads the bytes of the image.
     * Note: This is a streaming API. The bytes of an image might be broken up
     * into one or more ReadImageContentResponse and returned to you in order.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.ReadImageContent
     */
    readImageContent: {
      name: "ReadImageContent",
      I: ReadImageContentRequest,
      O: ReadImageContentResponse,
      kind: MethodKind.ServerStreaming
    },
    /**
     * Fetch annotations associated with an image.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.GetImageAnnotations
     */
    getImageAnnotations: {
      name: "GetImageAnnotations",
      I: GetImageAnnotationsRequest,
      O: GetImageAnnotationsResponse,
      kind: MethodKind.Unary
    },
    /**
     * Save annotations associated with an image.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.SaveImageAnnotations
     */
    saveImageAnnotations: {
      name: "SaveImageAnnotations",
      I: SaveImageAnnotationsRequest,
      O: SaveImageAnnotationsResponse,
      kind: MethodKind.Unary
    },
    /**
     * Corrects an existing image annotation.
     * Note: CorrectImageAnnotation only modifies the CorrectedImageAnnotation
     * field of an image annotation. No other field is modified.
     *
     * @generated from rpc tapestry.gridaware.api.v1.ImageService.CorrectImageAnnotation
     */
    correctImageAnnotation: {
      name: "CorrectImageAnnotation",
      I: CorrectImageAnnotationRequest,
      O: CorrectImageAnnotationResponse,
      kind: MethodKind.Unary
    }
  }
};
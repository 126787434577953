import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {FilterField} from '../constants/filters';
import {LayersFilterService} from '../services/layers_filter_service';
import {TagsService} from '../services/tags_service';
import {FilterMap} from '../typings/filter';
import {setsAreEqual} from '../utils/collections';

/**
 * Component for filtering images by tag.
 */
@Component({
  selector: 'tag-filters',
  templateUrl: './tag_filters.ng.html',
  styleUrls: ['./tag_filters.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagFilters implements OnInit, OnDestroy {
  @Input() layerId: string = '';
  @Output() readonly editingChange = new EventEmitter<boolean>();

  MAX_OPTIONS_TO_SHOW = 20;

  loading = true;
  editing = false;
  tags = new Set<string>();
  selectedTags = new Set<string>();
  allTags: string[] = [];
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly layersFilterService: LayersFilterService,
    private readonly tagsService: TagsService,
  ) {}

  ngOnInit() {
    // Keep track of all the tags.
    this.tagsService
      .getTags(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((allTags: string[]) => {
        this.allTags = allTags;
        this.loading = false;
        this.changeDetectionRef.markForCheck();
      });

    // Keep track of the current tags used in filter.
    this.layersFilterService
      .getFilterMap(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((filters: FilterMap) => {
        const tagFilter = filters[FilterField.TAG_NAMES] || new Set<string>();
        this.tags = new Set(tagFilter);
        this.selectedTags = new Set(tagFilter);
        this.changeDetectionRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onSelectedOptionsChanged(tags: string[]) {
    this.selectedTags = new Set(tags);
  }

  onFilterButtonClicked() {
    this.setEditing(true);
  }

  onApplied() {
    this.setEditing(false);
    this.updatePhotoFilters();
  }

  onCancel() {
    this.selectedTags = new Set();
    this.setEditing(false);
  }

  // When a user clicks an X button on a tag.
  onTagRemoved(tag: string) {
    this.selectedTags.delete(tag);
    this.updatePhotoFilters();
  }

  private setEditing(editing: boolean) {
    this.editing = editing;
    this.editingChange.emit(editing);
  }

  getTagsArray() {
    return Array.from(this.tags);
  }

  private updatePhotoFilters() {
    if (setsAreEqual(this.tags, this.selectedTags)) {
      return;
    }

    if (this.selectedTags.size === 0) {
      this.layersFilterService.removeFilter(this.layerId, FilterField.TAG_NAMES);
      return;
    }

    this.layersFilterService.addFilter(
      this.layerId,
      FilterField.TAG_NAMES,
      new Set(this.selectedTags),
    );
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

import {DeleteImageConfirmationDialog} from './delete_image_confirmation_dialog';

@NgModule({
  declarations: [DeleteImageConfirmationDialog],
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  exports: [DeleteImageConfirmationDialog],
})
export class DeleteImageConfirmationDialogModule {}

import {Component, Inject} from '@angular/core';
import {MatOption} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';

/**
 * Data that is passed to table printing dialog.
 */
export interface TableDialogData {
  selectedColumnNames: string[];
  allColumnNames: string[];
  showFeaturesPerPage: boolean;
  showIncludeLink: boolean;
  includeLinkColumnName: string;
}

const FEATURES_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 30, 35];
const SELECTED_FEATURE_PER_PAGE = 10;
const SELECT_ALL = 'Select all (recommended)';
const INCLUDE_LINK_CHECKED = true;

/**
 * Component for a table dialog where a user can select the columns and
 * features per page that they want to export.
 */
@Component({
  templateUrl: 'table_dialog.ng.html',
  styleUrls: ['table_dialog.scss'],
})
export class TableDialog {
  allFeaturesPerPage = FEATURES_PER_PAGE_OPTIONS;
  selectedFeaturesPerPage = SELECTED_FEATURE_PER_PAGE;
  selectAll = SELECT_ALL;
  includeLinkChecked = INCLUDE_LINK_CHECKED;

  constructor(
    private readonly dialogRef: MatDialogRef<TableDialog>,
    @Inject(MAT_DIALOG_DATA) readonly data: TableDialogData,
  ) {}

  /**
   * Toggles all options based on the selected option. Expects the selected
   * option to be the first option in the select component.
   */
  toggleSelectAll(selectedOption: MatOption, select: MatSelect) {
    const options: MatOption[] = select.options
      .toArray()
      .filter((option: MatOption) => option.value !== this.selectAll);

    for (const option of options) {
      if (selectedOption.selected) {
        option.select();
        continue;
      }
      option.deselect();
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  closeDialog() {
    this.data.selectedColumnNames = this.data.selectedColumnNames.filter(
      (columnName: string) => columnName !== this.selectAll,
    );
    if (this.data.showIncludeLink && this.includeLinkChecked) {
      this.data.selectedColumnNames.push(this.data.includeLinkColumnName);
    }
    this.dialogRef.close({
      columnNames: this.data.selectedColumnNames,
      featuresPerPage: this.data.showFeaturesPerPage ? this.selectedFeaturesPerPage : null,
    });
  }
}

/**
 * @fileoverview Service for error messages.
 */
import {Injectable} from '@angular/core';

/**
 * Handles adding and clearing the app error messages.
 */
@Injectable()
export class MessageService {
  // list of messages.
  private messages: string[] = [];

  /**
   * Adds a message to the list.
   * @param message - The message to add.
   */
  add(message: string) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }
}

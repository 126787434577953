import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import {Observable, Subject, Subscriber, from, of, takeUntil} from 'rxjs';

import {
  ExifMetadata_Orientation,
  Image as ImageBuf,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup,
  RelatedFeaturesGroup_RelatedFeatureRole,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb.js';

import {PendingFile} from '../typings/upload';

/**
 * The max image height that will be requested from FIFE and go/gmrs. Please see
 * go/fife-urls#url-options for more details. Default behavior is to preserve
 * aspect ratio, and images will not be scaled up.
 *
 * Currently, this value is based on Hershel camera images that have a height of
 * 5472px: go/sv-fov.
 */
export const MAX_IMAGE_HEIGHT_PX = 5472;

/**
 * In order to maximize cache hits and maintain quality, the UI will
 * request the same URL options from FIFE / go/gmrs.
 */
export const THUMBNAIL_SIZE_PX = 512;

// The width of the existing image card.
export const IMAGE_WIDTH_PX = 284;

/**
 *  URL options for requesting FIFE URLs or GCS generated thumbnail.
 */
export type ImageUrlOptions = {
  height: number;
  width: number;
};

export const defaultImageUrlOptions: ImageUrlOptions = {
  height: MAX_IMAGE_HEIGHT_PX,
  width: MAX_IMAGE_HEIGHT_PX, // match max height
};

/**
 * To check if the image.url is from Google Cloud Storage or FIFE.
 */
export const GOOGLE_CLOUD_STORAGE_URL_PREFIX = 'gs://';

/**
 * GCS Thumbnail size.
 */
export const GOOGLE_CLOUD_STORAGE_THUMBNAIL_SIZE = '512x512';

/**
 * Returns the related feature IDs for a given Image and role.
 */
export function getRelatedIds(
  image: ImageBuf,
  role: RelatedFeaturesGroup_RelatedFeatureRole,
): string[] {
  return (
    image.relatedFeaturesGroups
      .find((group: RelatedFeaturesGroup) => group.role === role)
      ?.relatedFeatures.map((relatedFeature: RelatedFeature) => relatedFeature.id) || []
  );
}

/**
 * Returns random ID for pending image upload.
 */
export function buildPendingImageID(): string {
  return Math.random().toString(36).slice(2);
}

/**
 * Reads the pending (added but not saved) file as data URL and builds a
 * corresponding pending file object.
 */
export function loadPendingFile(file: File): Observable<PendingFile> {
  return new Observable((subscriber: Subscriber<PendingFile>) => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      const imageId = buildPendingImageID();
      const dataUrl = fileReader.result as string;
      const pendingImageInfo = {
        file,
        url: dataUrl,
        id: imageId,
      };
      subscriber.next(pendingImageInfo);
      subscriber.complete();
    });
    fileReader.readAsDataURL(file);
  });
}

/**
 * Returns the degrees to rotate an image (clockwise) based on its orientation
 * metadata.
 */
export function getImageRotationDegrees(image: ImageBuf): number {
  switch (image.exifMetadata?.orientation) {
    case ExifMetadata_Orientation.ROTATED_90_CW:
      return 90;
    case ExifMetadata_Orientation.ROTATED_180_CW:
      return 180;
    case ExifMetadata_Orientation.ROTATED_270_CW:
      return 270;
    default:
      return 0;
  }
}

/**
 * Returns the new image index after the navigation to next/previous image in
 * the group is requested.
 */
export function getNewImageIndex(
  currentIndex: number,
  totalImageCount: number,
  direction: 'next' | 'prev',
): number {
  if (totalImageCount === 0) {
    console.error("Images don't exist. Can't return a new image index.");
    return -1;
  }
  if (direction === 'prev') {
    return currentIndex === 0 ? totalImageCount - 1 : currentIndex - 1;
  }
  if (direction === 'next') {
    return currentIndex === totalImageCount - 1 ? 0 : currentIndex + 1;
  }

  return -1;
}

/**
 * Retrieves the index of the image in the array that has specific imageId.
 */
export function getCurrentImageIndex(images: ImageBuf[], imageId: string): number {
  return images.findIndex((image: ImageBuf) => image.id === imageId) || 0;
}

/**
 * Checks if provided image URL is from Google Cloud Storage.
 */
export function isGCSImageUrl(imageurl: string): boolean {
  return imageurl.startsWith(GOOGLE_CLOUD_STORAGE_URL_PREFIX);
}

/**
 * Convert GCS filepath URI to a downloadable URL.
 */
export function convertGCSImageURL(imageurl: string): Observable<string> {
  const storage = getStorage();
  return from(
    getDownloadURL(ref(storage, imageurl))
      .then((downloadURL) => {
        return downloadURL;
      })
      .catch((error: Error) => {
        const message = `Failed to getDownloadURL: ${error}`;
        console.error(message);
        throw new Error(message);
      }),
  );
}

/**
 * Given a GCS Image URL, fetch its associated thumbnail image URL.
 */
function buildGCSThumbnailPath(GCSUrl: string): string {
  const dotIndex = GCSUrl.lastIndexOf('.');
  const filepath = GCSUrl.substring(0, dotIndex);
  const fileExtension = GCSUrl.substring(dotIndex);
  return filepath + '_' + GOOGLE_CLOUD_STORAGE_THUMBNAIL_SIZE + fileExtension;
}

/**
 * Determines URL type, then builds URL with options parameter.
 */
export function buildImageUrlWithOptions(
  image: ImageBuf,
  urlOptions: ImageUrlOptions,
): Observable<string> {
  if (isGCSImageUrl(image.url)) {
    if (urlOptions.height <= THUMBNAIL_SIZE_PX || urlOptions.width <= THUMBNAIL_SIZE_PX) {
      const thumbnailUrl = buildGCSThumbnailPath(image.url);
      return convertGCSImageURL(thumbnailUrl);
    }
    return convertGCSImageURL(image.url);
  }
  if (isDataUrl(image.url)) {
    return of(image.url);
  }
  return of(buildFifeUrl(image, urlOptions));
}

/**
 * Builds the FIFE url with appropriate options and rotation (if specified).
 * Please see go/fife-urls#url-options for more details.
 */
export function buildFifeUrl(image: ImageBuf, urlOptions: ImageUrlOptions): string {
  const url = image.url;
  const fifeUrl = `${url}=h${urlOptions.height}-w${urlOptions.width}`;
  const rotationDegrees = getImageRotationDegrees(image);
  if (rotationDegrees === 90 || rotationDegrees === 180 || rotationDegrees === 270) {
    return `${fifeUrl}-r${rotationDegrees}`;
  }
  return fifeUrl;
}

/**
 * Prefetches up to 10 of the given set of images.
 */
export function prefetchImages(
  images: ImageBuf[],
  urlOptions: ImageUrlOptions,
  destroyed: Subject<void>,
) {
  for (let i = 0; i < Math.min(images.length, 10); i++) {
    buildImageUrlWithOptions(images[i], urlOptions)
      .pipe(takeUntil(destroyed))
      .subscribe({
        next: (imageUrl) => {
          document.createElement('img').src = imageUrl;
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }
}

/**
 * Checks if URL representing an image is a data URL (vs FIFE URL).
 * Please see info on data URLs here:
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs.
 */
export function isDataUrl(url: string): boolean {
  return /^data:/.test(url);
}

/**
 * Replaces brackets with encoded symbols.
 * Otherwise, brackets in file names causes issues in serving image thumbnail url.
 */
export function encodeBracketsInImageUrl(url: string): string {
  return url.replace(/\(/g, '%28').replace(/\)/g, '%29');
}

/**
 * Prefetches a lidar image.
 */
export function prefetchLidarImage(image: ImageBuf): Observable<string> {
  return new Observable((subscriber: Subscriber<string>) => {
    const img = new Image();
    img.src = getLidarUrl(image.id);
    img.onload = () => {
      subscriber.next(image.id);
      subscriber.complete();
    };
    img.onerror = (errorEvent) => {
      subscriber.error(errorEvent);
    };
  });
}

/**
 * Returns the LiDAR image URL for a given image id.
 */
export function getLidarUrl(imageId: string) {
  return `${window.location.origin}/images/lidar-demo/${imageId}`;
}

/**
 * Returns the related features for a given image and role.
 */
export function getRelatedFeatures(
  image: ImageBuf,
  role: RelatedFeaturesGroup_RelatedFeatureRole,
): RelatedFeature[] {
  return (
    image.relatedFeaturesGroups.find((group: RelatedFeaturesGroup) => group.role === role)
      ?.relatedFeatures || []
  );
}

/**
 * Checks if provided image is an SOV image.
 */
export function isSoVImage(image: ImageBuf): boolean {
  return !image.user?.id;
}

/**
 * Returns the date the image was captured, or its upload date.
 * Returns null if neither is available.
 */
export function getTakenOn(image: ImageBuf): Date | null {
  return image.capturedAt?.toDate() || image.uploadedAt?.toDate() || null;
}

import {Component, Input} from '@angular/core';

/**
 * Component for rendering related feature entry in feature timeline.
 */
@Component({
  selector: 'timeline-related-feature',
  templateUrl: './timeline_related_feature.ng.html',
  styleUrls: ['./timeline_related_feature.scss'],
})
export class TimelineRelatedFeature {
  @Input() properties!: string[];
}

import {BehaviorSubject, Observable} from 'rxjs';

import {Injectable} from '@angular/core';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {Page} from './features_pagination_service';

/**
 * Service for caching most recently visited pages and relevant data.
 */
@Injectable({providedIn: 'root'})
export class PaginationCachingService {
  /**
   * Maps the layers to their most recently visited page.
   */
  private readonly cachedPagesByLayerId = new Map<string, BehaviorSubject<Page | null>>();

  /**
   * Maps the layers to their cached features.
   */
  private readonly featuresByLayerId = new Map<string, Feature[]>();

  /**
   * Maps the layers to their tokens for the next page.
   * Token is filled by server on initial response.
   * Initial request should keep the token blank.
   */
  private readonly nextPageTokenByLayerId = new Map<string, string>();

  setPaginationForLayer(layerId: string, length: number, pageIndex: number, pageSize: number) {
    if (!this.cachedPagesByLayerId.has(layerId)) {
      this.cachedPagesByLayerId.set(layerId, new BehaviorSubject<Page | null>(null));
    }
    this.cachedPagesByLayerId.get(layerId)!.next({length, pageIndex, pageSize});
  }

  getSavedPaginationForLayer(layerId: string): Observable<Page | null> {
    if (!this.cachedPagesByLayerId.has(layerId)) {
      this.cachedPagesByLayerId.set(layerId, new BehaviorSubject<Page | null>(null));
    }
    return this.cachedPagesByLayerId.get(layerId)!;
  }

  setFeaturesForLayer(layerId: string, features: Feature[]) {
    this.featuresByLayerId.set(layerId, features);
  }

  getFeaturesForLayer(layerId: string): Feature[] {
    return this.featuresByLayerId.get(layerId) || [];
  }

  addFeaturesForLayer(layerId: string, features: Feature[]) {
    const newFeatures = [...(this.featuresByLayerId.get(layerId) || []), ...features];
    this.featuresByLayerId.set(layerId, newFeatures);
  }

  updateFeaturesForLayer(layerId: string, features: Feature[]) {
    const updates = new Map<string, Feature>(features.map((feature) => [feature.id, feature]));
    const newFeatures = (this.featuresByLayerId.get(layerId) || []).map((feature) => {
      const id = feature?.id || '';
      return updates.has(id) ? updates.get(id) : feature;
    });

    this.featuresByLayerId.set(layerId, newFeatures as Feature[]);
  }

  setNextPageTokenForLayer(layerId: string, nextPageToken: string) {
    this.nextPageTokenByLayerId.set(layerId, nextPageToken);
  }

  getNextPageTokenForLayer(layerId: string): string {
    return this.nextPageTokenByLayerId.get(layerId) || '';
  }
}

<h1 mat-dialog-title class="header-text">{{ data.headerText || 'Tags' }}</h1>
<mat-dialog-content>
  @if (data.subheaderText) {
    <div class="subheader-text">
      {{ data.subheaderText }}
    </div>
  }
  <tags
    [tags]="tags"
    [layerId]="layerId"
    [opened]="opened"
    (editingChanged)="setEditing($event)"
    (tagsUpdated)="onTagsUpdated($event)"
    class="tags">
  </tags>
</mat-dialog-content>
@if (!editing) {
  <mat-dialog-actions class="actions" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button class="tags-action" (click)="returnTags()">
      {{ actionText }}
    </button>
  </mat-dialog-actions>
}

import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {BehaviorSubject, EMPTY, Observable, Subject, combineLatest, of} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Point} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {QueryFeaturesResponse} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_pb';
import {SolarInsight} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/solar_insight_pb';

import {DEFAULT_GEOLOCATION_OPTIONS} from '../constants/geolocation';
import {
  ASSETS_LAYER_ID,
  SOLAR_INSIGHTS_LAYER_ID,
  STREETVIEW_RECENCY_LAYER_ID,
  SUNROOF_LAYER_ID,
} from '../constants/layer';
import {NIGHT_THEME} from '../constants/map_theme';
import {FEATURE_PARAM_KEY, QUERY_PARAMS, ROUTE} from '../constants/paths';
import {IconSelectedFn, MarkersFactory} from '../factories/markers_factory';
import {SelectedFeature} from '../search/search';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {DeckGLService} from '../services/deckgl_service';
import {FeaturesService} from '../services/features_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {LayersService} from '../services/layers_service';
import {LoaderService} from '../services/loader_service';
import {LocalStorageService} from '../services/local_storage_service';
import {MapPropertiesService} from '../services/map_properties_service';
import {MapService} from '../services/map_service';
// import {OfflineAssetsDialogsService} from '../services/offline_assets_dialogs_service';
// import {OfflineSnackbarService} from '../services/offline_snackbar_service';
import {SidepanelService} from '../services/sidepanel_service';
import {SolarInsightsService} from '../services/solar_insights_service';
import {StreetViewRecencyService} from '../services/streetview_recency_service';
import {SunroofService} from '../services/sunroof_service';
import {UserPreferencesService} from '../services/user_preferences_service';
import {ColorScheme} from '../typings/common';
// import {UserLocationService} from '../services/user_location_service';
import {FilterMap, FilterUpdate} from '../typings/filter';
import {FeatureMetadata, Marker, MultiMarker, VisibilityByLayerUpdate} from '../typings/map';
import {getFeederName} from '../utils/feature';
import {boundsContainsAnyFeature, filterNearestMarkersByPosition} from '../utils/map';

/**
 * Layer ID and filters applied to that layer. updateMap will update the map
 * position to fit markers if set to true.
 */
export interface MapLayerFilters {
  layerId: string;
  filters: FilterMap;
  globalSearch: boolean;
  // Whether or not inactive results should be included in the query.
  includeInactiveResults?: boolean;

  bounds?: google.maps.LatLngBounds | null | undefined;
}

/**
 * The layer ID and bounding-box boolean of a layer that needs to be rendered.
 */
export interface MapContentChange {
  layerId: string;
  // When a filter is changed, this flag will be set to true, which gives
  // signal that the map should request features without a bounding box.
  globalSearch: boolean;
}

/**
 * Features split by duplicate and single locations. A duplicate location
 * implies that a feature exist at the same lat lng (independent of layer).
 */
interface FeaturesToRender<T extends Feature> {
  // Features that have a single location.
  singleLocations: T[];
  // Features that share their location with other features. Not limited to the
  // same layer.
  duplicateLocations: T[];
}

/**
 * Pixels of padding applied to the map's bounds when calling map.
 */
const FIT_BOUNDS_PADDING: google.maps.Padding = {
  top: 24,
  right: 24,
  bottom: 24,
  left: 24,
};

/**
 * Default time to show a snack bar before hiding.
 */
const SNACK_BAR_TIMEOUT_MS = 1000;

const MAX_LAYER_MARKERS_COUNT = 15000;

/**
 * The number of places after the decimal to truncate a location's lat and lng
 * values to. This is used for identifying icons that are close enough to
 * consider overlapping and should therefore share an icon. The precision maps
 * roughly to the size of a corner of a house.
 * @see https://xkcd.com/2170/.
 */
const LOCATION_PRECISION = 4;

/**
 * Component to render the primary Google Map view.
 */
@Component({
  selector: 'app-map',
  templateUrl: 'map_component.ng.html',
  styleUrls: ['map_component.scss'],
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @ViewChild('myLocationControl', {static: false})
  myLocationControl!: ElementRef;
  @ViewChild('map', {static: true}) mapComponent!: ElementRef;
  map!: google.maps.Map;
  @ViewChild('infoWindow', {read: ViewContainerRef})
  infoWindowVCRef!: ViewContainerRef;
  @ViewChild('areaSelectionControl', {static: true})
  areaSelectionControl!: ElementRef;
  @ViewChild('areaSelectionOverlay', {static: true})
  areaSelectionOverlay!: ElementRef;

  locationPin: google.maps.Marker | null = null;
  currentLocationMarker: google.maps.Marker | null = null;
  destroyed = new Subject<void>();
  listenersInitialized = false;
  sunroofLayerVisible = false;
  layerVisibilityById = new Map<string, boolean>();
  selectedFeatureId = '';
  // An ID of feature that hasn't been selected yet. This occurs when a
  // feaure ID exists as a query param but the feature hasn't been fetched yet.
  pendingFeatureId = '';

  // When a request goes out in one of the streams below, ie, fetchAndRenderXXX,
  // fetchingCount is incremented if said stream is not already fetching. If
  // said stream is already fetching, then the count will not be updated because
  // the previous request will be canceled. When the response comes back
  // (success or fail) fetchingCount is decremented.
  fetchingCount = 0;

  // fetchAndRenderXXX are used to fetch and render markers on the map.
  // They are called with their respective layer id via .next(layerId).
  fetchAndRenderFeaturesSubjects = new Map<string, Subject<MapContentChange>>();

  // A map of location to marker. Keeps track of markers by their
  // locations. When more than one marker shares the same location, the marker
  // will be moved from this map to multiMarkerByLocation.
  markerByLocation = new Map<string, Marker>();
  // A map of feature ID to marker. This map contains markers across layers.
  // Used to filter out fetched features that are renderd and select an initial
  // marker.
  markerByFeatureId = new Map<string, Marker>();
  // A map of layer ID to a set of markers. When a layer is removed, the
  // markers that are part of said layer will be traversed and removed.
  markersByLayerId = new Map<string, Set<Marker>>();
  // A map of location to multi markers.
  multiMarkerByLocation = new Map<string, MultiMarker>();
  // A map of feature ID to multi marker. Used to filter out fetched features
  // that are rendered and select an initial multi marker.
  multiMarkerByFeatureId = new Map<string, MultiMarker>();
  // A map of layer ID to a set of multi markers. When a layer is removed, the
  // multi markers that are part of said layer will be traversed and removed
  // (if there are no longer any features visible).
  multiMarkersByLayerId = new Map<string, Set<MultiMarker>>();
  // A map of layer ID to a boolean indicating whether or not results
  // are found in bounds for that layer.
  boundsContainAnyFeatureByLayerId = new Map<string, boolean>();

  // An integer ID that identifies the registered handler for watching current
  // position. The ID should be passed to the Geolocation.clearWatch()
  // to unregister the handler.
  watchPositionHandlerId: number | null = null;

  selectionAreaControlMessage = '';
  isAreaSelectionInProgress = false;

  isMapTableToggleEnabled = false;
  isMapPaginationEnabled = false;
  // Id of deleted feature to redraw or remove map marker.
  deletedFeatureId = '';

  private selectedSolarFeeders: SolarInsight[] | null = null;

  protected zoomChanged = new BehaviorSubject<google.maps.Map>(this.map);

  protected styles: google.maps.MapTypeStyle[] = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{visibility: 'off'}],
    },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly configService: ConfigService,
    protected readonly featureService: FeaturesService,
    // private readonly offlineAssetsDialogsService: OfflineAssetsDialogsService,
    // private readonly offlineSnackbarService: OfflineSnackbarService,
    protected readonly layersFilterService: LayersFilterService,
    private readonly layersService: LayersService,
    protected readonly loaderService: LoaderService,
    protected readonly mapService: MapService,
    protected readonly markersFactory: MarkersFactory,
    private readonly mapPropertiesService: MapPropertiesService,
    private readonly router: Router,
    private readonly sidepanelService: SidepanelService,
    private readonly snackBar: MatSnackBar,
    private readonly sunroofService: SunroofService,
    private readonly solarInsightsService: SolarInsightsService,
    // private readonly userLocationService: UserLocationService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly deckGLService: DeckGLService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly localStorageService: LocalStorageService,
    protected readonly streetviewRecencyService: StreetViewRecencyService,
  ) {
    this.selectionAreaControlMessage = 'Move the map to set the area for download';
    this.isMapPaginationEnabled = this.configService.mapPaginationEnabled;
    if (this.configService.mapPaginationEnabled) {
      this.deckGLService.markerByFeatureId = this.markerByFeatureId;
    }
  }

  ngOnDestroy() {
    this.stopWatchingPosition();
    this.destroyed.next();
    this.destroyed.complete();
    this.mapService.mapDestroyed();
  }

  ngAfterViewInit() {
    this.initMapListeners();
    this.attachControls();
    // This was commented out to avoid battery draining.
    // TODO(b/249780980): Give users more control over the current location
    // feature.
    // this.startWatchingPosition();

    // Track the end of the navigation within map component, which includes
    // feature details page, to determine whether the feature selected
    // state has changed. Map component includes feature details page,
    // where feature ID parameter is coming from - required to highlight
    // the selected feature marker - thus map component is listening for
    // child component's parameter change here.
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        // Navigation has already finished on initial load, trigger
        // to run once (for the case when user goes directly to
        // ../map/<layerID>/<featureID>).
        startWith(undefined),
        switchMap(() => this.route.firstChild?.paramMap ?? EMPTY),
        map((paramMap) => paramMap.get(FEATURE_PARAM_KEY) || ''),
        distinctUntilChanged(),
        takeUntil(this.destroyed),
      )
      .subscribe((featureId: string) => {
        this.selectFeature(featureId!);
      });

    this.mapService.mapReady(this.map);

    // Update service once projection becomes available.
    google.maps.event.addListenerOnce(this.map, 'projection_changed', () => {
      this.mapService.projectionReady();
    });

    this.mapService
      .onRemoveMarker()
      .pipe(takeUntil(this.destroyed))
      .subscribe((featureId: string) => {
        const marker = this.markerByFeatureId.get(featureId);
        if (!marker) {
          console.error(
            `failed to remove marker with feature ID ${featureId}: marker doesn't exist.`,
          );
          return;
        }
        if (!(marker.marker instanceof google.maps.Marker)) {
          console.error(
            `failed to remove marker with feature ID ${featureId}: only removing point markers is supported.`,
          );
          return;
        }
        this.removeMarker(this.createLocationKeyFromMarker(marker.marker));
      });
    this.mapService
      .onDeselectMarker()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.deselectFeature();
      });
  }

  visibleLayerIds(): Observable<string[]> {
    return this.mapService
      .onLayerVisibilityChanged()
      .pipe(
        map((visibilityByLayerUpdate: VisibilityByLayerUpdate): string[] =>
          [...visibilityByLayerUpdate.visibilityByLayerId]
            .filter(([, isVisible]) => isVisible)
            .map(([layerId]) => layerId),
        ),
      );
  }

  onFeatureSelected(selectedFeature: SelectedFeature) {
    this.mapService.setShouldRepositionMap(true);
    this.router.navigate([ROUTE.MAP, selectedFeature.layerID, selectedFeature.feature.id]);
  }

  selectOfflineArea() {
    const isSelectionInProgress = this.isAreaSelectionInProgress;
    if (isSelectionInProgress) {
      return;
    }
    this.sidepanelService.setSidepanelOpened(false);
    if (!this.layerVisibilityById.get(ASSETS_LAYER_ID)) {
      this.mapService.showLayer(ASSETS_LAYER_ID, true).pipe(take(1)).subscribe();
    }
    // TODO(b/248126622): verify if the zoom is actually needed.
    this.map.setZoom(this.mapPropertiesService.MAP_AREA_SELECTION_ZOOM);
    // Further limit map's min zoom to avoid caching overly large map portions.
    this.map.setOptions({
      minZoom: this.mapPropertiesService.MIN_AREA_SELECTION_ZOOM_LEVEL,
    });
    this.renderAreaSelection();
  }

  /**
   * Adds selection area to the map as well as corresponding controls.
   */
  renderAreaSelection() {
    if (!this.map) {
      return;
    }
    this.isAreaSelectionInProgress = true;
  }

  /**
   * Cancels offline area selection mode by clearing map selection area and
   * corresponding controls.
   */
  cancelAreaSelection() {
    this.clearAreaSelectionAndControls();
  }

  /**
   * Proceeds with offline area selection by clearing map selection area and
   * gathering final confirmation from user.
   */
  confirmAreaSelection() {
    // TODO(halinab): limit the bounds to the unshaded area.
    const bounds = this.map.getBounds();
    if (!bounds) {
      console.error('failed to process selection area bounds');
      return;
    }
    // this.processOfflineAreaSelection(bounds);
    this.clearAreaSelectionAndControls();
  }

  /**
   * Clears the map selection area and corresponding controls.
   */
  clearAreaSelectionAndControls() {
    this.isAreaSelectionInProgress = false;
    // Reset min zoom to its default value.
    this.map.setOptions({minZoom: this.mapPropertiesService.MIN_ZOOM_LEVEL});
  }

  // processOfflineAreaSelection(bounds: google.maps.LatLngBounds) {
  //   this.offlineSnackbarService.triggerLoadingSnackBar(true);
  //   // this.sendEvent(
  //   //   EventActionType.OFFLINE_ASSETS_CACHING_INITIATED,
  //   //   bounds.toString()
  //   // );
  //   this.featureService
  //     .queryFeaturesInBounds(ASSETS_LAYER_ID, bounds)
  //     .pipe(
  //       take(1),
  //       finalize(() => {
  //         // this.offlineSnackbarService.triggerLoadingSnackBar(false);
  //       }),
  //       takeUntil(this.destroyed)
  //     )
  //     .subscribe(
  //       (features: Feature[]) => {
  //         const assetsByFeederID = new Map<string, Feature[]>();
  //         for (const asset of features) {
  //           const feederId = getFeederId(asset);
  //           if (!feederId) {
  //             continue;
  //           }
  //           const newAssets = (assetsByFeederID.get(feederId) || []).concat(
  //             asset
  //           );
  //           assetsByFeederID.set(feederId, newAssets);
  //         }
  //
  //         // this.offlineAssetsDialogsService.openOfflineAssetsConfirmationDialog(
  //         //   assetsByFeederID,
  //         //   this.destroyed
  //         // );
  //       },
  //       error => {
  //         console.error(
  //           `Error processing offline assets selection. ${error?.message || ''}`
  //         );
  //         // this.sendEvent(
  //         //   EventActionType.OFFLINE_ASSETS_CACHING_FAILED,
  //         //   error?.message || ''
  //         // );
  //         // this.offlineSnackbarService.showInfoSnackBar(
  //         //   'There was an error populating assets in the area.'
  //         // );
  //       }
  //     );
  // }

  protected initMapListeners() {
    let styles = this.styles;
    if (this.configService.debugEnabled) {
      styles = styles.concat(this.mapPropertiesService.OBFUSCATION_STYLES);
    }
    const mapProperties: google.maps.MapOptions = {
      center: this.mapService.getSavedCenter(),
      fullscreenControl: false,
      gestureHandling: 'greedy',
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      styles,
      zoom: this.mapService.getSavedZoom(),
    };

    // Limit minimum zoom when performance can be an issue.
    if (!this.isMapPaginationEnabled) {
      mapProperties.minZoom = this.mapPropertiesService.MIN_ZOOM_LEVEL;
    }

    if (this.configService.vectorMapsEnabled) {
      mapProperties.mapId = this.configService.mapId;
    }

    const colorScheme = this.localStorageService.readColorScheme();
    if (colorScheme === ColorScheme.DARK) {
      mapProperties.styles = NIGHT_THEME;
    }
    this.mapService.mapOptions = mapProperties;

    // Initialize overview map.
    this.map = new google.maps.Map(this.mapComponent.nativeElement, mapProperties);
    this.map.get('streetView').setOptions({
      addressControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      imageDateControl: true,
    });
    this.map.addListener('idle', () => {
      if (!this.listenersInitialized) {
        this.initListeners();
        this.listenersInitialized = true;
      }
      const visibleLayers = this.getVisibleLayerIds();
      this.fetchAndRenderLayers(visibleLayers, false);
    });
    this.map.addListener('click', ({latLng}: google.maps.MapMouseEvent) => {
      this.markersFactory.hideTooltip();
      this.mapService.removeHighlightAroundSelected();
      this.deselectFeature();
      this.removeLocationPin();
      if (!latLng) {
        return;
      }
      if (this.sunroofLayerVisible && !this.configService.solar2Enabled) {
        this.router.navigate([
          'map/sunroof',
          {
            [QUERY_PARAMS.LATITUDE]: latLng.lat(),
            [QUERY_PARAMS.LONGITUDE]: latLng.lng(),
          },
        ]);
        return;
      }
      this.router.navigateByUrl(ROUTE.MAP);
    });
    this.map.addListener('zoom_changed', () => {
      this.zoomChanged.next(this.map);
    });
    this.map.addListener('zoom_changed', () => {
      this.zoomChanged.next(this.map);
    });
    this.mapService
      .getLocationPin()
      .pipe(takeUntil(this.destroyed))
      .subscribe((location: google.maps.LatLngLiteral) => {
        this.deselectFeature();
        this.dropLocationPin(location);
      });
    this.mapService
      .onCenterChanged()
      .pipe(takeUntil(this.destroyed))
      .subscribe((location: LatLng) => {
        this.repositionMap({
          lat: location.latitude,
          lng: location.longitude,
        });
      });
    this.mapService
      .onSelectOfflineArea()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.selectOfflineArea();
      });

    this.userPreferencesService
      .getColorScheme()
      .pipe(takeUntil(this.destroyed))
      .subscribe((colorScheme: ColorScheme) => {
        this.updateMapTheme(colorScheme === ColorScheme.DARK);
      });

    this.featureService
      .onFeatureDeleted()
      .pipe(takeUntil(this.destroyed))
      .subscribe((feature: Feature | null) => {
        if (feature !== null) {
          this.deletedFeatureId = feature?.id;
          this.markerByFeatureId.delete(feature?.id);
          this.multiMarkerByFeatureId.delete(feature?.id);
        }
      });

    if (this.configService.solar2Enabled) {
      this.solarInsightsService.selectedFeeders
        .pipe(takeUntil(this.destroyed))
        .subscribe((selectedSolarFeeders) => {
          this.selectedSolarFeeders = selectedSolarFeeders;
        });
    }
  }

  /**
   * Returns true if the amount of markers that are currently rendered on a
   * layer plus the amount of new markers to be rendered is greater than
   * the maximum amount of markers that we want to allow a layer to render.
   * This is used to determine whether or not to remove all markers before
   * rendering a new set.
   */
  protected maxMarkersCountExceeded(layerId: string, newMarkerCount: number): boolean {
    const markerCount = this.markersByLayerId.get(layerId)?.size || 0;
    const multiMarkerCount = this.multiMarkersByLayerId.get(layerId)?.size || 0;
    return markerCount + multiMarkerCount + newMarkerCount > MAX_LAYER_MARKERS_COUNT;
  }

  protected removeLocationPin() {
    if (this.locationPin) {
      this.locationPin.setMap(null);
      this.locationPin = null;
    }
  }

  initListeners() {
    combineLatest([
      this.mapService.onLayerVisibilityChanged(),
      this.userPreferencesService.getColorScheme(),
    ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        ([visibilityByLayerUpdate, colorScheme]: [VisibilityByLayerUpdate, ColorScheme]) => {
          this.markersFactory.hideTooltip();
          const layerIdsToRender = [];
          const layerIdsToRemove = [];
          for (const [
            layerId,
            layerBecomingVisible,
          ] of visibilityByLayerUpdate.visibilityByLayerId) {
            // Using !! to cast undefined to false. In the case that the layer
            // doesn't exist yet on the map, layerVisibilityById.get(layerId)
            // returns undefined.
            if (!!this.layerVisibilityById.get(layerId) === layerBecomingVisible) {
              continue;
            }
            if (layerBecomingVisible) {
              layerIdsToRender.push(layerId);
              this.layerVisibilityById.set(layerId, true);
              continue;
            }
            layerIdsToRemove.push(layerId);
            this.layerVisibilityById.set(layerId, false);
          }
          this.removeLayers(layerIdsToRemove);
          this.fetchAndRenderLayers(layerIdsToRender, false);

          this.updateMapTheme(colorScheme === ColorScheme.DARK);

          // By default if nothing is set, select all feeders with data. This
          // is to prevent the case when a user enables the Solar Insights
          // layer and has to manually select all feeders with data.
          if (this.configService.solar2Enabled && this.selectedSolarFeeders === null) {
            this.solarInsightsService.selectedFeeders.next(
              this.solarInsightsService.getAllFeeders(),
            );
          }
        },
      );

    this.layersFilterService
      .layerFiltersUpdated()
      .pipe(
        filter(
          (filterUpdate: FilterUpdate) => !!this.layerVisibilityById.get(filterUpdate.layerId),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe((filterUpdate: FilterUpdate) => {
        this.markersFactory.hideTooltip();
        this.removeLayers([filterUpdate.layerId]);
        this.fetchAndRenderLayers([filterUpdate.layerId], filterUpdate.globalSearch);
      });
  }

  protected fetchAndRenderFeatures(layerId: string): Subject<MapContentChange> {
    if (this.fetchAndRenderFeaturesSubjects.has(layerId)) {
      return this.fetchAndRenderFeaturesSubjects.get(layerId)!;
    }
    const subject = new Subject<MapContentChange>();
    this.fetchAndRenderFeaturesSubjects.set(layerId, subject);
    let fetching = false;
    subject
      .pipe(
        mergeMap(({layerId, globalSearch}: MapContentChange) => {
          return combineLatest([
            this.layersFilterService.getFilterMap(layerId).pipe(first()),
            this.layersFilterService.includeInactive(layerId).pipe(first()),
          ]).pipe(
            map(([filters, includeInactiveResults]: [FilterMap, boolean]) => {
              return {
                layerId,
                globalSearch,
                filters,
                includeInactiveResults,
              };
            }),
          );
        }),
        switchMap(({layerId, globalSearch, filters, includeInactiveResults}: MapLayerFilters) => {
          if (!fetching) {
            this.fetchingCount++;
            fetching = true;
          }
          const bounds = this.map.getBounds()!;
          const layerName = this.layersService.getLayerName(layerId);
          const featuresStream$ =
            globalSearch && !this.boundsContainAnyFeatureByLayerId.get(layerId)
              ? this.featureService
                  .queryFeatures({
                    layerId,
                    filters,
                    includeInactiveResults,
                  })
                  .pipe(
                    map((response: QueryFeaturesResponse): Feature[] => {
                      return response.features.slice();
                    }),
                  )
              : this.featureService.queryFeaturesInBounds(
                  layerId,
                  bounds,
                  filters,
                  includeInactiveResults,
                );

          return featuresStream$.pipe(
            catchError(() => {
              // TODO(reubenn): Add some type of debug-time logging.
              this.displayLayerFailedToLoad(layerName);
              return of([]);
            }),
            mergeMap((features: Feature[]) => {
              return combineLatest([of(layerId), of(features), of(globalSearch)]);
            }),
          );
        }),
        takeUntil(this.destroyed),
      )
      .subscribe(([layerId, features, globalSearch]: [string, Feature[], boolean]) => {
        this.fetchingCount--;
        fetching = false;
        if (!this.layerVisibilityById.get(layerId)) {
          return;
        }
        features = features.filter((feature: Feature) => feature.id !== this.deletedFeatureId);
        if (this.maxMarkersCountExceeded(layerId, features.length)) {
          this.removeLayers([layerId]);
        }
        this.boundsContainAnyFeatureByLayerId.set(
          layerId,
          boundsContainsAnyFeature(features, this.map.getBounds()!),
        );
        this.updateMarkerColors();
        const renderedFeatures = this.filterOutRenderedFeatures(features);
        const boundsContainFeatures = boundsContainsAnyFeature(
          renderedFeatures,
          this.map.getBounds()!,
        );
        // Only update the map bounds if the last query was a global
        // search, ie, a query after a filter has been updated, and there
        // aren't any features inside the map's current bounds but there
        // are features that should be rendered. If there is only a single
        // feature, zoom into it regardless of the map's bounds.
        const updateMapBounds =
          globalSearch &&
          ((renderedFeatures.length > 1 && !boundsContainFeatures) ||
            renderedFeatures.length === 1);
        const featuresToRender = this.separateDuplicateLocations(renderedFeatures);
        if (featuresToRender.duplicateLocations.length > 0) {
          this.renderDuplicateLocationFeatures(featuresToRender.duplicateLocations, layerId);
        }
        if (featuresToRender.singleLocations.length > 0) {
          this.renderSingleLocationFeatures(featuresToRender.singleLocations, layerId);
        }
        this.selectPendingFeature();
        if (updateMapBounds) {
          const allMarkerPositions = this.getPositionOfAllMarkersInLayer(layerId);
          if (allMarkerPositions.length > 0) {
            const markerPositions = filterNearestMarkersByPosition(
              allMarkerPositions,
              this.map.getBounds()!,
            );
            const bounds = new google.maps.LatLngBounds();
            for (const markerPosition of markerPositions) {
              bounds.extend(markerPosition);
            }
            this.map.fitBounds(bounds, FIT_BOUNDS_PADDING);
          }
        }
      });
    return subject;
  }

  private getPositionOfAllMarkersInLayer(
    layerId: string,
  ): (google.maps.LatLng | null | undefined)[] {
    const markers = this.markersByLayerId.has(layerId)
      ? [...this.markersByLayerId.get(layerId)!]
      : [];
    const multiMarkers = this.multiMarkersByLayerId.has(layerId)
      ? [...this.multiMarkersByLayerId.get(layerId)!]
      : [];
    const allMarkers = [...markers, ...multiMarkers].map(
      (
        marker: Marker | MultiMarker,
      ): google.maps.Marker | google.maps.Polygon | google.maps.Polyline => marker.marker,
    );
    const positions = [];
    for (const marker of allMarkers) {
      if (marker instanceof google.maps.Marker) {
        if (marker) {
          positions.push(marker.getPosition());
          continue;
        }
      }
      marker.getPath().forEach((position: google.maps.LatLng) => {
        if (position) {
          positions.push(position);
        }
      });
    }
    return positions;
  }

  protected selectPendingFeature() {
    if (!this.pendingFeatureId) {
      return;
    }
    if (
      this.markerByFeatureId.has(this.pendingFeatureId) ||
      this.multiMarkerByFeatureId.has(this.pendingFeatureId)
    ) {
      this.selectFeature(this.pendingFeatureId);
    }
  }

  protected selectFeature(featureId: string, updateMarkerSelectedState = true) {
    if (this.selectedFeatureId === featureId) {
      return;
    }
    this.removeLocationPin();
    this.deselectFeature();
    this.pendingFeatureId = '';
    const marker =
      this.markerByFeatureId.get(featureId) || this.multiMarkerByFeatureId.get(featureId);
    // Marker selection of polygons and polylines is not supported.
    if (marker?.marker instanceof google.maps.Marker) {
      if (updateMarkerSelectedState) {
        this.mapService.updateMarkerSelectedState(marker.marker, true);
      }
      this.selectedFeatureId = featureId;
      return;
    }
    this.pendingFeatureId = featureId;
  }

  protected deselectFeature() {
    if (!this.selectedFeatureId) {
      return;
    }
    const marker =
      this.markerByFeatureId.get(this.selectedFeatureId) ||
      this.multiMarkerByFeatureId.get(this.selectedFeatureId);
    if (marker?.marker instanceof google.maps.Marker) {
      this.mapService.updateMarkerSelectedState(marker.marker, false);
      this.selectedFeatureId = '';
    }
  }

  protected createIconSelectedFn(layerId: string): IconSelectedFn {
    return (marker: Marker, feature: Feature) => {
      this.sidepanelService.setSidepanelOpened(true);
      this.mapService.setShouldRepositionMap(false);
      if (
        this.configService.solar2Enabled &&
        this.layerVisibilityById.get(SOLAR_INSIGHTS_LAYER_ID)
      ) {
        return this.router.navigate(
          [
            ROUTE.FEEDER_DETAILS_MAP,
            encodeURIComponent(getFeederName(marker.featureMetadata.feature)),
          ],
          {
            queryParams: {
              [QUERY_PARAMS.FEATURE_ID]: feature.id,
              [QUERY_PARAMS.LAYER_ID]: layerId,
            },
          },
        );
      } else {
        return this.router.navigate([ROUTE.MAP, layerId, feature.id]);
      }
    };
  }

  protected renderSingleLocationFeatures(features: Feature[], layerId: string) {
    if (features.length === 0) {
      return;
    }
    const markers = this.markersFactory.createFeatureMarkers(
      features,
      layerId,
      this.createIconSelectedFn(layerId),
    );
    for (const marker of markers) {
      marker.marker.setMap(this.map);
      this.addToMarkersByLayerId(marker, layerId);
      this.markerByFeatureId.set(marker.featureMetadata.feature.id, marker);
      if (marker.marker instanceof google.maps.Marker) {
        this.markerByLocation.set(
          this.createLocationKeyFromFeature(marker.featureMetadata.feature),
          marker,
        );
      }
    }
  }

  protected renderDuplicateLocationFeatures(features: Feature[], layerId: string) {
    this.createMultiMarkers(features, layerId);

    // Ensure that correct multi-marker is selected after creating and adding to
    // multi-markers.
    const selectedMultiMarker = this.multiMarkerByFeatureId.get(this.selectedFeatureId);
    if (selectedMultiMarker) {
      this.mapService.updateMarkerSelectedState(selectedMultiMarker.marker, true);
    }
  }

  protected createMultiMarkers(
    features: Feature[],
    layerId: string,
    addToMap = true,
  ): MultiMarker[] {
    const multiMarkers: MultiMarker[] = [];
    for (const feature of features) {
      const locationKey = this.createLocationKeyFromFeature(feature);
      if (this.multiMarkerByLocation.has(locationKey)) {
        const multiMarker = this.multiMarkerByLocation.get(locationKey)!;
        multiMarker.featuresMetadata.push({
          feature,
          layerId,
        });
        this.multiMarkerByFeatureId.set(feature.id, multiMarker);
        this.addToMultiMarkersByLayerId(multiMarker, layerId);
        this.markersFactory.updateMultiMarkerIcon(multiMarker);
        continue;
      }
      const location = this.getLocationFromFeature(feature);
      if (!location) {
        console.error(`failed to create multi marker: location missing: feature ID: ${feature.id}`);
        return [];
      }
      const existingFeatureMetadata = this.markerByLocation.get(locationKey)?.featureMetadata;
      if (this.markerByLocation.has(locationKey)) {
        this.removeMarker(locationKey);
      }
      const featuresMetadata: FeatureMetadata[] = [
        {
          feature,
          layerId,
        },
      ];
      if (existingFeatureMetadata) {
        featuresMetadata.push(existingFeatureMetadata);
      }
      const multiMarker = this.markersFactory.createMultiMarker(
        location,
        featuresMetadata,
        this.infoWindowVCRef,
      );
      this.multiMarkerByLocation.set(locationKey, multiMarker);
      this.multiMarkerByFeatureId.set(feature.id, multiMarker);
      this.addToMultiMarkersByLayerId(multiMarker, layerId);
      multiMarkers.push(multiMarker);
      if (existingFeatureMetadata) {
        this.multiMarkerByFeatureId.set(existingFeatureMetadata.feature.id, multiMarker);
        this.addToMultiMarkersByLayerId(multiMarker, existingFeatureMetadata.layerId);
      }
      if (addToMap) {
        multiMarker.marker.setMap(this.map);
      }
    }
    return multiMarkers;
  }

  protected addToMarkersByLayerId(marker: Marker, layerId: string) {
    if (!this.markersByLayerId.has(layerId)) {
      this.markersByLayerId.set(layerId, new Set<Marker>());
    }
    this.markersByLayerId.get(layerId)!.add(marker);
  }

  protected addToMultiMarkersByLayerId(multiMarker: MultiMarker, layerId: string) {
    if (!this.multiMarkersByLayerId.has(layerId)) {
      this.multiMarkersByLayerId.set(layerId, new Set<MultiMarker>());
    }
    this.multiMarkersByLayerId.get(layerId)!.add(multiMarker);
  }

  protected separateDuplicateLocations<T extends Feature>(
    features: readonly T[],
  ): FeaturesToRender<T> {
    const featuresToRender: FeaturesToRender<T> = {
      singleLocations: [],
      duplicateLocations: [],
    };
    const featureByLocation = new Map<string, T>();
    const duplicateLocationKeys = new Set<string>();
    for (const feature of features) {
      if (feature instanceof Feature && feature.geometry?.geometry?.case !== 'point') {
        featuresToRender.singleLocations.push(feature);
        continue;
      }
      const locationKey = this.createLocationKeyFromFeature(feature);
      if (
        this.multiMarkerByLocation.has(locationKey) ||
        this.markerByLocation.has(locationKey) ||
        duplicateLocationKeys.has(locationKey)
      ) {
        featuresToRender.duplicateLocations.push(feature);
        continue;
      }
      if (featureByLocation.has(locationKey)) {
        featuresToRender.duplicateLocations.push(feature, featureByLocation.get(locationKey)!);
        duplicateLocationKeys.add(locationKey);
        featureByLocation.delete(locationKey);
        continue;
      }
      featureByLocation.set(locationKey, feature);
    }
    featuresToRender.singleLocations.push(...featureByLocation.values());
    return featuresToRender;
  }

  protected getLocationFromFeature(feature: Feature): LatLng | null {
    let location: LatLng | null = null;
    const point = feature.geometry?.geometry?.value as Point;
    location = point?.location || null;
    if (!location) {
      console.error(`location not found for feature ${feature.id}`);
      return location;
    }
    return location || null;
  }

  /**
   * Returns a location key based on a feature's lat and lng values.
   */
  protected createLocationKeyFromFeature(feature: Feature): string {
    const location = this.getLocationFromFeature(feature);
    if (!location?.latitude && !location?.longitude) {
      console.error(`Feature is missing location: ${feature.id}`);
      return '';
    }
    const truncatedLocation = this.truncateLocation(location);
    return `lat:${truncatedLocation.latitude},lng:${truncatedLocation.longitude}`;
  }

  /**
   * Returns a location key based on a marker's lat and lng values.
   */
  protected createLocationKeyFromMarker(marker: google.maps.Marker): string {
    const position = marker.getPosition();
    if (!position) {
      console.error(`Marker is missing location: ${marker?.getLabel()}`);
      return '';
    }
    const truncatedLocation = this.truncateLocation(
      new LatLng({
        latitude: position.lat(),
        longitude: position.lng(),
      }),
    );
    return `lat:${truncatedLocation.latitude},lng:${truncatedLocation.longitude}`;
  }

  protected truncateLocation(location: LatLng): LatLng {
    return new LatLng({
      latitude: Number(location.latitude.toFixed(LOCATION_PRECISION)),
      longitude: Number(location.longitude.toFixed(LOCATION_PRECISION)),
    });
  }

  protected removeLayers(layerIds: string[]) {
    for (const layerId of layerIds) {
      this.removeMarkers(layerId);
      this.removeFromMultiMarkers(layerId);
      const layerType = this.layersService.getLayerType(layerId);
      if (layerType === Layer_LayerType.IMAGE_TILE) {
        this.map.overlayMapTypes.clear();
        this.sunroofLayerVisible = false;
      }
    }
  }

  protected removeMarkers(layerId: string) {
    if (!this.markersByLayerId.has(layerId)) {
      return;
    }
    for (const marker of this.markersByLayerId.get(layerId)!.values()) {
      marker.marker.setMap(null);
      this.markerByFeatureId.delete(marker.featureMetadata.feature.id);
      if (marker.marker instanceof google.maps.Marker) {
        this.markerByLocation.delete(this.createLocationKeyFromMarker(marker.marker));
      }
    }
    this.markersByLayerId.delete(layerId);
  }

  protected removeMarker(locationKey: string) {
    const marker = this.markerByLocation.get(locationKey)!;
    this.markerByLocation.delete(locationKey);
    this.markerByFeatureId.delete(marker.featureMetadata.feature.id);
    this.markersByLayerId.get(marker.featureMetadata.layerId)?.delete(marker);
    marker.marker.setMap(null);
  }

  protected removeFromMultiMarkers(layerId: string) {
    if (!this.multiMarkersByLayerId.has(layerId)) {
      return;
    }
    for (const multiMarker of this.multiMarkersByLayerId.get(layerId)!.values()) {
      const remainingFeaturesMetadata: FeatureMetadata[] = [];
      for (const featureMetadata of multiMarker.featuresMetadata) {
        if (featureMetadata.layerId !== layerId) {
          remainingFeaturesMetadata.push(featureMetadata);
          continue;
        }
        this.multiMarkerByFeatureId.delete(featureMetadata.feature.id);
      }
      multiMarker.featuresMetadata = remainingFeaturesMetadata;
      if (remainingFeaturesMetadata.length > 1) {
        this.markersFactory.updateMultiMarkerIcon(multiMarker);
        continue;
      }
      multiMarker.marker.setMap(null);
      this.multiMarkerByLocation.delete(this.createLocationKeyFromMarker(multiMarker.marker));
      if (remainingFeaturesMetadata.length === 1) {
        const {feature, layerId} = remainingFeaturesMetadata[0];
        this.multiMarkerByFeatureId.delete(feature.id);
        this.multiMarkersByLayerId.get(layerId)!.delete(multiMarker);
        this.renderSingleLocationFeatures([feature], layerId);
      }
    }
    this.multiMarkersByLayerId.delete(layerId);
  }

  protected filterOutRenderedFeatures<T extends Feature>(features: readonly T[]): T[] {
    return features.filter((feature: T) => {
      return (
        !this.markerByFeatureId.has(feature.id) && !this.multiMarkerByFeatureId.has(feature.id)
      );
    });
  }

  protected fetchAndRenderSunroof(layerId: string) {
    if (this.sunroofLayerVisible) {
      // Do not rerender the sunroof layer if already rendered. The sunroof
      // doesn't render as a bounding box. The full set of image tiles are
      // rendered when it is turned on.
      return;
    }
    this.sunroofLayerVisible = true;
    this.map.overlayMapTypes.push(this.sunroofService.createImageTiles(layerId));
  }

  private fetchAndRenderLayers(layerIds: string[], globalSearch: boolean) {
    for (const layerId of layerIds) {
      const layerType = this.layersService.getLayerType(layerId);
      if (!layerType) {
        continue;
      }
      if (layerType === Layer_LayerType.IMAGE_TILE && !this.configService.solar2Enabled) {
        this.fetchAndRenderSunroof(layerId);
        return;
      }
      const updateLayer = {layerId, globalSearch};
      this.fetchAndRenderFeatures(layerId).next(updateLayer);
    }
  }

  protected displayLayerFailedToLoad(layerName: string | null) {
    this.snackBar.open(`Could not load ${layerName || 'requested'} layer.`, '', {duration: 2000});
  }

  /**
   * Returns the layer IDs that are visible.
   */
  protected getVisibleLayerIds(): string[] {
    const visibleLayerIds = [];
    for (const [layerId, visible] of this.layerVisibilityById) {
      if (visible) {
        visibleLayerIds.push(layerId);
      }
    }
    return visibleLayerIds;
  }

  protected attachControls() {
    if (this.myLocationControl) {
      const nativeMyLocationControl = this.myLocationControl.nativeElement;
      // Make the control show up underneath the other controls on the right
      // bottom of the map.
      nativeMyLocationControl.index = 0;
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(nativeMyLocationControl);
    }
  }

  protected dropLocationPin(latLngLiteral: google.maps.LatLngLiteral) {
    this.removeLocationPin();
    this.locationPin = new google.maps.Marker({
      map: this.map,
      position: latLngLiteral,
      zIndex: this.mapPropertiesService.LOCATION_PIN_Z_INDEX,
    });
  }

  protected repositionMap(latLngLiteral: google.maps.LatLngLiteral) {
    this.map.setCenter(latLngLiteral);
    this.map.setZoom(this.mapPropertiesService.MAP_REPOSITION_ZOOM_LEVEL);
  }

  // repositionMapToMyLocation() {
  //   this.userLocationService
  //     .getCurrentPosition()
  //     .pipe(first(), takeUntil(this.destroyed))
  //     .subscribe(
  //       (coords: GeolocationCoordinates) => {
  //         const latLngLiteral = {
  //           lat: coords.latitude,
  //           lng: coords.longitude,
  //         };
  //         this.repositionMap(latLngLiteral);
  //         this.dropLocationPin(latLngLiteral);
  //       },
  //       (error: Error) => {
  //         this.snackBar.open(
  //           'Could not show current location. Check that ' +
  //             'location settings are enabled.',
  //           '',
  //           {duration: SNACK_BAR_TIMEOUT_MS}
  //         );
  //       }
  //     );
  // }

  startWatchingPosition() {
    if (!navigator.geolocation) {
      return;
    }
    this.watchPositionHandlerId = navigator.geolocation.watchPosition(
      ({coords}: GeolocationPosition) => {
        this.showCurrentLocationMarker({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      },
      () => {
        this.removeCurrentLocationMarker();
        this.snackBar.open(
          'Could not show current location. Check that ' + 'location settings are enabled.',
          '',
          {duration: SNACK_BAR_TIMEOUT_MS},
        );
      },
      DEFAULT_GEOLOCATION_OPTIONS,
    );
  }

  protected stopWatchingPosition() {
    if (!this.watchPositionHandlerId) {
      return;
    }
    this.removeCurrentLocationMarker();
    navigator.geolocation.clearWatch(this.watchPositionHandlerId);
  }

  protected showCurrentLocationMarker(latLngLiteral: google.maps.LatLngLiteral) {
    this.removeCurrentLocationMarker();
    this.currentLocationMarker = this.markersFactory.createCurrentLocationMarker(
      latLngLiteral,
      this.map,
    );
  }

  protected removeCurrentLocationMarker() {
    this.currentLocationMarker?.setMap(null);
    this.currentLocationMarker = null;
  }

  protected sendEvent(eventActionType: EventActionType, eventLabel: string) {
    this.analyticsService.sendEvent(eventActionType, {
      event_category: EventCategoryType.MAP,
      event_label: eventLabel,
    });
  }

  protected updateMarkerColors() {
    // this.markersFactory.applySolarColoring =
    //   this.configService.solar2Enabled &&
    //   this.layerVisibilityById.get(SOLAR_INSIGHTS_LAYER_ID);

    for (const layerId of this.markersByLayerId.keys()) {
      for (const marker of this.markersByLayerId.get(layerId)!.values()) {
        const updatedIcon = this.markersFactory.createFeatureIcon(
          marker.featureMetadata.feature,
          layerId,
        );
        marker.marker.setOptions({
          icon: updatedIcon.deselected,
        });
      }
    }

    for (const layerId of this.multiMarkersByLayerId.keys()) {
      for (const multiMarker of this.multiMarkersByLayerId.get(layerId)!.values()) {
        this.markersFactory.updateMultiMarkerIcon(multiMarker);
      }
    }
  }

  // Update the map theme if needed. For example, with Sunroof and Solar,
  // it can be helpful to have a desaturated map theme for colors to stand out.
  private updateMapTheme(isDarkMode: boolean) {
    const applyDesaturatedLayer =
      this.layerVisibilityById.get(SOLAR_INSIGHTS_LAYER_ID) ||
      this.layerVisibilityById.get(SUNROOF_LAYER_ID) ||
      this.layerVisibilityById.get(STREETVIEW_RECENCY_LAYER_ID);
    if (applyDesaturatedLayer) {
      this.mapService.setMapStyles(isDarkMode ? 'dark' : 'silver');
    } else {
      // Apply default theme map style.
      this.mapService.setMapStyles(isDarkMode ? 'night' : 'default');
    }
  }
}

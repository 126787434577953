@if (isSidePanelVisible | async) {
  <sidepanel-layout>
    <div header></div>
    <div sidepanel>
      <router-outlet></router-outlet>
    </div>
    <div main class="multi-table-page container">
      <div class="map-table-toggle-container">
        <map-table-toggle></map-table-toggle>
      </div>
      @if (selectedLayerIDs | async; as layerIDs) {
        <div class="table-panels-container">
          @for (layerID of layerIDs; track layerID) {
            @if (layerID !== solarInsightsLayerId) {
              <data-table
                [attr.id]="layerID"
                [layerId]="layerID"
                (onContentChanged)="onTableUpdate(layerID)"
                class="scroll-container">
              </data-table>
            } @else if (solarInsightsEnabled) {
              <solar-data-table [attr.id]="layerID" [layerId]="layerID"></solar-data-table>
            }
          }
        </div>
      }
    </div>
  </sidepanel-layout>
}

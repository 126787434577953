import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {AuthProviderType, AuthService} from '../services/auth_service';

/**
 * Calls signInWithRedirection from the firebase service and acts as a loading
 * overlay until authentication completes.
 */
@Component({
  template: `<div class="overlay">
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="48">
    </mat-progress-spinner>
  </div>`,
  styleUrls: ['./auth_loading.scss'],
})
export class AuthLoadingComponent implements OnInit {
  signIn: boolean;
  authType: AuthProviderType;
  providerIndex: number;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    const state = this.router.getCurrentNavigation()?.extras.state;
    this.signIn = state && state['signIn'];
    this.authType = state && state['authType'];
    this.providerIndex = state && state['providerIndex'];
  }

  ngOnInit() {
    if (this.signIn && this.authType === AuthProviderType.OAUTH) {
      this.authService.oAuthSignInWithRedirection(this.providerIndex);
      return;
    } else if (this.signIn && this.authType === AuthProviderType.GOOGLE) {
      this.authService.googleSignInWithRedirection();
      return;
    }
    // The first time this page is hit one of the above redirections will be
    // called depending on whether the auth provider is OAuth or Google. When
    // the page is hit a second time, after OAuth or Google redirection, this
    // functions fires which verifies that there is a user and routes to the
    // map page or login page depending on whether a user is present.
    this.authService.getRedirectionResult();
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/tag.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * A tag is a label that is used to highlight a noteworthy attribute of an image
 * or an asset.
 * Tags are used to categorize and filter images along customer-specified lines
 * (e.g. images uploaded in "storm mode").
 * See http://google3/googlex/refinery/viaduct/proto/gridaware/tag.proto
 *
 * @generated from message tapestry.gridaware.api.v1.Tag
 */
export const Tag = proto3.makeMessageType("tapestry.gridaware.api.v1.Tag", () => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, ReplaySubject} from 'rxjs';
import {first, map, mergeMap, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Geometry} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';

import {ASSET_TYPE, CE_TAG_NUMBER, FEEDER_ID} from '../constants/asset';
import {IDB_ASSETS_STORE_INDEX_NAME, IDB_ASSETS_STORE_NAME} from '../constants/storage';
import {getPropertyValue} from '../utils/feature';
import {getAssetType, getFeederId, setPropertyValue} from '../utils/feature';
import {IndexedDBService} from './indexed_db_service';

/**
 * The info about the asset that gets saved for offline use.
 */
export declare interface OfflineAssetsInfo {
  featureId: string;
  externalId: string;
  // The name that is used for autocompleting when associating an asset.
  searchName: string;
  name?: string;
  feederId?: string;
  assetType?: string;
  latitude?: number;
  longitude?: number;
}

function getSearchName(asset: Feature): string {
  if (getAssetType(asset)?.toLowerCase() === 'support structure') {
    const value = getPropertyValue(CE_TAG_NUMBER, asset.properties);
    if (value.length) {
      return value;
    }
    console.error(`missing "${CE_TAG_NUMBER}" on asset: ${asset}`);
  }
  return asset.externalId;
}

/**
 * Service for providing offline asset information extracted
 * from IndexedDB snapshot.
 */
@Injectable({providedIn: 'root'})
export class OfflineAssetsService {
  private readonly storeName = IDB_ASSETS_STORE_NAME;
  readonly assets = new ReplaySubject<OfflineAssetsInfo[]>();
  readonly assetsCount = new ReplaySubject<number>(1);

  constructor(private readonly indexedDBService: IndexedDBService) {
    this.getNumberOfAssets().subscribe((assetsCount: number) => {
      this.assetsCount.next(assetsCount);
    });
  }

  /**
   * Fetches all stored info of assets from offline store,
   * publishes updates on store modifications.
   */
  getAssets(): Observable<OfflineAssetsInfo[]> {
    this.indexedDBService
      .getAllItems(this.storeName)
      .pipe(
        map((data): OfflineAssetsInfo[] => data as OfflineAssetsInfo[]),
        first(),
      )
      .subscribe((assets: OfflineAssetsInfo[]) => {
        this.assets.next(assets);
      });
    return this.assets;
  }

  /**
   * Fetches all external IDs of assets from offline store.
   */
  getAssetExternalIds(): Observable<string[]> {
    return this.indexedDBService
      .getAllItemKeys(this.storeName)
      .pipe(map((data): string[] => data as string[]));
  }

  /**
   * Fetches external IDs matching the provided prefix.
   */
  getAssetExternalIdsByPrefix(prefix: string, maxResults: number): Observable<string[]> {
    return this.indexedDBService.getItemKeysStartingWith(this.storeName, prefix, maxResults);
  }

  /**
   * Fetches the total number of assets in the offline store.
   */
  getNumberOfAssets(): Observable<number> {
    return this.indexedDBService.countAllItems(this.storeName);
  }

  /**
   * Fetches asset by feature ID (internal GridAware ID) from offline store.
   */
  getAssetByID(id: string): Observable<OfflineAssetsInfo | null> {
    return this.indexedDBService
      .getItemByIndexedField(this.storeName, IDB_ASSETS_STORE_INDEX_NAME, id)
      .pipe(
        map((data): OfflineAssetsInfo | null =>
          data && this.isOfflineAssetsInfo(data) ? (data as OfflineAssetsInfo) : null,
        ),
      );
  }

  /**
   * Clears the offline store and writes provided values to it.
   */
  overwriteAssetsStore(assets: Feature[]): Observable<number> {
    const newAssets = assets.map(
      (asset: Feature): OfflineAssetsInfo => this.formatFeatureForStorage(asset),
    );

    return this.indexedDBService.deleteAllItems(this.storeName).pipe(
      mergeMap(() => this.indexedDBService.addItems(this.storeName, newAssets)),
      tap(() => {
        this.assetsCount.next(newAssets.length);
        this.assets.next(newAssets);
      }),
    );
  }

  /**
   * Clears the offline store.
   */
  clearAssetsStore(): Observable<void> {
    return this.indexedDBService.deleteAllItems(this.storeName).pipe(
      tap(() => {
        this.assetsCount.next(0);
        this.assets.next([]);
      }),
    );
  }

  /**
   * Allows clients to stay updated on when the number of stored assets changes.
   */
  onAssetsCountChanged(): Observable<number> {
    return this.assetsCount.asObservable();
  }

  private formatFeatureForStorage(asset: Feature): OfflineAssetsInfo {
    // TODO(halinab): save all asset info instead of subset.
    let location = null;
    if (asset.geometry?.geometry.case === 'point') {
      location = asset.geometry?.geometry.value.location;
    }
    return {
      featureId: asset.id,
      externalId: asset.externalId,
      searchName: getSearchName(asset),
      name: asset.name,
      feederId: getFeederId(asset),
      assetType: getAssetType(asset),
      latitude: location?.latitude,
      longitude: location?.longitude,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isOfflineAssetsInfo(object: any): boolean {
    return (
      // eslint-disable-next-line no-prototype-builtins
      object.hasOwnProperty('externalId') && object.hasOwnProperty('featureId')
    );
  }
}

/**
 * Represents asset from offline store as feature.
 */
export function offlineAssetToFeature(asset: OfflineAssetsInfo): Feature {
  const feature = new Feature({
    id: asset.featureId,
    externalId: asset.externalId,
    name: asset.name || 'Unknown',
    properties: offlineAssetToProperties(asset),
  });
  if (asset.latitude && asset.longitude) {
    const location = new LatLng({
      latitude: asset.latitude,
      longitude: asset.longitude,
    });
    feature.geometry = new Geometry({
      geometry: {case: 'point', value: {location}},
    });
  }

  return feature;
}

function offlineAssetToProperties(asset: OfflineAssetsInfo): Property[] {
  let properties: Property[] = [];
  if (asset.assetType) {
    properties = setPropertyValue(ASSET_TYPE, asset.assetType, properties) || [];
  }
  if (asset.feederId) {
    properties = setPropertyValue(FEEDER_ID, asset.feederId, properties) || [];
  }
  return properties;
}

import {PromiseClient} from '@connectrpc/connect';
import {BehaviorSubject, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {EmptyService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/emptyservice_connect';

import {ApiService} from './api_service';

/**
 * Provides an API for toggling the sidepanel window.
 */
@Injectable({providedIn: 'root'})
export class SidepanelService {
  sidepanelOpened$ = new BehaviorSubject(true);
  private readonly emptyServiceClient: PromiseClient<typeof EmptyService>;

  constructor(
    private readonly apiService: ApiService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.emptyServiceClient = apiService.createEmptyServiceBEClient();
  }

  setSidepanelOpened(opened: boolean) {
    this.sidepanelOpened$.next(opened);

    this.apiService
      .withCallOptions((options) => this.emptyServiceClient.sendEmpty({}, options))
      .then(
        () => {},
        (error: Error) => {
          this.snackBar.open(error.message, 'close');
          console.error(error);
        },
      );
  }

  getSidepanelOpened$(): Observable<boolean> {
    return this.sidepanelOpened$.asObservable();
  }
}

import {LifecycleStage} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Layer_LayerType as LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole,
  RelatedFeaturesGroup,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

import {ASSET_TYPE, EQUIPMENT_ID, FEEDER_ID, FEEDER_NAME} from '../constants/asset';
import {LAYER_TYPE_BY_RELATED_ROLE} from '../constants/common';
import {FilterMap} from '../typings/filter';
import {FieldType, GlobalFormPropertyName, UploadFormTemplate} from '../typings/upload';
import {timestampToDateString, timestampToDateTimeString} from './date';

// A list of properties that should be preserved when editing an image group.
// Listed properties were added during image group creation and will not be
// added during the image group update.
const PROPERTIES_TO_PRESERVE_DURING_EDIT = new Set<string>([
  GlobalFormPropertyName.LOCATION_DESCRIPTION,
]);

/**
 * Returns the value of a key. If one is not found, returns empty string.
 */
export function getPropertyValue(key: string, properties: ReadonlyArray<Property>): string {
  if (!key) {
    return '';
  }
  const property = properties.find((item: Property) => item.key === key);
  return property ? (property.propertyValue.value as string) : '';
}

/**
 * Sets the value of the property key and returns a new list. If key doesn't
 * exist, appends. Returns null if the property list isn't unique.
 */
export function setPropertyValue(
  key: string,
  value: string,
  properties: ReadonlyArray<Property>,
): Property[] | null {
  const propertyKeys = properties.map((property: Property) => property.key);
  if (properties.length !== new Set(propertyKeys).size) {
    return null;
  }
  return setOrAddPropertyValue(key, value, properties);
}

/**
 * Sets the value of the property key and returns a new list. If key doesn't
 * exist, appends. It does not modify the original properties.
 */
export function setOrAddPropertyValue(
  key: string,
  value: string,
  properties: readonly Property[],
): Property[] {
  const newProperties: Property[] = [];
  let found = false;
  for (const property of properties) {
    const newProperty = property.clone();
    if (property.key === key) {
      newProperty.propertyValue = {case: 'value', value};
      found = true;
    }
    newProperties.push(newProperty);
  }
  if (!found) {
    newProperties.push(new Property({key, propertyValue: {case: 'value', value}}));
  }
  return newProperties;
}

/**
 * Returns a list of `Property` suitable for filtering features by properties.
 * Optionally exclude some filters listed in `exclude`.
 */
export function convertToProperties(filters: FilterMap, exclude: string[] = []): Property[] {
  const properties: Property[] = [];
  const excludeKeys = new Set(exclude);
  for (const [key, values] of Object.entries(filters)) {
    if (excludeKeys.has(key)) {
      continue;
    }
    for (const value of values) {
      properties.push(new Property({key, propertyValue: {case: 'value', value}}));
    }
  }
  return properties;
}

/**
 * Returns the child image count of a feature.
 */
export function getChildImageCount(feature: Feature): number | null {
  const imagesGroup = feature.relatedFeaturesGroups.find((group: RelatedFeaturesGroup) => {
    return (
      group.role === RelatedFeatureRole.CHILD_IMAGE ||
      group.role === RelatedFeatureRole.AUTOTOPOLOGY_CHILD_IMAGE
    );
  });
  return imagesGroup?.relatedFeatures.length || null;
}

/**
 * Returns the related feature IDs for a given feature and role.
 */
export function getRelatedIds(feature: Feature, role: RelatedFeatureRole): string[] {
  return (
    feature.relatedFeaturesGroups
      .find((group: RelatedFeaturesGroup) => group.role === role)
      ?.relatedFeatures.map((relatedFeature: RelatedFeature) => relatedFeature.id) || []
  );
}

/**
 * Gets the feeder ID from a feature's properties.
 */
export function getFeederId(feature: Feature): string {
  return getPropertyValue(FEEDER_ID, feature.properties);
}

/**
 * Gets the feeder name from a feature's properties.
 */
export function getFeederName(feature: Feature): string {
  return getPropertyValue(FEEDER_NAME, feature.properties);
}

/**
 * Returns all the related featuress.
 */
export function getAllRelatedFeatures(feature: Feature): RelatedFeature[] {
  return feature.relatedFeaturesGroups.flatMap(
    (group: RelatedFeaturesGroup) => group.relatedFeatures,
  );
}

/**
 * Returns sorted comma-separated display names of related features for a given
 * feature and layer ID.
 */
export function getRelatedFeaturesNames(feature: Feature, layerId: string): string {
  return getAllRelatedFeatures(feature)
    .filter((relatedFeature: RelatedFeature): boolean => relatedFeature.layerId === layerId)
    .map((relatedFeature: RelatedFeature): string => relatedFeature.displayName)
    .sort(lowerCaseComparator)
    .join(', ');
}

function lowerCaseComparator(a: string, b: string): number {
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
}

/**
 * Returns a map of layer IDs to related features (sorted).
 */
export function groupRelatedFeaturesByLayerId(
  relatedFeatureGroups: RelatedFeaturesGroup[],
): Map<string, RelatedFeature[]> {
  const relatedFeatureByLayerId = new Map<string, RelatedFeature[]>();
  for (const group of relatedFeatureGroups) {
    for (const relatedFeature of group.relatedFeatures) {
      if (!relatedFeatureByLayerId.has(relatedFeature.layerId)) {
        relatedFeatureByLayerId.set(relatedFeature.layerId, []);
      }
      relatedFeatureByLayerId.get(relatedFeature.layerId)!.push(relatedFeature);
    }
  }
  for (const [, relatedFeatures] of relatedFeatureByLayerId) {
    relatedFeatures.sort((a: RelatedFeature, b: RelatedFeature) => {
      return a.displayName.localeCompare(b.displayName);
    });
  }
  return relatedFeatureByLayerId;
}

/**
 * Returns a related feature string for displaying.
 */
export function buildRelatedFeatureString(
  related: RelatedFeature,
  role: RelatedFeatureRole,
): string {
  if (related.lifecycleStage === LifecycleStage.ACTIVE) {
    return related.displayName;
  }
  const layerType = LAYER_TYPE_BY_RELATED_ROLE.get(role);
  if (!layerType || !related.updatedAt) {
    // TODO(reubenn): Add some type of debug-time logging.
    return related.displayName;
  }
  if (layerType === LayerType.ASSETS) {
    return `${related.displayName} (removed on ${timestampToDateString(related.updatedAt!)})`;
  }
  if (layerType === LayerType.NATIVE) {
    return `${related.displayName} (closed at ${timestampToDateTimeString(related.updatedAt!)})`;
  }
  return '';
}

/**
 * Gets the asset type from a feature's properties.
 */
export function getAssetType(feature: Feature): string {
  return getPropertyValue(ASSET_TYPE, feature.properties);
}

/**
 * Gets the equipment ID from a feature's properties.
 */
export function getEquipmentId(feature: Feature): string {
  return getPropertyValue(EQUIPMENT_ID, feature.properties);
}

/**
 * Returns the related features for a given feature and role.
 */
export function getRelatedFeatures(feature: Feature, role: RelatedFeatureRole): RelatedFeature[] {
  return (
    feature.relatedFeaturesGroups.find((group: RelatedFeaturesGroup) => group.role === role)
      ?.relatedFeatures || []
  );
}

/**
 * Composes list of properties for edit.
 */
export function existingPropertiesToFormProperties(
  properties: Property[],
  formTemplate: UploadFormTemplate,
): Record<string, string> {
  // Collect form fields that are checkboxes and that are not
  // listed in PROPERTIES_TO_PRESERVE_DURING_EDIT.
  const checkboxesPropertyNames = new Set();
  for (const section of formTemplate.sections) {
    for (const field of section.fields) {
      if (
        field.type === FieldType.CHECKBOX &&
        !PROPERTIES_TO_PRESERVE_DURING_EDIT.has(field.propertyName)
      ) {
        checkboxesPropertyNames.add(field.propertyName);
      }
    }
  }
  const records: Record<string, string> = {};
  for (const property of properties) {
    if (!checkboxesPropertyNames.has(property.key) && property.propertyValue.case === 'value') {
      records[property.key] = property.propertyValue.value;
    }
  }
  return records;
}

/**
 * Returns true if any of the properties are found.
 */
export function hasAnyProperty(feature: Feature, properties: Property[]): boolean {
  const propertyValuesByKey = new Map<string, Set<string>>();
  for (const property of feature.properties) {
    if (property.propertyValue.case !== 'value') {
      continue;
    }
    const propertyValues = propertyValuesByKey.get(property.key) || new Set();
    propertyValues.add(property.propertyValue.value);
    propertyValuesByKey.set(property.key, propertyValues);
  }
  for (const property of properties) {
    const propertyValues = propertyValuesByKey.get(property.key);
    if (
      propertyValues &&
      property.propertyValue.case === 'value' &&
      propertyValues.has(property.propertyValue.value)
    ) {
      return true;
    }
  }
  return false;
}

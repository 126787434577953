import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

import {UploadProgress} from './progress';

@NgModule({
  declarations: [UploadProgress],
  imports: [CommonModule, MatButtonModule],
  exports: [UploadProgress],
})
export class UploadProgressModule {}

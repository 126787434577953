import {Injectable} from '@angular/core';

import {FilterMap, FilterMapByLayerId} from '../typings/filter';

// Keys in local storage.
enum KeyEnum {
  LAYER_FILTER_METADATA = 'layer filter metadata', // Deprecated.
  INCLUDE_INACTIVE = 'include inactive',
  LAYER_FILTERS = 'layer filters',
  LAYERS_SELECTED = 'layers selected',
  LAYER_PROPERTY_KEYS = 'layer property keys',
  LOGIN_STATUS = 'login status',
  DEFAULT_TAGS_OF_FORM = 'default tags of form',
  PREVIOUS_TAGS_OF_FORM = 'previous tags of form',
  OFFLINE_DIALOG_SHOWN = 'offline dialog shown',
  OFFLINE_MODE_DISABLED = 'offline mode disabled',
  OFFLINE_STORE_LAST_UPDATED = 'offline store last updated',
  OFFLINE_TABLE_COLUMNS_SELECTED = 'offline table columns selected',
  PENDING_UPLOOAD_NOTIFICATION_COUNT = 'pending upload notifications',
  MAP_CENTER = 'map center',
  MAP_ZOOM = 'map zoom',
  TABLE_COLUMNS_SELECTED = 'table columns selected',
  USER_TYPE = 'user type',
  FILTER_VIEWS_PANEL_OPEN = 'filter views panel open',
  LAYER_PROPERTY_KEYS_INCLUDE_INACTIVE = 'layer property keys include inactive',
  COLOR_SCHEME = 'color scheme',
}

interface SelectedColumnNamesForStorage {
  [key: string]: string[];
}
type SelectedColumnNamesByLayerKey = Map<string, string[]>;

// FilterMapByLayerIdForStorage and FilterMapForStorage are needed because the
// FilterMap that is a part of LayerFilterMetadataMap uses a Set as a value. It
// needs to be converted to an array so that it can be stored in local storage.
// These types should not be used outside of this file.
interface FilterMapByLayerIdForStorage {
  [layerId: string]: FilterMapForStorage;
}
interface FilterMapForStorage {
  [filterName: string]: string[];
}

interface TagsByFormForStorage {
  [tagName: string]: string[];
}

declare interface PropertyKeysByLayerIdForStorage {
  [key: string]: string[];
}

/**
 * Service for retrieving local storage instance
 */
@Injectable()
export class LocalStorageService {
  private readonly storage = window.localStorage;

  writeMapCenter(center: google.maps.LatLngLiteral): void {
    this.storage.setItem(KeyEnum.MAP_CENTER, JSON.stringify(center));
  }

  readMapCenter(): google.maps.LatLngLiteral | undefined {
    const center = this.storage.getItem(KeyEnum.MAP_CENTER);
    // TODO(b/131926196): Assert as a type, declared interface, or `unknown`.
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return center !== null ? (JSON.parse(center) as any) : undefined;
  }

  writeMapZoom(zoom: number): void {
    this.storage.setItem(KeyEnum.MAP_ZOOM, String(zoom));
  }

  readMapZoom(): number | undefined {
    const zoom = this.storage.getItem(KeyEnum.MAP_ZOOM);
    return zoom !== null ? Number(zoom) : undefined;
  }

  writeSelectedTableColumnNames(selectedColumnNamesByLayerKey: SelectedColumnNamesByLayerKey) {
    const selectedColumnNamesStore: SelectedColumnNamesForStorage = {};
    for (const [layerKey, selectedColumnNames] of selectedColumnNamesByLayerKey) {
      selectedColumnNamesStore[layerKey] = selectedColumnNames;
    }

    this.storage.setItem(KeyEnum.TABLE_COLUMNS_SELECTED, JSON.stringify(selectedColumnNamesStore));
  }

  readSelectedTableColumnNames(): SelectedColumnNamesByLayerKey {
    const selectedColumnNamesForStorage = this.storage.getItem(KeyEnum.TABLE_COLUMNS_SELECTED);
    // TODO(b/131926196): Assert as a type, declared interface, or `unknown`.
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const parsed = (JSON.parse(String(selectedColumnNamesForStorage)) as any) || {};
    /* eslint-enable @typescript-eslint/no-explicit-any */

    return new Map<string, string[]>(Object.entries(parsed));
  }

  writeOfflineSelectedColumnNames(selectedColumnNames: string[]) {
    this.storage.setItem(
      KeyEnum.OFFLINE_TABLE_COLUMNS_SELECTED,
      JSON.stringify(selectedColumnNames),
    );
  }

  readOfflineSelectedColumnNames(): string[] {
    const selectedColumnNames = this.storage.getItem(KeyEnum.OFFLINE_TABLE_COLUMNS_SELECTED);
    if (!selectedColumnNames) {
      return [];
    }
    try {
      return JSON.parse(selectedColumnNames) as string[];
    } catch {
      return [];
    }
  }

  writeLayerFilters(filterMapByLayerId: FilterMapByLayerId) {
    const filterMapByLayerIdForStorage: FilterMapByLayerIdForStorage = {};
    for (const [layerId, filterMap] of Object.entries(filterMapByLayerId)) {
      filterMapByLayerIdForStorage[layerId] = stringifyFilterMap(filterMap);
    }
    this.storage.setItem(KeyEnum.LAYER_FILTERS, JSON.stringify(filterMapByLayerIdForStorage));
  }

  readLayerFilters(): FilterMapByLayerId {
    const filtersByLayerId = this.storage.getItem(KeyEnum.LAYER_FILTERS);
    if (!filtersByLayerId) {
      return {};
    }
    try {
      const filterMapById: FilterMapByLayerId = {};
      for (const [layerId, filters] of Object.entries(
        JSON.parse(filtersByLayerId) as FilterMapByLayerIdForStorage,
      )) {
        filterMapById[layerId] = parseFilterMap(filters);
      }
      return filterMapById;
    } catch {
      return {};
    }
  }

  writeIncludeInactive(includeInactiveByLayerId: Map<string, boolean>) {
    this.storage.setItem(
      KeyEnum.INCLUDE_INACTIVE,
      JSON.stringify(Array.from(includeInactiveByLayerId.entries())),
    );
  }

  readIncludeInactive(): Map<string, boolean> {
    let entries: [] = [];
    try {
      // TODO(b/131926196): Assert as a type, declared interface, or `unknown`.
      /* eslint-disable @typescript-eslint/no-explicit-any */
      entries = JSON.parse(this.storage.getItem(KeyEnum.INCLUDE_INACTIVE) || '[]') as any;
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch {
      return new Map<string, boolean>();
    }
    return new Map<string, boolean>(entries);
  }

  writeLayerPropertyKeys(layerId: string, includeInactive: boolean, layerPropertyKeys: string[]) {
    const key = includeInactive
      ? KeyEnum.LAYER_PROPERTY_KEYS_INCLUDE_INACTIVE
      : KeyEnum.LAYER_PROPERTY_KEYS;
    try {
      const propertyKeysByLayerId = JSON.parse(
        this.storage.getItem(key)!,
      ) as PropertyKeysByLayerIdForStorage;
      propertyKeysByLayerId[layerId] = layerPropertyKeys;
      this.storage.setItem(key, JSON.stringify(propertyKeysByLayerId));
    } catch {
      this.storage.setItem(key, JSON.stringify({[layerId]: layerPropertyKeys}));
    }
  }

  readLayerPropertyKeys(layerId: string, includeInactive: boolean): string[] {
    const key = includeInactive
      ? KeyEnum.LAYER_PROPERTY_KEYS_INCLUDE_INACTIVE
      : KeyEnum.LAYER_PROPERTY_KEYS;
    try {
      const propertyKeysByLayerId = JSON.parse(
        this.storage.getItem(key)!,
      ) as PropertyKeysByLayerIdForStorage;
      return propertyKeysByLayerId[layerId] || [];
    } catch {
      return [];
    }
  }

  writeLoginStatus(loginStatus: boolean) {
    this.storage.setItem(KeyEnum.LOGIN_STATUS, JSON.stringify(loginStatus));
  }

  readLoginStatus(): boolean {
    const loginStatus = this.storage.getItem(KeyEnum.LOGIN_STATUS);
    return loginStatus ? (JSON.parse(loginStatus) as boolean) : false;
  }

  writeOfflineDialogShown() {
    this.storage.setItem(KeyEnum.OFFLINE_DIALOG_SHOWN, 'true');
  }

  readOfflineDialogShown(): boolean {
    return !!this.storage.getItem(KeyEnum.OFFLINE_DIALOG_SHOWN);
  }

  writeOfflineModeDisabled(disable: boolean) {
    this.storage.setItem(KeyEnum.OFFLINE_MODE_DISABLED, JSON.stringify(disable));
  }

  readOfflineModeDisabled(): boolean {
    const offlineModeDisabled = this.storage.getItem(KeyEnum.OFFLINE_MODE_DISABLED);
    return offlineModeDisabled ? (JSON.parse(offlineModeDisabled) as boolean) : false;
  }

  writeMapLayers(layerKeys: string[]): void {
    this.storage.setItem(KeyEnum.LAYERS_SELECTED, JSON.stringify(layerKeys));
  }

  readMapLayers(): string[] {
    const layerKeys = this.storage.getItem(KeyEnum.LAYERS_SELECTED);
    // TODO(b/131926196): Assert as a type, declared interface, or `unknown`.
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return layerKeys !== null ? (JSON.parse(layerKeys) as any) : [];
  }

  writeUserType(code: string) {
    this.storage.setItem(KeyEnum.USER_TYPE, code);
  }

  readUserType(): string | null {
    return this.storage.getItem(KeyEnum.USER_TYPE);
  }

  writeColorScheme(color: string) {
    this.storage.setItem(KeyEnum.COLOR_SCHEME, color);
  }

  readColorScheme(): string | null {
    return this.storage.getItem(KeyEnum.COLOR_SCHEME);
  }

  private readTagsOfAllForms(key: KeyEnum): TagsByFormForStorage | null {
    if (!this.storage.getItem(key)) {
      return null;
    }
    try {
      return JSON.parse(this.storage.getItem(key)!) as TagsByFormForStorage;
    } catch (error) {
      console.error(`error parsing tags form: ${error}`);
      return null;
    }
  }

  readPreviousTagsOfForms(formName: string): string[] | null {
    const previousTagsOfAllForms = this.readTagsOfAllForms(KeyEnum.PREVIOUS_TAGS_OF_FORM);
    return previousTagsOfAllForms?.[formName] || null;
  }

  writePreviousTagsOfForm(formName: string, tags: string[]) {
    const previousTagsOfAllForms =
      this.readTagsOfAllForms(KeyEnum.PREVIOUS_TAGS_OF_FORM) || ({} as TagsByFormForStorage);

    previousTagsOfAllForms[formName] = tags;
    this.storage.setItem(KeyEnum.PREVIOUS_TAGS_OF_FORM, JSON.stringify(previousTagsOfAllForms));
  }

  readDefaultTagsOfForms(formName: string): string[] | null {
    const defaultTagsOfAllForms = this.readTagsOfAllForms(KeyEnum.DEFAULT_TAGS_OF_FORM);
    return defaultTagsOfAllForms?.[formName] || null;
  }

  writeDefaultTagsOfForm(formName: string, tags: string[]) {
    const defaultTagsOfAllForms =
      this.readTagsOfAllForms(KeyEnum.DEFAULT_TAGS_OF_FORM) || ({} as TagsByFormForStorage);
    defaultTagsOfAllForms[formName] = tags;
    this.storage.setItem(KeyEnum.DEFAULT_TAGS_OF_FORM, JSON.stringify(defaultTagsOfAllForms));
  }

  writeOfflineStoreLastUpdatedTimestamp(timestamp: number) {
    this.storage.setItem(KeyEnum.OFFLINE_STORE_LAST_UPDATED, timestamp.toString());
  }

  readOfflineStoreLastUpdatedTimestamp(): number | null {
    const ts = Number(this.storage.getItem(KeyEnum.OFFLINE_STORE_LAST_UPDATED));
    return isFinite(ts) ? ts : null;
  }

  writeFilterViewsPanelOpen(isOpen: boolean) {
    this.storage.setItem(KeyEnum.FILTER_VIEWS_PANEL_OPEN, JSON.stringify(isOpen));
  }

  readFilterViewsPanelOpen(): boolean {
    const isPanelOpen = this.storage.getItem(KeyEnum.FILTER_VIEWS_PANEL_OPEN);
    // Pass on 'true' on empty since the filter views panel should be open when
    // no LS entry exists.
    return isPanelOpen ? (JSON.parse(isPanelOpen) as boolean) : true;
  }
}

function parseFilterMap(filterMapForStorage: FilterMapForStorage): FilterMap {
  const filterMap: FilterMap = {};
  for (const [filterName, filterValues] of Object.entries(filterMapForStorage)) {
    filterMap[filterName] = new Set(filterValues);
  }
  return filterMap;
}

function stringifyFilterMap(filterMap: FilterMap): FilterMapForStorage {
  const filterMapForStorage: FilterMapForStorage = {};
  for (const [filterName, filterValueSet] of Object.entries(filterMap)) {
    filterMapForStorage[filterName] = [...filterValueSet];
  }
  return filterMapForStorage;
}

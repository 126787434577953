import {PromiseClient} from '@connectrpc/connect';
import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, ReplaySubject, Subject, defer, of} from 'rxjs';
import {first, mergeMap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {Metadata} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {BoundingBox} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {
  AnnotatedImage,
  CorrectedImageAnnotation,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';
import {
  ExifMetadata,
  ExifMetadata_Orientation,
  ExifMetadata_Projection,
  Image,
  ImageMetadata,
  Image_ImageState,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {ImageService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/imageservice_connect';
import {
  AddGCSImageResponse,
  AddImageResponse,
  CorrectImageAnnotationRequest,
  CorrectImageAnnotationResponse,
  GetImageAnnotationsResponse,
  GetImagesResponse,
  ImageQuery,
  QueryImagesResponse,
  SaveImageAnnotationsRequest,
  SaveImageAnnotationsResponse,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/imageservice_pb';

import {FilterField} from '../constants/filters';
import {FilterMap} from '../typings/filter';
import {convertToProperties} from '../utils/feature';
import {AnalyticsService, EventActionType, EventCategoryType} from './analytics_service';
import {ApiService} from './api_service';

// Interface for grouped photos.
interface GroupedImages {
  [date: string]: Image[];
}

const QUERY_MAX_RESULTS = 2000;

const UNKNOWN_DATE = 'Unknown date';

/**
 * Handles getting, posting and sorting asset photos.
 */
@Injectable({providedIn: 'root'})
export class PhotosService {
  private readonly client: PromiseClient<typeof ImageService>;

  private readonly imagesByAssetId = new Map<string, ReplaySubject<Image[]>>();
  private readonly imagesByFeatureId = new Map<string, ReplaySubject<Image[]>>();
  private readonly imageById = new Map<string, ReplaySubject<Image | null>>();
  private readonly imageUpdated = new Subject<Image>();

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly apiService: ApiService,
  ) {
    this.client = apiService.createImageServiceBEClient();
    // Refresh the cache when an image updates.
    this.onImageUpdated()
      .pipe(mergeMap((image: Image) => this.getImage(image.id, true).pipe(first())))
      .subscribe();
  }

  onImageUpdated(): Observable<Image> {
    return this.imageUpdated.asObservable();
  }

  /**
   * Get the image associated with an image id.
   * @param id: the image id.
   * @param forceFetch - whether to force a fetch even if photos are stored.
   */
  getImage(id: string, forceFetch = false): Observable<Image | null> {
    if (!forceFetch && this.imageById.has(id)) {
      return this.imageById.get(id)!.asObservable();
    }

    if (!this.imageById.has(id)) {
      this.imageById.set(id, new ReplaySubject<Image | null>(1));
    }

    const replaySubject = this.imageById.get(id);

    this.apiService
      .withCallOptions((options) => this.client.getImages({ids: [id]}, options))
      .then(
        (response: GetImagesResponse) => {
          if (response.images.length === 0) {
            throw new Error(`No matching image with ID ${id}.`);
          }
          // Note: no longer processing image.
          replaySubject!.next(ensureURL(response.images[0]));
        },
        (error: Error) => {
          throw new Error(`Could not get image with ID ${id}: ${error.message}`);
        },
      );

    return replaySubject!.asObservable();
  }

  /**
   * Get the images by ids.
   * @param ids: the images ids.
   */
  getImagesByIds(ids: string[]): Observable<Image[]> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.getImages({ids}, options))
        .then(
          (response: GetImagesResponse) => {
            return response.images.map((img: Image): Image => ensureURL(img));
          },
          (error: Error) => {
            throw new Error(`Could not get images: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Get the image data for displaying image markers on the map.
   */
  getImagesForAssets(assetIds: string[]): Observable<Image[]> {
    return this.queryImages(new ImageQuery({assetIds}));
  }

  /**
   * Get all images optionally constrained with bounding box and/or `filters`.
   */
  getImages(
    filters: FilterMap = {},
    bounds?: google.maps.LatLngBounds,
    queryMaxResults?: number,
  ): Observable<Image[]> {
    const query = this.imageQuery(filters, bounds);
    return this.queryImages(query, queryMaxResults);
  }

  /**
   * Prepare image query with optional `bounds` and/or `filters`.
   */
  imageQuery(filters: FilterMap = {}, bounds?: google.maps.LatLngBounds): ImageQuery {
    const query = new ImageQuery();
    if (bounds) {
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      query.bounds = new BoundingBox({
        lo: new LatLng({latitude: southWest.lat(), longitude: southWest.lng()}),
        hi: new LatLng({latitude: northEast.lat(), longitude: northEast.lng()}),
      });
    }
    return this.getQueryWithFilters(query, filters);
  }

  private getQueryWithFilters(query: ImageQuery, filters: FilterMap): ImageQuery {
    const queryWithFilters = query.clone();
    const tagFilter = filters[FilterField.TAG_NAMES];
    if (tagFilter && tagFilter.size > 0) {
      queryWithFilters.tagNames = Array.from(tagFilter);
    }
    const propsFilter = convertToProperties(filters, [FilterField.TAG_NAMES]);
    if (propsFilter.length > 0) {
      queryWithFilters.properties = propsFilter;
    }
    return queryWithFilters;
  }

  private queryImages(query: ImageQuery, queryMaxResults?: number): Observable<Image[]> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) =>
          this.client.queryImages(
            {query, maxResults: queryMaxResults || QUERY_MAX_RESULTS},
            options,
          ),
        )
        .then(
          (response: QueryImagesResponse) => {
            return response.images.map((img: Image): Image => ensureURL(img));
          },
          (error: Error) => {
            throw new Error(`Could not search images: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Gets images for an asset given the asset ID.
   * @param forceFetch - whether to force a fetch even if photos are stored.
   */
  getImagesForAsset(assetId: string, forceFetch = false): Observable<Image[]> {
    if (!forceFetch && this.imagesByAssetId.has(assetId)) {
      return this.imagesByAssetId.get(assetId)!.asObservable();
    }

    if (!this.imagesByAssetId.has(assetId)) {
      this.imagesByAssetId.set(assetId, new ReplaySubject<Image[]>(1));
    }

    const replaySubject = this.imagesByAssetId.get(assetId);
    const query = new ImageQuery({assetIds: [assetId]});

    this.apiService
      .withCallOptions((options) => this.client.queryImages({query}, options))
      .then(
        (response: QueryImagesResponse) => {
          replaySubject!.next(response.images.map((img: Image): Image => ensureURL(img)));
        },
        (error: Error) => {
          throw new Error(`Could not search images: ${error.message}`);
        },
      );

    return replaySubject!.asObservable();
  }

  rotateImage(image: Image): Observable<Image> {
    let exif = image.exifMetadata;
    if (!exif) {
      exif = new ExifMetadata();
      image.exifMetadata = exif;
    }
    const orientation = exif.orientation;
    switch (orientation) {
      case ExifMetadata_Orientation.ROTATED_90_CW:
        exif.orientation = ExifMetadata_Orientation.ROTATED_180_CW;
        break;
      case ExifMetadata_Orientation.ROTATED_180_CW:
        exif.orientation = ExifMetadata_Orientation.ROTATED_270_CW;
        break;
      case ExifMetadata_Orientation.ROTATED_270_CW:
        exif.orientation = ExifMetadata_Orientation.DEFAULT;
        break;
      default:
        exif.orientation = ExifMetadata_Orientation.ROTATED_90_CW;
    }
    return this.updateImage(image);
  }

  deleteImage(image: Image): Observable<Image> {
    image.state = Image_ImageState.SOFT_DELETED;
    this.imageById.get(image.id)?.next(null);
    return this.updateImage(image);
  }

  updateImageProjection(image: Image, setTo360: boolean): Observable<Image> {
    if (!image.exifMetadata) {
      image.exifMetadata = new ExifMetadata();
    }
    image.exifMetadata!.imageProjection = setTo360
      ? ExifMetadata_Projection.SPHERICAL_PANO
      : ExifMetadata_Projection.RECTILINEAR;
    return this.updateImage(image);
  }

  /**
   * Update image details.
   */
  updateImage(image: Image): Observable<Image> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.updateImage({image}, options))
        .then(
          () => {
            this.imageUpdated.next(ensureURL(image));
            return image;
          },
          (error: Error) => {
            throw new Error(`Could not update image: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Saves uploaded image's metadata.
   * @param filename: name of the image to upload.
   * @param metadata: metadata to be associated with an image.
   * @param storageRef: gs:// bucket URL
   */
  addGCSImage(metadata: ImageMetadata, storageRef: string): Observable<Image | null> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) =>
          this.client.addGCSImage(
            {
              gcsUri: storageRef,
              name: metadata.originalFileName,
              originalFileName: metadata.originalFileName,
              description: metadata.description,
              imageLocation: metadata.imageLocation,
              tags: metadata.tags,
              capturedAt: metadata.capturedAt,
            },
            options,
          ),
        )
        .then(
          (response: AddGCSImageResponse) => {
            console.log(
              `Successfully added image to backend. URL: ${response.image?.url || '<empty>'}`,
            );
            return response.image || null;
          },
          (error: Error) => {
            console.log(error.message);
            throw new Error(`Failed to add image: ${error}.`);
          },
        ),
    );
  }

  /**
   * Uploads the image to the backend and saves it's metadata.
   * @param file: the image to upload.
   * @param id: image ID.
   * @param metadata: metadata to be associated with an image.
   */
  addImageWithUpload(file: File, id: string, metadata: ImageMetadata): Observable<Image | null> {
    return defer(() =>
      file
        .arrayBuffer()
        .then((bytes: ArrayBuffer) =>
          this.apiService.withCallOptions((options) =>
            this.client.addImage(
              {
                dataSource: {
                  value: new Uint8Array(bytes),
                  case: 'bytes',
                },
                originalFileName: file.name,
                imageMetadata: metadata,
              },
              options,
            ),
          ),
        )
        .then(
          (response: AddImageResponse) => {
            console.log(
              `Successfully added image to backend with ID ${id}. URL: ${
                response.image?.url || '<empty>'
              }`,
            );
            return response.image || null;
          },
          (error: Error) => {
            console.log(error.message);
            throw new Error(`Failed to add image: ${error}.`);
          },
        ),
    );
  }

  /**
   * Group photos by date.
   * @param photos - list of photos to sort.
   * @return sorted photos grouped by date.
   */
  groupImagesByDate(images: Image[]) {
    const groupedImages: GroupedImages = {};
    images.forEach((image: Image) => {
      const uploadedAt = image.uploadedAt
        ? image.uploadedAt!.toDate().toDateString()
        : UNKNOWN_DATE;
      const ts = groupedImages[uploadedAt] || [];
      groupedImages[uploadedAt] = [...ts, image];
    });
    return groupedImages;
  }

  /**
   * Returns css rotate property from EXIF orientation.
   */
  getRotationFromOrientation(orientation: ExifMetadata_Orientation) {
    // TODO(reubenn): Move this function to an image file under the util folder.
    switch (orientation) {
      case ExifMetadata_Orientation.ROTATED_90_CW:
        return 'rotate(90deg)';
      case ExifMetadata_Orientation.ROTATED_180_CW:
        return 'rotate(180deg)';
      case ExifMetadata_Orientation.ROTATED_270_CW:
        return 'rotate(270deg)';
      default:
        return 'rotate(0deg)';
    }
  }

  /**
   * Helper function to update a proto Metadata value by id.
   */
  setOrUpdateMetadata(image: Image, id: string, value: string) {
    const newMetadatas = [];
    for (const metadata of image.metadata) {
      if (metadata.key === id) {
        metadata.value = value;
        return;
      }
      newMetadatas.push(metadata);
    }
    newMetadatas.push(new Metadata({key: id, value: value}));
    image.metadata = newMetadatas;
  }

  getFeatureImages(featureId: string, forceFetch = false): Observable<Image[]> {
    if (!featureId) {
      console.error('Could not get images: missing featureId');
      return of([]);
    }
    if (!forceFetch && this.imagesByFeatureId.has(featureId)) {
      return this.imagesByFeatureId.get(featureId)!.asObservable();
    }
    if (!this.imagesByFeatureId.has(featureId)) {
      this.imagesByFeatureId.set(featureId, new ReplaySubject<Image[]>(1));
    }

    const replaySubject = this.imagesByFeatureId.get(featureId);
    const query = new ImageQuery({featureId});
    this.apiService
      .withCallOptions((options) =>
        this.client.queryImages({query, includeRelatedAssets: true}, options),
      )
      .then(
        (response: QueryImagesResponse) => {
          replaySubject!.next(response.images.map((img: Image): Image => ensureURL(img)));
        },
        (error: Error) => {
          throw new Error(`Could not get images of feature: ${error.message}`);
        },
      );
    return replaySubject!.asObservable();
  }

  /**
   * Saves image annotation information (such as shapes and labels, comments).
   */
  saveAnnotatedImage(request: SaveImageAnnotationsRequest): Observable<AnnotatedImage | null> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.saveImageAnnotations(request, options))
        .then(
          (response: SaveImageAnnotationsResponse) => {
            const result = response.annotatedImage || null;
            this.sendAnnotationsAnalytics(result);
            return result;
          },
          (error: Error) => {
            throw new Error(`Could not save image annotations: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Gets the annotations for images by ids.
   */
  getAnnotatedImagesByIds(ids: string[]): Observable<AnnotatedImage[]> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.getImageAnnotations({imageIds: ids}, options))
        .then(
          (response: GetImageAnnotationsResponse) => response.annotatedImages,
          (error: Error) => {
            this.analyticsService.sendEvent(EventActionType.IMAGE_ANNOTATION_FAILED, {
              event_category: EventCategoryType.MAP,
              event_label: error.message,
            });
            throw new Error(`Could not get image annotations: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Correct an image annotation. client.correctImageAnnotation does not modify
   * the existing image annotation's label nor comment but rather modifies
   * ImageAnnotation.Corrected field.
   */
  correctImageAnnotation(
    request: CorrectImageAnnotationRequest,
  ): Observable<CorrectedImageAnnotation> {
    return defer(() =>
      this.apiService
        .withCallOptions((options) => this.client.correctImageAnnotation(request, options))
        .then(
          (response: CorrectImageAnnotationResponse) => {
            this.sendAnnotationCorrectedAnalytics(
              EventActionType.IMAGE_ANNOTATION_CORRECTED,
              response.corrected!.label.toString(),
            );
            return response.corrected!;
          },
          (error: Error) => {
            this.sendAnnotationCorrectedAnalytics(
              EventActionType.IMAGE_ANNOTATION_CORRECTED_FAILED,
              error.message,
            );
            throw new Error(`Could not correct image annotation: ${error.message}`);
          },
        ),
    );
  }

  private sendAnnotationCorrectedAnalytics(action: EventActionType, label: string) {
    this.analyticsService.sendEvent(action, {
      event_category: EventCategoryType.IMAGE,
      event_label: label,
      value: 1,
    });
  }

  private sendAnnotationsAnalytics(image: AnnotatedImage | null) {
    this.analyticsService.sendEvent(EventActionType.IMAGE_ANNOTATED, {
      event_category: EventCategoryType.IMAGE,
      // TODO: b/321822268 - Verify whether value:1 provides value.
      value: 1,
    });
    this.analyticsService.sendEvent(EventActionType.IMAGE_ANNOTATED_ANNOTATIONS_TOTAL_COUNT, {
      event_category: EventCategoryType.MAP,
      value: image?.annotations.length,
    });
  }
}

/**
 * Populates Image.url if it isn't already set.
 */
export function ensureURL(img: Image): Image {
  if (img.url === '') {
    img = img.clone();
    img.url = `${window.location.origin}/images/by-id/${img.id}`;
  }
  return img;
}

<div mat-dialog-content class="container">
  <img src="assets/images/buildings_illo.png" class="illo" alt="" />
  <h1 class="header">Welcome to GridAware</h1>
  <mat-form-field appearance="outline" class="select">
    <mat-label> Select an occupation </mat-label>
    <mat-select
      [value]="selectedUserType && selectedUserType.code"
      (selectionChange)="onUserTypeSelect($event)">
      @for (type of userTypes; track type) {
        <mat-option [value]="type.code">
          {{ type.fullName }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

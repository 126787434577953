import {Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

/**
 * Defines an interface for components needing a specific deactivation logic.
 */
export interface CanDeactivateComponent {
  canDeactivate(nextUrl: string): Observable<boolean> | boolean;
}

/**
 * Provides deactivation guarding on specified routes.
 */
@Injectable({providedIn: 'root'})
export class CanDeactivateGuard {
  canDeactivate(
    component: CanDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ) {
    return component.canDeactivate ? component.canDeactivate(nextState.url) : true;
  }
}

<div class="container">
  <div class="login">
    <span class="title">GridAware</span>
    @if (showGoogleLogin) {
      <button mat-stroked-button class="sign-in" (click)="startGoogleSignIn()">
        Sign in with Google
      </button>
    }
    @if (showOAuthLogin) {
      @for (option of oAuthLoginOptionLabels; track option; let providerIndex = $index) {
        <button mat-stroked-button class="sign-in" (click)="startOAuthSignIn(providerIndex)">
          {{ option }}
        </button>
      }
    }
  </div>
</div>
<router-outlet></router-outlet>

import {BehaviorSubject, EMPTY, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, ParamMap, Router} from '@angular/router';

import {SOLAR_INSIGHTS_LAYER_ID} from '../constants/layer';
import {FEATURE_PARAM_KEY, LAYER_PARAM_KEY} from '../constants/paths';
import {ConfigService} from '../services/config_service';
import {MapService} from '../services/map_service';
import {TableService} from '../services/table_service';
import {SidepanelLayout} from '../sidepanel_layout/sidepanel_layout';
import {VisibilityByLayerUpdate} from '../typings/map';

/**
 * Page that displays the data of the selected layers in a tabular format.
 */
@Component({
  templateUrl: 'table.ng.html',
  styleUrls: ['table.scss'],
})
export class Table implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SidepanelLayout, {static: false})
  sidepanel: SidepanelLayout | null = null;

  isBannerVisible = false;

  readonly isSidePanelVisible = new BehaviorSubject(true);
  readonly selectedLayerIDs = new BehaviorSubject<string[]>([]);
  readonly solarInsightsLayerId = SOLAR_INSIGHTS_LAYER_ID;

  private scrollId = '';
  private readonly destroyed = new Subject<void>();

  protected solarInsightsEnabled = this.configService.solar2Enabled;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly mapService: MapService,
    private readonly tableService: TableService,
    private readonly router: Router,
    private readonly configService: ConfigService,
  ) {}

  ngOnInit() {
    this.listenForLayerVisibilityUpdates();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngAfterViewInit() {
    // We only want to do the scroll once on initial load.
    this.scrollId = this.route.firstChild?.snapshot?.params[LAYER_PARAM_KEY];
    this.scrollToAnchor();
    // Track the end of the navigation within component, which includes
    // feature details page, to determine whether the feature selected
    // state has changed. This logic helps to highlight the selected
    // feature row - this is why parent component is listening for
    // child component's parameter change here.
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        // Navigation has already finished on initial load, trigger
        // to run once (for the case when user goes directly to
        // ../map/<layerID>/<featureID>).
        startWith(undefined),
        switchMap(() => this.route.firstChild?.paramMap ?? EMPTY),
        distinctUntilChanged(),
        map((paramMap: ParamMap): string[] => [
          paramMap.get(FEATURE_PARAM_KEY) || '',
          paramMap.get(LAYER_PARAM_KEY) || '',
        ]),
        takeUntil(this.destroyed),
      )
      .subscribe(([featureId, layerId]) => {
        this.tableService.setSelectedFeature(featureId, layerId);
      });
  }

  showBanner() {
    this.isBannerVisible = true;
    this.changeDetectionRef.markForCheck();
  }

  dismissBanner() {
    this.isBannerVisible = false;
    this.changeDetectionRef.markForCheck();
  }

  onTableUpdate(layerId: string) {
    if (!this.scrollId || this.scrollId !== layerId) {
      return;
    }
    this.scrollToAnchor();
  }

  // Update the displayed data according to the layers toggle state.
  private listenForLayerVisibilityUpdates() {
    this.mapService
      .onLayerVisibilityChanged()
      .pipe(
        takeUntil(this.destroyed),
        map((visibilityByLayerUpdate: VisibilityByLayerUpdate): string[] =>
          [...visibilityByLayerUpdate.visibilityByLayerId]
            .filter(([, isVisible]) => isVisible)
            .map(([layerId]): string => layerId),
        ),
      )
      .subscribe((layerIds: string[]) => {
        this.selectedLayerIDs.next(layerIds);
      });
  }

  private scrollToAnchor() {
    if (!this.scrollId) {
      return;
    }

    setTimeout(() => {
      const element = document.querySelector(`#${this.scrollId}`);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }, 100);
  }
}

import {Injectable} from '@angular/core';
import {ParamMap} from '@angular/router';

import {QUERY_PARAMS} from '../constants/paths';
import {ParamValidator} from '../typings/param_validator';

const numberParamValidator: ParamValidator = {
  isValid: (value: string | null): boolean => {
    return value !== null && value.length > 0 && !isNaN(+value);
  },
  errorMessage: 'The number is not valid',
};

const latParamValidator: ParamValidator = {
  isValid: (value: string | null): boolean => {
    return numberParamValidator.isValid(value) && +value! <= 90 && +value! >= -90;
  },
  errorMessage: 'The lat is not valid',
};

const lonParamValidator: ParamValidator = {
  isValid: (value: string | null): boolean => {
    return numberParamValidator.isValid(value) && +value! <= 180 && +value! >= -180;
  },
  errorMessage: 'The lon is not valid',
};

const VALIDATOR_BY_PARAM = new Map<string, ParamValidator>([
  [QUERY_PARAMS.LATITUDE, latParamValidator],
  [QUERY_PARAMS.LONGITUDE, lonParamValidator],
]);

/**
 * Service for validating params.
 */
@Injectable({providedIn: 'root'})
export class ParamValidatorService {
  /**
   * Checks whether params are valid. The validity of a param is based on
   * its validity function as defined at the top of this file.
   */
  areValidParams(paramMap: ParamMap, params: string[]): boolean {
    for (const param of params) {
      if (!VALIDATOR_BY_PARAM.has(param)) {
        console.error(`${param} doesn't have a validator, add one to VALIDATOR_BY_PARAM`);
        return false;
      }

      const validator = VALIDATOR_BY_PARAM.get(param)!;
      if (!validator.isValid(paramMap.get(param))) {
        console.error(`Error (param: ${param}): ${validator.errorMessage}`);
        return false;
      }
    }

    return true;
  }
}

import {Observable, Subject, combineLatest, of} from 'rxjs';
import {map, mergeMap, takeUntil, tap, throttleTime} from 'rxjs/operators';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import {
  ExifMetadata_Projection,
  Image,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {AnnotationsService} from '../../services/annotations_service';
import {Annotation, PendingAnnotatedImage} from '../../typings/annotations';
import {getImage, setUpAnnotationsSvg, svgToDataUrl} from '../../utils/annotations';
import {
  buildImageUrlWithOptions,
  defaultImageUrlOptions,
  encodeBracketsInImageUrl,
  prefetchImages,
} from '../../utils/image';

/**
 * Needed so that the minifier doesn't minify these properties in OnChanges.
 */
declare interface PropertyChanges extends SimpleChanges {
  image: SimpleChange;
  isThumbnail: SimpleChange;
}

interface ImageStyle {
  'background-image': string;
  'background-size': string;
}

/**
 * Display data that will be used when the image is a thumbnail.
 */
export interface ThumbnailMetadata {
  // Total number of images in the image group. Used for displaying the total
  // number of images that are part of this group, eg, 1/13 (the 13).
  totalImageCount: number;
  // The select image index in the image group. Used for displaying which image
  // is selected out of how many total images, eg, 1/13 (the 1).
  selectedImageIndex: number;
  // The number of annotations that have been added to the image. Used for
  // displaying the number of annotations on the current image.
  // Using null as a potential value here because it is important to distinguish
  // between annotationCount 0 and not loaded.
  annotationCount: number | null;
}

/**
 * The previous width and height assigned to an image. This is used to keep
 * track of previous dimensions when zooming. The reason a simple multiplier is
 * not consistent is do to rounding by the browser.
 */
interface PreviousDimension {
  width: number;
  height: number;
}

const ZOOMED_IN_MULTIPLIER = 1.2;
const MAX_MARGIN_LEFT = 0;
const MAX_MARGIN_TOP = 0;
const MIN_ZOOM_LEVEL = 0;
const MAX_ZOOM_LEVEL = 12;
// Lower values mean increased wheel zoom sensitivity whereas higher
// values decrease sensitivity meaning slower wheel zooms.
const WHEEL_THROTTLE_TIME_MS = 20;

/**
 * Component for rendering an image.
 */
@Component({
  selector: 'lightbox',
  templateUrl: 'lightbox.ng.html',
  styleUrls: ['lightbox.scss'],
})
export class Lightbox implements OnInit, OnChanges, OnDestroy {
  @Input() isThumbnail = false;
  @Input() image!: Image;
  @Input() images: Image[] = [];
  // Only used when image is a thumbnail.
  @Input() thumbnailMetadata: ThumbnailMetadata | null = null;
  // Sets background-size CSS property for image.
  @Input() backgroundSize = 'contain';
  // Sets URL options for the image.
  @Input() imageUrlOptions = {...defaultImageUrlOptions};

  @Output() readonly previousPressed = new EventEmitter<void>();
  @Output() readonly nextPressed = new EventEmitter<void>();
  @Output() readonly imagePressed = new EventEmitter<void>();
  // Event passed on an attempt to edit the image with annotations.
  @Output() readonly onEdit = new EventEmitter<void>();
  @ViewChild('image', {static: false}) imageRef!: ElementRef;
  @ViewChild('imageSvg', {static: false}) annotationImageRef!: ElementRef;
  @ViewChild('imageContainer', {static: false}) imageContainerRef!: ElementRef;
  // SVG overlay pane.
  @ViewChild('annotationsOverlay', {static: false})
  annotationsOverlay!: ElementRef;

  @HostListener('window:resize')
  onResize() {
    if (this.isThumbnail) {
      return;
    }
    this.setNewMargins(0, 0);
  }

  is360Image = false;
  url360 = '';
  style: ImageStyle = {
    'background-image': '',
    'background-size': this.backgroundSize,
  };
  annotationOverlayStyle: ImageStyle = {
    'background-image': '',
    'background-size': this.backgroundSize,
  };
  minZoomLevel = MIN_ZOOM_LEVEL;
  // Current zoom level.
  zoomLevel = MIN_ZOOM_LEVEL;
  maxZoomLevel = MAX_ZOOM_LEVEL;

  // Whether the annotations overlay should be displayed.
  isAnnotationsLayerOn = true;

  // URL of an image containing annotations.
  // Displayed on top of original image when annotations are turned on.
  annotationSvgImageUrl = '';

  // Whether the image has annotations.
  hasAnnotations = false;

  wheelEvent = new Subject<WheelEvent>();

  private dragging = false;
  private lastClientX: number | null = null;
  private lastClientY: number | null = null;
  private previousDimensions: PreviousDimension[] = [];

  private readonly destroyed = new Subject<void>();

  constructor(private readonly annotationsService: AnnotationsService) {}

  ngOnInit() {
    this.wheelEvent
      .pipe(throttleTime(WHEEL_THROTTLE_TIME_MS), takeUntil(this.destroyed))
      .subscribe((event) => {
        this.zoomForWindowScroll(event);
      });
  }

  ngOnChanges(propertyChanges: PropertyChanges) {
    if (propertyChanges['images']) {
      prefetchImages(propertyChanges['images'].currentValue, this.imageUrlOptions, this.destroyed);
    }
    if (propertyChanges?.image?.currentValue) {
      const image = propertyChanges.image.currentValue;
      this.updateStyle(image);
      if (propertyChanges?.isThumbnail?.currentValue || this.isThumbnail) {
        return;
      }
      this.resetZoomState();
      this.processAnnotatedImageState(image);
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  toggleAnnotations() {
    this.isAnnotationsLayerOn = !this.isAnnotationsLayerOn;
  }

  private processAnnotatedImageState(image: Image) {
    if (!image) {
      return;
    }
    const id = image.id;
    const originalImageUrl = buildImageUrlWithOptions(image, this.imageUrlOptions);
    const imageAnnotations = this.annotationsService.getAnnotations([id]).pipe(
      map((annotatedImages: PendingAnnotatedImage[]): PendingAnnotatedImage | null =>
        annotatedImages.length === 1 ? annotatedImages[0] : null,
      ),
      tap((pendingAnnotatedImage: PendingAnnotatedImage | null) => {
        this.hasAnnotations =
          pendingAnnotatedImage !== null && pendingAnnotatedImage.annotations.length > 0;
      }),
    );
    combineLatest([originalImageUrl, imageAnnotations])
      .pipe(
        mergeMap(([imageUrl, annotationsData]) =>
          annotationsData
            ? this.buildAnnotatedImageDataUrl(imageUrl, annotationsData.annotations)
            : of(''),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe((annotatedImageDataUrl: string) => {
        this.annotationSvgImageUrl = annotatedImageDataUrl;
        this.updateAnnotationStyles();
      });
  }

  private buildAnnotatedImageDataUrl(
    originalImageUrl: string,
    annotations: Annotation[],
  ): Observable<string> {
    const svg = this.annotationsOverlay.nativeElement.cloneNode(true) as SVGSVGElement;
    setUpAnnotationsSvg(svg, annotations);

    return getImage(originalImageUrl).pipe(
      map((image: HTMLImageElement): string =>
        svgToDataUrl(svg, image.naturalWidth, image.naturalHeight),
      ),
    );
  }

  private updateAnnotationStyles() {
    const annotationUrl = `url(${this.annotationSvgImageUrl})`;
    this.annotationOverlayStyle = {
      'background-image': annotationUrl,
      'background-size': this.backgroundSize,
    };
  }

  private updateStyle(image: Image) {
    buildImageUrlWithOptions(this.image, this.imageUrlOptions)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (url) => {
          this.style = {
            'background-image': `url(${encodeBracketsInImageUrl(url)})`,
            'background-size': this.backgroundSize,
          };
        },
        error: (error) => {
          throw new Error(error);
        },
      });

    this.url360 = `${image.url}=h${this.imageUrlOptions.height}-w${this.imageUrlOptions.width}`;
    this.is360Image =
      image.exifMetadata?.imageProjection === ExifMetadata_Projection.SPHERICAL_PANO;
  }

  dragStart(mouseEvent: MouseEvent) {
    this.dragging = true;
    this.lastClientX = mouseEvent.clientX;
    this.lastClientY = mouseEvent.clientY;
  }

  dragIn(mouseEvent: MouseEvent) {
    // Check the compression state of the primary mouse button.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
    if (mouseEvent.buttons !== 1) {
      this.dragEnd();
    }
  }

  drag(mouseEvent: MouseEvent) {
    if (!this.dragging || this.lastClientX === null || this.lastClientY === null) {
      return;
    }
    const moveX = mouseEvent.clientX - this.lastClientX;
    const moveY = mouseEvent.clientY - this.lastClientY;
    this.setNewMargins(moveX, moveY);
    this.lastClientX = mouseEvent.clientX;
    this.lastClientY = mouseEvent.clientY;
  }

  dragEnd() {
    this.lastClientX = null;
    this.lastClientY = null;
    this.dragging = false;
  }

  zoomIn() {
    if (this.zoomLevel >= MAX_ZOOM_LEVEL) {
      return;
    }
    this.zoomLevel++;
    const {offsetWidth, offsetHeight} = this.imageRef.nativeElement;
    this.previousDimensions.push({width: offsetWidth, height: offsetHeight});
    const newWidth = ZOOMED_IN_MULTIPLIER * offsetWidth;
    const newHeight = ZOOMED_IN_MULTIPLIER * offsetHeight;
    this.updateZoom(newWidth, newHeight);
  }

  zoomOut() {
    if (this.zoomLevel <= MIN_ZOOM_LEVEL) {
      return;
    }
    this.zoomLevel--;
    if (this.zoomLevel === 0) {
      this.resetZoomState();
      return;
    }
    const {width, height} = this.previousDimensions.pop()!;
    this.updateZoom(width, height);
  }

  zoomForWindowScroll(wheelEvent: WheelEvent) {
    wheelEvent.deltaY < 0 ? this.zoomIn() : this.zoomOut();
  }

  resetZoomState() {
    this.previousDimensions = [];
    this.zoomLevel = MIN_ZOOM_LEVEL;
    this.lastClientX = null;
    this.lastClientY = null;
    this.dragging = false;
    if (!this.imageRef) {
      return;
    }
    this.resetImageStyles(this.imageRef.nativeElement);
    this.resetImageStyles(this.annotationImageRef.nativeElement);
  }

  private updateZoom(newWidth: number, newHeight: number) {
    const {offsetWidth, offsetHeight} = this.imageRef.nativeElement;
    this.updateImageSize(this.imageRef.nativeElement, newWidth, newHeight);
    this.updateImageSize(this.annotationImageRef.nativeElement, newWidth, newHeight);
    const moveX = (offsetWidth - newWidth) / 2;
    const moveY = (offsetHeight - newHeight) / 2;
    this.setNewMargins(moveX, moveY);
  }

  private calculateNewMargin(
    currentMargin: string,
    delta: number,
    maxMargin: number,
    minMargin: number,
  ): number {
    const proposedMargin = Number(currentMargin.slice(0, currentMargin.length - 2)) + delta;
    return Math.max(Math.min(maxMargin, proposedMargin), minMargin);
  }

  private setNewMargins(deltaX: number, deltaY: number) {
    const computedStyle = window.getComputedStyle(this.imageRef.nativeElement);
    const minMarginLeft =
      this.imageContainerRef.nativeElement.offsetWidth - this.imageRef.nativeElement.offsetWidth;
    const newMarginLeft = this.calculateNewMargin(
      computedStyle.marginLeft,
      deltaX,
      MAX_MARGIN_LEFT,
      minMarginLeft,
    );
    const minMarginTop =
      this.imageContainerRef.nativeElement.offsetHeight - this.imageRef.nativeElement.offsetHeight;
    const newMarginTop = this.calculateNewMargin(
      computedStyle.marginTop,
      deltaY,
      MAX_MARGIN_TOP,
      minMarginTop,
    );
    this.updateImageMargins(this.imageRef.nativeElement, newMarginLeft, newMarginTop);
    this.updateImageMargins(this.annotationImageRef.nativeElement, newMarginLeft, newMarginTop);
  }

  private resetImageStyles(image: HTMLImageElement) {
    image.style.width = '';
    image.style.height = '';
    image.style.marginLeft = '';
    image.style.marginTop = '';
  }

  private updateImageMargins(image: HTMLImageElement, newMarginLeft: number, newMarginTop: number) {
    image.style.marginLeft = `${newMarginLeft}px`;
    image.style.marginTop = `${newMarginTop}px`;
  }

  private updateImageSize(image: HTMLImageElement, newWidth: number, newHeight: number) {
    image.style.width = `${newWidth}px`;
    image.style.height = `${newHeight}px`;
  }
}

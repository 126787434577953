import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {ANNOTATION_FILTER_TYPES, LABELS} from '../constants/annotations';
import {FilterField} from '../constants/filters';
import {MultiselectAutocomplete} from '../multiselect_autocomplete/multiselect_autocomplete';
import {AnalyticsService, EventActionType} from '../services/analytics_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {FilterMap} from '../typings/filter';

/**
 * Component for filtering image groups by annotations based on
 * whether the image group includes any specified labels and/or excludes
 * all specified labels.
 */
@Component({
  selector: 'annotation-filters',
  templateUrl: './annotation_filters.ng.html',
  styleUrls: ['./annotation_filters.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationFilters implements OnInit, OnDestroy {
  @Input() layerId: string = '';
  @Input() reset: Observable<void> = new Subject<void>();
  @Output() readonly editingChange = new EventEmitter<boolean>();
  @ViewChild('include', {static: false})
  includeSelect!: MultiselectAutocomplete;
  @ViewChild('exclude', {static: false})
  excludeSelect!: MultiselectAutocomplete;

  // Default width of the labels autocomplete panel.
  // Overridden for better readability.
  autocompletePanelWidth = '350px';

  filterMap: FilterMap = {};
  editing = false;

  // TODO(b/281585966): Only labels for now but support more later.
  selectedType = ANNOTATION_FILTER_TYPES[2];

  /** All labels to include or exclude. */
  allLabels: string[] = [];

  /** String representation of included strings.  */
  get included(): string[] {
    if (!this.filterMap[FilterField.ANNOTATION_INCLUDE]) {
      return [];
    }
    return [...this.filterMap[FilterField.ANNOTATION_INCLUDE]];
  }

  /** String representation of excluded strings.  */
  get excluded(): string[] {
    if (!this.filterMap[FilterField.ANNOTATION_EXCLUDE]) {
      return [];
    }
    return [...this.filterMap[FilterField.ANNOTATION_EXCLUDE]];
  }

  /**
   * Copy of filterMap so users can cancel edit to restore version before.
   */
  previousFilterMap: FilterMap = {};

  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly layersFilterService: LayersFilterService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit() {
    this.allLabels = LABELS.map((l) => l.label).sort();
    // Update the UI based on the current annotation filters.
    this.layersFilterService
      .getFilterMap(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((filters: FilterMap) => {
        this.filterMap = structuredClone(filters);
        this.changeDetectionRef.markForCheck();
      });
    this.reset.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.onCancelEdit();
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /** Initiates edit mode and backs up current filters. */
  onAddFilterButtonClicked() {
    this.setEditing(true);
    this.previousFilterMap = structuredClone(this.filterMap);
  }

  /** Cancels editing and restores options before editing started. */
  onCancelEdit() {
    this.setEditing(false);
    this.filterMap = this.previousFilterMap;
    this.changeDetectionRef.markForCheck();
  }

  /** Applies selected filters to the entire UI. */
  onApplyEdit() {
    this.analyticsService.sendEvent(EventActionType.ANNOTATION_FILTER_ADD, {
      event_label: `Include: [${this.included}], Exclude: [${this.excluded}]`,
    });
    this.setEditing(false);

    // Update include and exclude selects even if user has not closed them.
    // This may happen when a user has a select open but ignores the select
    // apply button and instead clicks the filter apply button.
    this.includeSelect.autocompleteClosed();
    this.excludeSelect.autocompleteClosed();

    this.updatePhotoFilters();
  }

  removeExcludeOption(option: string) {
    this.filterMap[FilterField.ANNOTATION_EXCLUDE] = new Set(
      [...this.filterMap[FilterField.ANNOTATION_EXCLUDE]].filter((s) => s !== option),
    );
    this.updatePhotoFilters();
  }

  removeIncludeOption(option: string) {
    this.filterMap[FilterField.ANNOTATION_INCLUDE] = new Set(
      [...this.filterMap[FilterField.ANNOTATION_INCLUDE]].filter((s) => s !== option),
    );
    this.updatePhotoFilters();
  }

  updateIncluded(included: string[]) {
    const includedSet = this.filterMap[FilterField.ANNOTATION_INCLUDE]
      ? new Set(this.filterMap[FilterField.ANNOTATION_INCLUDE])
      : new Set<string>();

    for (const s of included) {
      includedSet.add(s);
    }
    this.filterMap[FilterField.ANNOTATION_INCLUDE] = includedSet;
  }

  updateExcluded(excluded: string[]) {
    const excludedSet = this.filterMap[FilterField.ANNOTATION_EXCLUDE]
      ? new Set(this.filterMap[FilterField.ANNOTATION_EXCLUDE])
      : new Set<string>();

    for (const s of excluded) {
      excludedSet.add(s);
    }
    this.filterMap[FilterField.ANNOTATION_EXCLUDE] = excludedSet;
  }

  private setEditing(editing: boolean) {
    this.editing = editing;
    this.editingChange.emit(editing);
  }

  /**
   * Update filters in the layersFilterService to propagate the changes
   * to the rest of the UI.
   */
  private updatePhotoFilters() {
    // If image group filters are set, cannot include or exclude.
    // TODO(b/288469959) Improve the UX for handling mutually exclusive filters.
    if (this.filterMap[FilterField.IMAGE_GROUPS]) {
      delete this.filterMap[FilterField.IMAGE_GROUPS];
    }
    this.layersFilterService.updateFilterMap(this.layerId, this.filterMap, true);
  }
}

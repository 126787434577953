<form
  class="filter-form"
  [formGroup]="filterForm"
  (keydown.enter)="$event.preventDefault()"
  (ngSubmit)="applySelectedOptions()">
  <mat-form-field class="form-field">
    <mat-label>Select field</mat-label>
    <mat-select formControlName="filterNameControl" required>
      @for (filterName of getFilterNames(); track filterName) {
        <mat-option [value]="filterName" class="select-field-option">
          {{ filterName | filterNameLabel }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  @if (dateRangeField) {
    <date-range-picker formControlName="dateRangeControl"> </date-range-picker>
  }
  @if (!selectionChangedFetching && !dateRangeField) {
    <multiselect-autocomplete
      placeholderText=""
      [options]="options"
      [initialSelectedOptions]="selectedOptions"
      [autoFocus]="false"
      [selectAllOptionName]="getSelectAllOptionName()"
      (apply)="applySelectedOptions()"
      (cancel)="cancel()"
      (selectedOptionsChanged)="setSelectedOptions($event)">
    </multiselect-autocomplete>
  }
  @if (selectionChangedFetching) {
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="24" class="spinner">
    </mat-progress-spinner>
  }
  @if (selectionChangedFetching || dateRangeField) {
    <div class="form-actions">
      <button mat-button type="button" (click)="cancel()">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="selectedOptions.length === 0">
        Apply
      </button>
    </div>
  }
</form>

<div class="container" [ngClass]="{'dark-theme': (colorScheme | async) === COLOR_SCHEME.DARK}">
  <header>
    <ng-content select="[header]"></ng-content>
  </header>
  @if (showIndeterminateProgressBar) {
    <div class="loader">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  }
  @for (opened of [sidepanelOpened$ | async]; track opened) {
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav
        class="sidepanel"
        mode="side"
        [opened]="opened"
        (touchstart)="swipeStart($event)"
        (touchend)="swipeEnd($event)">
        <ng-content select="[sidepanel]"></ng-content>
      </mat-sidenav>
      <mat-sidenav-content class="main">
        <div class="sidepanel-toggle-container">
          <button
            mat-icon-button
            class="collapse"
            id="toggle-sidepanel"
            (click)="setSidepanelOpened(!opened)"
            aria-label="Toggle sidepanel"
            i18n-aria-label
            matTooltip="Toggle sidepanel">
            <mat-icon>
              {{ opened ? 'arrow_left' : 'arrow_right' }}
            </mat-icon>
          </button>
        </div>
        @if (
          determinateProgress !== null && determinateProgress >= 0 && determinateProgress < 100
        ) {
          <div
            class="loader determinate"
            [ngClass]="{
              'dark-theme': (colorScheme | async) === COLOR_SCHEME.DARK
            }">
            <mat-progress-bar mode="determinate" [value]="determinateProgress"> </mat-progress-bar>
          </div>
        }
        <ng-content select="[main]"></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  }
</div>

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

// Only track navigation history up to this value.
const NAVIGATION_CAP = 1000;

/**
 * This service tracks the application navigation history to provide
 * helper functions related to the navigation URL history.
 */
@Injectable({providedIn: 'root'})
export class UrlService {
  private previousUrl?: string;
  private currentUrl?: string;
  private readonly navigationStack: string[] = [];

  constructor(private readonly router: Router) {
    this.router.events.pipe().subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        if (this.navigationStack.length === NAVIGATION_CAP) {
          this.navigationStack.shift();
        }
        this.navigationStack.push(this.currentUrl);
      }
    });
  }

  getPreviousUrl(): string | undefined {
    return this.previousUrl;
  }

  getNavigationStack(): string[] {
    return this.navigationStack;
  }
}

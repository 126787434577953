/**
 * @fileoverview Handler for http errors.
 */
import {Observable, of} from 'rxjs';

import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import {AuthService} from './auth_service';
import {MessageService} from './message_service';

/**
 * Type of the handleError function returned by
 * HttpErrorHandler.createHandleError.
 */
export type HandleError = <T>(
  operation?: string,
  result?: T,
) => (error: HttpErrorResponse) => Observable<T>;

/**
 * Handles HttpClient errors.
 */
@Injectable()
export class HttpErrorHandler {
  constructor(
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
  ) {}

  // Create curried handleError function that already knows the service name
  createHandleError =
    (serviceName = '') =>
    <T>(operation = 'operation', result = {} as T) =>
      this.handleError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      const message =
        error.error instanceof ErrorEvent
          ? error.error.message
          : `server returned code ${error.status} with body '${error.error}'`;

      this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

      switch (error.status) {
        case 401: {
          this.authService.setUrl(this.router.url);
          this.router.navigateByUrl('/login');
          break;
        }
        case 404: {
          this.router.navigateByUrl('/error/404', {skipLocationChange: true});
          break;
        }
        case 500: {
          this.router.navigateByUrl('/error/500', {skipLocationChange: true});
          break;
        }
        default: {
          break;
        }
      }
      // Let the app keep running by returning a safe result.
      return of(result);
    };
  }
}

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

import {AnnotationsService} from '../services/annotations_service';
import {Annotation, AnnotationType, LabelInfo, PendingAnnotatedImage} from '../typings/annotations';

/**
 * Component for image annotation summary displaying.
 */
@Component({
  selector: 'annotation-summary',
  templateUrl: './annotation_summary.ng.html',
  styleUrls: ['./annotation_summary.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnotationSummary implements OnInit {
  @Input() imageIds: string[] = [];
  defects = '';
  assets = '';
  destroyed = new Subject<void>();
  loading = true;

  constructor(private readonly annotationsService: AnnotationsService) {}

  ngOnInit() {
    this.annotationsService
      .getAnnotations(this.imageIds)
      .pipe(takeUntil(this.destroyed))
      .subscribe((annotatedImages: PendingAnnotatedImage[]) => {
        this.composeSummary(annotatedImages);
        this.loading = false;
      });
  }

  private composeSummary(annotatedImages: PendingAnnotatedImage[]) {
    const defects: string[] = [];
    const assets: string[] = [];
    const labelsSet = new Set<LabelInfo>();
    for (const annotatedImage of annotatedImages) {
      annotatedImage.annotations.forEach((annotation: Annotation) => {
        const labelInfo = annotation.info.label;
        if (labelInfo !== null) {
          if (!labelsSet.has(labelInfo)) {
            labelsSet.add(labelInfo);
            if (labelInfo.type === AnnotationType.ASSET) {
              assets.push(labelInfo.label);
            } else if (labelInfo.type === AnnotationType.DEFECT) {
              defects.push(labelInfo.label);
            }
          }
        }
      });
    }
    this.assets = assets.length === 0 ? '-' : assets.join(', ');
    this.defects = defects.length === 0 ? '-' : defects.join(', ');
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

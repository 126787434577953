import {Date as GoogDate} from 'ts-closure-library/lib/date/date';
import {DateTimeFormat, Format} from 'ts-closure-library/lib/i18n/datetimeformat';

import {Pipe, PipeTransform} from '@angular/core';

import {getRelativeTimeString} from '../utils/date';

const MS_IN_TWO_WEEKS = 604800000;

// TODO: Replace toDate function copied from @angular/common with another
// implementation or more application narrowed implementation.
// Copied from @angular/common
function toDate(value: string | number | Date): Date {
  function createDate(year: number, month: number, date: number): Date {
    // The `newDate` is set to midnight (UTC) on January 1st 1970.
    // - In PST this will be December 31st 1969 at 4pm.
    // - In GMT this will be January 1st 1970 at 1am.
    // Note that they even have different years, dates and months!
    const newDate = new Date(0);

    // `setFullYear()` allows years like 0001 to be set correctly. This function
    // does not change the internal time of the date. Consider calling
    // `setFullYear(2019, 8, 20)` (September 20, 2019).
    // - In PST this will now be September 20, 2019 at 4pm
    // - In GMT this will now be September 20, 2019 at 1am

    newDate.setFullYear(year, month, date);
    // We want the final date to be at local midnight, so we reset the time.
    // - In PST this will now be September 20, 2019 at 12am
    // - In GMT this will now be September 20, 2019 at 12am
    newDate.setHours(0, 0, 0);

    return newDate;
  }
  function isoStringToDate(match: RegExpMatchArray): Date {
    const date = new Date(0);
    let tzHour = 0;
    let tzMin = 0;

    // match[8] means that the string contains "Z" (UTC) or a timezone like
    // "+01:00" or "+0100".
    const dateSetter = match[8] ? date.setUTCFullYear : date.setFullYear;
    const timeSetter = match[8] ? date.setUTCHours : date.setHours;

    // If there is a timezone defined like "+01:00" or "+0100".
    if (match[9]) {
      tzHour = Number(match[9] + match[10]);
      tzMin = Number(match[9] + match[11]);
    }
    dateSetter.call(date, Number(match[1]), Number(match[2]) - 1, Number(match[3]));
    const h = Number(match[4] || 0) - tzHour;
    const m = Number(match[5] || 0) - tzMin;
    const s = Number(match[6] || 0);
    // The ECMAScript specification
    // (https://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.11) defines
    // that `DateTime` milliseconds should always be rounded down, so that
    // `999.9ms` becomes `999ms`.
    const ms = Math.floor(parseFloat('0.' + (match[7] || 0)) * 1000);
    timeSetter.call(date, h, m, s, ms);
    return date;
  }
  function isDate(value: unknown): value is Date {
    return value instanceof Date && !isNaN(value.valueOf());
  }
  if (isDate(value)) {
    return value;
  }

  if (typeof value === 'number' && !isNaN(value)) {
    return new Date(value);
  }

  if (typeof value === 'string') {
    value = value.trim();

    if (/^(\d{4}(-\d{1,2}(-\d{1,2})?)?)$/.test(value)) {
      /* For ISO Strings without time the day, month and year must be extracted
      from the ISO String before Date creation to avoid time offset and errors
      in the new Date. If we only replace '-' with ',' in the ISO String
      ("2015,01,01"), and try to create a new date, some browsers (e.g. IE 9)
      will throw an invalid Date error. If we leave the '-' ("2015-01-01") and
      try to create a new Date("2015-01-01") the timeoffset is applied. Note:
      ISO months are 0 for January, 1 for February, ... */
      const [y, m = 1, d = 1] = value.split('-').map((val: string) => +val);
      return createDate(y, m - 1, d);
    }

    const parsedNb = parseFloat(value);

    // any string that only contains numbers, like "1234" but not like
    // "1234hello"
    if (!isNaN((value as never) - parsedNb)) {
      return new Date(parsedNb);
    }

    let match: RegExpMatchArray | null;
    if (
      (match = value.match(
        /^(\d{4})-?(\d\d)-?(\d\d)(?:T(\d\d)(?::?(\d\d)(?::?(\d\d)(?:\.(\d+))?)?)?(Z|([+-])(\d\d):?(\d\d))?)?$/,
      ))
    ) {
      return isoStringToDate(match);
    }
  }

  const date = new Date(value as never);
  if (!isDate(date)) {
    throw new Error(`Unable to convert "${value}" into a date`);
  }
  return date;
}

/*
 * Formats a `Date` into short text that is either relative, a day and month, or
 * a short date, depending on the proximity of that date from today.
 * Usage:
 *   date | relativeDateTime
 */
@Pipe({name: 'relativeDateTime'})
export class RelativeDateTimePipe implements PipeTransform {
  transform(value: string | number | Date | GoogDate | null | undefined): string | null {
    if (value !== 0 && !value) return null;

    return formatRelativeDate(value);
  }
}

function formatRelativeDate(value: string | number | Date | GoogDate): string {
  const date = value instanceof GoogDate ? value : toDate(value);

  // Relative time in the format of 'N units ago' (up to 2 weeks), or an empty
  // string if older than 2 weeks ago.
  const timeDiff = new Date().getTime() - date.getTime();
  if (timeDiff <= MS_IN_TWO_WEEKS) {
    return getRelativeTimeString(date.getTime());
  }

  // Otherwise print the short date.
  return mediumDate.format(date);
}

const mediumDate = new DateTimeFormat(Format.MEDIUM_DATE);

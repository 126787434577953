import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {AnnotationsService} from '../services/annotations_service';
import {AnnotationSummary} from './annotation_summary';

@NgModule({
  declarations: [AnnotationSummary],
  exports: [AnnotationSummary],
  providers: [AnnotationsService],
  imports: [CommonModule, MatProgressSpinnerModule],
})
export class AnnotationSummaryModule {}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MultiMarkerDetails} from './multi_marker_details';

@NgModule({
  declarations: [MultiMarkerDetails],
  imports: [CommonModule],
  exports: [MultiMarkerDetails],
})
export class MultiMarkerDetailsModule {}

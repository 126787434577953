import {Injectable} from '@angular/core';

interface PropertyValuesByLayerIdForStorage {
  [layerIdPropertyKey: string]: string[];
}

// If an argument exceeds this many bytes, then the values will not be written
// to local storage.
const MAXIMUM_BYTES_TO_WRITE_SINGLE_PROPERTY_VALUES = 20000;

// If the total size of all stored layer-property values exceeds this many
// bytes, the layer-property-value cache will be cleared.
const MAXIMUM_BYTES_TO_WRITE_ALL_PROPERTY_VALUES = 3000000;

// Keys in session storage.
enum KeyEnum {
  LAYER_PROPERTY_KEY_VALUES = 'layer property key values',
}

/**
 * Service for interacting with session storage.
 */
@Injectable({providedIn: 'root'})
export class SessionStorageService {
  writeLayerPropertyKeyValues(layerId: string, propertyKey: string, propertyValues: string[]) {
    const numberOfBytesToWrite = propertyValues.join('').length;
    if (numberOfBytesToWrite > MAXIMUM_BYTES_TO_WRITE_SINGLE_PROPERTY_VALUES) {
      console.warn(
        `Failed to write property values to session storage: ${numberOfBytesToWrite} > ${MAXIMUM_BYTES_TO_WRITE_SINGLE_PROPERTY_VALUES}`,
      );
      return;
    }
    try {
      const propertyValuesById = this.getItem(
        KeyEnum.LAYER_PROPERTY_KEY_VALUES,
      )! as PropertyValuesByLayerIdForStorage;
      propertyValuesById[this.buildLayerPropertyKey(layerId, propertyKey)] = propertyValues;
      if (JSON.stringify(propertyValuesById).length > MAXIMUM_BYTES_TO_WRITE_ALL_PROPERTY_VALUES) {
        throw new Error();
      }
      this.setItem(KeyEnum.LAYER_PROPERTY_KEY_VALUES, propertyValuesById);
    } catch {
      this.setItem(KeyEnum.LAYER_PROPERTY_KEY_VALUES, {
        [this.buildLayerPropertyKey(layerId, propertyKey)]: propertyValues,
      });
    }
  }

  readLayerPropertyKeyValues(layerId: string, propertyKey: string): string[] {
    try {
      const propertyValuesById = this.getItem(
        KeyEnum.LAYER_PROPERTY_KEY_VALUES,
      )! as PropertyValuesByLayerIdForStorage;
      return propertyValuesById[this.buildLayerPropertyKey(layerId, propertyKey)] || [];
    } catch {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem(key: string): any {
    const storedItem = sessionStorage.getItem(key);
    return storedItem ? JSON.parse(storedItem) : null;
  }

  /**
   * The ID used to store and retrieve property values for a given layer and
   * property key.
   */
  private buildLayerPropertyKey(layerId: string, propertyKey: string): string {
    return `${layerId}-${propertyKey}`;
  }
}

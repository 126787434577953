@for (property of properties.slice(0, numberOfPropertiesToShow); track property) {
  <div class="properties">
    <span class="property-name">{{ propertyDisplayName(property.key) }}: </span>
    <span class="property-value">{{ property.propertyValue.value }}</span>
    @if (copyIconSet.has(property.key)) {
      <span>
        <button
          mat-icon-button
          class="icon-button"
          (click)="copyPropertyValue(property)"
          aria-label="Copy id"
          i18n-aria-label
          matTooltip="Copy id">
          <mat-icon class="icon">content_copy</mat-icon>
        </button>
      </span>
    }
    @if (shouldBeLink(property) && (idExists(property) | async)) {
      <span>
        <button
          mat-icon-button
          class="icon-button"
          color="primary"
          (click)="navigateByProperty(property)"
          aria-label="Open"
          i18n-aria-label
          matTooltip="Open">
          <mat-icon class="icon">open_in_new</mat-icon>
        </button>
      </span>
    }
  </div>
}
@if (totalNumberOfProperties > initialNumberOfPropertiesToShow) {
  <button
    class="button-action"
    color="primary"
    (click)="toggleMetadataVisibility()"
    mat-button
    aria-label="See more"
    i18n-aria-label>
    @if (!expanded) {
      <div>
        See more
        <mat-icon class="icon">arrow_drop_down</mat-icon>
      </div>
    } @else {
      See less
      <mat-icon class="icon">arrow_drop_up</mat-icon>
    }
  </button>
}

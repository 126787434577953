import {IconColor, IconGeneratorMetadata} from '../typings/icon';

/**
 * The color to be used if the front icon color is unavailable. This will be the
 * case when the SVG is in file format. TODO(reubenn): Use FileReader to read
 * data-icon-color attribute.
 */
export const DEFAULT_MULTI_MARKER_FRONT_ICON_COLOR = '#4d97ff'; // Blue 500.

/**
 * The color to be used if the front and back icon colors are unavailable.
 */
export const DEFAULT_MULTI_MARKER_BACK_ICON_COLOR = '#174ea6'; // Blue 900.

/**
 * The prefix that an SVG needs to render.
 */
export const EXTERNAL_LINK_ICON_URL_PREFIX = 'data:image/svg+xml;charset=UTF-8';

/**
 * The maximum number of characters that a icon label can have.
 */
export const MAX_CHARS_IN_LABEL = 3;

/**
 * SVG custom attribute whose value is the icon color. Used to determine the
 * colors of overlapping icons.
 */
export const ICON_COLOR_ATTRIBUTE = 'data-icon-color';

/**
 * SVG custom attribute whose value indicates its shape.
 */
export const ICON_SHAPE_ATTRIBUTE = 'data-icon-shape';

/**
 * Supported icon shapes.
 */
export enum SHAPE {
  CIRCLE = 'Circle',
  SQUARE = 'Square',
  DIAMOND = 'Diamond',
  TAG = 'Tag',
  TRIANGLE = 'Triangle',
}

/**
 * Dashed stroke. Each integer represents stroke then without stroke.
 * Eg: 10, 10 is 10 pixels of stroke followed by 10 pixels without stroke.
 */
export const DEFAULT_DASHED_STROKE = '20, 20';

/**
 * Without dashed stroke.
 */
export const WITHOUT_DASHED_STROKE = 'none';

const DEFAULT_STROKE_COLOR = '#fff';
const DEFAULT_STROKE_WIDTH_PX = '10';

/**
 * The default opacity of an icon.
 */
const DEFAULT_OPACITY = '1';

/**
 * All possible icon colors.
 */
export const ICON_COLORS: IconColor[] = [
  {label: 'Light red', hex: '#f59290'},
  {label: 'Red', hex: '#f53e3e'},
  {label: 'Dark red', hex: '#ea101f'},
  {label: 'Light blue', hex: '#c0dfff'},
  {label: 'Blue', hex: '#4d97ff'},
  {label: 'Dark blue', hex: '#4776dc'},
  {label: 'Light green', hex: '#b1e3d4'},
  {label: 'Green', hex: '#00b089'},
  {label: 'Dark green', hex: '#00946b'},
  {label: 'Light orange', hex: '#f7936d'},
  {label: 'Orange', hex: '#f56830'},
  {label: 'Dark orange', hex: '#ce5425'},
  {label: 'Light yellow', hex: '#faf097'},
  {label: 'Yellow', hex: '#f6e22a'},
  {label: 'Dark yellow', hex: '#f3b71c'},
  {label: 'Light purple', hex: '#eee5fb'},
  {label: 'Purple', hex: '#d2bff4'},
  {label: 'Dark purple', hex: '#9367e9'},
];

/**
 * Icon colors configurable through Settings page.
 */
export const CONFIGURATOR_ICON_COLORS: IconColor[] = [
  {label: 'Red', hex: '#f53e3e'},
  {label: 'Orange', hex: '#f56830'},
  {label: 'Yellow', hex: '#f6e22a'},
  {label: 'Blue', hex: '#4d97ff'},
  {label: 'Green', hex: '#00b089'},
  {label: 'Magenta', hex: '#d200d9'},
  {label: 'Purple', hex: '#d2bff4'},
];

/**
 * In case when icon colors were configured for specific
 * property through Settings page, this color will be applied
 * to all the uncovered by specific assignment values.
 */
export const UNASSIGNED_ICON_COLOR: IconColor = {
  label: 'Grey',
  hex: '#5f6368',
};

/**
 * The color palette to use for icon colors if one is not provided.
 */
export const DEFAULT_COLOR_PALETTE = ICON_COLORS.map((iconColor: IconColor) => iconColor.hex);

// TODO(b/183564453): Compress SVGs with go/svgomg.
/**
 * Circle icon SVG.
 */
export const CIRCLE_ICON_SVG = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="48" width="48" viewBox="-8 -8 160 160" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>" ${ICON_SHAPE_ATTRIBUTE}="${SHAPE.CIRCLE}">
    <g x="50" y="50">
      <path stroke="<$strokeColor>" stroke-width="<$strokeWidth>" stroke-dasharray="<$dashedStroke>" d="M75.85,0A76.15,76.15,0,0,0,0,75.85c0,41.67,34.19,75.86,75.85,75.86a76.15,76.15,0,0,0,75.86-75.86C151.71,34.19,117.52,0,75.85,0Z"/>
    </g>
    <text x="50%" y="55%" fill="#000" font-size="70" font-weight="700" fill-opacity="1.0" text-anchor="middle" dy=".25em" font-family="Roboto" alignment-baseline="bottom" letter-spacing=".1"><$label></text>
</svg>`;

/**
 * Square icon SVG.
 */
export const SQUARE_ICON_SVG = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="48" width="48" viewBox="-4 -4 168 168" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>" ${ICON_SHAPE_ATTRIBUTE}="${SHAPE.SQUARE}">
      <defs>
        <style>.cls-1{fill:url(#radial-gradient);}</style>
        <radialGradient id="radial-gradient" cx="3.77" cy="3.93" r="204.62" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fff" stop-opacity="0.1"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></radialGradient>
      </defs>
      <rect class="cls-1" width="152" height="152" rx="12"/>
      <path stroke="<$strokeColor>" stroke-width="<$strokeWidth>" stroke-dasharray="<$dashedStroke>" d="M152,10.1V141.9A10.1,10.1,0,0,1,141.9,152H10.1A10.1,10.1,0,0,1,0,141.9V10.1A10.1,10.1,0,0,1,10.1,0H141.9A10.1,10.1,0,0,1,152,10.1Z"/>
      <text x="47%" y="55%" fill="#000" font-size="70" font-weight="700" fill-opacity="1.0" text-anchor="middle" dy=".25em" font-family="Roboto" alignment-baseline="bottom" letter-spacing=".1"><$label></text>
    </svg>`;

/**
 * Diamond icon SVG.
 */
export const DIAMOND_ICON_SVG = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="48" width="48" viewBox="-4 -4 156 156" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>" ${ICON_SHAPE_ATTRIBUTE}="${SHAPE.DIAMOND}">
      <defs>
        <style>.cls-1{fill:url(#radial-gradient);}</style>
        <radialGradient id="radial-gradient" cx="22.79" cy="22.77" r="149.86" gradientTransform="translate(-0.04 0.1)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#fff" stop-opacity="0.1"/><stop offset="1" stop-color="#fff" stop-opacity="0"/>
        </radialGradient>
      </defs>
      <rect x="19.99" y="19.99" width="111.32" height="111.32" rx="12" transform="translate(-31.34 75.65) rotate(-45)"/>
      <path stroke="<$strokeColor>" stroke-width="<$strokeWidth>" stroke-dasharray="<$dashedStroke>" d="M80.88,2.17l68.25,68.25a7.39,7.39,0,0,1,0,10.46L80.88,149.13a7.39,7.39,0,0,1-10.46,0L2.17,80.88a7.39,7.39,0,0,1,0-10.46L70.42,2.17A7.39,7.39,0,0,1,80.88,2.17Z"/>
      <text x="47%" y="55%" fill="#000" font-size="70" fill-opacity="1.0" font-weight="700" text-anchor="middle" dy=".25em" font-family="Roboto" alignment-baseline="bottom" letter-spacing=".1"><$label></text>
    </svg>`;

/**
 * Tag icon SVG.
 */
export const TAG_ICON_SVG = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="48" width="48" viewBox="-4 -4 128 160" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>" ${ICON_SHAPE_ATTRIBUTE}="${SHAPE.TAG}">
      <path stroke="<$strokeColor>" stroke-width="<$strokeWidth>" stroke-dasharray="<$dashedStroke>" d="M123.86,121.22l-54.1,36.43a11,11,0,0,1-12.65,0L5,121.22a11.91,11.91,0,0,1-5-9.77V11.78A11.56,11.56,0,0,1,11.33,0H117.54a11.56,11.56,0,0,1,11.33,11.78v99.67A11.91,11.91,0,0,1,123.86,121.22Z"/>
      <text x="50%" y="50%" fill="#000" font-size="70" fill-opacity="1.0" font-weight="700" text-anchor="middle" dy=".25em" font-family="Roboto" alignment-baseline="bottom" letter-spacing=".1"><$label></text>
    </svg>`;

/**
 * Triangle icon SVG.
 */
export const TRIANGLE_ICON_SVG = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="48" width="48" viewBox="0 0 152 132" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>" ${ICON_SHAPE_ATTRIBUTE}="${SHAPE.TRIANGLE}">
      <path stroke="<$strokeColor>" stroke-width="<$strokeWidth>" stroke-dasharray="<$dashedStroke>" d="M140.66,132.3H11.11a11.09,11.09,0,0,1-9.56-16.71L66.33,5.47a11.09,11.09,0,0,1,19.12,0l64.77,110.12A11.09,11.09,0,0,1,140.66,132.3Z"/>
      <text x="50%" y="75%" fill="#000" font-size="70" fill-opacity="1.0" font-weight="700" text-anchor="middle" dy=".25em" font-family="Roboto" alignment-baseline="bottom" letter-spacing=".1"><$label></text>
    </svg>`;

/**
 * Default icon for deselected markers.
 */
const DEFAULT_DESELECTED_ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" height="48" width="48" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>">
          <path d="M38 2H10C7.79 2 6.02 3.79 6.02 6L6 31.87c0 1.38.7 2.6 1.76 3.32L23.99 46l16.23-10.81a4.011 4.011 0 0 0 1.76-3.32L42 6c0-2.21-1.79-4-4-4zM20 14 20 32z"/>
          <text x="50%" y="50%" font-size="20px" font-weight="700"
              text-anchor="middle" dy=".25em" fill="#000" font-family="Roboto"
              alignment-baseline="bottom" letter-spacing=".1">
            <$label>
          </text>
        </svg>`;

/**
 * Default icon for selected markers.
 */
const DEFAULT_SELECTED_ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48" height="48" width="48" fill="<$color>" fill-opacity="<$opacity>" ${ICON_COLOR_ATTRIBUTE}="<$color>">
          <path d="M38 2H10C7.79 2 6.02 3.79 6.02 6L6 31.87c0 1.38.7 2.6 1.76 3.32L23.99 46l16.23-10.81a4.011 4.011 0 0 0 1.76-3.32L42 6c0-2.21-1.79-4-4-4zM20 14 20 32z"/>
          <text x="50%" y="50%" font-size="20px" font-weight="700"
              text-anchor="middle" dy=".25em" fill="#000" font-family="Roboto"
              alignment-baseline="bottom" letter-spacing=".1">
            <$label>
          </text>
        </svg>`;

/**
 * Circular area around selected marker icon SVG.
 */
export const SELECTED_HIGHLIGHT_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 100 100" height="100" width="100" fill="<$color>" fill-opacity="<$opacity>">
  <circle cx="50%" cy="50%" r="50%"/>
</svg>`;

/**
 * Default icon metadata. Any number of these properties may be used for a given
 * icon.
 */
export const DEFAULT_ICON_METADATA: IconGeneratorMetadata = {
  layerId: '',
  label: '',
  colorKey: '',
  colorPalette: DEFAULT_COLOR_PALETTE,
  colorHex: '',
  strokeColor: DEFAULT_STROKE_COLOR,
  strokeIsDashed: false,
  strokeWidth: DEFAULT_STROKE_WIDTH_PX,
  opacity: DEFAULT_OPACITY,
  deselectedIconSvg: DEFAULT_DESELECTED_ICON_SVG,
  selectedIconSvg: DEFAULT_SELECTED_ICON_SVG,
};

/**
 * Icon for unselected multi marker.
 */
export const DESELECTED_MULTI_MARKER_ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="21" viewBox="0 0 28 21" fill="none">
      <g clip-path="url(#clip0)">
        <path d="M24.273 17.273A9.58 9.58 0 1010.727 3.728a9.58 9.58 0 0013.546 13.545z" fill="<$backColor>"/>
        <path d="M17.502 1.843c4.773 0 8.658 3.882 8.658 8.657s-3.883 8.657-8.658 8.657c-4.774 0-8.657-3.884-8.657-8.657s3.883-8.657 8.657-8.657zm0-1.843c-5.799 0-10.5 4.7-10.5 10.5S11.703 21 17.502 21c5.8 0 10.5-4.7 10.5-10.5S23.3 0 17.503 0z" fill="<$backBorderColor>"/>
        <path d="M10.5 20.079c5.291 0 9.58-4.289 9.58-9.579 0-5.29-4.289-9.579-9.58-9.579C5.21.921.923 5.21.923 10.5c0 5.29 4.289 9.579 9.579 9.579z" fill="<$frontColor>"/>
        <path d="M10.5 1.843c4.773 0 8.658 3.882 8.658 8.657s-3.883 8.657-8.658 8.657c-4.774 0-8.657-3.884-8.657-8.657S5.726 1.843 10.5 1.843zM10.5 0C4.702 0 0 4.7 0 10.5S4.701 21 10.5 21C16.3 21 21 16.3 21 10.5S16.3 0 10.5 0z" fill="<$frontBorderColor>"/>
      </g>
        <text x="37%" y="62%" font-size="10px" font-weight="700"
        text-anchor="middle" dy=".1em" fill="#000" font-family="Roboto"
        alignment-baseline="bottom" letter-spacing=".1">
          <$label>
        </text>
      <defs>
         <clipPath id="clip0">
           <path fill="#fff" d="M0 0h28v21H0z"/>
         </clipPath>
       </defs>
    </svg>`;

/**
 * Icon for selected multi marker.
 */
export const SELECTED_MULTI_MARKER_ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="36" height="27" viewBox="0 0 28 21" fill="none">
      <g clip-path="url(#clip0)">
        <path d="M24.273 17.273A9.58 9.58 0 1010.727 3.728a9.58 9.58 0 0013.546 13.545z" fill="<$backColor>"/>
        <path d="M17.502 1.843c4.773 0 8.658 3.882 8.658 8.657s-3.883 8.657-8.658 8.657c-4.774 0-8.657-3.884-8.657-8.657s3.883-8.657 8.657-8.657zm0-1.843c-5.799 0-10.5 4.7-10.5 10.5S11.703 21 17.502 21c5.8 0 10.5-4.7 10.5-10.5S23.3 0 17.503 0z" fill="<$backBorderColor>"/>
        <path d="M10.5 20.079c5.291 0 9.58-4.289 9.58-9.579 0-5.29-4.289-9.579-9.58-9.579C5.21.921.923 5.21.923 10.5c0 5.29 4.289 9.579 9.579 9.579z" fill="<$frontColor>"/>
        <path d="M10.5 1.843c4.773 0 8.658 3.882 8.658 8.657s-3.883 8.657-8.658 8.657c-4.774 0-8.657-3.884-8.657-8.657S5.726 1.843 10.5 1.843zM10.5 0C4.702 0 0 4.7 0 10.5S4.701 21 10.5 21C16.3 21 21 16.3 21 10.5S16.3 0 10.5 0z" fill="<$frontBorderColor>"/>
      </g>
        <text x="37%" y="62%" font-size="10px" font-weight="700"
        text-anchor="middle" dy=".1em" fill="#000" font-family="Roboto"
        alignment-baseline="bottom" letter-spacing=".1">
          <$label>
        </text>
      <defs>
         <clipPath id="clip0">
           <path fill="#fff" d="M0 0h28v21H0z"/>
         </clipPath>
       </defs>
    </svg>`;

/**
 * Multi-marker icon for overlapping features that contain user and
 * sensor collected images.
 */
export const DESELECTED_MULTI_MIXED_MARKER_ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28" height="21" viewBox="0 0 28 21" fill="none">
      <g clip-path="url(#clip0)">
        <path d="M24.273 17.273A9.58 9.58 0 1010.727 3.728a9.58 9.58 0 0013.546 13.545z" fill="<$backColor>"/>
        <path d="M17.502 1.843c4.773 0 8.658 3.882 8.658 8.657s-3.883 8.657-8.658 8.657c-4.774 0-8.657-3.884-8.657-8.657s3.883-8.657 8.657-8.657zm0-1.843c-5.799 0-10.5 4.7-10.5 10.5S11.703 21 17.502 21c5.8 0 10.5-4.7 10.5-10.5S23.3 0 17.503 0z" fill="<$backBorderColor>"/>
        <circle cx="7" cy="7" r="10" style="fill:#FEC44F;stroke-width:0" transform="translate(3 3)"/>
        <circle cx="7" cy="7" r="10" style="fill:#4d97ff;stroke-width:0" clip-path="url(#left-half)" transform="translate(2 2)"/>
        <path d="M10.5 1.843c4.773 0 8.658 3.882 8.658 8.657s-3.883 8.657-8.658 8.657c-4.774 0-8.657-3.884-8.657-8.657S5.726 1.843 10.5 1.843zM10.5 0C4.702 0 0 4.7 0 10.5S4.701 21 10.5 21C16.3 21 21 16.3 21 10.5S16.3 0 10.5 0z" fill="<$frontBorderColor>"/>
      </g>
        <text x="37%" y="62%" font-size="10px" font-weight="700"
        text-anchor="middle" dy=".1em" fill="#000" font-family="Roboto"
        alignment-baseline="bottom" letter-spacing=".1">
          <$label>
        </text>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h28v21H0z"/>
        </clipPath>
        <clipPath id="left-half">
          <rect x="0" y="0" width="9" height="21"/>
        </clipPath>
      </defs>
    </svg>`;

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/common.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * A Feature's lifecycle stage.
 *
 * @generated from enum tapestry.gridaware.api.v1.LifecycleStage
 */
export const LifecycleStage = proto3.makeEnum("tapestry.gridaware.api.v1.LifecycleStage", [{
  no: 0,
  name: "STATE_UNSPECIFIED"
}, {
  no: 1,
  name: "ACTIVE"
}, {
  no: 2,
  name: "INACTIVE"
}]);

/**
 * Type related on Images associated with feature.
 *
 * @generated from enum tapestry.gridaware.api.v1.ImageAssociationStatus
 */
export const ImageAssociationStatus = proto3.makeEnum("tapestry.gridaware.api.v1.ImageAssociationStatus", [{
  no: 0,
  name: "STATUS_UNSPECIFIED"
}, {
  no: 1,
  name: "NO_IMAGES"
}, {
  no: 2,
  name: "USER_IMAGES_ONLY"
}, {
  no: 3,
  name: "TAPESTRY_IMAGES_ONLY"
}, {
  no: 4,
  name: "USER_AND_TAPESTRY_IMAGES"
}]);

/**
 * User-generated list of data that's deliberately unstructured and not indexed
 * on our side.
 *
 * @generated from message tapestry.gridaware.api.v1.Metadata
 */
export const Metadata = proto3.makeMessageType("tapestry.gridaware.api.v1.Metadata", () => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
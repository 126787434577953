import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';

import {MultiselectAutocompleteModule} from '../multiselect_autocomplete/multiselect_autocomplete_module';
import {Tags} from './tags';
import {TagsDialog} from './tags_dialog';

@NgModule({
  declarations: [TagsDialog, Tags],
  imports: [
    CommonModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MultiselectAutocompleteModule,
  ],
  exports: [TagsDialog, Tags],
})
export class TagsModule {}

import {Observable, ReplaySubject, Subject, of} from 'rxjs';
import {catchError, map, mergeMap, take, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {
  AnnotatedImage,
  CorrectedImageAnnotation,
  ImageAnnotation,
  ImageAnnotationLabel,
  ImageAnnotationPoint,
  Rectangle,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';
import {
  CorrectImageAnnotationRequest,
  ImageAnnotationKey,
  SaveImageAnnotationsRequest,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/imageservice_pb';

import {LABELS} from '../constants/annotations';
import {
  Annotation,
  AnnotationShape,
  LabelInfo,
  PendingAnnotatedImage,
} from '../typings/annotations';
import {PhotosService} from './photos_service';

/**
 * Default annotations state for the image.
 */
const EMPTY_ANNOTATION_STATE = {
  id: '',
  annotations: [],
  updatedImadeURL: '',
};

/**
 * Service for handling image annotations.
 */
@Injectable({providedIn: 'root'})
export class AnnotationsService {
  readonly annotationsStateChanged = new Subject<Annotation[]>();

  private readonly pendingAnnotationsPerImage = new Map<
    string,
    ReplaySubject<PendingAnnotatedImage>
  >();

  constructor(private readonly photosService: PhotosService) {}

  /**
   * Adds pending (added but not saved yet) annotations state for image.
   */
  addPendingAnnotations(imageId: string, annotationsState: PendingAnnotatedImage) {
    if (!this.pendingAnnotationsPerImage.has(imageId)) {
      this.pendingAnnotationsPerImage.set(imageId, new ReplaySubject(1));
    }
    this.pendingAnnotationsPerImage.get(imageId)!.next(annotationsState);
  }

  /**
   * Retrieves pending (added but not saved yet) annotations state for image.
   */
  getPendingAnnotations(imageId: string): Observable<PendingAnnotatedImage> {
    if (!this.pendingAnnotationsPerImage.has(imageId)) {
      return this.getSavedAnnotations(imageId);
    }
    return this.pendingAnnotationsPerImage.get(imageId)!;
  }

  /**
   * Retrieves saved annotations state for image.
   */
  getSavedAnnotations(imageId: string): Observable<PendingAnnotatedImage> {
    if (!this.pendingAnnotationsPerImage.has(imageId)) {
      this.pendingAnnotationsPerImage.set(imageId, new ReplaySubject(1));
    }
    this.getAnnotations([imageId])
      .pipe(
        map(
          (annotatedImages: PendingAnnotatedImage[]): PendingAnnotatedImage =>
            annotatedImages.length > 0 ? annotatedImages[0] : EMPTY_ANNOTATION_STATE,
        ),
      )
      .subscribe((annotatedImage: PendingAnnotatedImage) => {
        this.pendingAnnotationsPerImage.get(imageId)?.next(annotatedImage);
      });
    return this.pendingAnnotationsPerImage.get(imageId)!;
  }

  /**
   * Clears pending annotations state for image.
   */
  clearPendingAnnotations(imageId: string) {
    this.pendingAnnotationsPerImage.get(imageId)?.next(EMPTY_ANNOTATION_STATE);
  }

  /**
   * Retrieves annotations for images by IDs. Also saves the annotations state
   * to local store for reuse.
   */
  getAnnotations(imageIds: string[]): Observable<PendingAnnotatedImage[]> {
    return this.photosService.getAnnotatedImagesByIds(imageIds).pipe(
      map((annotatedImages: AnnotatedImage[]): PendingAnnotatedImage[] =>
        annotatedImages.map(
          (image: AnnotatedImage): PendingAnnotatedImage =>
            this.annotatedImageToPendingState(image),
        ),
      ),
      catchError((error: Error) => {
        console.error(`Failed to fetch annotations for image IDs ${imageIds}. ${error.message}`);
        return of([]);
      }),
      tap((images: PendingAnnotatedImage[]) => {
        for (const image of images) {
          this.addPendingAnnotations(image.id, image);
        }
      }),
    );
  }

  /**
   * Saves latest annotations state for image. Reference ID can be provided
   * indicating that previously image has been referred to by different ID. This
   * is usually the case for pending (added but now saved yet) images. Before image
   * is submitted, it is assigned a temporary ID to associate annotations with.
   * Later it gets replaced by the actual ID from the database.
   */
  saveAnnotationsForImage(
    imageId: string,
    referenceId: string = '',
  ): Observable<AnnotatedImage | null> {
    return this.getPendingAnnotations(referenceId || imageId).pipe(
      take(1), // Required to avoid circular notification after save.
      mergeMap(
        (pending: PendingAnnotatedImage): Observable<AnnotatedImage | null> =>
          this.saveExplicitAnnotationsForImage(imageId, pending.annotations),
      ),
    );
  }

  /**
   * Saves provided annotations, associating them with image ID.
   */
  saveExplicitAnnotationsForImage(
    imageId: string,
    annotations: Annotation[],
  ): Observable<AnnotatedImage | null> {
    const annotatedImage = this.buildAnnotatedImageRequest(imageId, annotations);
    return this.photosService.saveAnnotatedImage(annotatedImage).pipe(
      tap(() => {
        // Reset cache after saving.
        this.getSavedAnnotations(imageId).pipe(take(1)).subscribe();
      }),
    );
  }

  correctImageAnnotation(
    imageId: string,
    annotation: ImageAnnotation,
    correctedLabel: ImageAnnotationLabel,
    correctedComment: string,
  ): Observable<CorrectedImageAnnotation> {
    if (annotation.label === correctedLabel) {
      throw new Error('Image annotation label must not be the same as the corrected label');
    }
    const annotationKey = new ImageAnnotationKey({
      imageId,
      annotationId: annotation.id,
    });
    const request = new CorrectImageAnnotationRequest({
      key: annotationKey,
      label: correctedLabel,
      comment: correctedComment,
    });
    return this.photosService.correctImageAnnotation(request).pipe(
      tap(() => {
        // Reset cache after saving.
        this.getSavedAnnotations(imageId).pipe(take(1)).subscribe();
      }),
    );
  }

  /**
   * Clears all pending annotations state.
   */
  clearPendingState() {
    this.pendingAnnotationsPerImage.clear();
  }

  // TODO(halinab): unify the typings and get rid of conversion.
  private buildAnnotatedImageRequest(
    imageId: string,
    annotations: Annotation[],
  ): SaveImageAnnotationsRequest {
    const imageAnnotations: ImageAnnotation[] = [];
    annotations.forEach((annotation) => {
      imageAnnotations.push(this.buildImageAnnotation(annotation));
    });
    const annotatedImage = new AnnotatedImage({
      imageId,
      annotations: imageAnnotations,
    });
    return new SaveImageAnnotationsRequest({annotatedImage});
  }

  // TODO(halinab): unify the typings and get rid of conversion.
  private buildImageAnnotation(annotation: Annotation): ImageAnnotation {
    const shape = this.buildAnnotationShape(annotation);
    if (!annotation.info.label) {
      console.error('annotation label is missing');
    }
    return new ImageAnnotation({
      shape: {case: 'rectangle', value: shape},
      label: annotation.info.label?.value,
      comment: annotation.info.comment,
      user: annotation.info.user,
      updatedAt: annotation.info.updatedAt,
      createdAt: annotation.info.createdAt,
      isMachineGenerated: annotation.info.isMachineGenerated,
      machineModelVersion: annotation.info.machineModelVersion,
      machineConfidenceScore: annotation.info.machineConfidenceScore,
      id: annotation.info.sourceAnnotation.id,
    });
  }

  // TODO(halinab): unify the typings and get rid of conversion.
  private buildAnnotationShape(annotation: Annotation): Rectangle {
    const topLeft = new ImageAnnotationPoint({
      x: annotation.shapeData.left,
      y: annotation.shapeData.top,
    });
    // TODO(halinab): implementation for other annotation shapes - e.g. arrow.
    return new Rectangle({
      topLeft,
      width: annotation.shapeData.width,
      height: annotation.shapeData.height,
    });
  }

  // TODO(halinab): unify the typings and get rid of conversion.
  private annotatedImageToPendingState(annotatedImage: AnnotatedImage): PendingAnnotatedImage {
    const pendingAnnotations: Annotation[] = [];
    for (const annotation of annotatedImage.annotations) {
      // TODO(halinab): implementation for other annotation shapes - e.g. arrow.
      const shapeType = AnnotationShape.RECTANGLE;
      if (!annotation.shape.case) continue;
      const shape = annotation.shape.value;
      const topLeft = shape.topLeft;
      if (!topLeft) continue;
      const shapeData = {
        top: topLeft.y,
        left: topLeft.x,
        width: shape.width,
        height: shape.height,
      };
      const info = {
        label: LABELS.find((label: LabelInfo) => label.value === annotation.label) || null,
        comment: annotation.comment,
        user: annotation.user,
        updatedAt: annotation.updatedAt,
        createdAt: annotation.createdAt,
        isMachineGenerated: annotation.isMachineGenerated,
        machineModelVersion: annotation.machineModelVersion,
        machineConfidenceScore: annotation.machineConfidenceScore,
        sourceAnnotation: annotation,
      };
      pendingAnnotations.push({info, shapeType, shapeData});
    }

    // TODO(b/262760245): build the image with annotations overlay for display
    // in thumbnail.
    return {
      id: annotatedImage.imageId,
      annotations: pendingAnnotations,
      updatedImadeURL: '',
    };
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/user.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message tapestry.gridaware.api.v1.User
 */
export const User = proto3.makeMessageType("tapestry.gridaware.api.v1.User", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "avatar_url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.UserType
 */
export const UserType = proto3.makeMessageType("tapestry.gridaware.api.v1.UserType", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "full_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "short_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "position",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';

/**
 * This service detects the browser's supported capabilities.
 */
@Injectable({providedIn: 'root'})
export class BrowserCapabilityDetectionService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  isWebGLAvailable(): boolean {
    try {
      const canvas = this.document.createElement('canvas');
      return !!(
        window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
      );
    } catch (e) {
      return false;
    }
  }
}

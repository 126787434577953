<div class="offline-search-container">
  <mat-form-field class="offline-search-input" appearance="outline" subscriptSizing="dynamic">
    <input
      matInput
      #input
      type="text"
      spellcheck="false"
      placeholder="{{ placeholder || 'Search' }}"
      aria-label="Search"
      i18n-aria-label
      [matAutocomplete]="auto"
      [formControl]="searchInputControl" />
    <mat-autocomplete #auto="matAutocomplete">
      <cdk-virtual-scroll-viewport
        class="autocomplete-viewport"
        [ngStyle]="{height: virtualScrollViewportHeight}"
        itemSize="50"
        minBufferPx="200"
        maxBufferPx="400">
        <mat-option
          *cdkVirtualFor="let option of filteredOptions"
          class="autocomplete-item"
          [value]="option.value"
          (onSelectionChange)="selectOption(option.key)">
          <span
            class="offline-option-value"
            innerHtml="{{ option.value | highlight: input.value }}"></span>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</div>

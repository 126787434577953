import {BehaviorSubject, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {SolarInsight} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/solar_insight_pb';

import {ICP_LAYER_ID, SOLAR_INSIGHTS_LAYER_ID} from '../constants/layer';
import {LayersFilterService} from '../services/layers_filter_service';
import {NetworkService} from '../services/network_service';
import {COLOR_SCALE, SolarInsightsService} from '../services/solar_insights_service';
import {FilterMap} from '../typings/filter';

/**
 * This component displays the range slider filter and filter modification
 * controls for a single layer within the sidepanel.
 */
@Component({
  selector: 'range-filter',
  templateUrl: './range_filter.ng.html',
  styleUrls: ['./range_filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeFilter implements OnInit, OnDestroy {
  @Input() protected maxAll = new BehaviorSubject<number>(0);
  @Input() protected maxReflection = new BehaviorSubject<number>(0);
  @Input() protected minAll = new BehaviorSubject<number>(0);
  @Input() protected minReflection = new BehaviorSubject<number>(0);
  @Input() protected legendStyle: Record<string, string> = this.getLegendStyle();

  allFeeders: SolarInsight[] = [];
  selectedFeeders: SolarInsight[] = [];
  protected solarInsightsLayerId = SOLAR_INSIGHTS_LAYER_ID;
  private readonly destroyed = new Subject<void>();

  private readonly globalFilters: FilterMap = {};
  private minValue: number = Number.MAX_SAFE_INTEGER;
  private maxValue: number = Number.MIN_SAFE_INTEGER;

  protected sliderDisabled = true;
  sliderFilterTitle = 'Max Potential Capacity (total kW) Filter';

  constructor(
    readonly networkService: NetworkService,
    private readonly solarInsightsService: SolarInsightsService,
    private readonly layersFilterService: LayersFilterService,
  ) {}

  ngOnInit() {
    this.getSelectedFeeders();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.solarInsightsService.setSelectedFeeders(this.selectedFeeders);
  }

  private getSelectedFeeders() {
    this.solarInsightsService
      .getSelectedFeeders()
      .pipe(
        takeUntil(this.destroyed),
        switchMap((feeders) => {
          this.selectedFeeders = feeders || [];
          return this.solarInsightsService.getAllCachedFeeders().pipe(
            takeUntil(this.destroyed),
            switchMap((allFeeders: SolarInsight[]) => {
              this.allFeeders = allFeeders || [];
              this.setMinAndMax();
              this.sliderDisabled = false;
              return feeders && feeders.length > 0 ? feeders : allFeeders;
            }),
          );
        }),
      )
      .subscribe(() => {});
  }

  private setMinAndMax() {
    const metricValues = Array.from(
      this.selectedFeeders.map((feeder) => {
        return feeder.maxKwConnectedCapacity;
      }),
    );
    this.minValue = +Math.min(...metricValues);
    this.maxValue = +Math.max(...metricValues);

    const allMetricValues = Array.from(
      this.allFeeders.map((feeder) => {
        return feeder.maxKwConnectedCapacity;
      }),
    );
    const allMinValue = +Math.min(...allMetricValues);
    const allMaxValue = +Math.max(...allMetricValues);

    this.minReflection.next(this.minValue);
    this.maxReflection.next(this.maxValue);

    this.minAll.next(allMinValue);
    this.maxAll.next(allMaxValue);
  }

  updateSliderMin(event: Event) {
    const minInput = event.target as HTMLInputElement;
    // Guards from the component's unexpected behavior.
    if (+minInput.value > this.maxReflection.value) {
      return;
    }
    this.minReflection.next(+minInput.value);
    this.sliderChanged();
  }

  updateSliderMax(event: Event) {
    const maxInput = event.target as HTMLInputElement;
    // Guards from the component's unexpected behavior.
    if (+maxInput.value < this.minReflection.value) {
      return;
    }
    this.maxReflection.next(+maxInput.value);
    this.sliderChanged();
  }

  sliderChanged() {
    const min = this.minReflection.value;
    const max = this.maxReflection.value;
    const filteredFeeders = this.allFeeders.filter(
      (feeder) => feeder.maxKwConnectedCapacity >= min && feeder.maxKwConnectedCapacity <= max,
    );

    const feederNames = [
      ...filteredFeeders.map((feeder) => {
        return feeder?.aggregationType.value?.feederCode || '';
      }),
      ...Array.from(this?.globalFilters['Feeder Name']?.values() || []),
    ];

    const feederNamesSet: FilterMap = {
      'Feeder Name': new Set(feederNames),
    };

    this.updateFilters(ICP_LAYER_ID, feederNamesSet);
    this.updateFilters(SOLAR_INSIGHTS_LAYER_ID, feederNamesSet);
  }

  protected updateFilters(layerId: string, feederNamesSet: FilterMap) {
    this.layersFilterService.updateFilterMap(layerId, feederNamesSet, true);
  }

  protected getLegendStyle(): Record<string, string> {
    return {
      background: `linear-gradient(to right, ${COLOR_SCALE.join(',')}`,
    };
  }
}

/**
 * @fileoverview Map Layers Module.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {AnnotationFiltersModule} from '../annotation_filters/annotation_filters_module';
import {AssetImageFiltersModule} from '../asset_image_filters/asset_image_filters_module';
import {LayerFiltersModule} from '../layer_filters/layer_filters_module';
import {MapLayersLegendModule} from '../map_layers_legend/map_layers_legend_module';
import {PipesModule} from '../pipes/pipes_module';
import {RangeFilterModule} from '../range_filter/range_filter_module';
// import {AssetsService} from '../services/assets_service';
import {LayersService} from '../services/layers_service';
import {TagFiltersModule} from '../tag_filters/tag_filters_module';
// import {IconGenerator} from '../utils/icon_util';
import {ViewsModule} from '../views/views_module';
import {MapLayersComponent} from './map_layers_component';

@NgModule({
  declarations: [MapLayersComponent],
  imports: [
    AnnotationFiltersModule,
    AssetImageFiltersModule,
    CommonModule,
    FormsModule,
    LayerFiltersModule,
    MapLayersLegendModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    PipesModule,
    RangeFilterModule,
    RouterModule,
    TagFiltersModule,
    ViewsModule,
  ],
  exports: [MapLayersComponent],
  providers: [
    // AssetsService,
    // IconGenerator,
    LayersService,
  ],
})
export class MapLayersModule {}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * Component to represent matching result for the filter.
 */
@Component({
  selector: 'matching-result',
  templateUrl: './matching_result.ng.html',
  styleUrls: ['./matching_result.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchingResult {
  @Input() quantity!: number;
}

import {Observable, Subject, combineLatest, of} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {DEFECTS_LAYER_ID} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {GalleryService} from '../services/gallery_service';
import {PendingUploadService} from '../services/pending_upload_service';
import {PhotosService} from '../services/photos_service';
import {UploadService} from '../services/upload_service';
import {AnnotationEditorMode} from '../typings/annotations';
import {sortImageGroup} from '../utils/sort';

const TOAST_DURATION_MS = 2500;
const TS_QUERY_PARAM_KEY = 'ts';

/**
 * The page for displaying images and their metadata, such as annotations.
 */
@Component({
  templateUrl: 'gallery.ng.html',
  styleUrls: ['gallery.scss'],
  providers: [GalleryService],
})
export class GalleryPage implements OnInit, OnDestroy {
  layerType: Layer_LayerType | null = null;
  selectedImage: Image | null = null;
  totalImageCount = 0;

  imageId = '';
  sourceUrl = '';
  opened = true;
  loading = false;
  imageIdsWithLidar = new Set<string>();
  // Indicator that the image comes from pending store - will be true in the
  // case when user tries to add annotations to not yet uploaded image.
  isNewUpload = false;
  destroyed = new Subject<void>();

  showAssetTimeline = false;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly configService: ConfigService,
    private readonly galleryService: GalleryService,
    private readonly uploadService: UploadService,
    private readonly pendingUploadService: PendingUploadService,
    private readonly photosService: PhotosService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    // This route fires one time and saves the source URL.
    this.route.queryParamMap
      .pipe(
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.SOURCE_URL)),
        first(),
      )
      .subscribe((params: ParamMap) => {
        this.sourceUrl = params.get(QUERY_PARAMS.SOURCE_URL) || '';
      });

    // This route fires one time when the page first loads.
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.FEATURE_ID)),
        mergeMap((queryParamMap: ParamMap) => {
          this.loading = true;
          this.galleryService.setProperties(queryParamMap);
          this.showAssetTimeline =
            this.configService.assetTimelineEnabled &&
            this.galleryService.checkLayerSupportsAssetTimeline();
          return combineLatest([
            this.getSortedImages(),
            of(queryParamMap.get(QUERY_PARAMS.IMAGE_ID)),
          ]);
        }),
        finalize(() => {
          this.loading = false;
        }),
        first(),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: ([images, imageId]: [Image[], string | null]) => {
          this.galleryService.updateImages(images);
          this.galleryService.updateCanEditMetadata(
            this.galleryService.layerId === DEFECTS_LAYER_ID,
          );
          this.totalImageCount = images.length;

          // Select first feature image if no imageId and selection exists.
          if (!imageId && !this.selectedImage && images.length > 0) {
            this.galleryService.selectImage(images[0], this.sourceUrl);
          }

          // Prefetch LiDAR images
          this.galleryService.prefetchLidarImages(images);
        },
        error: () => {
          this.snackBar.open('There was a problem getting the feature.', '', {
            duration: TOAST_DURATION_MS,
          });
        },
      });

    // This route fires every time the image ID in the URL changes or if
    // the image TS is updated, which occurs when an image is modified.
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.IMAGE_ID)),
        tap(() => {
          this.isNewUpload = false;
          this.galleryService.setIsNewUpload(false);
        }),
        mergeMap((queryParamMap: ParamMap) => {
          const imageId = queryParamMap.get(QUERY_PARAMS.IMAGE_ID)!;
          this.imageId = imageId;
          // Check if the image comes from pending store - this will be the
          // case when user tries to add annotations to not yet uploaded
          // image.
          const pendingImage = this.pendingUploadService.getPendingImage(imageId);
          if (pendingImage) {
            this.isNewUpload = true;
            this.galleryService.setIsNewUpload(true);
            return of(pendingImage);
          }
          if (this.galleryService.imageById.has(imageId)) {
            return of(this.galleryService.imageById.get(imageId)!);
          }
          return this.photosService.getImage(imageId).pipe(first());
        }),
        takeUntil(this.destroyed),
      )
      .subscribe({
        next: (image: Image | null) => {
          if (!image) {
            this.snackBar.open("There isn't an image matching your request.", '', {
              duration: TOAST_DURATION_MS,
            });
            return;
          }
          this.galleryService.buildEditImageMetadataRoute();
          this.galleryService.updateSelectedImage(image);
          if (this.galleryService.images.getValue().length === 0) {
            this.galleryService.updateImages([image]);
          }
        },
        error: () => {
          this.snackBar.open('There was a problem getting the image.', '', {
            duration: TOAST_DURATION_MS,
          });
        },
      });

    // This route fires every time the edit param is set in the URL. This may occur while
    // navigating to annotations editor for images in the upload form.
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.EDIT)),
        takeUntil(this.destroyed),
      )
      .subscribe((params: ParamMap) => {
        const editMode = params.get(QUERY_PARAMS.EDIT);
        this.galleryService.setEditorMode(
          editMode === 'true' ? AnnotationEditorMode.DRAW : AnnotationEditorMode.OFF,
        );
      });
    this.galleryService.selectedImage
      .pipe(takeUntil(this.destroyed))
      .subscribe((selectedImage: Image | null) => {
        this.selectedImage = selectedImage;
      });

    this.galleryService.newImageSelected
      .pipe(takeUntil(this.destroyed))
      .subscribe((sourceUrl: string) => {
        this.routeToSelectedImage(sourceUrl);
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  routeToSelectedImage(sourceUrl: string) {
    if (!this.isNewUpload && !this.uploadService.isUploadDialogInterrupted()) {
      this.analyticsService.sendEvent(EventActionType.LIGHTBOX_OPEN, {
        event_category: EventCategoryType.IMAGE,
        event_label: 'Gallery page', // From whence the lightbox was opened.
      });

      const queryParams = {
        [TS_QUERY_PARAM_KEY]: new Date().valueOf(),
        [QUERY_PARAMS.SOURCE_URL]: sourceUrl || this.sourceUrl,
        [QUERY_PARAMS.IMAGE_ID]: this.selectedImage?.id || this.imageId,
        [QUERY_PARAMS.LAYER_ID]: this.galleryService.layerId,
        [QUERY_PARAMS.FEATURE_ID]: this.galleryService.featureId,
        [QUERY_PARAMS.HIDE_CAROUSEL]: this.galleryService.hideCarousel,
      };

      this.router.navigate([ROUTE.LIGHTBOX], {queryParams});
    }
  }

  selectImage(image: Image) {
    this.galleryService.selectImage(image, this.sourceUrl);
  }

  selectNextImage() {
    this.galleryService.selectNextImage(this.sourceUrl);
  }

  selectPreviousImage() {
    this.galleryService.selectPreviousImage(this.sourceUrl);
  }

  goBack() {
    this.router.navigateByUrl(this.sourceUrl || ROUTE.MAP);
  }

  private getSortedImages(): Observable<Image[]> {
    const featureImages = this.photosService.getFeatureImages(this.galleryService.featureId);
    if (!this.showAssetTimeline) {
      return featureImages.pipe(map((images: Image[]): Image[] => sortImageGroup(images)));
    }
    return featureImages.pipe(
      switchMap(
        (images: Image[]): Observable<Image[]> =>
          this.galleryService.getDefaultGroupedImages(images),
      ),
    );
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/comment.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * A user-created comment for an asset (for example: "There's a dog here.")
 *
 * @generated from message tapestry.gridaware.api.v1.Comment
 */
export const Comment = proto3.makeMessageType("tapestry.gridaware.api.v1.Comment", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "asset_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "created_at",
  kind: "message",
  T: Timestamp
}, {
  no: 4,
  name: "user",
  kind: "message",
  T: User
}, {
  no: 5,
  name: "content",
  kind: "message",
  T: CommentContent
}, {
  no: 6,
  name: "lifecycle_stage",
  kind: "enum",
  T: proto3.getEnumType(Comment_LifecycleStage)
}]);

/**
 * A lifecycle stage for a feature.
 *
 * @generated from enum tapestry.gridaware.api.v1.Comment.LifecycleStage
 */
export const Comment_LifecycleStage = proto3.makeEnum("tapestry.gridaware.api.v1.Comment.LifecycleStage", [{
  no: 0,
  name: "LIFECYCLE_STAGE_UNSPECIFIED"
}, {
  no: 1,
  name: "ACTIVE"
}, {
  no: 2,
  name: "SOFT_DELETED"
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.CommentContent
 */
export const CommentContent = proto3.makeMessageType("tapestry.gridaware.api.v1.CommentContent", () => [{
  no: 1,
  name: "body",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
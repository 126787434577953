<div class="complete-overlay offline">
  <div class="offline-content">
    <div class="offline-content-first">
      While offline, tags, location, and asset selection are unavailable.
    </div>
    <div>Don't forget to validate your uploads.</div>
  </div>
  <div class="offline-actions">
    <button mat-flat-button class="add-more-images" color="primary" (click)="resetInput()">
      Add images
    </button>
    <button mat-stroked-button color="primary" (click)="goToPendingUpload()">
      View pending uploads
    </button>
  </div>
</div>

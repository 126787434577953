<div class="annotation-panel" [ngClass]="{'asset-timeline-enabled': assetTimelineEnabled}">
  <div
    class="main-container"
    #imageContainer
    [ngStyle]="{
      cursor: editorMode !== EDITOR_MODE.PAN ? 'default' : 'pointer'
    }">
    <div class="wrapper" id="canvasContainer">
      <img
        #imageCanvas
        id="imageCanvas"
        [src]="showLidarImage ? lidarImageUrl : imageUrl"
        alt=""
        priority
        class="image"
        (load)="onImageLoad()" />
      <svg
        #editor
        id="editor"
        class="svg-panel"
        xmlns="http://www.w3.org/2000/svg"
        [attr.viewBox]="viewBox"
        [attr.stroke-width]="strokeWidth"
        [attr.stroke]="color"
        preserveAspectRatio="xMinYMin meet">
        <style>
          rect {
            fill: transparent;
            cursor: move;
            pointer-events: stroke;
          }
          rect:focus {
            stroke: #f6e54c;
            outline: none;
          }
          .selected {
            stroke: #f6e54c;
            outline: none;
          }
          .defect {
            stroke: #b3261e;
          }
          .out-of-focus {
            opacity: 40%;
          }
          .hidden {
            display: none;
          }
        </style>
      </svg>
    </div>
  </div>
  @if (editorMode === EDITOR_MODE.OFF) {
    <div class="bottom-left-controls" [ngClass]="{'carousel-shown': showCarousel}">
      @if (lidarEnabled) {
        <div class="layers-container">
          @if (showLayers) {
            <div class="layers">
              @if (lidarExistsById.has(imageId)) {
                <div
                  class="layer lidar"
                  [ngClass]="{selected: showLidarImage}"
                  (click)="this.updateImage(true)">
                  <button mat-icon-button class="box" i18n-aria-label aria-label="View LiDAR Image">
                    <mat-icon>camera</mat-icon>
                  </button>
                  <span class="text">LiDAR</span>
                </div>
              }
              <div
                class="layer image"
                (click)="this.updateImage()"
                [ngClass]="{selected: !showLidarImage}">
                <button mat-icon-button class="box" i18n-aria-label aria-label="View Image">
                  <mat-icon>image</mat-icon>
                </button>
                <span class="text">Image</span>
              </div>
            </div>
          }
        </div>
      }
      @if (showLayersContainer) {
        <div class="carousel-actions actions-container">
          @if (lidarEnabled) {
            <button
              mat-icon-button
              class="action layers-action"
              [ngClass]="{'show-layers': showLayers}"
              (click)="showLayers = !showLayers"
              aria-label="Toggle layers"
              i18n-aria-label>
              <mat-icon>layers</mat-icon>
            </button>
          }
        </div>
      }
      @if (!carouselEnabled && showNavActions) {
        <div class="image-nav-actions actions-container" (click)="$event.stopPropagation()">
          <mat-icon class="arrow" [matTooltip]="'Previous image'" (click)="onPrevious.emit()">
            arrow_left
          </mat-icon>
          <div class="image-count">
            <!-- The one is being added to the index of the image so that e.g. 0/4 shows up as 1/4 -->
            {{ selectedImageIndex + 1 }} / {{ totalImageCount }}
          </div>
          <mat-icon class="arrow" [matTooltip]="'Next image'" (click)="onNext.emit()">
            arrow_right
          </mat-icon>
        </div>
      }
      @if (carouselEnabled) {
        <div class="carousel-actions actions-container">
          <button
            class="action toggle-action"
            [ngClass]="{
              'carousel-shown': showCarousel
            }"
            mat-icon-button
            aria-label="Toggle carousel"
            i18n-aria-label
            (click)="toggleCarousel()">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
      }
    </div>
  }
  <div class="bottom-right-controls" [ngClass]="{'carousel-shown': showCarousel}">
    @if (showCompass && bearing !== 0) {
      <div class="orientation-info-container">
        <svg
          id="compass"
          width="50"
          height="50"
          viewBox="-55 -55 110 110"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="0" cy="0" r="53" fill="#202124" />
          <text id="n" x="0" y="-30" font-size="25" text-anchor="middle" fill="white">N</text>
          <text id="s" x="0" y="48" font-size="25" text-anchor="middle" fill="white">S</text>
          <text id="w" x="-40" y="12" font-size="25" text-anchor="middle" fill="white">W</text>
          <text id="e" x="40" y="12" font-size="25" text-anchor="middle" fill="white">E</text>
          <g id="arrows">
            <path d="M 0,0 L -10,0 L 0,25 L 10,0 Z" fill="#C2C2C2" />
            <path d="M 0,0 L 10,0 L 0,-25 L -10,0 Z" fill="#EB2924" />
            <circle cx="0" cy="0" r="5" fill="#202124" />
          </g>
        </svg>
      </div>
    }
    <div class="actions-container">
      <button
        mat-icon-button
        class="action zoom-action"
        (click)="zoomIn()"
        [disabled]="currentScale >= maxScale"
        aria-label="Zoom in"
        i18n-aria-label>
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        class="action zoom-action"
        (click)="resetZoomState()"
        [disabled]="currentScale <= minScale"
        aria-label="Reset zoom"
        i18n-aria-label>
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button
        mat-icon-button
        class="action zoom-action"
        (click)="zoomOut()"
        [disabled]="currentScale <= minScale"
        aria-label="Zoom out"
        i18n-aria-label>
        <mat-icon>remove</mat-icon>
      </button>
    </div>
  </div>
  @if (isImageLoaded) {
    <div class="labels-overlay">
      <ng-template #labelsContainer></ng-template>
    </div>
  }
</div>

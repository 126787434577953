import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {InspectionStatus as Status} from '../constants/properties';
import {FeaturesService} from '../services/features_service';
import {GlobalFormPropertyName} from '../typings/upload';
import {getPropertyValue} from '../utils/feature';

/** Default status text if no status is found in the properties. */
const INSPECTION_STATUS_UNKNOWN = 'Unknown';

/**
 * Component for inspection status.
 *
 * A status is stored in the feature properties with the key
 * `GlobalFormPropertyName.INSPECTION_STATUS`.
 *
 * This component uses updateFeatureProperties() RPC for updating the selected
 * status.
 */
@Component({
  selector: 'inspection-status',
  templateUrl: './inspection_status.ng.html',
  styleUrls: ['./inspection_status.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InspectionStatus {
  /** ID of the feature associated with the status. */
  @Input() featureId = '';

  /** Properties of the feature. */
  @Input()
  set properties(props: Property[]) {
    this.status =
      getPropertyValue(GlobalFormPropertyName.INSPECTION_STATUS, props) ||
      INSPECTION_STATUS_UNKNOWN;
  }

  /** Current status displayed on the UI. */
  status = INSPECTION_STATUS_UNKNOWN;

  /** All supported statuses displayed in the drop-down menu. */
  inspectionStatuses = Status;

  /**
   * Indicates either an RPC call to change the status is in progress or not.
   */
  updateIsInProgress = false;

  constructor(
    private readonly featuresService: FeaturesService,
    private readonly snackBar: MatSnackBar,
  ) {}

  /**
   * Checks if the provided status match the current status of the component.
   */
  isCurrentStatus(status: string): boolean {
    return this.status === status;
  }

  /** Called when user clicks on a menu item with a `status`. */
  onMenuItemClicked(status: string) {
    if (this.status === status) {
      return;
    }
    this.updateIsInProgress = true;
    const inspectionStatusProperty = new Property({
      key: GlobalFormPropertyName.INSPECTION_STATUS,
      propertyValue: {
        case: 'value',
        value: status,
      },
    });

    this.featuresService.updateProperties(this.featureId, [inspectionStatusProperty]).subscribe(
      () => {
        this.updateIsInProgress = false;
        this.status = status;
      },
      () => {
        this.updateIsInProgress = false;
        this.snackBar.open('Unable to change inspection status. Try to change it later.', 'Close', {
          duration: 5000,
        });
      },
    );
  }
}

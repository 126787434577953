import {BehaviorSubject, Subject, takeUntil} from 'rxjs';

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy} from '@angular/core';

import {
  ExifMetadata_Projection,
  Image,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {PhotosService} from '../services/photos_service';
import {ImageUrlOptions, buildImageUrlWithOptions} from '../utils/image';

/**
 * Component for hosting a thumbnail image.
 */
@Component({
  selector: 'image-thumbnail',
  templateUrl: 'image_thumbnail.ng.html',
  styleUrls: ['image_thumbnail.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageThumbnail implements OnChanges, OnDestroy {
  @Input() image!: Image;
  // URL param options supported by go/fife-urls#url-options and go/gmrs.
  @Input() imageUrlOptions: ImageUrlOptions = {
    height: 200,
    width: 472,
  };

  SPHERICAL_PANO = ExifMetadata_Projection.SPHERICAL_PANO;
  style = new BehaviorSubject<{[property: string]: string}>({});

  private readonly destroyed = new Subject<void>();

  constructor(private readonly photosService: PhotosService) {}

  /**
   * Check to see if the photo property has changed. If it has, update style.
   */
  ngOnChanges() {
    buildImageUrlWithOptions(this.image, this.imageUrlOptions)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (url) => {
          this.style.next({
            'background-image': `url(${url})`,
          });
        },
        error: (error) => {
          throw new Error(error);
        },
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  /**
   * Get image rotation so the image can be positioned correctly.
   * @param image - an image object that includes the image orientation.
   * @return a string representing orientation (e.g. rotate(90deg))
   */
  getRotation(image: Image) {
    return this.photosService.getRotationFromOrientation(image.exifMetadata!.orientation);
  }
}

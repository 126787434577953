<mat-dialog-content class="container">
  <h2 class="export-options">Export options</h2>
  <mat-form-field class="field" appearance="outline">
    <mat-label>Select columns to export</mat-label>
    <mat-select #select multiple [(value)]="data.selectedColumnNames">
      <mat-option
        class="select-all"
        [value]="selectAll"
        (onSelectionChange)="toggleSelectAll($event.source, select)">
        {{ selectAll }}
      </mat-option>
      @for (column of data.allColumnNames; track column) {
        <mat-option [value]="column">
          {{ column }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  @if (data.showFeaturesPerPage) {
    <mat-form-field class="field" appearance="outline">
      <mat-label>Select features per page</mat-label>
      <mat-select [(value)]="selectedFeaturesPerPage">
        @for (featuresPerPage of allFeaturesPerPage; track featuresPerPage) {
          <mat-option [value]="featuresPerPage">
            {{ featuresPerPage }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (data.showIncludeLink) {
    <div class="include-link">
      <mat-checkbox
        [checked]="includeLinkChecked"
        (change)="includeLinkChecked = !includeLinkChecked">
        Include link
      </mat-checkbox>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button class="cancel" mat-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button mat-button mat-dialog-close color="primary" (click)="closeDialog()">Export</button>
</mat-dialog-actions>

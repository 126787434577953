// TODO(b/204386628): Cover with tests.

/**
 * Divider for tokens in the query string.
 */
const TOKENS_SPLITTER = /[^a-zA-Z\d]+/;

/**
 * Splits the given text into tokes.
 */
export function getTokens(text: string): string[] {
  if (!text) {
    return [];
  }
  return text
    .split(TOKENS_SPLITTER)
    .filter((t: string) => t !== '')
    .map((t: string) => t.toLowerCase());
}

/**
 * Checks whether the given text contains all the tokens or not.
 */
export function stringContainsAllTokens(text: string, tokens: string[]): boolean {
  const textTokens = new Set(getTokens(text));
  for (const t of tokens) {
    if (!textTokens.has(t)) {
      return false;
    }
  }
  return true;
}

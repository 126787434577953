import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

/**
 * Waits for signal to emit, then subscribes to the source observable.
 * Used when you want to wait at some point in an observable stream for a
 * different stream to emit.
 * Found here:
 * https://stackoverflow.com/questions/30519645/how-to-make-one-observable-sequence-wait-for-another-to-complete-before-emitting
 *
 * Example usage:
 * Stream A fetches data through an http request, prcesses that data, then
 * displays it on a map. Before displaying the data on the map, the map needs
 * to have loaded.
 * streamA.pipe(
 *   httpRequest,
 *   processData,
 *   waitFor(mapLoadedEmit)
 *  )
 * .subscribe(data => {
 *   displayOnMap(data);
 * }
 */
export function waitFor<T>(signal: Observable<unknown>) {
  return (source: Observable<T>) =>
    new Observable<T>((observer) =>
      signal.pipe(take(1)).subscribe(() => {
        source.subscribe(observer);
      }),
    );
}

import {BehaviorSubject, Observable, Subject, forkJoin, of} from 'rxjs';
import {catchError, filter, first, map, take, takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {ASSETS_LAYER_ID, AUTOTOP_LAYER_ID, DEFECTS_LAYER_ID} from '../../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../../constants/paths';
import {ConfigService} from '../../services/config_service';
import {FeaturesService} from '../../services/features_service';
import {GalleryService} from '../../services/gallery_service';
import {MapService} from '../../services/map_service';
import {PhotosService} from '../../services/photos_service';
import {MAX_IMAGE_HEIGHT_PX} from '../../utils/image';
import {isPathContainsLayer, isTablePath} from '../../utils/path';

const RETURN_TO_MAP_TEXT = 'Return to map';
const RETURN_TO_TABLE_TEXT = 'Return to table';

/**
 * Component for rendering an image's details for the gallery view.
 */
@Component({
  selector: 'gallery-info',
  templateUrl: 'gallery_info.ng.html',
  styleUrls: ['gallery_info.scss'],
})
export class GalleryInfo implements OnInit, OnDestroy {
  images: Image[] = [];
  layerType: typeof Layer_LayerType | null = null;
  selectedImage: Image | null = null;

  // Whether the metadata can be edited. If true, an icon will be visible that
  // routes to the upload page.
  canEditMetadata = false;
  // The route for editing metadata.
  editMetadataRoute = '';
  returnToText = RETURN_TO_MAP_TEXT;
  opened = true;
  sourceUrl = '';
  loading = false;
  // The default thumbnail image appears low quality so reuse the higher
  // quality lightbox image, which hopefully is already loaded or cached.
  thumbnailUrlOptions = `=h${MAX_IMAGE_HEIGHT_PX}`;

  // Indicates whether the image has not yet been uploaded and instead comes
  // from the pending store. This is the case when user tries to edit the not
  // yet uploaded image as part of upload process.
  isNewUpload = false;

  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly configService: ConfigService,
    private readonly featuresService: FeaturesService,
    private readonly galleryService: GalleryService,
    private readonly mapService: MapService,
    private readonly photosService: PhotosService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    // This route fires one time and saves the source URL.
    this.route.queryParamMap
      .pipe(
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.SOURCE_URL)),
        map((queryParamMap: ParamMap) => {
          return queryParamMap.get(QUERY_PARAMS.SOURCE_URL)!;
        }),
        first(),
      )
      .subscribe((sourceUrl: string) => {
        this.sourceUrl = sourceUrl;
        if (isTablePath(sourceUrl)) {
          this.returnToText = RETURN_TO_TABLE_TEXT;
        }
      });
    this.galleryService.images.pipe(takeUntil(this.destroyed)).subscribe((images: Image[]) => {
      this.images = images;
    });

    this.galleryService.selectedImage
      .pipe(takeUntil(this.destroyed))
      .subscribe((image: Image | null) => {
        this.selectedImage = image;
      });

    this.galleryService.canEditMetadata
      .pipe(takeUntil(this.destroyed))
      .subscribe((canEditMetadata: boolean) => {
        this.canEditMetadata = canEditMetadata;
      });

    this.galleryService.editMetadataRoute
      .pipe(takeUntil(this.destroyed))
      .subscribe((editMetadataRoute: string) => {
        this.editMetadataRoute = editMetadataRoute;
      });

    this.galleryService
      .getIsNewUpload()
      .pipe(takeUntil(this.destroyed))
      .subscribe((isNewUpload: boolean) => {
        this.isNewUpload = isNewUpload;
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  selectImage(image: Image) {
    this.galleryService.selectImage(image, this.sourceUrl);
  }

  deleteImage(image: Image) {
    this.refreshFeature().pipe(take(1), takeUntil(this.destroyed)).subscribe();
    const images = [...this.images].filter((currentImage: Image) => currentImage.id !== image.id);
    if (images.length === 0) {
      this.mapService.removeMarker(this.galleryService.featureId);
      this.goBack();
      return;
    }
    this.galleryService.selectNextImage(this.sourceUrl);
    this.galleryService.updateImages(images);
  }

  private refreshFeature(): Observable<null> {
    if (!this.galleryService.featureId) {
      return of(null);
    }

    const updates = [];
    updates.push(new BehaviorSubject<null>(null));
    updates.push(
      this.featuresService
        .getFeature(this.galleryService.layerId, this.galleryService.featureId, true)
        // Previously, this would call an assets-specific API, and we caught
        // errors in case the feature was not actually an asset. Perhaps this
        // catchError isn't needed anymore, but it seems safer to leave in
        // place than to remove.
        .pipe(catchError(() => of(null))),
    );
    updates.push(this.photosService.getFeatureImages(this.galleryService.featureId, true));
    return forkJoin(updates).pipe(map(() => null));
  }

  updateImage(image: Image) {
    const clonedImage = image.clone();
    this.galleryService.imageById?.set(image.id, clonedImage);
    const images = [...this.images];
    images[this.galleryService.indexById.get(clonedImage.id)!] = clonedImage;
    this.galleryService.updateImages(images);
    this.selectImage(clonedImage);
  }

  goBack() {
    const routePath = isTablePath(this.sourceUrl) ? ROUTE.TABLE : ROUTE.MAP;
    this.router.navigate([
      routePath,
      this.composeLayerId(routePath),
      this.galleryService.featureId,
    ]);
  }

  private composeLayerId(routePath: string): string {
    if (isPathContainsLayer(routePath, ROUTE.ASSETS, this.sourceUrl)) {
      return ASSETS_LAYER_ID;
    } else if (isPathContainsLayer(routePath, ROUTE.AUTOTOP, this.sourceUrl)) {
      return AUTOTOP_LAYER_ID;
    } else {
      return DEFECTS_LAYER_ID;
    }
  }
}

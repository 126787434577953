import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';

import {BreadcrumbNavigationModule} from '../breadcrumb_navigation/breadcrumb_navigation_module';
import {PipesModule} from '../pipes/pipes_module';
import {FeederDetailsPage} from './feeder_details';

@NgModule({
  declarations: [FeederDetailsPage],
  imports: [
    BreadcrumbNavigationModule,
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    PipesModule,
    RouterModule,
  ],
  exports: [FeederDetailsPage],
})
export class FeederDetailsModule {}

import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {FilterMap} from '../typings/filter';
import {FeaturesService, QueryFeaturesCount} from './features_service';

interface FeaturesCount {
  [layerKey: string]: number;
}

/**
 * The purpose of the service to assist with performance and store features'
 * count for each of the layer.
 */
@Injectable({providedIn: 'root'})
export class FeaturesCountService {
  featureCountByLayerId: {[layerId: string]: QueryFeaturesCount} = {};
  // Emits total count of features when filters updated.
  private readonly totalCountByLayer = new BehaviorSubject<FeaturesCount>({});

  constructor(private readonly featuresService: FeaturesService) {}

  // Save features count for particular layer.
  saveFeaturesCount(layerId: string, count: QueryFeaturesCount) {
    this.featureCountByLayerId[layerId] = count;
  }

  // Fetch or return already stored features' count for particular layer with
  // provided filters.
  getFeaturesCountByLayerId(
    layerId: string,
    filters: FilterMap,
    forceFetch = false,
  ): Observable<QueryFeaturesCount> {
    if (forceFetch) {
      return this.fetchFeaturesCount(layerId, filters);
    }
    const featureCountByLayerId = this.featureCountByLayerId[layerId];
    return featureCountByLayerId
      ? of(featureCountByLayerId)
      : this.fetchFeaturesCount(layerId, filters);
  }

  // Fetch features count for particular layer with provided filters.
  fetchFeaturesCount(layerId: string, filters: FilterMap): Observable<QueryFeaturesCount> {
    return this.featuresService.getQueryFeaturesCount(layerId, filters).pipe(
      tap((count: QueryFeaturesCount) => {
        this.saveFeaturesCount(layerId, count);
      }),
    );
  }

  // Calculate total count with or without inactive results.
  getTotalCount(
    {active, inactive}: QueryFeaturesCount = {active: 0, inactive: 0},
    includeInactive: boolean,
  ): number {
    return includeInactive ? active + inactive : active;
  }

  /**
   * Updates Observable with the total feature count for a layer.
   */
  setTotalCountForLayer(layerId: string, totalCount: number) {
    this.totalCountByLayer.next({
      ...this.totalCountByLayer.value,
      [layerId]: totalCount,
    });
  }

  /**
   * Returns Observable emitting values of the total features for a layer.
   */
  getTotalCountForLayer(layerId: string): Observable<number> {
    return this.totalCountByLayer.pipe(map((totalCount: FeaturesCount) => totalCount[layerId]));
  }
}

<ng-container>
  <div class="tag-filters-title">Tag filters</div>
  <mat-chip-listbox [selectable]="false">
    @for (tag of tags; track tag) {
      <div class="chip-wrapper">
        <mat-chip-option class="chip" [removable]="true" (removed)="onTagRemoved(tag)">
          <div class="chip-name">{{ tag }}</div>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
      </div>
    }
  </mat-chip-listbox>
  @if (editing) {
    <multiselect-autocomplete
      placeholderText="Filter by tags"
      class="tag-filter"
      [initialSelectedOptions]="getTagsArray()"
      [allowEmpty]="true"
      [options]="allTags"
      [maxOptionsToShow]="MAX_OPTIONS_TO_SHOW"
      [selectAllOptionName]="'Tags'"
      (apply)="onApplied()"
      (cancel)="onCancel()"
      (selectedOptionsChanged)="onSelectedOptionsChanged($event)">
    </multiselect-autocomplete>
  } @else {
    <button
      [disabled]="allTags.length === 0"
      class="add-filter-button"
      mat-flat-button
      color="primary"
      (click)="onFilterButtonClicked()">
      Add tag filter
    </button>
  }
</ng-container>

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

/** Component for rendering the dialog displayed on image deletion. */
@Component({
  selector: 'delete-image-confirmation-dialog',
  templateUrl: './delete_image_confirmation_dialog.ng.html',
  styleUrls: ['./delete_image_confirmation_dialog.scss'],
})
export class DeleteImageConfirmationDialog {
  constructor(readonly dialogRef: MatDialogRef<DeleteImageConfirmationDialog>) {}
}

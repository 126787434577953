import {BehaviorSubject, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {ICP_LAYER_ID, SOLAR_INSIGHTS_LAYER_ID} from '../constants/layer';
import {FEATURE_PARAM_KEY, LAYER_PARAM_KEY, ROUTE} from '../constants/paths';
import {ConfigService} from '../services/config_service';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {TableService} from '../services/table_service';
import {SidepanelLayout} from '../sidepanel_layout/sidepanel_layout';
import {VisibilityByLayerUpdate} from '../typings/map';

/**
 * Page that displays the data of the selected layers in a tabular format.
 */
@Component({
  templateUrl: 'solar_table.ng.html',
  styleUrls: ['solar_table.scss'],
})
export class SolarTablePage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SidepanelLayout, {static: false})
  sidepanel: SidepanelLayout | null = null;

  isBannerVisible = false;

  readonly isSidePanelVisible = new BehaviorSubject(true);
  readonly selectedLayerID = new BehaviorSubject<string>('');

  private scrollId = '';
  private selectedFeatureId = '';
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly configService: ConfigService,
    private readonly mapService: MapService,
    private readonly router: Router,
    private readonly tableService: TableService,
    private readonly layersService: LayersService,
  ) {}

  ngOnInit() {
    // Navigate to map page if feature flag is not set to true.
    if (!this.configService.solar2Enabled) {
      this.navigateToMap();
    }
    this.layersService.getAllLayersMetadata().pipe(take(1), takeUntil(this.destroyed)).subscribe();
    this.listenForLayerVisibilityUpdates();
  }

  ngOnDestroy() {
    this.tableService.saveSelectedColumnState();
    this.destroyed.next();
  }

  ngAfterViewInit() {
    this.scrollId = this.route.firstChild?.snapshot?.params[LAYER_PARAM_KEY];
    this.selectedFeatureId = this.route.firstChild?.snapshot?.params[FEATURE_PARAM_KEY];
    this.scrollToAnchor();
  }

  showBanner() {
    this.isBannerVisible = true;
    this.changeDetectionRef.markForCheck();
  }

  dismissBanner() {
    this.isBannerVisible = false;
    this.changeDetectionRef.markForCheck();
  }

  navigateToMap() {
    this.router.navigateByUrl(ROUTE.MAP);
  }

  onTableUpdate(layerId: string) {
    if (!this.scrollId || this.scrollId !== layerId) {
      return;
    }
    this.scrollToAnchor();
  }

  getSelectedFeatureId(layerId: string): string {
    return layerId === this.scrollId ? this.selectedFeatureId : '';
  }

  // Update the displayed data according to the layers toggle state.
  private listenForLayerVisibilityUpdates() {
    this.mapService
      .onLayerVisibilityChanged()
      .pipe(
        takeUntil(this.destroyed),
        map((visibilityByLayerUpdate: VisibilityByLayerUpdate): string[] =>
          [...visibilityByLayerUpdate.visibilityByLayerId]
            .filter((layerVisibility: [string, boolean]) => layerVisibility[1])
            .map((layerVisibility: [string, boolean]): string => layerVisibility[0]),
        ),
      )
      .subscribe((layerIds: string[]) => {
        layerIds.find((id) => {
          if (id === ICP_LAYER_ID || id === SOLAR_INSIGHTS_LAYER_ID) {
            this.selectedLayerID.next(id);
          }
        });
      });
  }

  private scrollToAnchor() {
    if (!this.scrollId) {
      return;
    }

    setTimeout(() => {
      const element = document.querySelector(`#${this.scrollId}`);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }, 100);
  }
}

@if (isSidePanelVisible | async) {
  <sidepanel-layout>
    <div header></div>
    <div sidepanel>
      <router-outlet></router-outlet>
    </div>
    <div main class="multi-table-page container">
      <div class="map-table-toggle-container mat-elevation-z2">
        <map-table-toggle></map-table-toggle>
      </div>
      @if (selectedLayerID | async; as selectedLayerID) {
        <div class="table-panels-container">
          <solar-data-table
            [attr.id]="selectedLayerID"
            [layerId]="selectedLayerID"
            [selectedFeatureId]="getSelectedFeatureId(selectedLayerID)"
            (onContentChanged)="onTableUpdate(selectedLayerID)"
            class="solar-table scroll-container">
          </solar-data-table>
        </div>
      }
    </div>
  </sidepanel-layout>
}

import {BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, merge, of} from 'rxjs';
import {combineLatestWith, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Layer_LayerType as LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {SolarInsight as Feeder} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/solar_insight_pb';

import {FEEDER_NAME} from '../constants/asset';
import {SOLAR_INSIGHTS_LAYER_ID} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {FeaturesCountService} from '../services/features_count_service';
import {FeaturesPaginationService, Page} from '../services/features_pagination_service';
import {QueryFeaturesCount} from '../services/features_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {LayersService} from '../services/layers_service';
import {PaginationCachingService} from '../services/pagination_caching_service';
import {FeederMetrics, SolarInsightsService} from '../services/solar_insights_service';
import {TableService} from '../services/table_service';
import {FilterUpdate} from '../typings/filter';
import {FilterMap, LayerFilters} from '../typings/filter';

/**
 * These columns correspond to fields on the Solar 2 proto that should
 * be displayed as columns. These static columns are opposed to the dynamic
 * columns that are generated from the features properties.
 */
export enum StaticFeatureColumn {
  FEEDER_CODE = 'Feeder id',
  CARBON_OFFSET_FACTOR_PER_MWH = 'Carbon offset factor (kg/MWh)',
  MAX_YEARLY_KWH_POTENTIAL_AC = 'Max yearly kwh potential ac',
  MAX_M2_PANEL_AREA = 'Max panel area (square meters)',
  AVG_ROOF_MAX_SUNSHINE_HRS_PER_M2 = 'Max sunshine hours per year',
}

/**
 * The suggested maximum number of results for efficient table performance.
 */
const TABLE_QUERY_MAX_SUGGESTED_RESULTS = 50000;

const EDIT_TAGS_COLUMN_ID = 'EDIT_TAGS_COLUMN_ID';

/**
 * Component for displaying rows of data.
 */
@Component({
  selector: 'solar-data-table',
  templateUrl: 'solar_data_table.ng.html',
  styleUrls: ['solar_data_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FeaturesPaginationService],
  encapsulation: ViewEncapsulation.None,
})
export class SolarDataTable implements OnInit, AfterViewInit, OnDestroy {
  // ID of the layer to display the data for.
  @Input({required: true}) layerId = '';

  // ID of the pre-selected feature if any.
  @Input() selectedFeatureId = '';

  // Updates on when the requested rows exceed the recommended range.
  @Output() readonly maxEntriesReached = new EventEmitter();

  // Updates on when the table completes rendering a set of data rows.
  @Output() readonly onContentChanged = new EventEmitter<void>();

  @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  // Default width of the column select overlay panel.
  columnSelectPanelWidth = '280px';
  layerName = '';
  layerType: LayerType | null = null;
  allColumnNames: string[] = [];
  selectedColumnNames: string[] = [];
  editTagsColumnId = EDIT_TAGS_COLUMN_ID;
  featureToTagEditById = new Map<string, Feature>();
  // Needed in order to maintain a different order between the select-menu
  // dropdown and the table.
  selectedColumnNamesForDropdown: string[] = [];
  sortedColumnNamesForDropdown: string[] = [];
  // Alias to the enum.
  readonly staticFeatureColumn = StaticFeatureColumn;
  selectedRow: Feature | null = null;
  excludedStaticFeatureColumns = new Set<string>();
  relatedLayerIdByName = new Map<string, string>();

  protected readonly itemsPerPageOptions = [10, 20, 50];

  page: Page = {
    pageIndex: 0,
    pageSize: this.itemsPerPageOptions[0],
  };

  tableData = new MatTableDataSource<Feeder>([]);
  features: Feeder[] = [];
  private readonly feederName = FEEDER_NAME;
  protected readonly FEEDER_CODE = FeederMetrics.FEEDER_CODE;
  private sortableColumnNames = new Set<string>();

  private readonly viewReady = new ReplaySubject<void>(1);
  private readonly destroyed = new Subject<void>();

  readonly featuresLoading = new BehaviorSubject<boolean>(false);
  readonly countLoading = new BehaviorSubject<boolean>(false);
  readonly featuresCount = new BehaviorSubject<number>(0);
  // To persist a sorted table column. Updated from Solar Insights Service.
  readonly sortedByColumn = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly featuresCountService: FeaturesCountService,
    private readonly featuresPaginationService: FeaturesPaginationService,
    private readonly layersFilterService: LayersFilterService,
    private readonly layersService: LayersService,
    private readonly paginationCachingService: PaginationCachingService,
    private readonly router: Router,
    private readonly tableService: TableService,
    private readonly solarInsightsService: SolarInsightsService,
  ) {}

  ngOnInit() {
    this.fetchAndRender();
    this.layersService
      .onLayersReady()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.layerName = SOLAR_INSIGHTS_LAYER_ID;
        this.layerType = this.layersService.getLayerType(this.layerId);
      });
    this.paginationCachingService
      .getSavedPaginationForLayer(this.layerId)
      .pipe(takeUntil(this.destroyed))
      .subscribe((page: Page | null) => {
        this.page = {
          pageIndex: page?.pageIndex || 0,
          pageSize: page?.pageSize || this.itemsPerPageOptions[0],
          length: page?.length || 0,
        };
      });
    this.layerFiltersUpdates()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        // Reset cache.
        this.paginationCachingService.setFeaturesForLayer(this.layerId, []);
        this.fetchAndRender();
      });
    this.featuresPaginationService
      .onDataFetchTriggered()
      .pipe(takeUntil(this.destroyed))
      .subscribe((featuresCount: number) => {
        if (featuresCount > TABLE_QUERY_MAX_SUGGESTED_RESULTS) {
          this.maxEntriesReached.emit();
        }
      });
  }

  ngAfterViewInit() {
    this.viewReady.next();
    this.onPaginationChange();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.saveSettings();
    this.saveTableData();
  }

  /**
   * Saves table columns and sorted selection in Solar Insights Service.
   */
  private saveTableData() {
    this.solarInsightsService.setTableColumns(this.selectedColumnNames);
    this.solarInsightsService.sortedByColumn.next(this.sortedByColumn.value);
  }

  onPaginationChange() {
    merge(this.paginator.page)
      .pipe(
        map((page: PageEvent) => {
          const {pageSize, length} = page;
          const isPageSizeChanged = this.page.pageSize !== pageSize;
          this.paginationCachingService.setPaginationForLayer(
            this.layerId,
            length,
            isPageSizeChanged ? 0 : page.pageIndex,
            pageSize,
          );
          return {...page, pageIndex: this.page.pageIndex};
        }),
        switchMap((): Observable<LayerFilters> => this.getLayerFilters(this.layerId)),
        takeUntil(this.destroyed),
      )
      .subscribe();
  }

  getAllFeatures(): Observable<Feeder[]> {
    return of(this.features);
  }

  getLayerFilters(layerId: string): Observable<LayerFilters> {
    return combineLatest([
      this.layersFilterService.getFilterMap(layerId).pipe(take(1)),
      this.layersFilterService.includeInactive(layerId).pipe(take(1)),
    ]).pipe(
      map(([filters, includeInactiveResults]: [FilterMap, boolean]) => {
        return {layerId, filters, includeInactiveResults};
      }),
    );
  }

  getFeaturesCount(layerFilters: LayerFilters): Observable<QueryFeaturesCount> {
    const {layerId, filters, includeInactiveResults} = layerFilters;
    this.countLoading.next(true);
    return this.featuresCountService.fetchFeaturesCount(layerId, filters).pipe(
      tap((queryFeaturesCount: QueryFeaturesCount) => {
        const count = this.featuresCountService.getTotalCount(
          queryFeaturesCount,
          includeInactiveResults,
        );

        this.featuresCount.next(count);
        this.countLoading.next(false);
      }),
    );
  }

  /**
   * Returns the count to use in the paginator.
   *
   * -   If the count is not yet known, or if the count from the
   *     server is known to be unreliable (e.g. because we already
   *     received more than that many values), returns Infinity.
   * -   If we have received all results from the server, returns
   *     the number received.
   * -   Otherwise, returns the GetQueryFeaturesCount result from
   *     the server.
   */
  getCountForPaginator(): number {
    const nextPageToken = this.paginationCachingService.getNextPageTokenForLayer(this.layerId);
    const cachedFeatures = this.paginationCachingService.getFeaturesForLayer(this.layerId);
    if (nextPageToken === '') {
      // We have reached the end of the list. What if we haven't received the
      // first response? Seems like it'd be better to show "many" (or loading or
      // something?) in the UI instead of 0.
      return cachedFeatures.length;
    }
    // There are results left - if the count from the server is loading or
    // lower than the actual count return an arbitrarily large value,
    // otherwise return the count from the server.
    if (this.countLoading.getValue() || this.featuresCount.getValue() < cachedFeatures.length) {
      return Infinity;
    }
    return this.featuresCount.getValue();
  }

  fetchAndRender() {
    // Save the current selected columns settings.
    this.saveSettings();
    this.sortedByColumn.next(this.solarInsightsService.sortedByColumn.value);

    this.getLayerFilters(this.layerId)
      .pipe(
        combineLatestWith(this.solarInsightsService.getAllCachedFeeders()),
        switchMap(([filters, allFeeders]: [LayerFilters, Feeder[]]) => {
          let selectedFeeders: Feeder[] = [];

          if (Object.keys(filters.filters).length === 0) {
            selectedFeeders = allFeeders;
          } else {
            selectedFeeders = allFeeders.filter((feeder: Feeder) => {
              const feederCode = feeder?.aggregationType.value?.feederCode;
              if (
                feederCode &&
                Array.from(filters.filters[this.feederName].values()).includes(feederCode)
              ) {
                return feeder;
              } else {
                return false;
              }
            });
          }

          this.getFeaturesCount(filters);
          return of(selectedFeeders);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((features: Feeder[]) => {
        this.features = features;
        const resetToFirstPage = !this.hasCachedFeatures();
        this.setColumnNames();
        this.setSelectedColumnNames();
        this.setDefaultSelectedColumnNames();
        this.sortFeatures(this.sortedByColumn.value);
        this.tableData = new MatTableDataSource<Feeder>(features);
        this.page.pageIndex = resetToFirstPage ? 0 : this.page.pageIndex;
      });
  }

  onColumnMenuOpen() {
    this.analyticsService.sendEvent(EventActionType.TABLE_CUSTOMIZED, {
      event_category: EventCategoryType.TABLE,
      event_label: `${this.layerName} table`,
    });
  }

  onSort(sort: Sort) {
    this.analyticsService.sendEvent(EventActionType.TABLE_SORTED, {
      event_category: EventCategoryType.TABLE,
      event_label: `Sort ${sort.active} column on ${this.layerName} table`,
    });
    this.featuresLoading.next(true);
    this.getAllFeatures()
      .pipe(
        take(1),
        tap(() => {
          this.featuresLoading.next(false);
          this.features = this.sortFeatures(sort)
            .slice(0, this.page.pageSize)
            .map((feature: Feeder) => feature);
          this.tableData = new MatTableDataSource<Feeder>(this.features);
          this.paginator.pageIndex = 0;
          this.paginationCachingService.setPaginationForLayer(
            this.layerId,
            this.features.length,
            this.paginator.pageIndex,
            this.page.pageSize,
          );
        }),
      )
      .subscribe();
  }

  sortFeatures({active, direction}: Sort): Feeder[] {
    const features = this.features;

    if (!direction) {
      return features;
    }

    features.sort((a, b) => {
      const cellA = this.getCell(active, a);
      const cellB = this.getCell(active, b);
      const compare = direction === 'asc' ? cellA < cellB : cellA > cellB;
      return compare ? -1 : 1;
    });

    this.sortedByColumn.next({active, direction});

    return features;
  }

  isSortable(name: string): boolean {
    return this.sortableColumnNames.has(name);
  }

  getCell(columnName: string, feature: Feeder): string | number {
    switch (columnName) {
      case FeederMetrics.FEEDER_CODE:
        return feature?.aggregationType.value?.feederCode || 0;
      case FeederMetrics.PERCENT_RESIDENTIAL:
        return feature?.percentResidential || 0;
      case FeederMetrics.PERCENT_COMMERCIAL:
        return feature?.percentCommercial || 0;
      case FeederMetrics.PERCENT_OTHER:
        return feature?.percentOther || 0;
      case FeederMetrics.MAX_CONNECTED_CAPACITY:
        return feature?.maxKwConnectedCapacity || 0;
      case FeederMetrics.AVG_MAX_KW_RESIDENTIAL:
        return feature?.avgMaxKwResidential || 0;
      case FeederMetrics.AVG_MAX_KW_COMMERCIAL:
        return feature?.avgMaxKwCommercial || 0;
      case FeederMetrics.MAX_YEARLY_KWH_POTENTIAL_AC:
        return feature?.maxYearlyKwhPotentialAc || 0;
      case FeederMetrics.MAX_M2_PANEL_AREA:
        return feature?.maxM2PanelArea || 0;
      case FeederMetrics.AVG_ROOF_MAX_SUNSHINE_HRS_PER_M2:
        return feature?.avgRoofMaxSunshineHrsPerM2 || 0;
      case FeederMetrics.NE_ONLY_KW_CONNECTED_CAPACITY:
        return feature?.neOnlyKwConnectedCapacity || 0;
      case FeederMetrics.NE_ONLY_AVG_KW_RESIDENTIAL:
        return feature?.neOnlyAvgKwResidential || 0;
      case FeederMetrics.NE_ONLY_AVG_KW_COMMERCIAL:
        return feature?.neOnlyAvgKwCommercial || 0;
      case FeederMetrics.NE_ONLY_YEARLY_KWH_POTENTIAL_AC:
        return feature?.neOnlyYearlyKwhPotentialAc || 0;
      case FeederMetrics.CARBON_OFFSET_FACTOR_PER_MWH:
        return feature?.carbonOffsetFactorPerMwh || 0;
      default:
        return 0;
    }
  }

  private setColumnNames() {
    const staticColumns = Object.values(FeederMetrics).filter(
      (columnName) => columnName !== FeederMetrics.DIVIDER,
    );

    this.sortableColumnNames = new Set([...staticColumns]);
    this.allColumnNames = [...new Set([...staticColumns])];
  }

  updateSelectedColumnNames(newColumns: string[]) {
    this.selectedColumnNames = this.sortSelectedColumnNamesForTable(newColumns);
    this.sortColumnNamesForDropdown();
  }

  /**
   * Sort the selected column names. This is the order that the columns will
   * appear in the table. This is different than the order (alphabetically) that
   * the column names appear in the select dropdown.
   */
  sortSelectedColumnNamesForTable(selectedColumnNames: string[]) {
    const selectedColumns = new Set(selectedColumnNames);
    return this.allColumnNames.filter((columnName) => selectedColumns.has(columnName));
  }

  sortColumnNamesForDropdown() {
    const selectedColumnNames = new Set(this.selectedColumnNames);
    const unselectedColumnNames = this.allColumnNames.filter(
      (columnName: string) => !selectedColumnNames.has(columnName),
    );
    this.sortedColumnNamesForDropdown = [
      ...[...this.selectedColumnNames].sort(caseInsensitiveAlphaSort),
      ...unselectedColumnNames.sort(caseInsensitiveAlphaSort),
    ];
  }

  /**
   * Sets the default columns to show but users can still open the top right
   * dropdown to see more columns.
   */
  private setDefaultSelectedColumnNames() {
    if (this.selectedColumnNames.length > 0) {
      return;
    }
    this.selectedColumnNames = [
      FeederMetrics.FEEDER_CODE,
      FeederMetrics.PERCENT_RESIDENTIAL,
      FeederMetrics.PERCENT_COMMERCIAL,
      FeederMetrics.MAX_CONNECTED_CAPACITY,
      FeederMetrics.MAX_YEARLY_KWH_POTENTIAL_AC,
      FeederMetrics.MAX_M2_PANEL_AREA,
      FeederMetrics.AVG_ROOF_MAX_SUNSHINE_HRS_PER_M2,
    ];
    this.selectedColumnNamesForDropdown = [...this.selectedColumnNames];
    this.sortColumnNamesForDropdown();
  }

  /**
   * Loads the selected columns from local storage and sets them. The selected
   * columns are the columns that will be rendered in the table.
   */
  private setSelectedColumnNames() {
    if (this.selectedColumnNames.length > 0) {
      return;
    }

    this.solarInsightsService
      .getTableColumns()
      .pipe(takeUntil(this.destroyed))
      .subscribe((columns: string[]) => {
        if (columns.length > 0) {
          this.selectedColumnNames = columns;
          this.selectedColumnNamesForDropdown = [...this.selectedColumnNames];
          this.sortColumnNamesForDropdown();
          this.sortFeatures(this.solarInsightsService.sortedByColumn.value);
        }
      });
  }

  /**
   * Add or remove a feature from bulk tag editing.
   */
  toggleFeatureFromTagEdit(shouldAdd: boolean, feature: Feature) {
    if (shouldAdd) {
      this.featureToTagEditById.set(feature.id, feature);
      return;
    }
    this.featureToTagEditById.delete(feature.id);
  }

  isSelectedForBulkEditTags(feature: Feature): boolean {
    return this.featureToTagEditById.has(feature.id);
  }

  private saveSettings() {
    if (this.selectedColumnNames.length === 0) {
      return;
    }
    this.tableService.setSelectedColumnsForLayer(this.layerId, this.selectedColumnNames);
  }

  private hasCachedFeatures(): boolean {
    return this.paginationCachingService.getFeaturesForLayer(this.layerId).length > 0;
  }

  private layerFiltersUpdates(): Observable<FilterUpdate> {
    return this.layersFilterService.layerFiltersUpdated().pipe(
      filter((update: FilterUpdate) => update.layerId === this.layerId),
      takeUntil(this.destroyed),
    );
  }

  onRowClick(row: Feeder) {
    const feederName: string | undefined = row.aggregationType.value?.feederCode;
    if (feederName && feederName !== '') {
      this.goToFeederView(encodeURIComponent(feederName));
    }
  }

  private goToFeederView(feederName: string) {
    this.router.navigate([ROUTE.FEEDER_DETAILS_TABLE, feederName], {
      queryParams: {
        [QUERY_PARAMS.FEATURE_ID]: this.selectedRow?.id,
        [QUERY_PARAMS.LAYER_ID]: this.layerId,
      },
    });
  }
}

function caseInsensitiveAlphaSort(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

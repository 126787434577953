import {Layer_LayerType as LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

/**
 * Map of label by related role.
 */
export const LABEL_BY_RELATED_ROLE = new Map([
  [RelatedFeatureRole.PARENT_ASSET, 'Associated assets'],
  [RelatedFeatureRole.CHILD_ASSET, 'Assets at this location'],
  [RelatedFeatureRole.PARENT_WORK_ORDER, 'Parent work orders'],
  [RelatedFeatureRole.CHILD_WORK_ORDER, 'Work orders'],
  [RelatedFeatureRole.CHILD_DEFECT, 'Images'],
  [RelatedFeatureRole.PARENT_DEFECT, 'Image groups'],
]);

/**
 * Map of layer type by its related role.
 */
export const LAYER_TYPE_BY_RELATED_ROLE = new Map([
  [RelatedFeatureRole.PARENT_ASSET, LayerType.ASSETS],
  [RelatedFeatureRole.CHILD_ASSET, LayerType.ASSETS],
  [RelatedFeatureRole.PARENT_WORK_ORDER, LayerType.NATIVE],
  [RelatedFeatureRole.CHILD_WORK_ORDER, LayerType.NATIVE],
  [RelatedFeatureRole.CHILD_DEFECT, LayerType.NATIVE],
  [RelatedFeatureRole.PARENT_DEFECT, LayerType.NATIVE],
  [RelatedFeatureRole.CHILD_IMAGE, LayerType.IMAGES],
]);

/**
 * Radius of the earth in miles.
 */
export const EARTH_RADIUS_MILES = 3958.8;

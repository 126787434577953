import {Subject, merge, of} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {FormControl} from '@angular/forms';

import {OfflineAssetsInfo, OfflineAssetsService} from '../../services/offline_assets_service';

/**
 * Needed so that the minifier minifies these properties in OnChanges.
 * Exporting for test.
 */
export declare interface PropertyChanges extends SimpleChanges {
  assetId: SimpleChange;
}

/** Fixed height of the scrollable items in pixels. */
const VIRTUAL_SCROLL_ITEM_HEIGHT = 50;

/** Maximum number of the items in the virtual scroll viewport. */
const VIRTUAL_SCROLL_MAX_ITEMS = 4;

interface AssetOption {
  key: string;
  value: string;
}

/**
 * Component for seaching among asset IDs pre-saved for offline scenarios.
 */
@Component({
  selector: 'offline-search',
  templateUrl: 'offline_search.ng.html',
  styleUrls: ['offline_search.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfflineSearch implements OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() assetId = '';
  @Output() readonly optionSelected = new EventEmitter<string>();

  readonly searchInputControl = new FormControl<string>('', {
    nonNullable: true,
  });
  virtualScrollViewportHeight: string;
  private allOptions: AssetOption[] = [];
  filteredOptions: AssetOption[] = [];

  private readonly destroyed = new Subject<void>();
  private readonly cancelAssetLookup = new Subject<void>();

  constructor(private readonly offlineAssetsService: OfflineAssetsService) {
    this.virtualScrollViewportHeight = this.getVirtualScrollViewportHeight(0);
  }

  ngOnInit() {
    this.offlineAssetsService
      .getAssets()
      .pipe(
        takeUntil(this.destroyed),
        catchError((error: Error) => {
          console.error(`Failed to load assets for offline scenario. ${error.message}.`);
          return of([]);
        }),
      )
      .subscribe((assets: OfflineAssetsInfo[]) => {
        // countBySearchName is a Map<search name, count>. It is used to
        // determine which search names are not unique so that an external
        // ID can be appended to the term eg, "search name (1234)".
        const countBySearchName = new Map<string, number>();
        for (const asset of assets) {
          const count = countBySearchName.get(asset.searchName) || 0;
          countBySearchName.set(asset.searchName, count + 1);
        }
        const results: AssetOption[] = [];
        for (const asset of assets) {
          results.push(createSearchTerm(asset, countBySearchName));
        }
        this.allOptions = [...results];
        this.filteredOptions = [...results];
        this.virtualScrollViewportHeight = this.getVirtualScrollViewportHeight(
          this.filteredOptions.length,
        );
      });

    this.searchInputControl.valueChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe((input: string) => {
        this.filteredOptions = this.allOptions.filter((option: AssetOption) =>
          option.value.toLowerCase().includes(input.toLowerCase()),
        );
        this.virtualScrollViewportHeight = this.getVirtualScrollViewportHeight(
          this.filteredOptions.length,
        );
      });
  }

  ngOnChanges(propertyChanges: PropertyChanges) {
    this.cancelAssetLookup.next();
    const assetId = propertyChanges?.assetId?.currentValue;
    if (!assetId) {
      return;
    }
    this.offlineAssetsService
      .getAssetByID(assetId)
      .pipe(takeUntil(merge(this.destroyed, this.cancelAssetLookup)))
      .subscribe({
        next: (asset: OfflineAssetsInfo | null) => {
          if (!asset) {
            console.error(`failed to find asset with internal ID "${assetId}"`);
            return;
          }
          this.searchInputControl.setValue(asset.searchName || asset.externalId);
        },
        error: (error: Error) => {
          console.error(`error looking up asset with internal ID "${assetId}": ${error.message}`);
        },
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private getVirtualScrollViewportHeight(itemsNumber: number): string {
    if (itemsNumber > 0 && itemsNumber < VIRTUAL_SCROLL_MAX_ITEMS) {
      return `${itemsNumber * VIRTUAL_SCROLL_ITEM_HEIGHT}px`;
    }
    return `${VIRTUAL_SCROLL_MAX_ITEMS * VIRTUAL_SCROLL_ITEM_HEIGHT}px`;
  }

  /**
   * Passes forward user's selection of an asset's external ID.
   */
  selectOption(option: string) {
    this.optionSelected.emit(option);
  }
}

function createSearchTerm(
  asset: Readonly<OfflineAssetsInfo>,
  countBySearchName: ReadonlyMap<string, number>,
): AssetOption {
  const searchTerm =
    countBySearchName.get(asset.searchName) === 1
      ? asset.searchName
      : `${asset.searchName} (${asset.externalId})`;
  return {key: asset.externalId, value: searchTerm};
}

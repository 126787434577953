/**
 * @fileoverview Page Errors Module.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {HeaderModule} from '../header/header_module';
import {PageErrorsComponent} from './page_errors_component';

@NgModule({
  declarations: [PageErrorsComponent],
  imports: [CommonModule, HeaderModule],
  exports: [PageErrorsComponent],
})
export class PageErrorsModule {}

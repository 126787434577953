/**
 * @fileoverview This is a pipe for highliting texts by adding <b> tags.
 */
import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe for highliting a substring within a longer string.
 */
@Pipe({name: 'highlight'})
export class HighlightPipe implements PipeTransform {
  transform(value: string, args: string): string {
    //'gi' for case insensitive and can use 'g' if you want the search to be
    // case sensitive.
    const re = new RegExp(args, 'gi');
    return value.replace(re, (substr) => {
      return '<strong>' + substr + '</strong>';
    });
  }
}

import {BehaviorSubject, Observable} from 'rxjs';

import {Injectable} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';

import {TableDialog} from '../table/table_dialog';
import {TagsDialog, TagsDialogData, TagsDialogResponse} from '../tags/tags_dialog';
import {LayerFilters} from '../typings/filter';
import {PrintableMetadata} from '../typings/printing';
import {DialogService} from './dialog_service';
import {LocalStorageService} from './local_storage_service';

/**
 * Combination of IDs that defines selected feature.
 */
export declare interface FeatureSelectionState {
  featureId: string;
  layerId: string;
}

/**
 * The column name of the map feature link if a user chooses to include it when
 * exporting as a CSV.
 */
const INCLUDE_LINK_COLUMN_NAME = 'Link';

interface TagsDialogMetadata {
  header: string;
  subheader: string;
  action: string;
  opened: boolean;
}

const TAGS_DIALOG_METADATA: TagsDialogMetadata = {
  header: 'Edit tags',
  subheader: 'Select tag(s) to apply to selected items',
  action: 'Save',
  opened: true,
};

/**
 * Service for handling table state.
 */
@Injectable({providedIn: 'root'})
export class TableService {
  /**
   * Maps the layer IDs to their respective column selection state.
   */
  private readonly selectedColumnsByLayer = new Map<string, string[]>();
  private readonly selectedFeatureIdAndLayerId = new BehaviorSubject<FeatureSelectionState>({
    featureId: '',
    layerId: '',
  });
  private readonly appliedFiltersByLayerId = new Map<string, LayerFilters>();

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly dialogService: DialogService,
  ) {
    this.selectedColumnsByLayer = this.localStorageService.readSelectedTableColumnNames();
  }

  /**
   * Adds the columns selection state for layer.
   */
  setSelectedColumnsForLayer(layerId: string, columnNames: string[]) {
    this.selectedColumnsByLayer.set(layerId, columnNames);
  }

  /**
   * Retrieves the columns selection state for layer.
   */
  getSelectedColumnsForLayer(layerId: string): string[] {
    return this.selectedColumnsByLayer.get(layerId) || [];
  }

  /**
   * Persists column selection state for all layers to local storage.
   */
  saveSelectedColumnState() {
    this.localStorageService.writeSelectedTableColumnNames(this.selectedColumnsByLayer);
  }

  /**
   * Records the feature selection state (feature id and layer id).
   */
  setSelectedFeature(featureId: string, layerId: string) {
    this.selectedFeatureIdAndLayerId.next({featureId, layerId});
  }

  /**
   * Retrieves the feature selection state (feature id and layer id).
   */
  getSelectedFeature(): Observable<FeatureSelectionState> {
    return this.selectedFeatureIdAndLayerId.asObservable();
  }

  /**
   * Opens dialog to collect user preferences for printing.
   * Returns columns for printing and features per page.
   */
  getPrintableMetadata(
    availableColumns: string[],
    selectedColumns: string[],
    showFeaturesPerPage: boolean,
    showIncludeLink: boolean,
  ): Observable<PrintableMetadata | null> {
    return this.dialogService.render(TableDialog, {
      data: {
        allColumnNames: availableColumns,
        selectedColumnNames: selectedColumns,
        showFeaturesPerPage,
        showIncludeLink,
        includeLinkColumnName: INCLUDE_LINK_COLUMN_NAME,
      },
    });
  }

  /**
   * Opens dialog to collect user preferences for tags update.
   */
  renderTagsDialog(layerId: string, seededTags: string[]): Observable<TagsDialogResponse> {
    const tagsDialogConfig: MatDialogConfig<TagsDialogData> = {
      data: {
        headerText: TAGS_DIALOG_METADATA.header,
        subheaderText: TAGS_DIALOG_METADATA.subheader,
        actionText: TAGS_DIALOG_METADATA.action,
        opened: TAGS_DIALOG_METADATA.opened,
        layerId: layerId,
        seededTags,
      },
    };
    return this.dialogService.render<TagsDialog, TagsDialogResponse>(TagsDialog, tagsDialogConfig);
  }

  /**
   * Sets the latest filter state for layer.
   * Helps to distinguish between cases when
   * new dataset needs to be fetched vs when
   * the cached data and pagination need to be used.
   */
  setAppliedFilters(layerId: string, filters: LayerFilters) {
    this.appliedFiltersByLayerId.set(layerId, filters);
  }

  /**
   * Retrieves the latest filter state for layer.
   */
  getAppliedFilters(layerId: string): LayerFilters | null {
    return this.appliedFiltersByLayerId.get(layerId) || null;
  }

  /**
   * Checks if the filter state for layer have changed since the last data fetch.
   */
  appliedFiltersChanged(layerId: string, newFilters: LayerFilters): boolean {
    const appliedFilters = this.getAppliedFilters(layerId);
    // This check is needed here to ignore cache if the filters have changed.
    // Cache persistence is required for the page and selected row to be remembered
    // on feature navigation from table and back. But if the filters have changed
    // at any step in-between, we need a clean state.
    const sameFilters =
      newFilters.includeInactiveResults === appliedFilters?.includeInactiveResults &&
      newFilters.filters === appliedFilters?.filters;
    return !sameFilters;
  }
}

import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

/**
 * Component for rendering the share overlay allowing to copy the provided
 * message.
 */
@Component({
  selector: 'share-card',
  templateUrl: './share_card.ng.html',
  styleUrls: ['./share_card.scss'],
})
export class ShareCard {
  @Input() value!: string;

  constructor(private readonly snackbar: MatSnackBar) {}

  onCopy(): void {
    this.snackbar.open('Successfully copied to clipboard.', 'OK', {
      duration: 5000,
    });
  }
}

import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, ReplaySubject} from 'rxjs';

import {Injectable} from '@angular/core';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Image, ImageLocation} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';

import {PendingFile, UploadFormTemplate} from '../typings/upload';

const NANOS_IN_MS = 1e6;
/**
 * Service for handling pending image upload state.
 */
@Injectable({providedIn: 'root'})
export class PendingUploadService {
  // Maps the information (such as Data URL) of unsubmitted images to their
  // respective IDs. Even though they are not saved to the database yet, pending
  // files should still be available for annotation and review.
  private readonly pendingImagesById = new Map<string, PendingFile>();

  private readonly pendingImages = new ReplaySubject<PendingFile[]>();

  private pendingDataProperties: Record<string, string> = {};

  private pendingTags: Set<string> | null = null;

  private pendingAsset: Feature | null = null;

  private pendingLocation: LatLng | null = null;

  private pendingFormTemplate: UploadFormTemplate | null = null;

  /**
   * Adds the info of a pending (added but not saved yet) image.
   */
  addPendingImage(imageId: string, info: PendingFile) {
    this.pendingImagesById.set(imageId, info);
    this.pendingImages.next(Array.from(this.pendingImagesById.values()));
  }

  /**
   * Deletes the info of a pending (added but not saved yet) image.
   */
  deletePendingImage(imageId: string) {
    this.pendingImagesById.delete(imageId);
    this.pendingImages.next(Array.from(this.pendingImagesById.values()));
  }

  /**
   * Retrieves pending (added but not saved yet) image.
   */
  getPendingImage(imageId: string): Image | null {
    const file = this.pendingImagesById.get(imageId);
    if (!file) {
      return null;
    }
    const image = new Image({
      id: imageId,
      url: file.url,
      originalFileName: file.file.name,
      capturedAt: {nanos: file.file.lastModified * NANOS_IN_MS},
    });
    if (this.pendingLocation) {
      image.location = new ImageLocation({
        location: {
          latitude: this.pendingLocation.latitude,
          longitude: this.pendingLocation.longitude,
        },
      });
    }
    return image;
  }

  /**
   * Retrieves the Data URL of a pending (added but not saved yet) image.
   */
  getPendingImageDataUrl(imageId: string): string | null {
    return this.pendingImagesById.get(imageId)?.url || null;
  }

  /**
   * Retrieves all pending (added but not saved yet) images in
   * current session.
   */
  getAllPendingImages(): Observable<PendingFile[]> {
    return this.pendingImages.asObservable();
  }

  /**
   * Overrides the previously saved data properties (form fields) with provided
   * values.
   */
  setPendingDataState(properties: Record<string, string>) {
    this.pendingDataProperties = properties;
  }

  /**
   * Retrieves the previously saved data properties (form fields).
   */
  getPendingDataState(): Record<string, string> {
    return this.pendingDataProperties;
  }

  /**
   * Overrides the previously saved form tags with provided
   * values.
   */
  setPendingTags(tags: Set<string>) {
    this.pendingTags = tags;
  }

  /**
   * Retrieves the previously saved form tags.
   */
  getPendingTags(): Set<string> | null {
    return this.pendingTags;
  }

  /**
   * Overrides the previously saved asset the form is associated with.
   */
  setPendingAsset(asset: Feature | null) {
    this.pendingAsset = asset;
  }

  /**
   * Retrieves the previously saved asset the form is associated with.
   */
  getPendingAsset(): Feature | null {
    return this.pendingAsset;
  }

  /**
   * Overrides the previously saved location info.
   */
  setPendingLocation(location: LatLng | null) {
    this.pendingLocation = location;
  }

  /**
   * Retrieves the previously saved location info.
   */
  getPendingLocation(): LatLng | null {
    return this.pendingLocation;
  }

  /**
   * Overrides the selected form template.
   */
  setPendingFormTemplate(formTemplate: UploadFormTemplate | null) {
    this.pendingFormTemplate = formTemplate;
  }

  /**
   * Retrieves the previously selected form template.
   */
  getPendingFormTemplate(): UploadFormTemplate | null {
    return this.pendingFormTemplate;
  }

  /**
   * Clears all pending state. Usually done on session reset.
   */
  clearPendingState() {
    this.pendingImagesById.clear();
    this.pendingImages.next([]);
    this.pendingDataProperties = {};
    this.pendingTags = null;
    this.pendingAsset = null;
    this.pendingLocation = null;
    this.pendingFormTemplate = null;
  }

  /**
   * Checks if there were any pending changes registered.
   */
  hasPendingState(): boolean {
    return (
      this.pendingImagesById.size > 0 ||
      Object.keys(this.pendingDataProperties).length > 0 ||
      this.pendingTags !== null ||
      this.pendingAsset !== null ||
      this.pendingLocation !== null
    );
  }
}

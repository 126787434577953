/**
 * @fileoverview A pipe to sort the annotation type selectors by their title.
 */
import {Pipe, PipeTransform} from '@angular/core';

import {AnnotationTypeSelector} from './annotation_type_selector';

/**
 * A pipe to sort the annotation type selectors by their title.
 */
@Pipe({name: 'sortAnnotationTypeSelectors'})
export class SortAnnotationTypeSelectorsPipe implements PipeTransform {
  transform(arr: AnnotationTypeSelector[]): AnnotationTypeSelector[] {
    arr.sort((a: AnnotationTypeSelector, b: AnnotationTypeSelector) => {
      return a.title.localeCompare(b.title, undefined, {sensitivity: 'base'});
    });
    return arr;
  }
}

import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {Observable, Observer, Subject, catchError, of} from 'rxjs';
import {startWith, switchMap, takeUntil} from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Layer} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {FeaturesService} from '../services/features_service';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {getTokens, stringContainsAllTokens} from '../utils/search_tokens';

/**
 * An identifying information about the selected feature.
 */
export interface SelectedFeature {
  layerID: string;
  feature: Feature;
}

interface PlaceOption {
  readonly description: string;
  readonly placeId: string;
}

const MIN_SEARCH_LENGTH = 2;
const SNACK_BAR_TIMEOUT_MS = 2000;

const SEARCH_PROMPT = `Search results will include Google Maps results plus
 results from layers that are turned on.`;

/**
 * Component for seaching features by query string or street address.
 */
@Component({
  selector: 'search',
  templateUrl: 'search.ng.html',
  styleUrls: ['search.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Search implements OnInit, OnDestroy {
  @Input() layerIds!: Observable<string[]>;
  @Input() placeholder = '';
  @Input() showHelp = true;
  @Input() showSearchIcon = true;
  @Input() isGlobalSearch = true;
  @Output()
  private readonly featureSelected = new EventEmitter<SelectedFeature>();

  map: google.maps.Map | null = null;
  searchInputControl = new UntypedFormControl('');
  searchValue = '';
  /**
   * searchTokens is an array created out of searchValue, split by separators.
   * Each element in the array is an lph-numeric lower-cased word.
   */
  searchTokens: string[] = [];
  featureResults = new Map<string, Observable<Feature[]>>();
  placeResults: Observable<PlaceOption[]> = this.searchInputControl.valueChanges.pipe(
    startWith(''),
    switchMap((value) => this.searchLocations(value)),
  );
  visibleLayers: Layer[] = [];
  searchPrompt = SEARCH_PROMPT;
  private placesAutocompleteService?: google.maps.places.AutocompleteService;
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly layersService: LayersService,
    private readonly mapService: MapService,
    private readonly snackBar: MatSnackBar,
    private readonly featuresService: FeaturesService,
  ) {}

  ngOnInit() {
    this.mapService.getMapReady().subscribe((map: google.maps.Map) => {
      this.map = map;
      this.placesAutocompleteService = new google.maps.places.AutocompleteService();
    });
    this.layerIds.pipe(takeUntil(this.destroyed)).subscribe((layerIds: string[]) => {
      for (const layerId of layerIds) {
        this.featureResults.set(
          layerId,
          this.searchInputControl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => {
              if (!value || value.length < MIN_SEARCH_LENGTH) {
                return of([]);
              }
              this.searchValue = value;
              this.searchTokens = getTokens(this.searchValue);
              return this.searchFeatures(layerId, value);
            }),
          ),
        );
      }
      this.visibleLayers = this.getVisibleLayers(layerIds);
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private getVisibleLayers(layerIds: string[]): Layer[] {
    return layerIds
      .map((layerId: string): Layer | undefined => this.layersService.layerById.get(layerId))
      .filter((layer: Layer | undefined): boolean => !!layer)
      .map((layer: Layer | undefined): Layer => layer!);
  }

  /**
   * Searches for features given a field to search on and the value.
   */
  searchFeatures(layerId: string, substring: string): Observable<Feature[]> {
    return this.featuresService.getFeaturesAutocompleteResults(layerId, substring).pipe(
      catchError(() => {
        this.snackBar.open('Could not search features. Please try again.', '', {
          duration: SNACK_BAR_TIMEOUT_MS,
        });
        return of([]);
      }),
    );
  }

  searchLocations(value: string): Observable<PlaceOption[]> {
    // Work-around for the compiler.
    const service = this.placesAutocompleteService;
    if (!value || value.length < MIN_SEARCH_LENGTH || !service) {
      return of([]);
    }

    return new Observable<PlaceOption[]>((observer: Observer<PlaceOption[]>) => {
      service.getPlacePredictions(
        {
          bounds: this.map ? (this.map.getBounds() as google.maps.LatLngBounds) : undefined,
          input: value,
        },
        (
          results: google.maps.places.AutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            observer.next([]);
            return;
          }
          observer.next(
            (results || []).map((result: google.maps.places.AutocompletePrediction) => {
              return {
                description: result.description,
                placeId: result.place_id,
              };
            }),
          );
          observer.complete();
        },
      );
    });
  }

  onAddressChange(placeId: string) {
    this.sendSearchEvent();
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {placeId},
      (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
        if (status && results) {
          const location = results[0].geometry.location;
          const lat = location.lat();
          const lng = location.lng();

          this.mapService.setMapCenter(new LatLng({latitude: lat, longitude: lng}));
          this.mapService.setLocationPin({lat, lng});
        }
      },
    );
  }

  /**
   * Set selected feature from results.
   * @param layerId - the layer where the feature is.
   * @param feature - selected feature.
   */
  selectFeature(layerId: string, feature: Feature) {
    this.sendSearchEvent();
    this.featureSelected.emit({layerID: layerId, feature});
  }

  sendSearchEvent(): void {
    this.analyticsService.sendEvent(EventActionType.SEARCH, {
      event_category: EventCategoryType.MAP,
      event_label: this.searchValue,
    });
  }

  /**
   * Returns the second line for the feature displayed in the dropdown with
   * search results. The best effort is made to display the property matched the
   * query. In some corner cases, when it's hard to match the right property,
   * ExternalID is returned by default.
   */
  getSecondLine(feature: Feature): string {
    const defaultSecondLine = feature.externalId;
    if (
      stringContainsAllTokens(feature.name, this.searchTokens) ||
      (!!feature.externalId && feature.externalId.includes(this.searchValue))
    ) {
      // If the query was found in either name or external_id, then return
      // external_id for the second line.
      return defaultSecondLine;
    }
    for (const prop of feature.properties) {
      if (!prop.propertyValue) {
        // Empty property, is it possible?
        continue;
      }
      if (stringContainsAllTokens(prop.propertyValue.value as string, this.searchTokens)) {
        return `${prop.key}: ${prop.propertyValue.value}`;
      }
    }
    // Safety-net, return external_id in all other cases.
    return defaultSecondLine;
  }
}

import {ReplaySubject, combineLatest} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {
  FEATURE_PARAM_KEY,
  IMAGE_PARAM_KEY,
  LAYER_PARAM_KEY,
  QUERY_PARAMS,
  ROUTE,
} from '../constants/paths';
import {FeaturesService} from '../services/features_service';
import {UrlService} from '../services/url_service';

/**
 * Renders navigation breadcrumbs based on the current route.
 */
@Component({
  selector: 'breadcrumb-navigation',
  templateUrl: './breadcrumb_navigation.ng.html',
  styleUrls: ['./breadcrumb_navigation.scss'],
})
export class BreadcrumbNavigation implements OnInit, OnDestroy {
  title = '';

  // Show "images" crumb only when in the image studio.
  showImagesCrumb = false;

  rootPath: ROUTE = ROUTE.MAP;
  layerId = '';
  featureId = '';
  imageId = '';

  private readonly destroyed = new ReplaySubject<void>(1);

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly featuresService: FeaturesService,
    private readonly urlService: UrlService,
  ) {}

  ngOnInit() {
    combineLatest([this.route.queryParamMap, this.route.paramMap])
      .pipe(
        takeUntil(this.destroyed),
        switchMap(([queryParams, params]: [ParamMap, ParamMap]) => {
          this.layerId =
            queryParams.get(QUERY_PARAMS.LAYER_ID) || params.get(LAYER_PARAM_KEY) || '';
          this.featureId =
            queryParams.get(QUERY_PARAMS.FEATURE_ID) || params.get(FEATURE_PARAM_KEY) || '';
          this.imageId =
            queryParams.get(QUERY_PARAMS.IMAGE_ID) || params.get(IMAGE_PARAM_KEY) || '';
          this.showImagesCrumb = location.hash.includes('lightbox');
          this.rootPath = this.getLastRoute();

          return this.featuresService.getFeature(this.layerId, this.featureId, false);
        }),
      )
      .subscribe((feature: Feature | null) => {
        this.title = feature?.name || '';
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  navigateToRoot() {
    if (this.rootPath === ROUTE.TABLE) {
      this.router.navigate([ROUTE.TABLE, this.layerId]);
    } else {
      this.router.navigate([ROUTE.MAP]);
    }
  }

  navigateToFeature() {
    this.router.navigate([
      this.rootPath === ROUTE.TABLE ? ROUTE.TABLE : ROUTE.MAP,
      this.layerId,
      this.featureId,
      this.imageId,
    ]);
  }

  navigateToRootTooltip() {
    return this.rootPath === ROUTE.TABLE ? 'Go to table' : 'Go to map';
  }

  navigateToFeatureTooltip() {
    return this.rootPath === ROUTE.TABLE ? 'Go to table feature' : 'Go to map feature';
  }

  /**
   * Find the last route visited that's either the map or table.
   * This is necessary because a user may be navigating from the lightbox.
   * If no route is found then default to the map route.
   */
  private getLastRoute(): ROUTE {
    const navigationStack = this.urlService.getNavigationStack();
    for (let i = navigationStack.length - 1; i >= 0; i--) {
      if (navigationStack[i].includes(ROUTE.TABLE)) {
        return ROUTE.TABLE;
      }
      if (navigationStack[i].includes(ROUTE.MAP)) {
        return ROUTE.MAP;
      }
    }
    return ROUTE.MAP;
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/commentservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Comment, CommentContent } from "./comment_pb.js";

/**
 * @generated from message tapestry.gridaware.api.v1.GetCommentsRequest
 */
export const GetCommentsRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.GetCommentsRequest", () => [{
  no: 1,
  name: "asset_ids",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.GetCommentsResponse
 */
export const GetCommentsResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.GetCommentsResponse", () => [{
  no: 1,
  name: "comments",
  kind: "message",
  T: Comment,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.AddCommentRequest
 */
export const AddCommentRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.AddCommentRequest", () => [{
  no: 1,
  name: "asset_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "content",
  kind: "message",
  T: CommentContent
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.AddCommentResponse
 */
export const AddCommentResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.AddCommentResponse", () => [{
  no: 1,
  name: "comment",
  kind: "message",
  T: Comment
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.DeleteCommentRequest
 */
export const DeleteCommentRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.DeleteCommentRequest", () => [{
  no: 1,
  name: "comment_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.DeleteCommentResponse
 */
export const DeleteCommentResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.DeleteCommentResponse", []);
<div [ngClass]="{'global-search': isGlobalSearch}" class="search-container">
  @if (showSearchIcon) {
    <mat-icon matSuffix class="search-icon">search</mat-icon>
  }
  <mat-form-field class="search-input" appearance="outline" subscriptSizing="dynamic">
    <input
      matInput
      class="input"
      type="text"
      placeholder="{{ placeholder || 'Search map' }}"
      aria-label="Search map"
      i18n-aria-label
      autocorrect="off"
      autocomplete="off"
      [formControl]="searchInputControl"
      [matAutocomplete]="auto" />
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      class="search-autocomplete-results">
      @for (layer of visibleLayers; track layer) {
        @if (featureResults.get(layer.id) | async; as features) {
          @if (features.length > 0) {
            <mat-optgroup class="option-group" [label]="layer.name">
              @for (feature of features; track feature) {
                <mat-option
                  class="option"
                  [value]="feature.name"
                  (onSelectionChange)="selectFeature(layer.id, feature)">
                  <div
                    class="option-display-value"
                    innerHtml="{{ feature.name | highlightList: searchTokens }}"></div>
                  <div
                    class="option-subtext"
                    innerHtml="{{ getSecondLine(feature) | highlightList: searchTokens }}"></div>
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      }
      <mat-optgroup class="option-group" label="Locations">
        @for (result of placeResults | async; track result) {
          <mat-option
            class="option"
            [value]="result.description"
            (onSelectionChange)="onAddressChange(result.placeId)">
            <span innerHtml="{{ result.description | highlight: searchValue }}"></span>
          </mat-option>
        }
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
  @if (showHelp) {
    <mat-icon
      class="help-icon search-panel-icon"
      [matTooltip]="searchPrompt"
      [matTooltipClass]="{'multiline-tooltip': true}">
      help_outline
    </mat-icon>
  }
</div>
<!--
(onSelectionChange)="selectFeature(layer.getId(), feature)" -->

/**
 * Indicates the maximum length of time (in milliseconds) the device is
 * allowed to take in order to return a position.
 */
export const GEOLOCATION_TIMEOUT_MS = 20000;

/**
 * Indicates the maximum age (in milliseconds) of a possible cached position
 * that is acceptable to return.
 */
export const GEOLOCATION_MAX_STALENESS_MS = 20000;

/**
 * Default geolocation configuration.
 */
export const DEFAULT_GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
  maximumAge: GEOLOCATION_MAX_STALENESS_MS,
  timeout: GEOLOCATION_TIMEOUT_MS,
};

import {Observable, ReplaySubject, Subject, of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {safeAnchorEl} from 'safevalues/dom';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {DeleteImageConfirmationDialog} from '../delete_image_confirmation_dialog/delete_image_confirmation_dialog';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {DialogService} from '../services/dialog_service';

/**
 * Component for viewing files.
 */
@Component({
  templateUrl: './file_viewer.ng.html',
  styleUrls: ['./file_viewer.scss'],
})
export class FileViewer implements OnInit, OnDestroy {
  @Input() files: File[] = [];
  @Input() index = 0;
  @Output() fileViewerClosed = new EventEmitter<void>();
  @Output() fileDeleted = new EventEmitter<File[]>();

  imageSrc: Observable<string> | null = null;
  // The key for this map is the file's timestamp.
  private readonly readImageCache = new Map<number, Observable<string>>();
  private readonly destroyed = new Subject<void>();

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.previous();
        break;
      case 'ArrowRight':
        this.next();
        break;
      default:
        return;
    }
  }

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dialogService: DialogService,
    private sanitizer: DomSanitizer,
  ) {}

  downloadImage() {
    const currentFile = this.files[this.index];
    const blobUrl = URL.createObjectURL(currentFile);
    try {
      const downloadAnchorEl = document.createElement('a');
      const safeUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, blobUrl) || '';
      safeAnchorEl.setHref(downloadAnchorEl, safeUrl);
      downloadAnchorEl.download = currentFile.name;
      downloadAnchorEl.click();
    } finally {
      URL.revokeObjectURL(blobUrl);
    }
  }

  ngOnInit() {
    this.renderImage();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  close() {
    this.fileViewerClosed.emit();
  }

  openDeleteImageConfirmationDialog() {
    this.dialogService
      .render<DeleteImageConfirmationDialog, boolean>(DeleteImageConfirmationDialog)
      .pipe(takeUntil(this.destroyed))
      .subscribe((deletionConfirmed: boolean) => {
        if (deletionConfirmed) {
          this.deleteFile();
        }
      });
  }

  private deleteFile() {
    const files = this.files.filter((file: File) => file !== this.files[this.index]);
    this.fileDeleted.next(files);
    if (files.length === 0) {
      this.close();
      return;
    }
    this.previous();
    this.files = files;
  }

  next() {
    if (this.files.length === 1) {
      return;
    }
    this.index++;
    this.index %= this.files.length;
    this.renderImage();
  }

  previous() {
    if (this.files.length === 1) {
      return;
    }
    this.index = this.index === 0 ? this.files.length - 1 : this.index - 1;
    this.renderImage();
  }

  private renderImage() {
    const file = this.files[this.index];
    this.imageSrc = this.readImage(file);
    this.sendFileViewedEvent(file);
    this.changeDetectorRef.detectChanges();
  }

  private readImage(file: File): Observable<string> {
    const key = file.lastModified;
    if (!key) {
      return of('');
    }
    if (!this.readImageCache.has(key)) {
      const imageSrc = new ReplaySubject<string>(1);
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        imageSrc.next(fileReader.result as string);
      });
      fileReader.readAsDataURL(file);
      this.readImageCache.set(key, imageSrc.pipe(takeUntil(this.destroyed)));
    }
    return this.readImageCache.get(key)!;
  }

  private sendFileViewedEvent(file: File): void {
    this.analyticsService.sendEvent(EventActionType.VIEW_FILE, {
      event_category: EventCategoryType.FILE,
      event_label: `Filename: ${file.name}`,
    });
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule} from '@angular/router';

import {MapTableToggleModule} from '../map_table_toggle/map_table_toggle_module';
import {SearchModule} from '../search/search_module';
import {MapService} from '../services/map_service';
import {SidepanelLayoutModule} from '../sidepanel_layout/sidepanel_layout_module';
import {MapComponent} from './map_component';
import {MapDeckGLComponent} from './map_deckgl_component';
import {MapRouteComponent} from './map_route_component';

@NgModule({
  declarations: [MapComponent, MapRouteComponent, MapDeckGLComponent],
  imports: [
    CommonModule,
    // MapLayersModule,
    MapTableToggleModule,
    // MatButtonModule,
    // MatIconModule,
    MatProgressSpinnerModule,
    // MatSnackBarModule,
    // MatTooltipModule,
    // OfflineAssetsDialogsModule,
    RouterModule,
    SearchModule,
    SidepanelLayoutModule,
  ],
  providers: [MapService],
  exports: [MapComponent, MapRouteComponent, MapDeckGLComponent],
})
export class MapModule {}

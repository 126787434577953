import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * Component for rendering comment entry in feature timeline.
 */
@Component({
  selector: 'timeline-comment',
  templateUrl: './timeline_comment.ng.html',
  styleUrls: ['./timeline_comment.scss'],
})
export class TimelineComment {
  @Input() comment!: string;
  @Input() featureId!: string;
  @Output() commentComplete = new EventEmitter();
  replying: boolean = false;

  refreshComments(comment: Comment | null) {
    this.replying = false;
    if (!comment) {
      return;
    }
    this.commentComplete.emit(comment);
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/config/v1/gridaware_config.proto (package tapestry.gridaware.config.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { LatLng } from "../../../../google/type/latlng_pb.js";

/**
 * Protocol message type used to configure Gridaware app.
 * Contains non-overridable environment variables that are set serverside.
 *
 * Next tag: 22
 *
 * @generated from message tapestry.gridaware.config.v1.GridawareConfig
 */
export const GridawareConfig = proto3.makeMessageType("tapestry.gridaware.config.v1.GridawareConfig", () => [{
  no: 1,
  name: "feedback_bucket",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "feedback_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "ga_tracker_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "gcip_api_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 5,
  name: "gcip_config",
  kind: "message",
  T: AuthConfig,
  repeated: true
}, {
  no: 6,
  name: "gcp_project_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 7,
  name: "google_api_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "map_center",
  kind: "message",
  T: LatLng
}, {
  no: 9,
  name: "one_platform_api_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "one_platform_url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "scotty_url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 19,
  name: "scotty_force_trace_url_params",
  kind: "message",
  T: UrlParam,
  repeated: true
}, {
  no: 12,
  name: "units",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "debug_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 14,
  name: "service_worker_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 15,
  name: "is_internal_user",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 16,
  name: "release_number",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 17,
  name: "available_upload_form_types",
  kind: "enum",
  T: proto3.getEnumType(GridawareConfig_UploadFormType),
  repeated: true
}, {
  no: 18,
  name: "auth_override_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 20,
  name: "use_serving_domain_as_auth_domain",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 21,
  name: "partner_name",
  kind: "enum",
  T: proto3.getEnumType(GridawareConfig_PartnerName)
}, {
  no: 22,
  name: "gridaware_backend_url",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 23,
  name: "map_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * Type of the form associated with an individudal partner
 * use case for the upload flow.
 * LINT.IfChange
 *
 * @generated from enum tapestry.gridaware.config.v1.GridawareConfig.UploadFormType
 */
export const GridawareConfig_UploadFormType = proto3.makeEnum("tapestry.gridaware.config.v1.GridawareConfig.UploadFormType", [{
  no: 0,
  name: "FORM_TYPE_UNSPECIFIED"
}, {
  no: 4,
  name: "DEFAULT_IMAGE_UPLOAD"
}, {
  no: 5,
  name: "VTS_ASSET_SITE_VISIT"
}, {
  no: 6,
  name: "VTS_POLE_INSPECTIONS"
}, {
  no: 7,
  name: "CMS_SECURITY_ASSESSMENT"
}, {
  no: 8,
  name: "CMS_POLE_INSPECTIONS"
}, {
  no: 1,
  name: "STANDARD"
}, {
  no: 2,
  name: "SHORT"
}, {
  no: 3,
  name: "POLE"
}]);

/**
 * A unique key to identify each GridAware partner.
 *
 * @generated from enum tapestry.gridaware.config.v1.GridawareConfig.PartnerName
 * @deprecated
 */
export const GridawareConfig_PartnerName = proto3.makeEnum("tapestry.gridaware.config.v1.GridawareConfig.PartnerName", [{
  no: 0,
  name: "UNKNOWN"
}, {
  no: 1,
  name: "CMS"
}, {
  no: 2,
  name: "NBP"
}, {
  no: 3,
  name: "VTS"
}, {
  no: 4,
  name: "DPL"
}, {
  no: 5,
  name: "AES"
}]);

/**
 * SSO provider settings.
 *
 * @generated from message tapestry.gridaware.config.v1.AuthConfig
 */
export const AuthConfig = proto3.makeMessageType("tapestry.gridaware.config.v1.AuthConfig", () => [{
  no: 1,
  name: "gcip_provider_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "gcip_tenant_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "display_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * Represents a single name=value URL parameter.
 *
 * @generated from message tapestry.gridaware.config.v1.UrlParam
 */
export const UrlParam = proto3.makeMessageType("tapestry.gridaware.config.v1.UrlParam", () => [{
  no: 1,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
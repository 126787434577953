import {ReplaySubject, Subject, firstValueFrom, of} from 'rxjs';
import {catchError, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import {Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

import {
  Layer,
  LayerStyle,
  Layer_LayerType,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

//import {SafeUrl, sanitizeUrl} from 'safevalues/dom';
import {
  CIRCLE_ICON_SVG,
  CONFIGURATOR_ICON_COLORS,
  DIAMOND_ICON_SVG,
  ICON_COLORS,
  SHAPE,
  SQUARE_ICON_SVG,
  TAG_ICON_SVG,
  TRIANGLE_ICON_SVG,
  UNASSIGNED_ICON_COLOR,
} from '../constants/icon';
import {LayersService} from '../services/layers_service';
import {IconColor} from '../typings/icon';
import {IconGenerator} from '../utils/icon_util';

interface ProcessedIcon {
  // Code representation of an SVG element. Eg, `<svg><path /><text /></svg>`
  svg: string;
  // The SVG as a URL. Ie, the svg field that is a part of this interface with
  // URL encoding and the data schema `data:image/svg+xml;charset=UTF-8`
  // prepended.
  svgUrl: string | null;
  label: string;
}

interface UnprocessedIcon {
  // Code representation of an SVG element. Eg, `<svg><path /><text /></svg>`
  svg: string;
  label: string;
}

interface IconStyle {
  backgroundColor: string;
  label: string;
  strokeColor: string;
  strokeWidth: string;
  strokeIsDashed: boolean;
  opacity: string;
}

// All the fields that can be set. The only required field is selectedIcon.
// The asset layer is the only layer where selectedIcon is not available and
// is therefore not a required field. Only selectedHoverPropertyKey and
// priorityPropertyKeys can be set for the asset layer.
interface UploadForm {
  selectedHoverPropertyKey: string;
  selectedLabelPropertyKey: string;
  priorityPropertyKeys: string[];
  // A layer's SVG icon.
  selectedIcon: ProcessedIcon;
  // A layer's palette of selected colors which binds to a multiple select.
  selectedColors: string[];
  // A layer's single selected color which binds to a single select. This can
  // optionally be set when a single color is being chosen for an entire layer,
  // ie, the case where label property is not being used.
  selectedColor: string;
  // A layer's color palette bound to selected property values.
  // Will define the marker color based on label property value.
  colorsPerPropertyValue: Map<string, string>;
}

const ICONS: UnprocessedIcon[] = [
  {svg: CIRCLE_ICON_SVG, label: SHAPE.CIRCLE},
  {svg: SQUARE_ICON_SVG, label: SHAPE.SQUARE},
  {svg: DIAMOND_ICON_SVG, label: SHAPE.DIAMOND},
  {svg: TAG_ICON_SVG, label: SHAPE.TAG},
  {svg: TRIANGLE_ICON_SVG, label: SHAPE.TRIANGLE},
];

// Used when composing SVG icons for rendering in a select component.
const ICON_STYLE: IconStyle = {
  backgroundColor: '#e8eaed', // Light Grey.
  label: '',
  strokeColor: '#5f6368', // Grey.
  strokeWidth: '5', // Width in pixels scaled.
  strokeIsDashed: true,
  opacity: '1',
};

const BY_LAYER_RADIO_LABEL = 'Use one color for all icons';
const BY_LABEL_RADIO_LABEL = 'Color code based on a property';
const ERROR_TOAST_DURATION = 1000;
// TODO(reubenn): Format TOGOS properties formally.
const TOGOS_PREFIX = 'TOGOS_';

/**
 * Component that exists as part of the layer config page.
 */
@Component({
  selector: 'layer-config',
  templateUrl: 'layer_config.ng.html',
  styleUrls: ['layer_config.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayerConfig implements OnInit, OnChanges, OnDestroy {
  @Input() layer: Layer | null = null;

  // Default width of the properties select overlay panel.
  propertiesSelectPanelWidth = '280px';

  selectedPropertyKey = new UntypedFormControl();

  colors = ICON_COLORS;
  colorNameByHex = new Map(
    ICON_COLORS.map((iconColor: IconColor) => [iconColor.hex, iconColor.label]),
  );
  icons: ProcessedIcon[] = [];
  byLayerRadio = BY_LAYER_RADIO_LABEL;
  byLabelRadio = BY_LABEL_RADIO_LABEL;

  selectedRadio = BY_LAYER_RADIO_LABEL;
  selectedIcon: ProcessedIcon | null = null;
  allLayerPropertyKeys: string[] = [];
  uploadForm: UploadForm = {
    selectedHoverPropertyKey: '',
    selectedLabelPropertyKey: '',
    priorityPropertyKeys: [],
    selectedColors: [],
    selectedColor: '',
    selectedIcon: {svg: '', svgUrl: null, label: ''},
    colorsPerPropertyValue: new Map(),
  };
  propertyValues: string[] = [];
  displayedColumns = ['color', 'propertyValue'];
  loading = false;
  propertyValuesLoading = false;
  assetLayerSelected = false;
  layerHasPointMarker = false;
  fetchLayerPropertyKeys = new ReplaySubject<string>(1);
  propertyColors = CONFIGURATOR_ICON_COLORS.concat(UNASSIGNED_ICON_COLOR);
  destroyed = new Subject();

  constructor(
    private readonly iconGenerator: IconGenerator,
    private readonly layersService: LayersService,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnChanges() {
    this.clearState();
    if (!this.layer) {
      return;
    }
    this.assetLayerSelected = this.layer.layerType === Layer_LayerType.ASSETS;
    this.layerHasPointMarker = !!this.layer?.layerStyle?.pointMarker;
    this.fetchLayerPropertyKeys.next(this.layer.id);
    this.setExistingLayerStyles();
  }

  ngOnInit() {
    this.listenForPropertyChanges();
    // For the initial load when onChanges sets the value before
    // the subscription happens in onInit, re-set the form control value.
    this.updatePropertyKeyValue();
    this.fillAndLabelIcons();
    this.initFetchLayerPropertyKeys();
  }

  ngOnDestroy() {
    this.destroyed.next(0);
  }

  private initFetchLayerPropertyKeys() {
    this.fetchLayerPropertyKeys
      .pipe(
        switchMap((layerId: string) => {
          return (
            this.layersService
              // For the layer config we always fetch all property keys
              // including the keys of the inactive features.
              .getLayerPropertyKeys(layerId, false, true)
              .pipe(
                catchError((error: Error) => {
                  console.error(
                    'An error occurred fetching layer property keys for ' +
                      `layer with ID ${layerId}: ${error}`,
                  );
                  return of([]);
                }),
              )
          );
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((propertyKeys: string[]) => {
        this.allLayerPropertyKeys = propertyKeys
          .map((key: string) => key.replace(TOGOS_PREFIX, ''))
          .sort(lowerCaseSort);
      });
  }

  setExistingLayerStyles() {
    const layerStyle = this.layer!.layerStyle;
    if (!layerStyle) {
      console.warn(`Layer with ID '${this.layer!.id}' doesn't have a layer style`);
      return;
    }
    if (!layerStyle?.pointMarker) {
      this.uploadForm.priorityPropertyKeys = layerStyle.priorityPropertyKeys;
      return;
    }
    const pointMarker = layerStyle!.pointMarker;
    this.uploadForm = {
      selectedHoverPropertyKey: pointMarker!.hoverPropertyKey,
      selectedLabelPropertyKey: pointMarker!.labelPropertyKey,
      priorityPropertyKeys: layerStyle!.priorityPropertyKeys,
      selectedColors: pointMarker!.colorPalette,
      selectedColor: pointMarker!.colorPalette.length === 1 ? pointMarker!.colorPalette[0] : '',
      selectedIcon: {svg: '', svgUrl: null, label: ''},
      colorsPerPropertyValue: this.colorSchemaToColorsPerPropertyValue(
        new Map(Object.entries(pointMarker!.colorSchema)),
      ),
    };
    if (pointMarker!.labelPropertyKey) {
      this.selectedRadio = BY_LABEL_RADIO_LABEL;
    }
    const svg = pointMarker!.unselectedIconSvg;
    const shapeLabel = this.iconGenerator.getShapeFromSvg(svg);
    if (shapeLabel) {
      const processedIcon = this.fillAndLabelIcon({svg, label: shapeLabel});
      this.uploadForm.selectedIcon = processedIcon;
    }
    this.updatePropertyKeyValue();
  }

  isDefaultColor(color: IconColor): boolean {
    return color === UNASSIGNED_ICON_COLOR;
  }

  private clearState() {
    this.selectedRadio = BY_LAYER_RADIO_LABEL;
    this.uploadForm = {
      selectedHoverPropertyKey: '',
      selectedLabelPropertyKey: '',
      priorityPropertyKeys: [],
      selectedColors: [],
      selectedColor: '',
      selectedIcon: {svg: '', svgUrl: null, label: ''},
      colorsPerPropertyValue: this.colorSchemaToColorsPerPropertyValue(new Map()),
    };
  }

  private fillAndLabelIcons() {
    this.icons = ICONS.map((icon: UnprocessedIcon) => this.fillAndLabelIcon(icon));
  }

  private fillAndLabelIcon(icon: UnprocessedIcon): ProcessedIcon {
    const svgUrl = this.iconGenerator.getEncodedIconUrl({
      svg: icon.svg,
      label: ICON_STYLE.label,
      color: ICON_STYLE.backgroundColor,
      strokeColor: ICON_STYLE.strokeColor,
      strokeWidth: ICON_STYLE.strokeWidth,
      strokeIsDashed: ICON_STYLE.strokeIsDashed,
      opacity: ICON_STYLE.opacity,
    });
    return {
      svg: icon.svg,
      // svgUrl: sanitizeUrl(svgUrl),
      svgUrl: svgUrl,
      label: icon.label,
    };
  }

  // TODO (reubenn): Remove when asset layer config updates are fully supported.
  // Only updates to the hover property and prioritized properties are supported
  // for the asset layer at this time.
  private buildUpdatedAssetLayerStyle(): LayerStyle | null {
    const layerStyle = this.layer?.layerStyle?.clone();
    if (!layerStyle) {
      console.error('Asset layer must have layer style');
      return null;
    }
    layerStyle.priorityPropertyKeys = this.uploadForm.priorityPropertyKeys;
    layerStyle.pointMarker!.hoverPropertyKey = this.uploadForm.selectedHoverPropertyKey;
    return layerStyle;
  }

  private buildUpdatedPointMarkerLayerStyle(): LayerStyle | null {
    if (this.selectedRadio === BY_LAYER_RADIO_LABEL) {
      this.uploadForm.selectedLabelPropertyKey = '';
      this.uploadForm.colorsPerPropertyValue = new Map();
    }
    const layerStyle = this.layer!.layerStyle?.clone() || new LayerStyle();
    const pointMarker = layerStyle.pointMarker;
    if (!pointMarker) {
      console.error(
        'Layer style does not have a point marker. ' +
          'Only updates to layer style point markers are allowed',
      );
      return null;
    }
    layerStyle.priorityPropertyKeys = this.uploadForm.priorityPropertyKeys;
    pointMarker.unselectedIconSvg = this.uploadForm.selectedIcon.svg;
    pointMarker.selectedIconSvg = this.uploadForm.selectedIcon.svg;
    pointMarker.hoverPropertyKey = this.uploadForm.selectedHoverPropertyKey;
    pointMarker.labelPropertyKey = this.uploadForm.selectedLabelPropertyKey;
    pointMarker.colorPalette = this.getColorPalette();
    this.overrideColorSchema(
      new Map(Object.entries(pointMarker.colorSchema)),
      this.uploadForm.colorsPerPropertyValue,
    );
    return layerStyle;
  }

  private buildUpdatedLayerStyle(): LayerStyle | null {
    if (this.assetLayerSelected) {
      // TODO (reubenn): Remove when asset layer config updates are fully
      // supported.
      return this.buildUpdatedAssetLayerStyle();
    }
    if (this.layerHasPointMarker) {
      return this.buildUpdatedPointMarkerLayerStyle();
    }
    const layerStyle = this.layer?.layerStyle?.clone() || new LayerStyle();
    layerStyle.priorityPropertyKeys = this.uploadForm.priorityPropertyKeys;
    return layerStyle;
  }

  private colorSchemaToColorsPerPropertyValue(
    colorSchema: Map<string, string>,
  ): Map<string, string> {
    const colorsPerPropertyValue = new Map(
      this.propertyColors.map((iconColor: IconColor) => [iconColor.hex, '']),
    );
    for (const [propKey, colorHex] of colorSchema) {
      if (colorsPerPropertyValue.has(colorHex)) {
        colorsPerPropertyValue.set(colorHex, propKey);
      }
    }
    return colorsPerPropertyValue;
  }

  private overrideColorSchema(
    colorSchema: Map<string, string>,
    colorsPerPropertyValue: Map<string, string>,
  ) {
    colorSchema.clear();
    for (const [colorHex, propValue] of colorsPerPropertyValue) {
      if (propValue) {
        colorSchema.set(propValue, colorHex);
      }
    }
  }

  private updatePropertyKeyValue() {
    if (this.uploadForm.selectedLabelPropertyKey) {
      this.selectedPropertyKey.setValue(this.uploadForm.selectedLabelPropertyKey);
    }
  }

  async save() {
    this.loading = true;
    this.uploadForm.selectedLabelPropertyKey = this.selectedPropertyKey.value;
    const layerStyle = this.buildUpdatedLayerStyle();
    if (!layerStyle) {
      console.error(`Unable to build updated layer style for layer with ID '${this.layer!.id}'.`);
      return;
    }
    try {
      await firstValueFrom(this.layersService.updateLayerStyle(this.layer!.id, layerStyle));
      this.snackBar.open('Settings successfully updated', '', {
        duration: ERROR_TOAST_DURATION,
      });
    } catch (error: unknown) {
      console.error(`Unable to update layer with ID '${this.layer!.id}':
          ${error}`);
      this.snackBar.open(`Unable to update '${this.layer!.name} layer`, 'Close');
    }
    this.loading = false;
  }

  /**
   * This function is needed so that material select shows an initial icon.
   * This is required in cases where the input is not a simple array.
   */
  compareSelectedIcons(optionIcon: ProcessedIcon, selectionIcon: ProcessedIcon): boolean {
    return optionIcon.label === selectionIcon.label;
  }

  saveEnabled() {
    // TODO (reubenn): Remove assetLayerSelected when asset layer config updates
    // are fully supported.
    return (
      this.assetLayerSelected ||
      !this.layerHasPointMarker ||
      this.uploadForm.selectedIcon.label !== ''
    );
  }

  displayColors(hexColors: string[]) {
    return hexColors.map((hexColor: string) => this.colorNameByHex.get(hexColor) || '').join(', ');
  }

  assignPropertyColor(colorHex: string, value: string) {
    // Remove previous association if any.
    for (const [assignedColorHex, assignedValue] of this.uploadForm.colorsPerPropertyValue) {
      if (assignedValue === value && assignedColorHex !== colorHex) {
        this.uploadForm.colorsPerPropertyValue.set(assignedColorHex, '');
      }
    }
    // Set new association.
    this.uploadForm.colorsPerPropertyValue.set(colorHex, value);
  }

  private listenForPropertyChanges() {
    this.selectedPropertyKey.valueChanges
      .pipe(
        filter((propertyKey: string | null) => !!propertyKey),
        map((propertyKey: string | null): string => propertyKey!),
        tap((propertyKey: string) => {
          this.propertyValuesLoading = true;
          this.uploadForm.selectedLabelPropertyKey = propertyKey;
        }),
        switchMap((propertyKey: string) => {
          return this.layersService.getLayerPropertyValues(this.layer!.id, propertyKey, false);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((propertyValues: string[]) => {
        this.propertyValuesLoading = false;
        this.propertyValues = propertyValues.filter((propertyValue) => !!propertyValue);
        const valuesToAssign = [...this.propertyValues];
        this.uploadForm.colorsPerPropertyValue = new Map(
          CONFIGURATOR_ICON_COLORS.map((iconColor: IconColor) => [
            iconColor.hex,
            valuesToAssign.shift() || '',
          ]),
        );
      });
  }

  private getColorPalette(): string[] {
    if (this.selectedRadio === BY_LABEL_RADIO_LABEL) {
      // Color palette is replaced with color schema.
      return [];
    }
    return [this.uploadForm.selectedColor];
  }
}

function lowerCaseSort(a: string, b: string) {
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
}

/**
 * @fileoverview Range Filter Module.
 */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSliderModule} from '@angular/material/slider';

import {PipesModule} from '../pipes/pipes_module';
import {ViewsModule} from '../views/views_module';
import {RangeFilter} from './range_filter';

@NgModule({
  declarations: [RangeFilter],
  imports: [CommonModule, FormsModule, MatInputModule, MatSliderModule, PipesModule, ViewsModule],
  exports: [RangeFilter],
  providers: [],
})
export class RangeFilterModule {}

@if (!isImageBlock) {
  <div class="editable-subsection bordered">
    <div class="subsection-heading row-confirmation">
      <div class="asset-confirmation-header">{{ header }}</div>
      <a role="button" class="edit-link" (click)="editFeature()"> Edit </a>
    </div>
    <div class="asset-details-confirmation">
      @for (property of featureProperties; track property) {
        <div class="row-confirmation">
          <div class="asset-key">{{ property.key }}</div>
          <div class="asset-value">{{ property.propertyValue.value }}</div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="subsection-heading asset-confirmation-header">{{ header }}</div>
  @for (image of images; track image; let i = $index, last = $last) {
    <div class="editable-subsection row-confirmation" [ngClass]="{bordered: !last}">
      <div class="asset-key">Image {{ i + 1 }}</div>
      <div class="asset-image-box">
        <img class="thumbnail" [src]="image.url" [alt]="image.name" />
      </div>
      <a role="button" class="edit-link" (click)="editFeature()"> Edit </a>
    </div>
  }
}

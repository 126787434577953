import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

import {PipesModule} from '../../pipes/pipes_module';
import {OfflineSearch} from './offline_search';

@NgModule({
  declarations: [OfflineSearch],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    PipesModule,
    ScrollingModule,
  ],
  exports: [OfflineSearch],
})
export class OfflineSearchModule {}

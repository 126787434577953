import {Timestamp} from '@bufbuild/protobuf';
// eslint-disable-next-line node/no-unpublished-import
import * as d3 from 'd3';
import {Observable, Subject, fromEvent} from 'rxjs';
import {
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';

import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {
  CorrectedImageAnnotation,
  ImageAnnotation,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';

import {AnnotationsService} from '../services/annotations_service';
import {ConfigService} from '../services/config_service';
import {GalleryService} from '../services/gallery_service';
import {LoaderService} from '../services/loader_service';
import {
  DEFAULT_ANNOTATION_COLOR,
  DEFAULT_TEXT_ANNOTATION_COLOR,
  DEFECT_ANNOTATION_COLOR,
  DEFECT_TEXT_ANNOTATION_COLOR,
} from '../styles/constants';
import {
  Annotation,
  AnnotationEditorMode,
  AnnotationInfo,
  AnnotationShape,
  AnnotationType,
  Rectangle,
} from '../typings/annotations';
import {
  buildShapeID,
  createRectShape,
  equals,
  moveRectShape,
  redrawRectShape,
} from '../utils/annotations';
import {getLidarUrl} from '../utils/image';
import {LabelPanel} from './label_panel';
import {type LabelSelection} from './label_selector';

/**
 * Shape info.
 */
interface SVGShape {
  shapeType: AnnotationShape;
  element: SvgRectSelection;
}

/**
 * Coordinates identifying specific overlay location.
 */
interface Position {
  x: number;
  y: number;
}

type SvgRectSelection = d3.Selection<SVGRectElement, unknown, HTMLElement | null, undefined>;

/**
 * Scale by which to zoom in/out on the canvas on explicit zoom action buttons
 * click. Please note that different (d3 default) scaling factor is used
 * on wheel / double click zoom.
 */
const ZOOM_SCALING_FACTOR = 1.2;
const MIN_ZOOM_LEVEL = 1;
const MAX_ZOOM_LEVEL = 10;
const HIDDEN_CLASS = 'hidden';
const DRAGGABLE_CLASS = 'draggable';
const SELECTED_CLASS = 'selected';
const ML_GENERATED_CLASS = 'ml-generated';
const OUT_OF_FOCUS_CLASS = 'out-of-focus';
const DEFECT_CLASS = 'defect';
const STROKE_DASHARRAY_ATTRIBUTE = 'stroke-dasharray';
const MIN_CLICK_DIFF = 2;
// When positioning labels overlay, we will align it by related shape SVG's
// top left corner. This correction is applied to calculated pixels for
// better visual positioning.
const OVERLAY_CORRECTION_POSITION_PIXELS_LEFT = 2;

// When positioning labels overlay, we will align it by related shape SVG's
// top left corner. This correction is applied to calculated pixels for
// better visual positioning. Top correction includes the height of the label
// panel itself.
const OVERLAY_CORRECTION_POSITION_PIXELS_TOP = 32;

// When repositioning labels, add correction for margin.
const OVERLAY_CORRECTION_POSITION_PIXELS_BOTTOM = 3;

// Used to distinguish between double click and fast click.
const POINTER_DOWN_THROTTLE_TIME_MS = 1000;

// Value of stroke-dasharray used on initial zoom level.
const DEFAULT_STROKE_DASHARRAY = 16;

// Value of stroke width used on initial zoom level.
const DEFAULT_STROKE_WIDTH = 3;

/**
 * Image canvas component used for annotating an image with pre-defined shapes
 * and labels. Implemented as a SVG overlay on top of the fixed image canvas.
 */
@Component({
  selector: 'image-canvas',
  templateUrl: './image_canvas.ng.html',
  styleUrls: ['./image_canvas.scss'],
})
export class ImageCanvas implements OnChanges, OnDestroy, OnInit {
  // URL of an image to annotate.
  @Input({required: true}) imageUrl = '';

  // ID of an image to annotate.
  @Input({required: true}) imageId = '';

  // Annotations already defined on the image.
  @Input({required: true}) existingAnnotations!: Annotation[];

  // Direction from which the image was taken (0-360).
  @Input() bearing = 0;

  // Element used for pointer release tracking in case it overflows the editor
  // area. If null the editor pane will be used for pointer release / leave
  // tracking.
  @Input() releaseTrackingElement: HTMLElement | null = null;

  // Editor mode: can be used to switch between view-only, draw and hand tool.
  @Input() editorMode = AnnotationEditorMode.OFF;

  // Allows to explicitly request labels re-positioning (e.g. on non-trivial
  // layout shift).
  @Input() rePositionLabels: Observable<void> = new Subject<void>();

  // Total number of images in the group. Used for navigation between images.
  @Input() totalImageCount = 0;

  // Index of the current image in the group. Used for navigation between
  // images.
  @Input() selectedImageIndex = 0;

  // Show if true. Note this differs from the `carouselEnabled` feature flag.
  @Input() showCarousel = true;

  // If enabled render controls for toggling the carousel.
  @Input() carouselEnabled = false;

  // If enabled then show the back and forward buttons if carouselEnabled=false.
  @Input() showNavActions = true;

  // Callback on previous image navigation.
  @Output() readonly onPrevious = new EventEmitter<void>();

  // Callback on next image navigation.
  @Output() readonly onNext = new EventEmitter<void>();

  // Callback on hiding or showing the carousel.
  @Output() readonly onHideCarousel = new EventEmitter<boolean>();

  // SVG overlay editor pane.
  @ViewChild('editor', {static: true}) annotationEditor!: ElementRef;
  @ViewChild('imageCanvas', {static: true}) imageCanvasRef!: ElementRef;
  @ViewChild('imageContainer', {static: true}) imageContainerRef!: ElementRef;

  // Container for dynamically added label panels.
  @ViewChild('labelsContainer', {read: ViewContainerRef})
  container!: ViewContainerRef;

  // Used to expose the AnnotationEditorMode enum for use in the template.
  EDITOR_MODE = AnnotationEditorMode;

  // The following are used to manage the zoom and pan features.
  minScale = MIN_ZOOM_LEVEL;
  maxScale = MAX_ZOOM_LEVEL;
  currentScale = 1;

  // Arbitrary view box value that will operate well on image resize.
  // NOTE: This must never be changed! It results in annotation coordinates
  // being scaled as if the shorter side of the image were 700 units long. If
  // that constant factor changes, the resulting code will misrender existing
  // annotations and write new ones that will be misrendered by current code.
  viewBox = '0 0 700 700';
  // Annotation SVG border width that's updated according to zoom level.
  strokeWidth = `${DEFAULT_STROKE_WIDTH}`;
  // Annotation SVG border stroke dasharray value that's updated according to
  // zoom level.
  strokeDashArray = `${DEFAULT_STROKE_DASHARRAY}`;
  // Color of both the box and the label tag.
  color = DEFAULT_ANNOTATION_COLOR;
  // Arbitrary values used to restrict shape drag.
  private readonly minX = 0;
  private readonly minY = 0;
  private readonly maxX = 1500;
  private readonly maxY = 1500;

  // Indicator on whether the image has loaded.
  isImageLoaded = false;

  // Indicator on whether the annotation state is being saved at specific
  // moment.
  saving = false;

  annotationCount = 0;

  // Type of shape that can be drawn at any point.
  private readonly currentShapeType = AnnotationShape.RECTANGLE;
  // Will be populated if user is in the middle of shape drawing. Represents a
  // reference to the annotation shape that is currently being drawn. Used to
  // differentiate user intentions between new shape draw and resizing of
  // previously added shape.
  private currentShapeId: string | null = null;
  // Shape that is in unfinished state - meaning has no associated labels. User
  // may be done drawing the shape (currenShapeId is cleared), but not done
  // attaching necessary label info to it. As opposed to the finalized shapes,
  // unfinished shape will be removed if new drawing has been started.
  private unfinishedShapeId = '';
  // Will be populated if user has clicked on any shape.
  private selectedShape: SvgRectSelection | null = null;

  private readonly annotationComponentsByShapeId = new Map<string, ComponentRef<LabelPanel>>();
  private readonly annotationShapesByShapeId = new Map<string, SVGShape>();
  private readonly annotationsByShapeId = new Map<string, Annotation>();

  // The following are used to keep track of the 'dirty' state of annotations
  // (whether any modifications have been made).
  private readonly shapeIdsForSave: string[] = [];
  private readonly newShapeIds: string[] = [];

  // Whether the annotations overlay should be displayed.
  isAnnotationsLayerOn = true;

  // Provide lidar controls if enabled.
  lidarEnabled = true;

  // A set of all image ids with prefetched lidar images.
  lidarExistsById = new Set<string>();

  // Specifies a LiDAR image URL.
  lidarImageUrl = '';

  // If true then show lidar image.
  showLidarImage = false;

  // If true then show layers button.
  showLayers = false;

  // If true then show layers button to toggle layers.
  showLayersContainer = false;

  // Whether the image orientation info should be displayed.
  showCompass = true;

  protected assetTimelineEnabled = false;

  /** Object that controls zoom operations. */
  private zoom?: d3.ZoomBehavior<HTMLElement, unknown>;
  /** Element used to track zoom/pan interactions. */
  private zoomContainer?: d3.Selection<HTMLElement, unknown, HTMLElement | null, unknown>;
  private zoomContainerWidth: number = Infinity;
  private zoomContainerHeight: number = Infinity;

  private readonly destroyed = new Subject<void>();

  private currentSelectedId = '';

  /**
   * Callback for when dragging ends.
   * Used to put annotations back in place.
   */
  onZoomEnd() {
    this.rePositionAllLabelPanels();
  }

  /**
   * Callback for zoom/pan operations.
   */
  onZoom(
    transform: {x: number; y: number; k: number},
    image: d3.Selection<HTMLElement, unknown, HTMLElement | null, undefined>,
    overlay: d3.Selection<HTMLElement, unknown, HTMLElement | null, undefined>,
  ) {
    this.hideAllLabels();
    this.applyTransform(image, transform.x, transform.y, transform.k);
    this.applyTransform(overlay, transform.x, transform.y, transform.k);
    this.reCalculateStrokeOnZoom(transform.k);
    this.updateStrokeDasharray();
    this.rePositionAllLabelPanels();
    this.currentScale = transform.k;
  }

  zoomIn() {
    this.zoomContainer!.call(this.zoom!.scaleBy, ZOOM_SCALING_FACTOR);
  }

  zoomOut() {
    this.zoomContainer!.call(this.zoom!.scaleBy, 1 / ZOOM_SCALING_FACTOR);
  }

  resetZoomState() {
    this.zoomContainer!.call(this.zoom!.scaleTo, 1);
    this.rePositionAllLabelPanels();
  }

  private initZoom() {
    this.zoomContainer = d3.select<HTMLElement, unknown>('#canvasContainer');
    this.zoomContainerWidth = this.zoomContainer.node()!.offsetWidth;
    this.zoomContainerHeight = this.zoomContainer.node()!.offsetHeight;
    this.zoom = d3
      .zoom<HTMLElement, unknown>()
      .scaleExtent([this.minScale, this.maxScale])
      .extent([
        [0, 0],
        [this.zoomContainerWidth, this.zoomContainerHeight],
      ])
      .translateExtent([
        [0, 0],
        [this.zoomContainerWidth, this.zoomContainerHeight],
      ])
      .filter((event) => {
        if (event.type === 'mousedown') {
          // Disallow pan in draw mode.
          return this.editorMode !== AnnotationEditorMode.DRAW;
        }
        return true;
      });

    this.zoom.on('end', () => {
      this.onZoomEnd();
    });

    this.zoom.on('zoom', (event) => {
      this.onZoom(
        event.transform,
        d3.select<HTMLElement, unknown>('#imageCanvas'),
        d3.select<HTMLElement, unknown>('#editor'),
      );
    });

    this.zoomContainer.call(this.zoom);
    this.zoomContainer.call(this.zoom.scaleTo, 1);
  }

  ngOnInit() {
    this.assetTimelineEnabled = this.configService.assetTimelineEnabled;
    if (this.lidarEnabled) {
      this.galleryService.lidarImageLoaded.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.checkLidar();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateImageBearing(changes);
    this.updateEditableLabelPanels(changes);
    this.checkLidar();
    if (changes['imageUrl']) {
      this.isImageLoaded = false;
      this.cleanUpAnnotationsState();
      this.loaderService.showIndeterminateProgress.next(!this.isImageLoaded);
    }
    if (this.isAnnotationsStateChanged(changes)) {
      const newAnnotations = changes['existingAnnotations'].currentValue as Annotation[];
      if (newAnnotations.length > 0 && !this.isImageLoaded) {
        return;
      }
      this.cleanUpAnnotationsState();
      this.reRenderAnnotationShapes();
    }
  }

  private cleanUpAnnotationsState() {
    d3.select('#editor').selectAll('rect').on('focusin', null).on('focusout', null).remove();

    this.annotationsByShapeId.clear();
    this.annotationComponentsByShapeId.clear();
    this.annotationsByShapeId.clear();
    this.container?.clear();
  }

  private isAnnotationsStateChanged(changes: SimpleChanges) {
    const change = changes['existingAnnotations'];
    if (!change) {
      return false;
    }
    const previousAnnotations = (change.previousValue as Annotation[]) || [];
    const newAnnotations = (change.currentValue as Annotation[]) || [];
    if (previousAnnotations.length !== newAnnotations.length) {
      return true;
    }
    for (const annotation of previousAnnotations) {
      if (newAnnotations.find((newAnnotation) => equals(annotation, newAnnotation)) === undefined) {
        return true;
      }
    }
    return false;
  }

  private updateImageBearing(changes: SimpleChanges) {
    const change = changes['bearing'];
    if (change) {
      this.updateArrowAngle(change.currentValue);
    }
  }

  private updateEditableLabelPanels(changes: SimpleChanges) {
    const change = changes['editorMode'];
    if (
      change?.previousValue === AnnotationEditorMode.OFF ||
      change?.currentValue === AnnotationEditorMode.OFF
    ) {
      for (const [shapeId, componentRef] of this.annotationComponentsByShapeId) {
        const annotation = this.annotationsByShapeId.get(shapeId);
        if (!annotation) {
          continue;
        }
        componentRef.setInput(
          'editable',
          this.editorMode !== AnnotationEditorMode.OFF &&
            !this.isAnnotationReadOnly(annotation.info),
        );
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.zoomContainer?.on('.zoom', null);
    this.loaderService.showIndeterminateProgress.next(false);
    this.cleanUpAnnotationsState();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.rePositionAllLabelPanels();
  }

  constructor(
    private readonly annotationsService: AnnotationsService,
    private readonly configService: ConfigService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly galleryService: GalleryService,
    private readonly loaderService: LoaderService,
    private readonly snackBar: MatSnackBar,
  ) {}

  onImageLoad() {
    this.isImageLoaded = true;
    this.loaderService.showIndeterminateProgress.next(!this.isImageLoaded);
    this.initZoom();
    this.checkLidar();

    this.reRenderAnnotationShapes();
    this.listenForInput();
    this.rePositionLabels.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.rePositionAllLabelPanels();
    });
    this.annotationsService.annotationsStateChanged
      .pipe(takeUntil(this.destroyed))
      .subscribe((annotations: Annotation[]) => {
        this.updateHiddenAnnotations(annotations);
      });
    if (this.bearing !== 0) {
      this.updateArrowAngle(this.bearing);
    }
  }

  onImageLoaded() {
    this.isImageLoaded = true;
    this.loaderService.showIndeterminateProgress.next(!this.isImageLoaded);
  }

  updateImage(showLidar = false) {
    this.isImageLoaded = false;
    this.loaderService.showIndeterminateProgress.next(!this.isImageLoaded);
    this.showLidarImage = showLidar;
    this.checkLidar();
  }

  updateHiddenAnnotations(annotations: Annotation[]) {
    this.existingAnnotations = annotations;
    for (const [shapeId, annotation] of this.annotationsByShapeId) {
      const isHidden =
        annotations.find((newAnnotation) => equals(newAnnotation, annotation))?.isHidden || false;
      if (isHidden !== this.isShapeHidden(shapeId) && shapeId) {
        isHidden ? this.hideShape(shapeId) : this.unhideShape(shapeId);
      }
    }
  }

  processAnnotation(shapeId: string, annotationInfo: AnnotationInfo) {
    if (!this.annotationsByShapeId.has(shapeId)) {
      return;
    }
    const data = this.annotationsByShapeId.get(shapeId)!;
    data.info = annotationInfo;
    if (this.unfinishedShapeId === shapeId) {
      this.unfinishedShapeId = '';
    }
    this.propagateAnnotationChange(shapeId, true);
  }

  toggleCarousel() {
    this.showCarousel = !this.showCarousel;
    this.onHideCarousel.emit(this.showCarousel);
  }

  // Re-adjusts arrow on a compass according to the provided angle.
  private updateArrowAngle(angle: number) {
    if (this.showCompass) {
      const newAngle = 360 - angle;
      d3.select('#compass').select('#arrows').attr('transform', `rotate(${newAngle})`);
      let radAngle = newAngle * (Math.PI / 180);
      const xVert = 40 * Math.sin(radAngle);
      const yVert = 40 * Math.cos(radAngle) - 37;
      d3.select('#n').attr('transform', `translate(${xVert}, ${-yVert})`);
      d3.select('#s').attr('transform', `translate(${-xVert}, ${yVert})`);
      radAngle = (newAngle - 90) * (Math.PI / 180);
      const xHor = 40 * Math.sin(radAngle) + 39;
      const yHor = 40 * Math.cos(radAngle);
      d3.select('#w').attr('transform', `translate(${xHor}, ${-yHor})`);
      d3.select('#e').attr('transform', `translate(${-xHor}, ${yHor})`);
    }
  }

  // Renders the pre-existing annotations on the canvas.
  private reRenderAnnotationShapes() {
    this.annotationCount = this.existingAnnotations.length;
    for (const annotation of this.existingAnnotations) {
      const shape = annotation.shapeData;
      const topLeft = {x: shape.left, y: shape.top};
      const bottomRight = {
        x: shape.left + shape.width,
        y: shape.top + shape.height,
      };
      const id = this.createShape(
        topLeft,
        bottomRight,
        annotation.shapeType,
        annotation.info.isMachineGenerated,
        annotation.info?.label?.type || AnnotationType.ASSET,
        this.editorMode !== AnnotationEditorMode.OFF && this.isAnnotationReadOnly(annotation.info),
      );
      if (id !== null) {
        this.createLabelPanelComponent(id, annotation.info);
        this.annotationsByShapeId.set(id, annotation);
        if (id && annotation.isHidden) {
          this.hideShape(id);
        }
      }
    }
  }

  private isShapeHidden(shapeId: string): boolean {
    return this.annotationShapesByShapeId.get(shapeId)?.element.classed(HIDDEN_CLASS) || false;
  }

  private hideShape(shapeId: string) {
    this.annotationShapesByShapeId.get(shapeId)?.element.classed(HIDDEN_CLASS, true);
    this.annotationComponentsByShapeId.get(shapeId)?.setInput('hiddenInput', true);
  }

  private unhideShape(shapeId: string) {
    this.annotationShapesByShapeId.get(shapeId)?.element.classed(HIDDEN_CLASS, false);
    const componentRef = this.annotationComponentsByShapeId.get(shapeId);
    if (componentRef) {
      componentRef.setInput('hiddenInput', false);
      this.positionLabelPanelNextToShape(shapeId, componentRef);
      this.changeDetectorRef.detectChanges();
    }
  }

  // Capture all user interaction events on a drawing canvas.
  private listenForInput() {
    const editorPane = this.annotationEditor.nativeElement as HTMLElement;
    this.handleDrawEvents(editorPane, this.releaseTrackingElement);
    this.handleKeyPressEvents(editorPane);
  }

  // If LiDAR is enabled then show the layers control for rendering it.
  private checkLidar() {
    if (this.lidarEnabled) {
      this.lidarExistsById = this.galleryService.lidarExistsById;
      this.lidarImageUrl = getLidarUrl(this.imageId);

      // If no LiDAR image exists then just show the existing image.
      if (!this.lidarExistsById.has(this.imageId)) {
        this.lidarImageUrl = this.imageUrl;
        this.showLidarImage = false;
        this.showLayersContainer = false;
      } else {
        this.showLayersContainer = true;
      }
    }
  }

  // Capture all pointer down events followed by pointer move.
  // This accounts for both shape drawing mode and drag-and-drop on existing
  // shapes. Please note that the pointer release / leave can be tracked on a
  // different element if the desired interaction area overflows the editor.
  private handleDrawEvents(element: HTMLElement, releaseTrackingElement: HTMLElement | null) {
    this.onDrawStart(element, 'pointerdown')
      .pipe(
        filter((startPosition: Position) => startPosition.x !== 0 && startPosition.y !== 0),
        switchMap((startPosition: Position) => {
          return this.onDraw(element, 'pointermove', startPosition).pipe(
            takeUntil(fromEvent(releaseTrackingElement || element, 'pointerup')),
            takeUntil(fromEvent(releaseTrackingElement || element, 'pointerleave')),
            finalize(() => {
              if (this.isAnnotationsLayerOn && this.isNoShapeSelected()) {
                this.updateHiddenAnnotations(this.existingAnnotations);
              }
              if (this.editorMode !== AnnotationEditorMode.DRAW) {
                return;
              }
              if (this.currentShapeId !== null) {
                // Create mode - add label panel next to the shape.
                const id = this.currentShapeId;
                this.createLabelPanelComponent(id);
                this.setUpAnnotation(id);
                this.unfinishedShapeId = id;
                this.newShapeIds.push(id);
              } else {
                // Drag and drop mode.
                const shapeId = this.selectedShape?.attr('id');
                if (!shapeId) {
                  return;
                }
                this.setUpAnnotation(shapeId);
                const labelPanel = this.annotationComponentsByShapeId.get(shapeId)!;
                this.positionLabelPanelNextToShape(shapeId, labelPanel);
              }
              this.currentShapeId = null;
              this.selectedShape = null;
            }),
          );
        }),
      )
      .subscribe((positions: Position[]) => {
        const startClickPosition = positions[0];
        const currentPosition = positions[1];
        if (this.editorMode !== AnnotationEditorMode.DRAW) {
          return;
        }
        if (this.isNoShapeSelected()) {
          // Create mode.
          this.createOrRedrawShape(startClickPosition, currentPosition);
          return;
        }
        // Drag and drop mode.
        const shapeId = this.selectedShape?.attr('id');
        if (!shapeId) {
          return;
        }
        const shape = this.annotationShapesByShapeId.get(shapeId);
        if (!shape) {
          return;
        }
        if (shape.shapeType !== AnnotationShape.RECTANGLE) {
          // Only rectangle is supported as the annotation shape at the
          // moment.
          return;
        }
        this.propagateAnnotationChange(shapeId, true);
        moveRectShape(
          this.selectedShape!,
          startClickPosition,
          currentPosition,
          {x: this.minX, y: this.minY},
          {x: this.maxX, y: this.maxY},
        );
      });
  }

  // Capture all pointer down events.
  // Prepares the ground for both shape drawing and drag-and-drop mode.
  private onDrawStart(element: HTMLElement, eventName: string): Observable<Position> {
    return fromEvent(element, eventName).pipe(
      map((event: Event): MouseEvent => event as MouseEvent),
      filter((event: MouseEvent) => event?.button === 0 && event.target !== null),
      throttleTime(POINTER_DOWN_THROTTLE_TIME_MS),
      tap((event: MouseEvent) => {
        this.unFocusShape(this.currentSelectedId);
        if (this.editorMode === AnnotationEditorMode.DRAW) {
          const target = this.eventTarget(event);
          if (target && this.isDraggable(target)) {
            this.selectedShape = target;
          }
        }
      }),
      map((startEvent: MouseEvent): Position => {
        const clickPosition = this.getSVGPosition(startEvent);
        if (
          this.editorMode !== AnnotationEditorMode.OFF &&
          this.isAnnotationsLayerOn &&
          this.isNoShapeSelected()
        ) {
          this.hideAllAnnotations();
        }
        return this.selectedShape
          ? // In case of click on a shape, track which
            // part of the shape in order to move it
            // appropriately.
            this.getClickOffsetPosition(this.selectedShape, clickPosition)
          : // Otherwise track initial click position.
            clickPosition;
      }),
    );
  }

  // Capture all pointer move events following pointer down.
  private onDraw(
    element: HTMLElement,
    eventName: string,
    startPosition: Position,
  ): Observable<Position[]> {
    if (
      this.isAnnotationsLayerOn &&
      this.editorMode === AnnotationEditorMode.DRAW &&
      this.isNoShapeSelected()
    ) {
      this.hideAllAnnotations();
    }
    return fromEvent(element, eventName).pipe(
      tap((event: Event) => {
        event.preventDefault();
      }),
      map((currentEvent: Event): Position => this.getSVGPosition(currentEvent as MouseEvent)),
      // Prevents micro pointer moves from triggering shape
      // creation.
      filter((currentPosition: Position) => {
        const x = Math.abs(startPosition.x - currentPosition.x);
        const y = Math.abs(startPosition.y - currentPosition.y);
        return x > MIN_CLICK_DIFF && y > MIN_CLICK_DIFF;
      }),
      map((currentPosition: Position): Position[] => [startPosition, currentPosition]),
    );
  }

  private hideAllAnnotations() {
    for (const [shapeId] of this.annotationsByShapeId) {
      this.hideShape(shapeId);
    }
  }

  private hideAllLabels() {
    for (const [, componentRef] of this.annotationComponentsByShapeId) {
      componentRef.setInput('hiddenInput', true);
    }
  }

  // Capture all click events on shape followed by key press.
  // Allows the shapes to be deleted on 'Delete' key press.
  private handleKeyPressEvents(element: HTMLElement) {
    if (this.editorMode === AnnotationEditorMode.OFF) {
      return;
    }
    fromEvent(element, 'mousedown')
      .pipe(
        filter((event: Event) => (event as MouseEvent).button === 0),
        filter((event: Event) => {
          const target = this.eventTarget(event);
          return target ? this.isDraggable(target) : false;
        }),
        map((event: Event): HTMLElement => event.target as HTMLElement),
        switchMap((selectedShape: HTMLElement) => {
          // After click record key press.
          return fromEvent(element, 'keydown').pipe(
            filter((event: Event) => this.isDeleteKeyPress(event as KeyboardEvent)),
            // Stop once the user clicks again.
            takeUntil(fromEvent(element, 'mousedown')),
            map(() => selectedShape),
          );
        }),
      )
      .subscribe((selectedShape: HTMLElement) => {
        const shapeId = selectedShape?.getAttribute('id');
        if (!shapeId) {
          return;
        }
        this.deleteAnnotation(shapeId, true);
      });
  }

  private getShapeData(shape: SVGShape): Rectangle | null {
    switch (shape.shapeType) {
      case AnnotationShape.RECTANGLE: {
        const left = Number(shape.element.attr('x'));
        const top = Number(shape.element.attr('y'));
        const width = Number(shape.element.attr('width'));
        const height = Number(shape.element.attr('height'));
        return {top, left, width, height};
      }
      default:
        // TODO(halinab): implementation for arrow and other shapes.
        return null;
    }
  }

  private createOrRedrawShape(start: Position, current: Position) {
    if (this.currentShapeType !== AnnotationShape.RECTANGLE) {
      // Only rectangle is supported as the annotation shape at the moment.
      return;
    }
    if (this.currentShapeId !== null) {
      // User is in the middle of rectangular shape resize.
      const shape = this.annotationShapesByShapeId.get(this.currentShapeId);
      if (!shape) {
        return;
      }
      redrawRectShape(shape.element, start, current);
      return;
    }
    // User has initiated new shape draw. Remove previous unfinished annotation
    // if any.
    this.deleteAnnotation(this.unfinishedShapeId);
    this.currentShapeId = this.createShape(
      start,
      current,
      this.currentShapeType,
      false,
      AnnotationType.ASSET,
    );
  }

  private createShape(
    pointA: Position,
    pointB: Position,
    shapeType: AnnotationShape,
    isMlGenerated: boolean,
    labelType: AnnotationType,
    isReadOnly = false,
  ): string | null {
    if (shapeType !== AnnotationShape.RECTANGLE) {
      // Only rectangle is supported as the annotation shape at the moment.
      return null;
    }
    const id = buildShapeID();
    const parentSvg = d3.select<SVGElement, unknown>('#editor');
    const newShape = createRectShape(parentSvg, id, pointA, pointB);
    if (!newShape) {
      console.error('error building shape');
      return null;
    }
    if (!isReadOnly) {
      this.makeDraggable(newShape);
    }
    if (isMlGenerated) {
      newShape.attr(STROKE_DASHARRAY_ATTRIBUTE, this.strokeDashArray);
      newShape.classed(ML_GENERATED_CLASS, true);
    }
    if (labelType === AnnotationType.DEFECT) {
      newShape.classed(DEFECT_CLASS, true);
    }
    newShape.on('focusin', () => {
      this.unFocusShape(this.currentSelectedId);
      this.currentSelectedId = id;
      this.focusShape(id);
    });
    newShape.on('focusout', () => {
      this.unFocusShape(id);
      this.currentSelectedId = '';
    });
    this.annotationShapesByShapeId.set(id, {shapeType, element: newShape});
    return id;
  }

  private focusShape(targetShapeId: string) {
    for (const [shapeId, shape] of this.annotationShapesByShapeId) {
      const shapeClass = shapeId === targetShapeId ? SELECTED_CLASS : OUT_OF_FOCUS_CLASS;
      shape.element.classed(shapeClass, true);
    }
    for (const [shapeId, labelComponent] of this.annotationComponentsByShapeId) {
      labelComponent.setInput(shapeId === targetShapeId ? 'focused' : 'outOfFocus', true);
    }
  }

  private unFocusShape(targetShapeId: string) {
    for (const [shapeId, shape] of this.annotationShapesByShapeId) {
      const shapeClass = shapeId === targetShapeId ? SELECTED_CLASS : OUT_OF_FOCUS_CLASS;
      shape.element.classed(shapeClass, false);
    }
    for (const [shapeId, labelComponent] of this.annotationComponentsByShapeId) {
      labelComponent.setInput(shapeId === targetShapeId ? 'focused' : 'outOfFocus', false);
    }
  }

  private deleteAnnotation(shapeId: string, isUserInitiated = false) {
    if (!shapeId || shapeId.length === 0) {
      return;
    }
    if (this.newShapeIds.includes(shapeId)) {
      this.removeUnsavedId(shapeId);
    }
    const shape = this.annotationShapesByShapeId.get(shapeId);
    if (!shape) {
      return;
    }
    shape.element.on('focusin', null);
    shape.element.on('focusout', null);
    shape.element.remove();
    this.removeLabelPanelComponent(shapeId);
    this.annotationShapesByShapeId.delete(shapeId);
    this.annotationComponentsByShapeId.delete(shapeId);
    this.annotationsByShapeId.delete(shapeId);
    if (this.unfinishedShapeId === shapeId) {
      this.unfinishedShapeId = '';
    }
    if (isUserInitiated) {
      this.propagateAnnotationChange(shapeId);
    }
  }

  private removeUnsavedId(id: string) {
    const index = this.shapeIdsForSave.findIndex((el) => el === id);
    if (index > -1) {
      this.shapeIdsForSave.splice(index, 1);
    }
  }

  private createLabelPanelComponent(shapeId: string, annotationInfo: AnnotationInfo | null = null) {
    // Workaround for dynamic components on ViewChild.
    // Please see https://github.com/angular/angular/issues/11007.
    setTimeout(() => {
      if (!this.container) {
        return;
      }
      const componentRef: ComponentRef<LabelPanel> =
        this.annotationComponentsByShapeId.get(shapeId) ||
        this.container.createComponent(LabelPanel);
      this.positionLabelPanelNextToShape(shapeId, componentRef);
      componentRef.setInput('annotationInfo', annotationInfo);
      componentRef.setInput(
        'editable',
        this.editorMode !== AnnotationEditorMode.OFF && !this.isAnnotationReadOnly(annotationInfo),
      );
      this.applyColorToLabel(componentRef, annotationInfo?.label?.type === AnnotationType.DEFECT);
      componentRef.instance.onComplete
        .pipe(takeUntil(this.destroyed))
        .subscribe((data: AnnotationInfo) => {
          const isDefect = data?.label?.type === AnnotationType.DEFECT;
          this.applyColorToLabel(componentRef, isDefect);
          const shapeElement = this.annotationShapesByShapeId.get(shapeId)?.element;
          if (shapeElement) {
            shapeElement.classed(DEFECT_CLASS, isDefect);
          }
          this.processAnnotation(shapeId, data);
        });
      componentRef.instance.onClear.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.deleteAnnotation(shapeId, true);
      });
      componentRef.instance.onSelect.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.unFocusShape(this.currentSelectedId);
        this.currentSelectedId = shapeId;
        this.focusShape(shapeId);
      });
      componentRef.instance.onClose.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.currentSelectedId = '';
        this.unFocusShape(shapeId);
      });

      // Reposition after view init due to label width changes.
      // TODO(b/322727002) Fix noticeable layout shift due to repositioning.
      componentRef.instance.onReady.pipe(takeUntil(this.destroyed)).subscribe(() => {
        setTimeout(() => {
          this.positionLabelPanelNextToShape(shapeId, componentRef);
        }, 10);
      });
      componentRef.instance.onCorrectAnnotation
        .pipe(
          takeUntil(this.destroyed),
          mergeMap((selection: LabelSelection) =>
            this.annotationsService.correctImageAnnotation(
              this.imageId,
              annotationInfo!.sourceAnnotation,
              selection.label.value,
              selection.comment,
            ),
          ),
        )
        .subscribe({
          next: (corrected: CorrectedImageAnnotation) => {
            const sourceAnnotation = annotationInfo?.sourceAnnotation.clone();
            if (sourceAnnotation) {
              sourceAnnotation.corrected = corrected;
            }
            componentRef.setInput('annotationInfo', {
              ...annotationInfo,
              sourceAnnotation,
            });
            this.snackBar.open('Image annotation corrected.', '', {
              duration: 3000,
            });
          },
          error: (error: Error) => {
            // Handle the error flow in a different way so that the observable
            // doesn't close.
            console.error('error correcting image annotation', error);
            this.snackBar.open('Image annotation correction failed.', 'Dismiss');
          },
        });
      this.annotationComponentsByShapeId.set(shapeId, componentRef);
    }, 100);
  }

  /**
   * On the initial render, show off-screen labels if the user has not hidden
   * them. When zooming though, hide the off-screen labels as the user has
   * chosen to focus on a part of the image and likely does not want to see
   * off-screen labels.
   */
  private positionLabelPanelNextToShape(
    shapeId: string,
    componentRef: ComponentRef<LabelPanel>,
    showOffscreenLabels = true,
  ) {
    const shapeElement = this.annotationShapesByShapeId.get(shapeId)?.element;
    const shape = shapeElement?.node() || null;
    if (!shape) {
      return;
    }
    // Only show off-screen labels on default zoom (i.e. not zoomed in).
    const isDefaultZoom = this.currentScale === MIN_ZOOM_LEVEL;

    const boundingRect = shape.getBoundingClientRect();
    const newX = boundingRect.left + window.scrollX - OVERLAY_CORRECTION_POSITION_PIXELS_LEFT;
    const newY = boundingRect.top + window.scrollY - OVERLAY_CORRECTION_POSITION_PIXELS_TOP;

    // TODO(b/322727002) Replace with something more Angular-like.
    const label = componentRef.location.nativeElement.firstChild;
    const labelWidth = Number(label.offsetWidth);
    const labelHeight = Number(label.offsetHeight);

    if (this.isLabelPanelInsideContainer(newX, newY, labelWidth, labelHeight)) {
      componentRef.setInput('x', newX);
      componentRef.setInput('y', newY);
      componentRef.setInput('hiddenInput', false);
    } else if (showOffscreenLabels && (isDefaultZoom || shapeId === this.unfinishedShapeId)) {
      this.rePositionInsideContainer(newX, newY, componentRef, shape, labelWidth, labelHeight);

      // Only show label if its shape is not hidden by the user.
      if (!shapeElement?.classed(HIDDEN_CLASS)) {
        componentRef.setInput('hiddenInput', false);
      }
    } else {
      componentRef.setInput('hiddenInput', true);
    }
  }

  private isLabelPanelInsideContainer(
    newX: number,
    newY: number,
    labelWidth: number,
    labelHeight: number,
  ): boolean {
    const imageContainer = this.imageContainerRef.nativeElement;
    const imageContainerRect = imageContainer.getBoundingClientRect();

    return (
      newX >= imageContainerRect.left &&
      newX + labelWidth <= imageContainerRect.right &&
      newY >= imageContainerRect.top &&
      newY + labelHeight <= imageContainerRect.bottom
    );
  }

  /*
   * Reposition labels to be inside the image container.
   * TODO(b/322727002) Improve handling of off-screen labels, such as
   * when labels overlap or if a repositioned label is still off-screen.
   */
  private rePositionInsideContainer(
    newX: number,
    newY: number,
    labelRef: ComponentRef<LabelPanel>,
    shapeElement: SVGElement,
    labelWidth: number,
    labelHeight: number,
  ) {
    const image = this.imageContainerRef.nativeElement;
    const imageContainerRect = image.getBoundingClientRect();

    const shapeRect = shapeElement.getBoundingClientRect();
    const shapeX = shapeRect.left;
    const shapeY = shapeRect.top;
    const shapeHeight = shapeRect.height;

    // Place label next to its shape if in bounds or default to the top left.
    let fixedX =
      shapeX > imageContainerRect.left && shapeX < imageContainerRect.right
        ? newX
        : imageContainerRect.left;
    let fixedY =
      shapeY > imageContainerRect.top && shapeY < imageContainerRect.bottom
        ? newY
        : imageContainerRect.top;

    // If outside the right boundary, place label to the left of the shape.
    if (newX + labelWidth > imageContainerRect.right) {
      fixedX = shapeX - labelWidth;
    }
    // If outside the top boundary, place label to the bottom of the shape.
    if (shapeY - labelHeight < imageContainerRect.top) {
      fixedY = shapeY + shapeHeight - OVERLAY_CORRECTION_POSITION_PIXELS_BOTTOM;
    }
    // If outside the bottom boundary, place label to the top of the shape.
    if (newY + labelHeight > imageContainerRect.bottom) {
      fixedY = shapeY - shapeHeight - labelHeight;
    }

    labelRef.setInput('x', fixedX - OVERLAY_CORRECTION_POSITION_PIXELS_LEFT);
    labelRef.setInput('y', fixedY);
  }

  private rePositionAllLabelPanels() {
    for (const [shapeId, componentRef] of this.annotationComponentsByShapeId) {
      this.positionLabelPanelNextToShape(shapeId, componentRef);
    }
    this.changeDetectorRef.detectChanges();
  }

  private removeLabelPanelComponent(shapeId: string) {
    this.annotationComponentsByShapeId.get(shapeId)?.destroy();
  }

  // Retrieves SVG position of the mouse click event.
  private getSVGPosition(event: MouseEvent): Position {
    return this.pixelToSVGPosition({x: event.clientX, y: event.clientY});
  }

  // Uses Screen CTM (Current Transformation Matrix) to convert from the
  // screen coordinate system to SVG coordinate system.
  private pixelToSVGPosition(pixelPosition: Position): Position {
    const CTM = this.annotationEditor.nativeElement.getScreenCTM();
    const point = new DOMPoint(pixelPosition.x, pixelPosition.y);
    return point.matrixTransform(CTM.inverse());
  }

  // Calculates the offset from click position to element's top left corner.
  private getClickOffsetPosition(el: SvgRectSelection, clickPosition: Position) {
    const left = Number(el.attr('x'));
    const top = Number(el.attr('y'));
    const x = clickPosition.x - left;
    const y = clickPosition.y - top;
    return {x, y};
  }

  private makeDraggable(el: SvgRectSelection) {
    el.classed(DRAGGABLE_CLASS, true);
  }

  private isDraggable(el: SvgRectSelection): boolean {
    return el.classed(DRAGGABLE_CLASS);
  }

  private isDeleteKeyPress(event: KeyboardEvent): boolean {
    const key = event.key;
    return key === 'Backspace' || key === 'Delete';
  }

  private setUpAnnotation(shapeId: string) {
    const emptyAnnotation = {
      info: {
        label: null,
        comment: '',
        updatedAt: new Timestamp(),
        createdAt: new Timestamp(),
        isMachineGenerated: false,
        machineModelVersion: '',
        machineConfidenceScore: 0,
        sourceAnnotation: new ImageAnnotation(),
      },
      shapeType: AnnotationShape.RECTANGLE,
      shapeData: {top: 0, left: 0, width: 0, height: 0},
    };
    const annotation = this.annotationsByShapeId.get(shapeId) || emptyAnnotation;
    const shape = this.annotationShapesByShapeId.get(shapeId);
    if (!shape) {
      return;
    }
    const shapeGeometryData = this.getShapeData(shape);
    if (!shapeGeometryData) {
      return;
    }
    annotation.shapeData = shapeGeometryData;
    annotation.shapeType = shape.shapeType;
    this.annotationsByShapeId.set(shapeId, annotation);
  }

  // Updates the stroke-dasharray property for eligible elements.
  // TODO(halinab): come up with a way to use data binding instead.
  private updateStrokeDasharray() {
    for (const [, shape] of this.annotationShapesByShapeId) {
      if (shape.element.attr(STROKE_DASHARRAY_ATTRIBUTE)) {
        shape.element.attr(STROKE_DASHARRAY_ATTRIBUTE, this.strokeDashArray);
      }
    }
  }

  private reCalculateStrokeOnZoom(scale: number) {
    this.strokeWidth =
      scale === 1 ? `${DEFAULT_STROKE_WIDTH}` : (DEFAULT_STROKE_WIDTH / scale).toString();
    this.strokeDashArray =
      scale === 1 ? `${DEFAULT_STROKE_DASHARRAY}` : (DEFAULT_STROKE_DASHARRAY / scale).toString();
  }

  private isNoShapeSelected() {
    return this.selectedShape === null;
  }

  private isAnnotationReadOnly(annotationInfo?: AnnotationInfo | null): boolean {
    return annotationInfo?.isMachineGenerated || false;
  }

  private applyTransform(
    element: d3.Selection<HTMLElement, unknown, HTMLElement | null, undefined>,
    translateX: number,
    translateY: number,
    scale: number,
  ) {
    element.style('transform', `scale(${scale})`);
    element.style('left', `${translateX}px`);
    element.style('top', `${translateY}px`);
  }

  private propagateAnnotationChange(shapeId: string, isExplicitlyVisible = false) {
    this.shapeIdsForSave.push(shapeId);
    if (isExplicitlyVisible && this.annotationsByShapeId.has(shapeId)) {
      this.annotationsByShapeId.get(shapeId)!.isExplicitlyVisible = true;
    }
    const updatedState = {
      id: this.imageId,
      annotations: Array.from(this.annotationsByShapeId.values()),
      updatedImadeURL: this.imageUrl,
    };
    this.annotationsService.addPendingAnnotations(this.imageId, updatedState);
  }

  private eventTarget(event: Event): SvgRectSelection | null {
    if (!event.target) {
      return null;
    }
    const id = (event.target as HTMLElement).getAttribute('id');
    return id ? d3.select<SVGRectElement, unknown>(`#${id}`) : null;
  }

  private applyColorToLabel(componentRef: ComponentRef<LabelPanel>, isDefect: boolean) {
    componentRef.setInput('color', isDefect ? DEFECT_ANNOTATION_COLOR : this.color);
    componentRef.setInput(
      'textColor',
      isDefect ? DEFECT_TEXT_ANNOTATION_COLOR : DEFAULT_TEXT_ANNOTATION_COLOR,
    );
  }
}

<ng-container>
  <div class="comment">
    <div class="comment-body">{{ comment }}</div>
    <button mat-button [disableRipple]="true" (click)="replying = !replying" class="reply-button">
      {{ replying ? 'Close' : 'Reply' }}
    </button>
    @if (replying) {
      <comment-form [featureId]="featureId" (commentComplete)="refreshComments($event)">
      </comment-form>
    }
  </div>
</ng-container>

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {MatChipListboxChange} from '@angular/material/chips';

import {FilterField} from '../constants/filters';
import {LayersFilterService} from '../services/layers_filter_service';
import {AnnotationFilterOption} from '../typings/annotations';
import {FilterMap} from '../typings/filter';

/**
 * Component for filtering image groups by annotations based on whether an
 * image group's images contain no annotations or 1 or more annotations.
 *
 * If no chip is selected (the default) then no filter is applied and all image
 * groups are shown.
 */
@Component({
  selector: 'annotation-filter-chips',
  templateUrl: './annotation_filter_chips.ng.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnotationFilterChips implements OnInit, OnDestroy {
  @Input() layerId = '';

  @Output() readonly removeChip = new EventEmitter<string>();

  filterMap!: FilterMap;
  imageGroupFilter = AnnotationFilterOption.ALL_IMAGE_GROUPS;
  filterOptions = [
    AnnotationFilterOption.NO_ANNOTATIONS,
    AnnotationFilterOption.ONE_OR_MORE_ANNOTATIONS,
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly layersFilterService: LayersFilterService,
  ) {}

  ngOnInit() {
    // Update the UI based on the current annotation filters.
    this.layersFilterService
      .getFilterMap(this.layerId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters: FilterMap) => {
        this.filterMap = structuredClone(filters);
        const annotationFilter = filters[FilterField.IMAGE_GROUPS] || new Set<string>();

        // If no image group filter is selected, show all images.
        this.imageGroupFilter =
          annotationFilter.size === 0
            ? AnnotationFilterOption.ALL_IMAGE_GROUPS
            : (String(Array.from(annotationFilter)[0]) as AnnotationFilterOption);

        this.changeDetectionRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Applies image group filters and removes other filters.
   */
  onImageGroupFilterChange(change: MatChipListboxChange) {
    this.imageGroupFilter = change.value || AnnotationFilterOption.ALL_IMAGE_GROUPS;
    this.updatePhotoFilters();
  }

  /**
   * Update filters in the layersFilterService to propagate the changes
   * to the rest of the UI.
   */
  private updatePhotoFilters() {
    // Filter if images have annotations (i.e. "has none", "has 1 or more").
    if (this.imageGroupFilter !== AnnotationFilterOption.ALL_IMAGE_GROUPS) {
      this.filterMap[FilterField.IMAGE_GROUPS] = new Set([this.imageGroupFilter]);
      // TODO(b/288469959) Improve the UX for  mutually exclusive filters.
      delete this.filterMap[FilterField.ANNOTATION_INCLUDE];
      delete this.filterMap[FilterField.ANNOTATION_EXCLUDE];
      this.changeDetectionRef.markForCheck();
    } else {
      // If "all image groups" is specified it's the same as having no filter.
      delete this.filterMap[FilterField.IMAGE_GROUPS];
    }
    this.layersFilterService.updateFilterMap(this.layerId, this.filterMap, true);
  }
}

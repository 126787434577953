import {Observable, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ChangeDetectorRef, Component, ContentChild, OnDestroy, OnInit} from '@angular/core';

import {BannerContentDirective} from '../banner/banner_content_directive';
import {DEFAULT_SWIPE_CONFIG} from '../constants/swipes';
import {LoaderService} from '../services/loader_service';
import {SidepanelService} from '../services/sidepanel_service';
import {UserPreferencesService} from '../services/user_preferences_service';
import {ColorScheme} from '../typings/common';

/** An Interface to represent coordinates of a screen touch event. */
interface Coordinate {
  x: number;
  y: number;
}

/** An Interface to represent a screen touch event. */
interface Touch {
  // The coordinate of a screen touch.
  coordinate: Coordinate;
  // The time in milliseconds when the touch occurred.
  timeInMs: number;
}

/**
 * Component to render a sidebar with an icon button to collapse or open it.
 * This sidebar can also be closed by a 'RightToLeft' horizontal swipe event on
 * the touch screen devices.
 */
@Component({
  selector: 'sidepanel-layout',
  templateUrl: 'sidepanel_layout.ng.html',
  styleUrls: ['sidepanel_layout.scss'],
})
export class SidepanelLayout implements OnInit, OnDestroy {
  @ContentChild(BannerContentDirective) bannerContent?: BannerContentDirective;

  // Used to expose the ColorScheme enum for use in the template.
  COLOR_SCHEME = ColorScheme;

  sidepanelOpened$: Observable<boolean>;
  colorScheme: Observable<ColorScheme>;

  /**
   * If true then show an indeterminate progress bar and do not show
   * 'determineProgress'.
   */
  showIndeterminateProgressBar = false;

  /**
   * The progress of the indeterminate progress bar from 0 to 100.
   * Only shown if 'showIndeterminateProgressBar' is false.
   */
  determinateProgress: number | null = null;

  private touchStart?: Touch;
  private readonly destroyed = new ReplaySubject<void>(1);

  constructor(
    private readonly loaderService: LoaderService,
    private readonly sidepanelService: SidepanelService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sidepanelOpened$ = this.sidepanelService.getSidepanelOpened$();
    this.colorScheme = this.userPreferencesService.getColorScheme();
  }

  ngOnInit() {
    this.loaderService.showIndeterminateProgress
      .pipe(takeUntil(this.destroyed))
      .subscribe((showIndeterminateProgress: boolean) => {
        this.showIndeterminateProgressBar = showIndeterminateProgress;
        this.cdr.detectChanges(); // Needed or else there's a lag.
      });
    this.loaderService.determinateLoadingProgress
      .pipe(takeUntil(this.destroyed))
      .subscribe((loadingProgress: number | null) => {
        this.determinateProgress = loadingProgress;
        this.cdr.detectChanges(); // Needed or else there's a lag.
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  setSidepanelOpened(opened: boolean) {
    this.sidepanelService.setSidepanelOpened(opened);
  }

  swipeStart(touchEvent: TouchEvent): void {
    this.touchStart = {
      coordinate: {
        x: touchEvent.changedTouches[0].clientX,
        y: touchEvent.changedTouches[0].clientY,
      },
      timeInMs: Date.now(),
    };
  }

  swipeEnd(touchEvent: TouchEvent): void {
    if (!this.touchStart) {
      return;
    }

    const touchEnd = {
      coordinate: {
        x: touchEvent.changedTouches[0].clientX,
        y: touchEvent.changedTouches[0].clientY,
      },
      timeInMs: Date.now(),
    };

    const durationInMs = touchEnd.timeInMs - this.touchStart.timeInMs;
    if (durationInMs > DEFAULT_SWIPE_CONFIG.maxDurationInMs) {
      return;
    }

    const diffX = touchEnd.coordinate.x - this.touchStart.coordinate.x;
    const diffY = touchEnd.coordinate.y - this.touchStart.coordinate.y;

    if (Math.abs(diffX) < DEFAULT_SWIPE_CONFIG.minLength) {
      return;
    }

    // We should check that the bounding box (formed by the touch screen start
    // event and the end event) has its horizontal width that exceeds its
    // vertical height by at least the configurable minimum ratio. The bounding
    // box should be stretched enough horizontally to be qualified as a
    // horizontal swipe event.
    if (Math.abs(diffX) < Math.abs(diffY * DEFAULT_SWIPE_CONFIG.minRatio)) {
      return;
    }

    // Checking that the swipe was done from right to left.
    if (diffX < 0) {
      this.setSidepanelOpened(false);
    }
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/emptyservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message tapestry.gridaware.api.v1.SendEmptyRequest
 */
export const SendEmptyRequest = proto3.makeMessageType("tapestry.gridaware.api.v1.SendEmptyRequest", []);

/**
 * @generated from message tapestry.gridaware.api.v1.SendEmptyResponse
 */
export const SendEmptyResponse = proto3.makeMessageType("tapestry.gridaware.api.v1.SendEmptyResponse", []);
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';

import {DeleteImageConfirmationDialogModule} from '../delete_image_confirmation_dialog/delete_image_confirmation_dialog_module';
import {FileViewer} from './file_viewer';

@NgModule({
  declarations: [FileViewer],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    DeleteImageConfirmationDialogModule,
  ],
  exports: [FileViewer],
})
export class FileViewerModule {}

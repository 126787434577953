import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';

import {MultiselectAutocompleteModule} from '../multiselect_autocomplete/multiselect_autocomplete_module';
import {LayersFilterService} from '../services/layers_filter_service';
//import {TagsService} from '../services/tags_service';
import {AnnotationFilterChips} from './annotation_filter_chips';
import {AnnotationFilters} from './annotation_filters';

@NgModule({
  declarations: [AnnotationFilters, AnnotationFilterChips],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MultiselectAutocompleteModule,
    ReactiveFormsModule,
  ],
  exports: [AnnotationFilters, AnnotationFilterChips],
  providers: [
    LayersFilterService,
    //TagsService
  ],
})
export class AnnotationFiltersModule {}

<ng-container class="inspection-status">
  <mat-chip [matMenuTriggerFor]="menu">
    <div class="status-chip">
      <span>{{ status }}</span>
      @if (updateIsInProgress) {
        <mat-spinner class="status-update-spinner" diameter="16"> </mat-spinner>
      } @else {
        <mat-icon> arrow_drop_down </mat-icon>
      }
    </div>
  </mat-chip>
  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      @for (item of inspectionStatuses | keyvalue; track item) {
        <button mat-menu-item (click)="onMenuItemClicked(item.value)">
          <div class="status-menu-item">
            <span class="status-name">{{ item.value }}</span>
            @if (isCurrentStatus(item.value)) {
              <mat-icon class="status-icon"> check </mat-icon>
            }
          </div>
        </button>
      }
    </ng-template>
  </mat-menu>
</ng-container>

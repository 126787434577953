import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';

import {AssetImageFilters} from './asset_image_filters';

@NgModule({
  declarations: [AssetImageFilters],
  imports: [CommonModule, FormsModule, MatCheckboxModule, MatListModule],
  exports: [AssetImageFilters],
})
export class AssetImageFiltersModule {}

/**
 * IndexedDB name.
 */
export const IDB_NAME = 'GridAwareDB';

/**
 * IndexedDB version. Increment only.
 */
export const IDB_VERSION = 5;

/**
 * IndexedDB uploads dedicated object store name.
 */
export const IDB_UPLOADS_STORE_NAME = 'uploads';

/**
 * IndexedDB assets dedicated object store name.
 */
export const IDB_ASSETS_STORE_NAME = 'assets';

/**
 * IndexedDB key path for assets dedicated object store name.
 */
export const IDB_ASSETS_KEY_PATH = 'externalId';

/**
 * IndexedDB shared state (used for e.g. coordinating background data sync)
 * object store name.
 */
export const IDB_SHARED_STATE_STORE_NAME = 'state';

/**
 * Name of the <feature_id> index in assets dedicated object store.
 */
export const IDB_ASSETS_STORE_INDEX_NAME = 'featureId';

/**
 * Key path of the <feature_id> index in assets dedicated object store.
 */
export const IDB_ASSETS_STORE_INDEX_KEY_PATH = 'featureId';

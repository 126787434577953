<span class="button-container">
  <button
    mat-icon-button
    class="action-button map-button inactive"
    color="primary"
    [ngClass]="{active: isOnMapPage()}"
    [disabled]="isOnMapPage()"
    (click)="navigateToMap()"
    aria-label="Switch to map"
    i18n-aria-label
    matTooltip="Switch to map">
    <mat-icon class="icon" fontSet="material-icons-outlined">map</mat-icon>
  </button>
  <mat-divider [vertical]="true" class="divider"> </mat-divider>
  <button
    mat-icon-button
    color="primary"
    class="action-button table-button inactive"
    [ngClass]="{active: isOnTablePage()}"
    [disabled]="isOnTablePage()"
    (click)="navigateToTable()"
    aria-label="Switch to table"
    i18n-aria-label
    matTooltip="Switch to table">
    <mat-icon class="icon" fontSet="material-icons-outlined">table_rows</mat-icon>
  </button>
</span>

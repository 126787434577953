import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {ImageCarouselModule} from '../image_carousel/image_carousel_module';
import {PipesModule} from '../pipes/pipes_module';
import {ImageCanvas} from './image_canvas';
import {ImageStudio} from './image_studio';
import {LabelFilterPipe} from './label-filter.pipe';
import {LabelPanel} from './label_panel';
import {LabelSelector} from './label_selector';
import {LoadedDirective} from './loaded_directive';

@NgModule({
  declarations: [
    ImageStudio,
    ImageCanvas,
    LabelPanel,
    LabelSelector,
    LabelFilterPipe,
    LoadedDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ImageCarouselModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTooltipModule,
    OverlayModule,
    PipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [ImageStudio, ImageCanvas, LabelPanel, LoadedDirective],
})
export class ImageStudioModule {}

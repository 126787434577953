import {DateRange} from '../date_range_picker/date_range_picker';
import {dateStringToDate} from '../utils/date';

/**
 * Retrieve filter name by its key.
 */
export function getFilterNameByKey(filterNamesMap: Map<string, string>, filterKey: string): string {
  return filterNamesMap.get(filterKey) || filterKey;
}

/**
 * Extract date range from selected options.
 */
export function extractDateRange(selectedOptions: string[]): DateRange | null {
  if (selectedOptions.length === 0) {
    return null;
  }
  let min = selectedOptions[0];
  let max = selectedOptions[0];
  for (const option of selectedOptions) {
    if (option < min) {
      min = option;
    }
    if (option > max) {
      max = option;
    }
  }
  return {
    startDate: dateStringToDate(min),
    endDate: dateStringToDate(max),
  };
}

<h1 mat-dialog-title class="dialog-title">Delete Image</h1>
<mat-dialog-content class="mat-typography">
  <h3 class="confirmation-text">Do you want to delete this image?</h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="action-button cancel-button" mat-button mat-dialog-close>Cancel</button>
  <button
    class="action-button delete-button"
    color="primary"
    mat-button
    mat-raised-button
    [mat-dialog-close]="true">
    Delete
  </button>
</mat-dialog-actions>

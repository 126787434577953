/**
 * @fileoverview This is a pipe for highliting texts by adding <b> tags.
 */
import {Pipe, PipeTransform} from '@angular/core';

/**
 * Pipe for highlighting all instances of any of a list of words within a longer
 * string. Searches for each given word individually.
 */
@Pipe({name: 'highlightList'})
export class HighlightListPipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    const pattern = args.join('|');
    //'gi' for case insensitive and can use 'g' if you want the search to be
    // case sensitive.
    const re = new RegExp(pattern, 'gi');
    return value.replace(re, (substr) => {
      return '<strong>' + substr + '</strong>';
    });
  }
}

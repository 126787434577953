import {Observable, ReplaySubject} from 'rxjs';

import {Injectable} from '@angular/core';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {PendingUploadGroup, UploadState} from '../typings/upload';

/**
 * Information about the pending upload and it's state.
 */
export interface PendingUploadState {
  // Internal upload ID, only populated in case of the new upload (as opposed to
  // defect editing flow), since only the new uploads will be saved to pending
  // uploads queue in IndexedDB. Refers to ID of the record in IndexedDB.
  pendingUploadId: number | null;
  // Current state of the upload.
  state: UploadState;
  // Upload data. Contains the form input collected from the user,
  // uploaded files as well as general image group metadata (creation time,
  // location etc.).
  uploadData: PendingUploadGroup | null;
  // Defect created as a result of current upload (new upload flow) or the
  // defect being edited.
  defect: Feature | null;
}

/**
 * Service for handling the states of active uploads.
 */
@Injectable({providedIn: 'root'})
export class PendingUploadQueueService {
  private readonly uploadUpdates = new ReplaySubject<PendingUploadState | null>(1);

  /**
   * Adds pending upload state for given ID.
   */
  setPendingUploadState(
    pendingUploadId: number | null,
    uploadState: UploadState,
    uploadData: PendingUploadGroup | null,
    defect: Feature | null,
  ) {
    const update = {pendingUploadId, state: uploadState, uploadData, defect};
    this.uploadUpdates.next(update);
  }

  /**
   * Retrieves all ongoing upload updates.
   */
  getUploadUpdates(): Observable<PendingUploadState | null> {
    return this.uploadUpdates.asObservable();
  }

  /**
   * Submits empty state to upload updates queue to avoid duplicate processing
   * of the older upload entry.
   */
  clearUploadUpdates() {
    this.uploadUpdates.next(null);
  }
}

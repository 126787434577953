<mat-chip-listbox
  [value]="imageGroupFilter"
  (change)="onImageGroupFilterChange($event)"
  i18n-aria-label
  aria-label="Annotation filter selection">
  @for (option of filterOptions; track option) {
    <mat-chip-option color="accent">
      <div>{{ option }}</div>
    </mat-chip-option>
  }
</mat-chip-listbox>

import {Observable, Subject, of} from 'rxjs';
import {distinctUntilChanged, mergeMap, takeUntil} from 'rxjs/operators';

import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Building} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/sunroof_pb';

import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {GoogleMapsService} from '../services/google_maps_service';
import {MapService} from '../services/map_service';
import {ParamValidatorService} from '../services/param_validator_service';
import {SunroofService} from '../services/sunroof_service';

const PARAMS = [QUERY_PARAMS.LATITUDE, QUERY_PARAMS.LONGITUDE];

/**
 * Displays details about a sunroof that a user clicked.
 */
@Component({
  templateUrl: 'sunroof_details.ng.html',
  styleUrls: ['sunroof_details.scss'],
})
export class SunroofDetailsPage implements OnDestroy {
  @ViewChild('satellite', {static: true}) satellite!: ElementRef<HTMLElement>;

  address$: Observable<string | null> = of(null);
  properties: Property[] = [];
  destroyed = new Subject<void>();
  location!: google.maps.LatLngLiteral;

  constructor(
    private readonly router: Router,
    private readonly googleMapsService: GoogleMapsService,
    mapService: MapService,
    paramValidatorService: ParamValidatorService,
    route: ActivatedRoute,
    sunroofService: SunroofService,
  ) {
    route.paramMap
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed),
        mergeMap((paramMap: ParamMap) => {
          if (!paramValidatorService.areValidParams(paramMap, PARAMS)) {
            throw new Error();
          }
          this.location = {
            lat: +paramMap.get(QUERY_PARAMS.LATITUDE)!,
            lng: +paramMap.get(QUERY_PARAMS.LONGITUDE)!,
          };
          return sunroofService.getRoofData(this.location);
        }),
      )
      .subscribe(
        (building: Building) => {
          if (building.center) {
            // More accurate building center location available, so update
            // where we want the pin to go.
            this.location = {
              lat: building.center!.latitude,
              lng: building.center!.longitude,
            };
          }
          this.properties = this.computeProperties(building);
          mapService.setLocationPin(this.location);
          this.address$ = this.googleMapsService.getAddressFromLatLng(this.location);
          this.googleMapsService.renderSatelliteMap(this.satellite.nativeElement, this.location);
        },
        () => {
          this.goToMap();
        },
      );
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.googleMapsService.destroy();
  }

  computeProperties(building: Building): Property[] {
    const properties: Property[] = [];
    const solarPotential = building.solarPotential;
    if (!solarPotential) {
      return properties;
    }
    if (solarPotential.maxArrayPanelsCount) {
      properties.push(
        new Property({
          key: 'Maximum number of panels',
          propertyValue: {
            value: solarPotential.maxArrayPanelsCount.toString(),
            case: 'value',
          },
        }),
      );
    }
    if (solarPotential.maxArrayAreaMeters2) {
      properties.push(
        new Property({
          key: 'Maximum panel area (square meters)',
          propertyValue: {
            value: Math.round(solarPotential.maxArrayAreaMeters2).toString(),
            case: 'value',
          },
        }),
      );
    }
    if (solarPotential.maxSunshineHoursPerYear) {
      properties.push(
        new Property({
          key: 'Maximum sunshine hours per year',
          propertyValue: {
            value: Math.round(solarPotential.maxSunshineHoursPerYear).toString(),
            case: 'value',
          },
        }),
      );
    }
    if (solarPotential.carbonOffsetFactorKgPerMwh) {
      properties.push(
        new Property({
          key: 'Carbon offset factor (kg/MWh)',
          propertyValue: {
            value: Math.round(solarPotential.carbonOffsetFactorKgPerMwh).toString(),
            case: 'value',
          },
        }),
      );
    }
    return properties;
  }

  goToMap() {
    this.router.navigateByUrl(ROUTE.MAP);
  }
}

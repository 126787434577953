<form [formGroup]="formGroup">
  <mat-form-field appearance="fill" subscriptSizing="dynamic" class="multiselect-form-field">
    <mat-label>{{ placeholderText }}</mat-label>
    <input
      matInput
      class="multiselect-input-value"
      #valueInput
      formControlName="valueControl"
      [required]="required"
      [matAutocomplete]="auto"
      (focus)="valueInputFocused()"
      (keydown.enter)="optionEnterKeyPressed($event)"
      type="text"
      tabindex="0"
      [attr.aria-label]="placeholderText" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [panelWidth]="panelWidth"
      (closed)="autocompleteClosed()"
      class="autocomplete-results multiselect-autocomplete">
      @if (filteredOptions | async; as filteredOptionValues) {
        <div class="options-container">
          @if (filteredOptionValues.length) {
            <div class="select-all-container select-option">
              <mat-checkbox
                class="select-all-checkbox checkbox"
                [checked]="allSelected(filteredOptionValues)"
                [indeterminate]="someSelected(filteredOptionValues)"
                (change)="setAll($event.checked, filteredOptionValues)">
                {{ selectAllOptionName }}
              </mat-checkbox>
            </div>
            @for (option of filteredOptionValues; track option) {
              <mat-option class="select-option" [value]="option">
                <div class="select-option-container">
                  <mat-checkbox
                    #checkbox
                    class="checkbox"
                    [checked]="selectedOptionsSet.has(option)"
                    (click)="$event.stopPropagation(); toggleSelection(option)">
                    <span
                      class="option-span"
                      innerHtml="{{ option | highlight: valueControl.value }}">
                    </span>
                  </mat-checkbox>
                  <button
                    mat-flat-button
                    class="hover-button"
                    color="accent"
                    aria-label="Select only"
                    i18n-aria-label
                    (click)="selectOnly($event, option)">
                    Only
                  </button>
                </div>
              </mat-option>
            }
          } @else {
            @if (canAddValue(allowNewElements, filteredOptions | async, cleanNewValue)) {
              <mat-option [value]="cleanNewValue">
                <div (click)="addValue($event, cleanNewValue)">
                  Create &quot;<b>{{ cleanNewValue }}</b
                  >&quot; {{ newElementName }}
                </div>
              </mat-option>
            }
            @if (valueInput.value && !allowNewElements) {
              <mat-option class="no-results" [disabled]="true">
                No matching results found
              </mat-option>
            }
          }
        </div>
      }
      <div class="actions-container">
        <button
          mat-button
          class="action-button"
          type="button"
          aria-label="Cancel"
          i18n-aria-label="true"
          (click)="onCancel($event)">
          Cancel
        </button>
        <button
          mat-button
          mat-raised-button
          class="action-button"
          type="button"
          color="primary"
          [attr.aria-label]="applyActionTitle"
          [attr.i18n-aria-label]="true"
          [disabled]="!allowEmpty && selectedOptionsSet.size === 0"
          (click)="onApply($event)">
          {{ applyActionTitle }}
        </button>
      </div>
    </mat-autocomplete>
  </mat-form-field>
</form>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';

import {PipesModule} from '../pipes/pipes_module';
import {MultiselectAutocomplete} from './multiselect_autocomplete';

const materialModules = [MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatInputModule];

@NgModule({
  declarations: [MultiselectAutocomplete],
  exports: [MultiselectAutocomplete],
  providers: [],
  imports: [CommonModule, ...materialModules, PipesModule, ReactiveFormsModule],
})
export class MultiselectAutocompleteModule {}

import {DateLike, Date as GoogDate} from 'ts-closure-library/lib/date/date';

import {Pipe, PipeTransform} from '@angular/core';

import {getRelativeTimeString} from '../utils/date';

/**
 * Angular pipe for date formatting. Provides extended formatting options
 * such as relative date or datetime. E.g. 'Today, 11:20 AM'. Currently
 * defaults to en-US locale.
 */
@Pipe({name: 'relativeDate'})
export class RelativeDatePipe implements PipeTransform {
  transform(value: DateLike) {
    if (!value) {
      return '';
    }
    const date = toDate(value)!;
    return getRelativeTimeString(date);
  }
}

function toDate(input: DateLike): Date | null {
  if (input instanceof GoogDate) {
    return new Date(input.getTime());
  }
  return input;
}

import {PromiseClient} from '@connectrpc/connect';
import {Observable, ReplaySubject} from 'rxjs';
import {take, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {UserType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/user_pb';
import {UserService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/userservice_connect';
import {ListUserTypesResponse} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/userservice_pb';

import {ApiService} from './api_service';
import {AuthGuard} from './auth_guard';
import {UserPreferencesService} from './user_preferences_service';

/**
 * Service for retrieving user types.
 */
@Injectable({providedIn: 'root'})
export class UsersService {
  private readonly client: PromiseClient<typeof UserService>;

  private userTypes: ReplaySubject<UserType[]> | null = null;

  constructor(
    private readonly apiService: ApiService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly authGuard: AuthGuard,
  ) {
    this.client = apiService.createUserServiceBEClient();
    // We must fetch user types only after user is authorized.
    // We would have normally this in the UserPreferencesService itself
    // but if we add apiService to UserPreferencesService then the app
    // crashed with "firebase not defined" message.
    // TODO(golobokov): research UserPreferencesService dependency problem.
    this.authGuard
      .getAuthorized$()
      .pipe(
        tap(() => {
          this.getUserTypes();
        }),
        take(1),
      )
      .subscribe();
  }

  getUserTypes(forceUpdate = false): Observable<UserType[]> {
    if (!forceUpdate && this.userTypes) {
      return this.userTypes.asObservable();
    }

    if (!this.userTypes) {
      this.userTypes = new ReplaySubject<UserType[]>(1);
    }

    this.apiService
      .withCallOptions((options) => this.client.listUserTypes({}, options))
      .then(
        (response: ListUserTypesResponse) => {
          this.userTypes!.next(response.userTypes);
          this.userPreferencesService.setUserTypes(response.userTypes);
        },
        (error: Error) => {
          throw new Error(`Could not get user types: ${error.message}`);
        },
      );

    return this.userTypes.asObservable();
  }
}

import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

import {Injectable} from '@angular/core';

import {ConfigService} from './config_service';
import {LocalStorageService} from './local_storage_service';

//import {ServiceWorkerService} from './service_worker_service';

/**
 * The network service sets the quality of the connection from
 * navigator.connection and holds the offline state.
 */
@Injectable({providedIn: 'root'})
export class NetworkService {
  offline: boolean | null = null;
  offline$ = new ReplaySubject<boolean>(1);
  learnMoreVisibility$ = new BehaviorSubject<boolean>(true);
  private isInExplicitOfflineMode: boolean = false;

  constructor(
    private readonly configService: ConfigService,
    private readonly localStorageService: LocalStorageService,
    //private readonly serviceWorkerService: ServiceWorkerService,
  ) {
    if (
      //!this.configService.serviceWorkerEnabled ||
      //!this.serviceWorkerService.isServiceWorkerSupported() ||
      !this.localStorageService.readLoginStatus() ||
      this.localStorageService.readOfflineModeDisabled()
    ) {
      this.setOffline(false);
      return;
    }
    this.setOffline(!window.navigator.onLine);
    window.addEventListener('offline', () => {
      if (this.localStorageService.readOfflineModeDisabled()) {
        return;
      }
      this.setOffline(true);
    });
    window.addEventListener('online', () => {
      this.setOffline(false);
    });
  }

  setOffline(offline: boolean) {
    if (offline === this.offline) {
      return;
    }
    this.offline = offline;
    this.offline$.next(offline);
  }

  getOffline$(): Observable<boolean> {
    return this.offline$.asObservable();
  }

  setLearnMoreVisibility(visible: boolean) {
    this.learnMoreVisibility$.next(visible);
  }

  getLearnMoreVisibility$(): Observable<boolean> {
    return this.learnMoreVisibility$.asObservable();
  }

  /**
   * Retrieves the value for explicit (user specified) offline mode.
   */
  getExplicitOfflineMode(): boolean {
    return this.isInExplicitOfflineMode;
  }

  /**
   * Allows to configure explicit (user specified) offline mode.
   */
  setExplicitOfflineMode(isOffline: boolean) {
    this.isInExplicitOfflineMode = isOffline;
    this.setOffline(isOffline);
  }
}

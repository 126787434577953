import {saveAs} from 'file-saver';

import {Injectable} from '@angular/core';

const CSV_MIME_TYPE = 'text/csv';

/**
 * Service for validating CSV data and making it downloadable.
 */
@Injectable({providedIn: 'root'})
export class CSVService {
  /**
   * Builds CSV Blob with provided data and saves it to the file system.
   * @param filename Name of the file to use.
   * @param columnNames Data columns to be used in building of CSV Blob.
   * @param rows Data rows to be used in building of CSV Blob.
   */
  getCSVFile(filename: string, columnNames: string[], rows: string[][]) {
    const csvBlob = this.makeCSVBlob(columnNames, rows);
    saveAs(csvBlob, filename);
  }

  /**
   * Builds the uniform file name for the data to be used in download scenarios.
   * @param prefix Prefix to be used.
   * @param date Date to be used.
   * @returns File name.
   */
  makeFilename(prefix: string, date: Date): string {
    const rawFilename = `${prefix}_${date.toLocaleString()}.csv`;
    const formattedFilename = rawFilename.toLowerCase().replace(/ |, /g, '_');
    return formattedFilename;
  }

  /**
   * Builds CSV Blob with provided data.
   * @param columnNames Data columns to be used in building of CSV Blob.
   * @param rows Data rows to be used in building of CSV Blob.
   * @returns CSV Blob.
   */
  makeCSVBlob(columnNames: string[], rows: string[][]): Blob {
    const columnStr = this.cleanRow(columnNames).join();
    const rowsStr = rows.map((row: string[]) => this.cleanRow(row)).join('\n');
    const csv = `${columnStr}\n${rowsStr}`;
    return new Blob([csv], {type: CSV_MIME_TYPE});
  }

  private cleanRow(row: string[]): string[] {
    return row.map((cell: string) => {
      const d = doubleDoubleQuotes(cell);
      return wrapInDoubleQuotes(d);
    });
  }
}

/**
 * Wraps a value in double quotes.
 */
export function wrapInDoubleQuotes(val: string): string {
  return `"${val}"`;
}

/**
 * Adds an extra double quote to every double quote.
 */
export function doubleDoubleQuotes(val: string): string {
  return val.replace(/"/g, '""');
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {ImageThumbnailModule} from '../../image_thumbnail/image_thumbnail_module';
import {MetadataModule} from '../../metadata/metadata_module';
import {TagsModule} from '../../tags/tags_module';
import {ImageStudioDetails} from './image_studio_details';

@NgModule({
  declarations: [ImageStudioDetails],
  imports: [
    CommonModule,
    ImageThumbnailModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MetadataModule,
    RouterModule,
    TagsModule,
  ],
  exports: [ImageStudioDetails],
})
export class ImageStudioDetailsModule {}

<div class="snackbar-message">
  <div class="info">
    @if (snackbarData.category === Category.FAILURE) {
      <mat-icon class="icon status-icon error-icon"> error </mat-icon>
    }
    @if (snackbarData.category === Category.SUCCESS) {
      <mat-icon class="icon status-icon success-icon"> done </mat-icon>
    }
    <div>
      {{ snackbarData.message }}
      <a class="action-link" role="button" (click)="navigateTo(snackbarData.link)">
        {{ snackbarData.linkLabel }}
      </a>
    </div>
  </div>
  <button
    mat-icon-button
    matTooltip="Dismiss"
    i18n-aria-label
    aria-label="Dismiss"
    class="dismiss-button"
    (click)="snackBarRef.dismiss()">
    <mat-icon class="icon">close</mat-icon>
  </button>
</div>

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

import {DISPLAY_NAME_BY_TOGO} from '../constants/image';
import {FILTER_NAMES_MAP} from '../filter/filter_config';
import {FilterModule} from '../filter/filter_module';
import {PipesModule} from '../pipes/pipes_module';
import {PropertyFilters} from './property_filters';

@NgModule({
  declarations: [PropertyFilters],
  exports: [PropertyFilters],
  imports: [CommonModule, MatButtonModule, PipesModule, FilterModule],
  providers: [{provide: FILTER_NAMES_MAP, useValue: DISPLAY_NAME_BY_TOGO}],
})
export class PropertyFiltersModule {}

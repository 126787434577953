import {
  NguCarousel,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguItemComponent,
  NguTileComponent,
} from '@ngu/carousel';

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {CommentModule} from '../comment/comment_module';
import {PipesModule} from '../pipes/pipes_module';
import {Timeline} from './timeline';
import {TimelineComment} from './timeline_comment';
import {TimelineEntryDescription} from './timeline_entry_description';
import {TimelineImage} from './timeline_image';
import {TimelineRelatedFeature} from './timeline_related_feature';

@NgModule({
  declarations: [
    Timeline,
    TimelineComment,
    TimelineEntryDescription,
    TimelineImage,
    TimelineRelatedFeature,
  ],
  imports: [
    CommentModule,
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    NguCarousel,
    NguTileComponent,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,
    PipesModule,
  ],
  exports: [
    Timeline,
    TimelineComment,
    TimelineEntryDescription,
    TimelineImage,
    TimelineRelatedFeature,
  ],
})
export class TimelineModule {}

@if (pendingUploadGroup) {
  <div class="container">
    <div class="row">
      <img
        [src]="headerImage | async"
        [alt]="alt"
        (click)="openFileViewer()"
        class="header-image"
        [ngStyle]="headerImageStyle" />
      <div class="date-image-count">
        <div class="date">{{ date }}</div>
        <div class="image-count">{{ text }}</div>
      </div>
      <div class="actions-container">
        @if (isViewButtonVisible()) {
          <button
            class="button"
            mat-stroked-button
            [disabled]="networkService.getOffline$() | async"
            (click)="navigateToImageGroup()">
            View
          </button>
        }
        @if (actionButtonVisible) {
          @if (!loading && flatButton) {
            <button
              class="action-button"
              mat-flat-button
              [disabled]="networkService.getOffline$() | async"
              [color]="buttonColor"
              (click)="handleAction()">
              {{ buttonText }}
            </button>
          }
          @if (!loading && !flatButton) {
            <button
              class="action-button"
              mat-stroked-button
              [disabled]="networkService.getOffline$() | async"
              [color]="buttonColor"
              (click)="handleAction()">
              {{ buttonText }}
            </button>
          }
        }
        @if (loading) {
          <div class="spinner-container">
            <mat-progress-spinner mode="indeterminate" color="primary" diameter="20">
            </mat-progress-spinner>
          </div>
        }
        @if (editIconVisible()) {
          <button
            mat-icon-button
            class="icon-button"
            aria-label="Edit"
            i18n-aria-label
            (click)="editPendingUpload()"
            [matTooltip]="'Edit'">
            <mat-icon>edit</mat-icon>
          </button>
        }
        @if (removeIconVisible()) {
          <button
            mat-icon-button
            class="icon-button"
            aria-label="Remove"
            i18n-aria-label
            (click)="openDeleteImageConfirmationDialog()"
            [matTooltip]="'Remove'">
            <mat-icon>delete_outline</mat-icon>
          </button>
        }
      </div>
    </div>
    @if (errorMessage.length > 0) {
      <div class="error-message">
        {{ errorMessage }}
      </div>
    }
  </div>
}

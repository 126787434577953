import {Subject, combineLatest, of} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, take, takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {Layer, Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {LayersService} from '../services/layers_service';

/**
 * Component for rendering the layer config page.
 */
@Component({
  templateUrl: 'layer_config_container.ng.html',
  styleUrls: ['layer_config_container.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayerConfigContainerPage implements OnInit, OnDestroy {
  layers: Layer[] = [];
  layerById = new Map<string, Layer>();
  selectedLayer: Layer | null = null;
  destroyed = new Subject();

  constructor(
    private readonly layersService: LayersService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.layersService.getAllLayersMetadata().pipe(take(1), takeUntil(this.destroyed)).subscribe();
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        map((queryParamMap: ParamMap) => queryParamMap.get(QUERY_PARAMS.LAYER_ID)),
        mergeMap((layerId: string | null) => {
          return combineLatest([
            of(layerId),
            this.layersService.onLayersMetadataChanged().pipe(take(1)),
          ]);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe(
        ([layerId, layers]: [string | null, Layer[]]) => {
          const settingsSupportedLayers = layers.filter(
            (layer: Layer) => layer.layerType !== Layer_LayerType.IMAGE_TILE,
          );
          if (settingsSupportedLayers.length === 0) {
            console.warn('Layer metadata not found.');
            this.snackBar.open('Layer metadata not found. Please refresh page', 'Close');
          }
          if (!layerId) {
            this.selectLayer(settingsSupportedLayers[0]);
            return;
          }
          this.layers = settingsSupportedLayers;
          this.setLayersById(settingsSupportedLayers);
          if (!this.layerById.has(layerId)) {
            console.error(`Layer with ID '${layerId}' does not exist.`);
            return;
          }
          this.selectedLayer = this.layerById.get(layerId)!;
        },
        (error: Error) => {
          console.error(`An error occurred fetching all layer metadata: ${error}`);
          this.snackBar.open('Could not fetch layer metadata. Please refresh page', 'Close');
        },
      );
  }

  private setLayersById(layers: Layer[]) {
    for (const layer of layers) {
      this.layerById.set(layer.id, layer);
    }
  }

  selectLayer(layer: Layer) {
    this.router.navigate([ROUTE.LAYER_CONFIG], {
      queryParams: {
        [QUERY_PARAMS.LAYER_ID]: layer.id,
      },
    });
    this.selectedLayer = layer;
  }

  ngOnDestroy() {
    this.destroyed.next(0);
  }
}

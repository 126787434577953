import {Comment} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/comment_pb';
import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

/**
 * Types of entries that can be displayed in feature timeline.
 */
export enum TimelineEntryType {
  IMAGE = 'Image',
  COMMENT = 'Comment',
  RELATED_FEATURE = 'Related Feature',
}

/**
 * Types of entry actions that can be displayed in feature timeline.
 */
export enum TimelineEntryAction {
  ADDED = 'Added',
  UPDATED = 'Updated',
  REMOVED = 'Removed',
}

/**
 * Representation object of the entry in the timeline.
 */
export interface TimelineEntry {
  type: TimelineEntryType;
  action: TimelineEntryAction;
  record: Comment | Feature;
  featureId: string;
  layerId: string;
  details: string | Record<string, string>;
  date: Date;
}

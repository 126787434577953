<header></header>
<div class="content">
  @if (errorDescriptions[code]) {
    <div class="page-error">
      <div class="code">{{ errorDescriptions[code].codeMessage }}</div>
      <div class="description">{{ errorDescriptions[code].description }}</div>
      @if (errorDescriptions[code].action) {
        <div>
          <a class="action-link" [href]="errorDescriptions[code].action.href">
            {{ errorDescriptions[code].action.label }}
          </a>
        </div>
      }
    </div>
  }
</div>

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/sunroof.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { LatLng } from "../../../../google/type/latlng_pb.js";

/**
 * Information about a Sunroof building.
 *
 * @generated from message tapestry.gridaware.api.v1.Building
 */
export const Building = proto3.makeMessageType("tapestry.gridaware.api.v1.Building", () => [{
  no: 1,
  name: "center",
  kind: "message",
  T: LatLng
}, {
  no: 2,
  name: "solar_potential",
  kind: "message",
  T: SolarPotential
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.SolarPotential
 */
export const SolarPotential = proto3.makeMessageType("tapestry.gridaware.api.v1.SolarPotential", () => [{
  no: 1,
  name: "max_array_panels_count",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 2,
  name: "max_array_area_meters2",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 3,
  name: "max_sunshine_hours_per_year",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}, {
  no: 4,
  name: "carbon_offset_factor_kg_per_mwh",
  kind: "scalar",
  T: 2 /* ScalarType.FLOAT */
}]);
import {PromiseClient} from '@connectrpc/connect';
import {Observable, ReplaySubject, from} from 'rxjs';

import {Injectable} from '@angular/core';

import {FilterView} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filter_view_pb';
import {FilterViewService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filterviewservice_connect';
import {
  ListFilterViewsResponse,
  SearchFilterViewsResponse,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filterviewservice_pb';

import {ApiService} from './api_service';

/**
 * Service for handling user defined filter views.
 */
@Injectable({providedIn: 'root'})
export class FilterViewsService {
  private readonly client: PromiseClient<typeof FilterViewService>;

  private readonly cachedFilterViewsById = new Map<string, ReplaySubject<FilterView>>();

  constructor(private readonly apiService: ApiService) {
    this.client = apiService.createFilterViewServiceBEClient();
  }

  /**
   * Fetches saved filter views.
   */
  listFilterViews(): Observable<FilterView[]> {
    return from(
      this.apiService
        .withCallOptions((options) => this.client.listFilterViews({}, options))
        .then(
          (response: ListFilterViewsResponse) => {
            const filterViews = response.filterViews;
            for (const filterView of filterViews) {
              this.getCachedFilterView(filterView.id).next(filterView);
            }
            return filterViews;
          },
          (error: Error) => {
            throw new Error(`Could not list filter views: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Fetches a saved filter view by ID.
   */
  getFilterView(id: string, forceUpdate = false): Observable<FilterView | null> {
    if (!forceUpdate && this.cachedFilterViewsById.has(id)) {
      return this.cachedFilterViewsById.get(id)!.asObservable();
    }
    const replaySubject = this.getCachedFilterView(id);

    this.apiService
      .withCallOptions((options) => this.client.getFilterView({id}, options))
      .then(
        (savedFilterView: FilterView) => {
          replaySubject.next(savedFilterView);
        },
        (error: Error) => {
          replaySubject.error(
            new Error(`Could not get filter view with ID ${id}: ${error.message}`),
          );
        },
      );

    return replaySubject.asObservable();
  }

  /**
   * Creates a new saved filter view.
   */
  createFilterView(filterView: FilterView): Observable<FilterView | null> {
    return from(
      this.apiService
        .withCallOptions((options) => this.client.createFilterView({filterView}, options))
        .then(
          (savedFilterView: FilterView) => savedFilterView,
          (error: Error) => {
            throw new Error(`Could not create filter view: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Deletes a saved filter view by ID.
   */
  deleteFilterView(id: string): Observable<void> {
    return from(
      this.apiService
        .withCallOptions((options) => this.client.deleteFilterView({id}, options))
        .then(
          () => {
            this.cachedFilterViewsById.delete(id);
          },
          (error: Error) => {
            throw new Error(`Could not delete filter view with ID ${id}: ${error.message}`);
          },
        ),
    );
  }

  /**
   * Fetches saved filter views that have the matching name.
   */
  searchFilterViewsByName(name: string): Observable<FilterView[]> {
    return from(
      this.apiService
        .withCallOptions((options) => this.client.searchFilterViews({displayName: name}, options))
        .then(
          (response: SearchFilterViewsResponse) => {
            const filterViews = response.filterViews;
            for (const filterView of filterViews) {
              this.getCachedFilterView(filterView.id).next(filterView);
            }
            return filterViews;
          },
          (error: Error) => {
            throw new Error(`Could not search filter views by name: ${error.message}`);
          },
        ),
    );
  }

  getCachedFilterView(id: string): ReplaySubject<FilterView> {
    if (!this.cachedFilterViewsById.has(id)) {
      this.cachedFilterViewsById.set(id, new ReplaySubject<FilterView>(1));
    }
    return this.cachedFilterViewsById.get(id)!;
  }
}

<div class="annotation-selector">
  <mat-toolbar class="header">
    <span class="annotations-header">Annotations</span>
    <span class="spacer"></span>
    @if (editorMode === EDITOR_MODE.OFF) {
      <button
        mat-button
        class="top-bar-button"
        (click)="switchToAnnotationsEditor()"
        aria-label="Add/Edit"
        i18n-aria-label
        matTooltip="Open annotations editor">
        Add/Edit
      </button>
    } @else {
      <mat-divider [vertical]="true" class="divider"> </mat-divider>
      <button
        mat-icon-button
        class="active"
        color="primary"
        [ngClass]="{inactive: editorMode !== EDITOR_MODE.PAN}"
        (click)="setEditing(false)"
        aria-label="Move"
        i18n-aria-label
        matTooltip="Move">
        <mat-icon class="icon status-icon">front_hand</mat-icon>
      </button>
      <mat-divider [vertical]="true" class="divider"> </mat-divider>
      <button
        mat-icon-button
        color="primary"
        class="active"
        [ngClass]="{inactive: editorMode !== EDITOR_MODE.DRAW}"
        (click)="setEditing(true)"
        aria-label="Edit"
        i18n-aria-label
        matTooltip="Edit">
        <mat-icon class="icon status-icon">crop_square</mat-icon>
      </button>
      <mat-divider [vertical]="true" class="divider"> </mat-divider>
      <button mat-button aria-label="Exit" i18n-aria-label color="accent" (click)="cancel()">
        Exit
      </button>
    }
  </mat-toolbar>
  <div class="annotation-selector-content">
    @if (annotationTypeSelector | async; as mainSelector) {
      <div class="selectors-panel">
        @for (selector of nonEmptySelectors(mainSelector.subSelectors); track selector) {
          <div class="main-filter-title-container">
            <span class="group-title"
              >{{ selector.title }} ({{ selector.annotations.length }})</span
            >
            <mat-checkbox
              class="group-toggle-checkbox"
              color="primary"
              [checked]="allAnnotationsSelected(selector)"
              [indeterminate]="someAnnotationsSelected(selector)"
              (change)="toggleGroupVisibility(selector, $event.checked)"
              (click)="$event.stopPropagation()">
            </mat-checkbox>
          </div>
          @if (selector.subSelectors) {
            <mat-accordion displayMode="flat" togglePosition="before" multi>
              @for (
                majorSubSelector of nonEmptySelectors(selector.subSelectors);
                track majorSubSelector
              ) {
                <mat-expansion-panel class="mat-elevation-z0" expanded="true">
                  <mat-expansion-panel-header class="type-header major-type-header">
                    <div class="filter-title-container">
                      <div>
                        <div
                          [ngClass]="[
                            'box',
                            getAnnotationBoxType(majorSubSelector.annotations[0])
                          ]"></div>
                        <span class="group-title"
                          >{{ majorSubSelector.title }} ({{
                            majorSubSelector.annotations.length
                          }})</span
                        >
                      </div>
                      <mat-checkbox
                        class="group-toggle-checkbox"
                        color="primary"
                        [checked]="allAnnotationsSelected(majorSubSelector)"
                        [indeterminate]="someAnnotationsSelected(majorSubSelector)"
                        (change)="toggleGroupVisibility(majorSubSelector, $event.checked)"
                        (click)="$event.stopPropagation()">
                      </mat-checkbox>
                    </div>
                  </mat-expansion-panel-header>
                  @if (
                    majorSubSelector.subSelectors | sortAnnotationTypeSelectors;
                    as sortedMinorSubSelectors
                  ) {
                    <mat-accordion displayMode="flat" togglePosition="before" multi>
                      @for (
                        minorSubSelector of nonEmptySelectors(sortedMinorSubSelectors);
                        track minorSubSelector
                      ) {
                        <mat-expansion-panel class="mat-elevation-z0" expanded="false">
                          <mat-expansion-panel-header class="type-header minor-type-header">
                            <div class="filter-title-container">
                              <span class="group-title"
                                >{{ minorSubSelector.title }} ({{
                                  minorSubSelector.annotations.length
                                }})</span
                              >
                              <mat-checkbox
                                class="group-toggle-checkbox"
                                color="primary"
                                [checked]="allAnnotationsSelected(minorSubSelector)"
                                [indeterminate]="someAnnotationsSelected(minorSubSelector)"
                                (change)="toggleGroupVisibility(minorSubSelector, $event.checked)"
                                (click)="$event.stopPropagation()">
                              </mat-checkbox>
                            </div>
                          </mat-expansion-panel-header>
                          <mat-list class="annotation-list" role="list">
                            @for (annotation of minorSubSelector.annotations; track annotation) {
                              <mat-list-item class="annotation-entry">
                                <div class="filter-title-container">
                                  <span
                                    class="annotation-label"
                                    [ngClass]="
                                      annotation.isHidden
                                        ? 'hidden-annotation-label'
                                        : 'visible-annotation-label'
                                    ">
                                    {{ annotation.info.label?.label }}
                                  </span>
                                </div>
                                <mat-checkbox
                                  [checked]="!annotation.isHidden"
                                  (change)="toggleAnnotation(annotation, $event.checked)"
                                  color="primary">
                                </mat-checkbox>
                              </mat-list-item>
                            }
                          </mat-list>
                        </mat-expansion-panel>
                      }
                    </mat-accordion>
                  }
                </mat-expansion-panel>
              }
            </mat-accordion>
          }
        }
      </div>
    }
    @if (editorMode !== EDITOR_MODE.OFF) {
      <div class="editor-buttons-container">
        <button
          mat-flat-button
          aria-label="Save"
          i18n-aria-label
          color="accent"
          (click)="save()"
          class="action-button">
          <div class="save-button-content">
            @if (savingIsInProgress) {
              <mat-progress-spinner mode="indeterminate" diameter="20" class="save-spinner">
              </mat-progress-spinner>
            }
            <span>Save</span>
          </div>
        </button>
        <button
          mat-button
          aria-label="Cancel"
          i18n-aria-label
          color="accent"
          (click)="cancel()"
          class="action-button">
          Cancel
        </button>
      </div>
    }
  </div>
</div>

import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';

import {ROUTE} from '../../constants/paths';

/**
 * Displays offline upload progress.
 */
@Component({
  selector: 'upload-progress',
  templateUrl: './progress.ng.html',
  styleUrls: ['./progress.scss'],
})
export class UploadProgress {
  @Output() readonly reset = new EventEmitter<void>();

  constructor(private readonly router: Router) {}

  resetInput() {
    this.reset.emit();
  }

  goToPendingUpload() {
    this.router.navigateByUrl(ROUTE.PENDING_UPLOAD);
  }
}

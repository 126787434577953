import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {DEFECTS_LAYER_ID} from '../constants/layer';

/**
 * The data that is passed into the tags dialog.
 */
export interface TagsDialogData {
  // The text that is displayed as the header of this dialog.
  headerText?: string;
  subheaderText?: string;
  seededTags?: string[];
  layerId?: string;
  actionText?: string;
  opened?: boolean;
}

/**
 * The data that the tags dialog responds with.
 */
export interface TagsDialogResponse {
  tags: Tag[];
}

const DEFAULT_ACTION_TEXT = 'Upload all';

/**
 * Dialog for selecting tags.
 */
@Component({
  templateUrl: 'tags_dialog.ng.html',
  styleUrls: ['tags_dialog.scss'],
})
export class TagsDialog implements OnInit {
  tags = new Set<string>();
  editing = false;
  layerId = DEFECTS_LAYER_ID;
  actionText = DEFAULT_ACTION_TEXT;
  // Controls whether the tags component should be initialized opened.
  opened = false;

  constructor(
    private readonly dialogRef: MatDialogRef<TagsDialog>,
    @Inject(MAT_DIALOG_DATA) readonly data: TagsDialogData,
  ) {}

  ngOnInit() {
    this.tags = new Set<string>(this.data?.seededTags || []);
    this.layerId = this.data?.layerId || this.layerId;
    this.actionText = this.data?.actionText || this.actionText;
    this.opened = this.data?.opened || this.opened;
  }

  onTagsUpdated(tags: Set<string>) {
    this.tags = tags;
  }

  setEditing(editing: boolean) {
    this.editing = editing;
  }

  returnTags(): void {
    const tags = [...this.tags].map((tagName: string) => new Tag({name: tagName}));
    this.dialogRef.close({
      tags,
    });
  }
}

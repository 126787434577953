<div
  class="label-icon-container"
  [ngClass]="{
    'focused-label': focused,
    'out-of-focus': outOfFocus,
    'missing-label': !annotationInfo?.label
  }"
  [style]="{
    'background-color': color,
    color: textColor,
    top: y + 'px',
    left: x + 'px',
    visibility: hiddenInput ? 'hidden' : 'visible'
  }">
  <div
    class="annotation-label-container"
    [ngClass]="{'filled-content': annotationInfo?.label}"
    [ngStyle]="{'background-color': color, color: textColor}"
    (click)="selectLabel()"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin">
    @if (!annotationInfo?.label) {
      <span class="applied-label-info"> Add label </span>
    } @else {
      <span class="applied-label-info label-name">
        {{ annotationInfo?.label?.label }}
      </span>
    }
  </div>
  <button
    mat-icon-button
    class="icon-button info-button"
    matTooltip="See details"
    (click)="showPanel()">
    <mat-icon fontSet="material-icons-outlined" class="icon">info</mat-icon>
  </button>
  @if (annotationInfo?.sourceAnnotation?.corrected) {
    <mat-icon iconPositionEnd class="icon flag-icon"> outlined_flag </mat-icon>
  }
</div>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop="true"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayOpen]="showOverlay"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositionStrategy]="positionStrategy"
  [cdkConnectedOverlayWidth]="panelWidth"
  (overlayKeydown)="handleKeyPressEvents($event)"
  (overlayOutsideClick)="closeIfNotNewAnnotation()">
  @if (editable || correctAnnotationSelectorVisible) {
    @if (correctAnnotationSelectorVisible) {
      <label-selector
        #correctAnnotationSelector
        class="correct-annotation-selector"
        [header]="{text: 'Correct label', style: {color: 'red'}}"
        (onApply)="applyCorrectedAnnotation($event)"
        (onDelete)="closePanel()"
        (onCancel)="closePanel()">
      </label-selector>
    } @else {
      <label-selector
        class="createEditLabel"
        [existingLabel]="annotationInfo?.label || null"
        [existingComment]="annotationInfo?.comment || ''"
        (onApply)="apply($event)"
        (onDelete)="deleteAnnotation()"
        (onCancel)="closePanel()">
      </label-selector>
    }
  } @else {
    <mat-card
      class="mat-elevation-z2 dense annotation-card read-only-annotation-card"
      [@smoothPanel]="showOverlay">
      <mat-card-content class="annotation-card-content">
        <div class="annotation-card-actions">
          <button
            mat-icon-button
            aria-label="Close"
            i18n-aria-label
            color="primary"
            class="close-button"
            (click)="closePanel()">
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
        <div class="comment-info">{{ annotationInfo?.comment }}</div>
        <div class="author-info">
          @if (annotationInfo?.isMachineGenerated) {
            <span
              >Generated by: ML model version {{ annotationInfo?.machineModelVersion || '' }}</span
            >
          }
          @if (!annotationInfo?.isMachineGenerated) {
            <span>Created by: {{ annotationInfo?.user?.name || 'N/A' }}</span>
          }
        </div>
        <div class="time-info">Last modified: {{ formattedDate }}</div>
        @if (annotationInfo?.isMachineGenerated) {
          <button
            color="primary"
            class="flag-icon"
            aria-label="Correct label"
            mat-icon-button
            i18n-aria-label
            matTooltip="Correct label"
            (click)="openCorrectAnnotationSelector()">
            <mat-icon>outlined_flag</mat-icon>
          </button>
        }
        @if (corrected) {
          <mat-divider></mat-divider>
          <div class="corrected-annotation">
            <div class="corrected-header">Corrected annotation details</div>
            <div>{{ annotationNameByLabel.get(corrected.label) }}</div>
            <div>Comment: {{ corrected.comment }}</div>
            <div>Created by: {{ corrected.user?.name }}</div>
            <div>Created at: {{ formattedCorrectedAnnotationDate }}</div>
          </div>
        }
      </mat-card-content>
    </mat-card>
  }
</ng-template>

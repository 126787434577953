<ng-container>
  @if (timelineEntries.length === 0) {
    <div>No recent updates</div>
  }
  @if (timelineEntries.length > 0) {
    <div class="timeline">
      @for (entry of timelineEntries; track entry) {
        <mat-expansion-panel
          class="mat-elevation-z0"
          [hideToggle]="
            entry.type === TimelineEntryType.COMMENT || entry.type === TimelineEntryType.IMAGE
          "
          [expanded]="
            entry.type === TimelineEntryType.COMMENT || entry.type === TimelineEntryType.IMAGE
          "
          [class.disable-toggle]="entry.type === TimelineEntryType.COMMENT">
          <mat-expansion-panel-header>
            <mat-panel-title class="title">
              <div class="date">{{ entry.date | relativeDate: 'dateTime' }}</div>
              <timeline-entry-description [entry]="entry" (navigate)="goToRelatedFeature(entry)">
              </timeline-entry-description>
            </mat-panel-title>
          </mat-expansion-panel-header>
          @switch (entry.type) {
            @case (TimelineEntryType.COMMENT) {
              <timeline-comment
                [comment]="entry.details"
                [featureId]="currentFeatureId"
                (commentComplete)="addCommentAndRefresh($event)">
              </timeline-comment>
            }
            @case (TimelineEntryType.IMAGE) {
              <timeline-image [featureId]="entry.featureId"></timeline-image>
            }
            @case (TimelineEntryType.RELATED_FEATURE) {
              <timeline-related-feature [properties]="entry.details"></timeline-related-feature>
            }
            @default {
              <div class="description">{{ entry.details }}</div>
            }
          }
        </mat-expansion-panel>
      }
    </div>
  }
</ng-container>

@if (!feederCode.value) {
  <mat-progress-spinner mode="indeterminate" color="primary" diameter="48" class="spinner">
  </mat-progress-spinner>
}

@if (showSolar2Insights) {
  <div class="container solar2-container">
    <breadcrumb-navigation></breadcrumb-navigation>
    <h3 class="header">Feeder ID: {{ feederCode.value }}</h3>
    <mat-table [dataSource]="tableData" class="table feeder-table">
      <ng-container matColumnDef="keyValue">
        <mat-cell class="cell" mat-cell *matCellDef="let data">
          @for (item of data | keyvalue; track item) {
            @if (item.key === 'DIVIDER') {
              <div class="group-separator">{{ item.value }}</div>
            } @else {
              <span [innerHTML]="item.key"></span>
              <span>{{ getMetricLabel(item.value) | number: '1.0-0' }}</span>
            }
          }
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
}

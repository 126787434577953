// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/emptyservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { SendEmptyRequest, SendEmptyResponse } from "./emptyservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service tapestry.gridaware.api.v1.EmptyService
 */
export const EmptyService = {
  typeName: "tapestry.gridaware.api.v1.EmptyService",
  methods: {
    /**
     * @generated from rpc tapestry.gridaware.api.v1.EmptyService.SendEmpty
     */
    sendEmpty: {
      name: "SendEmpty",
      I: SendEmptyRequest,
      O: SendEmptyResponse,
      kind: MethodKind.Unary
    }
  }
};
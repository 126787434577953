import {Timestamp} from '@bufbuild/protobuf';

import {
  DateFilter,
  FilterView,
  LayerFilterView,
  PropertyFilter,
  StringFilter,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filter_view_pb';

import {FilterMap, LayerFilters} from '../typings/filter';
import {buildFormattedDateRange} from './date';
import {isDateField} from './properties';

/**
 * Analogous to layer visibility toggle on UI (setting to ON or OFF).
 * Clones the original FilterView and sets layer visibility on it by
 * enabling the results from the given layer to be included in filtered
 * set (if ON) or excluding them (if OFF).
 */
export function toggleLayer(view: FilterView, layerId: string, isOn: boolean): FilterView {
  return updateViewSetLayerVisibility(view.clone(), layerId, isOn);
}

/**
 * Analogous to multiple layers visibility toggle on UI (setting to ON or OFF).
 * Clones the original FilterView and sets layers visibility on it by
 * enabling the results from the given layers to be included in filtered
 * set (if ON) or excluding them (if OFF).
 */
export function toggleLayers(
  view: FilterView,
  visibilityByLayerId: Map<string, boolean>,
): FilterView {
  let updatedView = view.clone();
  for (const [layerId, isOn] of visibilityByLayerId) {
    updatedView = updateViewSetLayerVisibility(updatedView, layerId, isOn);
  }
  return updatedView;
}

/**
 * Analogous to adding filter on UI and/or 'Include inactive results' toggle.
 * Enables the results matching the criteria to be included in filtered set.
 * Clones the original FilterView and updates the layer view with the provided
 * filters map.
 */
export function setFiltersForLayer(view: FilterView, layerFilters: LayerFilters): FilterView {
  if (!view.filtersByLayerId || !view.filtersByLayerId[layerFilters.layerId]) {
    return view;
  }
  const updatedView = view.clone();
  const layer = updatedView.filtersByLayerId[layerFilters.layerId]!;
  layer.activeOnly = !layerFilters.includeInactiveResults;
  layer.propertyFilters = {};
  for (const [filterName, filterValues] of Object.entries(layerFilters.filters)) {
    layer.propertyFilters[filterName] = buildPropertyFilter(filterName, [...filterValues]);
  }

  return updatedView;
}

/**
 * Analogous to removing filter on UI. Clones the original FilterView
 * and updates the layer by removing filter with provided name from filter set.
 */
export function removeFilterFromLayer(
  view: FilterView,
  layerId: string,
  filterName: string,
): FilterView {
  if (!view.filtersByLayerId || !view.filtersByLayerId[layerId]) {
    return view;
  }
  const updatedView = view.clone();
  const propertyFilters = updatedView.filtersByLayerId[layerId].propertyFilters;
  delete propertyFilters[filterName];

  return updatedView;
}

/**
 * Converts the FilterView to the LayerFilters by layer ID representation, used
 * to actually load filters in the UI.
 */
export function extractFiltersByLayer(view: FilterView): Map<string, LayerFilters> {
  const filtersByLayer = new Map<string, LayerFilters>();
  for (const [layerId, layerFilters] of Object.entries(view.filtersByLayerId || {})) {
    filtersByLayer.set(layerId, {
      layerId,
      filters: layerFiltersToFilterMap(layerFilters),
      includeInactiveResults: !layerFilters.activeOnly,
    });
  }
  return filtersByLayer;
}

function layerFiltersToFilterMap(layerFilters: LayerFilterView): FilterMap {
  const filterMap: FilterMap = {};
  for (const [propertyName, propertyFilter] of Object.entries(layerFilters.propertyFilters)) {
    filterMap[propertyName] = propertyFilterToValues(propertyFilter);
  }
  return filterMap;
}

function propertyFilterToValues(filter: PropertyFilter): Set<string> {
  let values: readonly string[] = [];
  switch (filter.filter.case) {
    case 'stringFilter': {
      values = filter.filter.value.values;
      break;
    }
    case 'dateFilter': {
      const filterValue = filter.filter.value;
      values = buildFormattedDateRange(
        filterValue.fromTime!.toDate(),
        filterValue.toTime!.toDate(),
      );
      break;
    }
    default:
      break;
  }
  return new Set(values);
}

function updateViewSetLayerVisibility(
  view: FilterView,
  layerId: string,
  isOn: boolean,
): FilterView {
  const existingFilters = view.filtersByLayerId || {};
  const isLayerAlreadyOn = existingFilters[layerId] !== undefined;
  if (isOn && !isLayerAlreadyOn) {
    const layerFilter = new LayerFilterView();
    layerFilter.activeOnly = false;
    existingFilters[layerId] = layerFilter;
  }
  if (!isOn && isLayerAlreadyOn) {
    delete existingFilters[layerId];
  }
  return view;
}

function buildPropertyFilter(filterName: string, filterValues: string[]): PropertyFilter {
  const propertyFilter = new PropertyFilter();
  if (isDateField(filterName)) {
    const dateFilter = parseDateFilterFromValues(filterValues);
    if (dateFilter) {
      propertyFilter.filter.case = 'dateFilter';
      propertyFilter.filter.value = dateFilter;
      return propertyFilter;
    }
  }
  const filter = new StringFilter({values: filterValues});
  propertyFilter.filter.case = 'stringFilter';
  propertyFilter.filter.value = filter;
  return propertyFilter;
}

function parseDateFilterFromValues(filterValues: string[]): DateFilter | null {
  let dates: Date[] = filterValues.map((value: string) => new Date(value));
  if (dates.some((dateValue: Date) => isNaN(dateValue.getTime()))) {
    return null;
  }
  dates = dates.sort((a, b) => a.getTime() - b.getTime());
  const dateFilter = new DateFilter();
  dateFilter.fromTime = Timestamp.fromDate(dates[0]);
  dateFilter.toTime = Timestamp.fromDate(dates[dates.length - 1]);
  return dateFilter;
}

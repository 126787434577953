import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

/**
 * Categorization of the banner according to the information conveyed.
 */
export enum BannerLevel {
  ANNOUNCEMENT,
  WARNING,
}

/**
 * Informational banner component.
 */
@Component({
  selector: 'banner',
  templateUrl: './banner.ng.html',
  styleUrls: ['./banner.scss'],
})
export class Banner {
  // Info category the banner belongs to.
  @Input() level = BannerLevel.WARNING;

  // Message the banner should convey.
  @Input() message = '';

  // Label for the action link, if applicable.
  @Input() linkLabel = '';

  // Action link, if applicable.
  @Input() link = '';

  // Callback on banner dismissal.
  @Output() readonly onDismiss = new EventEmitter();

  constructor(private readonly router: Router) {}

  isWarning(): boolean {
    return this.level === BannerLevel.WARNING;
  }

  hasLink(): boolean {
    return this.link !== '' && this.linkLabel !== '';
  }

  dismiss() {
    this.onDismiss.emit();
  }

  navigateTo(link: string) {
    this.router.navigate([link]);
    this.dismiss();
  }
}

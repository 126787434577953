import {Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {EXCLUDED_SENSITIVE_KEYS, SENSITIVE_KEYS_PATTERN} from '../constants/properties';

// Matches when any word is date (case insensitive).
const MATCH_DATE_PATTERN = /\bdate\b/i;

// Matches when any word is datetime (case insensitve).
const MATCH_DATETIME_PATTERN = /\bdatetime\b/i;

/**
 * Returns true if the last word of the field is date or the field exists
 * in DATE_RANGE_FIELDS.
 */
export function isDateField(field: string): boolean {
  return MATCH_DATE_PATTERN.test(field);
}

/**
 * Returns true if the last word of the field is 'datetime' (case insensitive).
 */
export function isDateTimeField(field: string): boolean {
  return MATCH_DATETIME_PATTERN.test(field);
}

/**
 * Removes sensitive properties.
 */
export function removeSensitiveProperties(properties: Property[]): Property[] {
  return properties.filter((property: Property) => !isSensitiveKey(property.key));
}

/**
 * Returns true if the key should not be shown because its value may contain
 * sensitive information.
 */
export function isSensitiveKey(key: string): boolean {
  return EXCLUDED_SENSITIVE_KEYS.has(key?.toLowerCase()) ? false : SENSITIVE_KEYS_PATTERN.test(key);
}

/**
 * Merges property values with the same key together.
 * Example: [animal, dog], [animal, cat] -> [animal, 'dog, cat']
 */
export function mergePropertyValuesWithSameKey(properties: Property[]): Property[] {
  const valuesByKey = new Map<string, string[]>();
  for (const property of properties) {
    const propertyValues = valuesByKey.get(property.key) || [];
    if (typeof property.propertyValue.value === 'string') {
      propertyValues.push(property.propertyValue.value);
    }
    valuesByKey.set(property.key, propertyValues);
  }
  const newProperties: Property[] = [];
  for (const [key, values] of valuesByKey) {
    values.sort(caseInsensitiveAlphaSort);
    newProperties.push(
      new Property({
        key,
        propertyValue: {
          case: 'value',
          value: values.join(', '),
        },
      }),
    );
  }
  return newProperties;
}

/**
 * Returns properties sorted according to the defined priority list.
 */
export function sortPropertiesByPriority(
  properties: Property[],
  priorityPropertyKeys: string[],
): Property[] {
  if (!priorityPropertyKeys || priorityPropertyKeys.length === 0) {
    return properties;
  }
  return properties.sort((propA, propB) => {
    const isPriorityA = priorityPropertyKeys.includes(propA.key);
    const isPriorityB = priorityPropertyKeys.includes(propB.key);
    if (isPriorityA === isPriorityB) {
      return 0;
    }
    return isPriorityA ? -1 : 1;
  });
}

/**
 * Removes empty and ignored properties and returns new properties array.
 */
export function removeEmptyAndIgnoredProperties(
  properties: Property[],
  ignorePropertyKeys: string[],
): Property[] {
  return properties.filter(
    (prop) => !ignorePropertyKeys.includes(prop.key) && !!prop.propertyValue.value,
  );
}

/**
 * Returns property keys sorted according to the defined priority list.
 */
export function sortPropertyKeysByPriority(
  propertyKeys: string[],
  priorityPropertyKeys: string[],
): string[] {
  const uniquePriorityKeys = new Set(priorityPropertyKeys);
  return [...propertyKeys].sort((propA: string, propB: string): number => {
    const isPriorityA = uniquePriorityKeys.has(propA);
    const isPriorityB = uniquePriorityKeys.has(propB);
    if (isPriorityA === isPriorityB) {
      return propA.toLowerCase() < propB.toLowerCase() ? -1 : 1;
    }
    return isPriorityA ? -1 : 1;
  });
}

/**
 * Removes empty and ignored property keys and returns new property keys array.
 */
export function removeIgnoredPropertyKeys(
  propertyKeys: string[],
  ignorePropertyKeys: string[],
): string[] {
  const uniqueIgnoredKeys = new Set(ignorePropertyKeys);
  return propertyKeys.filter((prop) => !uniqueIgnoredKeys.has(prop));
}

function caseInsensitiveAlphaSort(a: string, b: string): number {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

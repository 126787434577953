import {ReplaySubject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

import {MapService} from '../services/map_service';
import {SolarProperty, SolarStats, SunroofService} from '../services/sunroof_service';

const FEEDER_ID_KEY = 'FEEDERID';
const PLACEHOLDER = '--';

interface SolarInsight {
  key: SolarProperty;
  value: number;
  unit: string;
}

/**
 * Renders Solar Insights.
 */
@Component({
  selector: 'solar-insights',
  templateUrl: './solar_insights.ng.html',
  styleUrls: ['./solar_insights.scss'],
})
export class SolarInsights implements OnInit, OnDestroy {
  @Input() feature: Feature | null = null;
  @Input() featureProperties: Property[] | null = null;

  feederId = PLACEHOLDER;
  solarStats: SolarInsight[] = [];
  loading = true;

  private readonly destroyed = new ReplaySubject<void>(1);

  constructor(
    private readonly sunroofService: SunroofService,
    private readonly mapService: MapService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.mapService.setMapStyles('silver');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['featureProperties'] && this.featureProperties) {
      const feederIdProperty = this.featureProperties.find(
        (property) => property.key === FEEDER_ID_KEY,
      );
      this.feederId =
        feederIdProperty?.propertyValue.case === 'value'
          ? feederIdProperty?.propertyValue.value
          : PLACEHOLDER;
    }
    if (changes['feature'] && this.feature) {
      this.loading = true;
      this.sunroofService
        .getAggregateRoofData(this.feature)
        .pipe(
          takeUntil(this.destroyed),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((solarStats: SolarStats) => {
          this.solarStats = this.getSolarStatsArray(solarStats);
        });
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.mapService.setMapStyles('default');
  }

  formatKey(key: string) {
    if (!key || key.length < 2) {
      return PLACEHOLDER;
    }
    return `${key.slice(0, 1)}${key.slice(1, key.length).toLowerCase().replaceAll('_', ' ')}`;
  }

  private getSolarStatsArray(solarStats: SolarStats): SolarInsight[] {
    if (!solarStats) {
      return [];
    }
    return [
      {
        key: SolarProperty.CARBON_OFFSET_FACTOR,
        value: solarStats.carbonOffsetFactor,
        unit: 'kg/MWh',
      },
      {
        key: SolarProperty.MAXIMUM_NUMBER_PANELS,
        value: solarStats.maximumNumberPanels,
        unit: '',
      },
      {
        key: SolarProperty.MAXIMUM_PANEL_AREA,
        value: solarStats.maximumPanelArea,
        unit: 'm2',
      },
      {
        key: SolarProperty.MAXIMUM_SUNSHINE_HOURS_PER_YEAR,
        value: solarStats.maximumSunshineHoursPerYear,
        unit: 'hours',
      },
    ];
  }
}

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Injectable, OnDestroy} from '@angular/core';

import {STREETVIEW_RECENCY_LAYER_ID} from '../constants/layer';
import {VisibilityByLayerUpdate} from '../typings/map';
import {MapService} from './map_service';

/**
 * Service for managing street view recency functionality.
 */
@Injectable({providedIn: 'root'})
export class StreetViewRecencyService implements OnDestroy {
  private destroyed = new Subject<void>();

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  // TODO(iominh): Replace with real API data.
  private heatmapData = this.generateRandomPoints(42.16927283, -84.57207072, 100, 1000);

  private heatmapLayer = google.maps.visualization
    ? new google.maps.visualization.HeatmapLayer({
        data: this.heatmapData,
      })
    : null;

  constructor(private mapService: MapService) {
    this.listenForLayerVisibilityUpdates();
  }

  addHeatmap() {
    this.heatmapLayer?.setMap(this.mapService.map);
  }

  removeHeatmap() {
    this.heatmapLayer?.setMap(null);
  }

  getOpacity() {
    return this.heatmapLayer?.get('opacity') || 1;
  }

  getRadius() {
    return this.heatmapLayer?.get('radius') || 1;
  }

  onOpacityChange(opacity: number) {
    this.heatmapLayer?.set('opacity', opacity);
  }

  onRadiusChange(radius: number) {
    this.heatmapLayer?.set('radius', radius);
  }

  private listenForLayerVisibilityUpdates() {
    this.mapService
      .onLayerVisibilityChanged()
      .pipe(takeUntil(this.destroyed))
      .subscribe((visibilityByLayerUpdate: VisibilityByLayerUpdate) => {
        if (visibilityByLayerUpdate.visibilityByLayerId.get(STREETVIEW_RECENCY_LAYER_ID)) {
          this.addHeatmap();
        } else {
          this.removeHeatmap();
        }
      });
  }

  // TODO(iominh): Replace with real API data.
  private generateRandomPoints(
    lat: number,
    lng: number,
    radius: number,
    numPoints: number,
  ): google.maps.LatLng[] {
    const points: google.maps.LatLng[] = [];
    const earthRadius = 3958.8; // Earth's radius in miles.

    for (let i = 0; i < numPoints; i++) {
      // Convert radius from miles to radians.
      const radiusInRadians = radius / earthRadius;

      // Generate a random angle.
      const angle = Math.random() * 2 * Math.PI;

      // Generate a random distance.
      const distance = Math.sqrt(Math.random()) * radiusInRadians;

      // Calculate the new latitude.
      const newLat = Math.asin(
        Math.sin((lat * Math.PI) / 180) * Math.cos(distance) +
          Math.cos((lat * Math.PI) / 180) * Math.sin(distance) * Math.cos(angle),
      );

      // Calculate the new longitude.
      const newLng =
        (lng * Math.PI) / 180 +
        Math.atan2(
          Math.sin(angle) * Math.sin(distance) * Math.cos((lat * Math.PI) / 180),
          Math.cos(distance) - Math.sin((lat * Math.PI) / 180) * Math.sin(newLat),
        );

      // Convert radians back to degrees.
      const finalLat = (newLat * 180) / Math.PI;
      const finalLng = (newLng * 180) / Math.PI;

      points.push(new google.maps.LatLng(finalLat, finalLng));
    }
    return points;
  }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';

import {MetadataModule} from '../metadata/metadata_module';
import {SunroofDetailsPage} from './sunroof_details';

@NgModule({
  declarations: [SunroofDetailsPage],
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule, MetadataModule],
  exports: [SunroofDetailsPage],
})
export class SunroofModule {}

@if (imageSrc | async; as img) {
  <div class="container">
    <div class="background-image" style="background-image: url({{ img }})">
      <button
        mat-icon-button
        class="nav back"
        (click)="close()"
        aria-label="Go back"
        i18n-aria-label
        [matTooltip]="'Back'">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button
        mat-icon-button
        class="nav delete"
        aria-label="Delete"
        i18n-aria-label
        (click)="openDeleteImageConfirmationDialog()"
        [matTooltip]="'Delete'">
        <mat-icon>delete_outline</mat-icon>
      </button>
      <button
        mat-icon-button
        class="nav previous"
        (click)="previous()"
        aria-label="View previous photo"
        i18n-aria-label
        [matTooltip]="'Previous'">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button
        mat-icon-button
        class="nav next"
        (click)="next()"
        aria-label="View next photo"
        i18n-aria-label
        [matTooltip]="'Next'">
        <mat-icon>navigate_next</mat-icon>
      </button>
      <button
        mat-icon-button
        class="nav download"
        (click)="downloadImage()"
        aria-label="Download image"
        i18n-aria-label
        [matTooltip]="'Download image'">
        <mat-icon>download</mat-icon>
      </button>
    </div>
  </div>
}

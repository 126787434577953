import {Injectable} from '@angular/core';

import {
  DEFAULT_COLOR_PALETTE,
  DEFAULT_DASHED_STROKE,
  DEFAULT_ICON_METADATA,
  DESELECTED_MULTI_MARKER_ICON_SVG,
  DESELECTED_MULTI_MIXED_MARKER_ICON_SVG,
  EXTERNAL_LINK_ICON_URL_PREFIX,
  ICON_SHAPE_ATTRIBUTE,
  MAX_CHARS_IN_LABEL,
  SELECTED_HIGHLIGHT_ICON,
  SELECTED_MULTI_MARKER_ICON_SVG,
  WITHOUT_DASHED_STROKE,
} from '../constants/icon';
import {ColorPaletteState, Icon, IconGeneratorMetadata} from '../typings/icon';

/**
 * Required arguments to create a Icon URL for displaying SVGs in image tags.
 */
export interface IconUrl {
  // An SVG element.
  svg: string;
  label: string;
  color: string;
  strokeColor: string;
  strokeWidth: string;
  strokeIsDashed: boolean;
  opacity: string;
}

/**
 * The front and back icon properties of a multi-marker icon.
 */
export interface MultiMarkerIconProperties {
  fillColor: string;
  borderColor: string;
  // If true then this multi-marker contains user and sensor collected images.
  isMixedImage: boolean;
}

/**
 * The front and back colors of a multi-marker icon.
 */
export interface MultiMarkerIconColor {
  front: MultiMarkerIconProperties;
  back: MultiMarkerIconProperties;
}

/**
 * Contains selected and unselected SVG icon strings.
 */
export interface IconSvgForPointMaker {
  selectedIcon: string;
  unselectedIcon: string;
}

/**
 * The pattern that returns an icon's shape from an SVG string.
 */
const ICON_SHAPE_PATTERN = new RegExp(`${ICON_SHAPE_ATTRIBUTE}="(.+)"`);

/**
 * Generates icons for a set of data, keeping track of colors as they're used
 * for various keys.
 */
@Injectable()
export class IconGenerator {
  private nextColorIndex = 0;
  // Map a layer's ID to its color palette. Every layer has a separate palette
  // with its own state. If a palette isn't provided, a default palette will be
  // used. In cases where a default palette is used, palette state will still
  // remain separate by layer.
  colorPaletteStateByLayerId = new Map<string, ColorPaletteState>();

  /**
   * Creates an deslected and selected icon for a marker. Keeps track of layer
   * color palettes separately.
   */
  getIcon(iconGeneratorMetadata: IconGeneratorMetadata): Icon {
    const {
      layerId,
      label,
      colorKey,
      colorPalette,
      colorHex,
      strokeColor,
      strokeWidth,
      strokeIsDashed,
      opacity,
      deselectedIconSvg,
      selectedIconSvg,
    } = {...DEFAULT_ICON_METADATA, ...iconGeneratorMetadata};

    // If a layer doesn't have a color palette state, create one. Otherwise, the
    // existing color palette state will be used. A color palette state holds
    // a Map of color by colorKey. The color palette will cycle through (if the
    // colorKey doesn't match a color yet or if there isn't a colorKey provided)
    // the colors.
    if (!this.colorPaletteStateByLayerId.has(layerId)) {
      this.colorPaletteStateByLayerId.set(layerId, {
        palette: colorPalette!,
        index: 0,
        colorByLabel: new Map<string, string>(),
      });
    }
    const labelPrefix = label!.slice(0, MAX_CHARS_IN_LABEL);
    const color =
      colorHex || this.getColor(colorKey!, this.colorPaletteStateByLayerId.get(layerId)!);
    const populatedDeselectedIconSvg = this.getEncodedIconUrl({
      svg: deselectedIconSvg!,
      label: labelPrefix,
      color,
      strokeColor: strokeColor!,
      strokeWidth: strokeWidth!,
      strokeIsDashed: strokeIsDashed!,
      opacity: opacity!,
    });
    const populatedSelectedIconSvg = this.getEncodedIconUrl({
      svg: selectedIconSvg!,
      label: labelPrefix,
      color,
      strokeColor: strokeColor!,
      strokeWidth: strokeWidth!,
      strokeIsDashed: strokeIsDashed!,
      opacity: opacity!,
    });
    return {
      deselected: {
        scaledSize: new google.maps.Size(18, 18),
        url: populatedDeselectedIconSvg,
        // Align the center of the marker with the geopoint.
        // By default, the anchor is located along the center
        // point of the bottom of the image.
        // https://developers.google.com/maps/documentation/javascript/reference/marker#Icon.anchor
        anchor: new google.maps.Point(9, 9),
      },
      selected: {
        scaledSize: new google.maps.Size(32, 32),
        url: populatedSelectedIconSvg,
        // Align the center of the marker with the geopoint.
        // By default, the anchor is located along the center
        // point of the bottom of the image.
        // https://developers.google.com/maps/documentation/javascript/reference/marker#Icon.anchor
        anchor: new google.maps.Point(16, 16),
      },
    };
  }

  getMultiMarkerIcon(label: string, multiMarkerIconColor: MultiMarkerIconColor): Icon {
    const populatedDeselectedIconSvg = this.populateMultiIconSvg(
      multiMarkerIconColor.front.isMixedImage
        ? DESELECTED_MULTI_MIXED_MARKER_ICON_SVG
        : DESELECTED_MULTI_MARKER_ICON_SVG,
      label,
      multiMarkerIconColor.front.fillColor,
      multiMarkerIconColor.back.fillColor,
      multiMarkerIconColor.front.borderColor,
      multiMarkerIconColor.back.borderColor,
    );
    const populatedSelectedIconSvg = this.populateMultiIconSvg(
      multiMarkerIconColor.front.isMixedImage
        ? DESELECTED_MULTI_MIXED_MARKER_ICON_SVG
        : SELECTED_MULTI_MARKER_ICON_SVG,
      label,
      multiMarkerIconColor.front.fillColor,
      multiMarkerIconColor.back.fillColor,
      multiMarkerIconColor.front.borderColor,
      multiMarkerIconColor.back.borderColor,
    );
    return {
      deselected: {
        url: `${EXTERNAL_LINK_ICON_URL_PREFIX},${encodeURIComponent(populatedDeselectedIconSvg)}`,
        anchor: new google.maps.Point(14, 10),
      },
      selected: {
        url: `${EXTERNAL_LINK_ICON_URL_PREFIX},${encodeURIComponent(populatedSelectedIconSvg)}`,
        anchor: new google.maps.Point(18, 13),
      },
    };
  }

  /**
   * Returns an encoded URL that can be used in an img tag to display an SVG.
   */
  getEncodedIconUrl(iconUrl: IconUrl) {
    return `${EXTERNAL_LINK_ICON_URL_PREFIX},${encodeURIComponent(this.populateIconSvg(iconUrl))}`;
  }

  /**
   * Returns an SVG's shape which is saved as an attribute on the SVG.
   */
  getShapeFromSvg(svg: string): string {
    const match = svg.match(ICON_SHAPE_PATTERN);
    return match ? match[1] : '';
  }

  /**
   * Returns the next color from the default palette. This uses a shared color
   * palette state.
   */
  getColorFromDefaultPalette(): string {
    const index = this.nextColorIndex % DEFAULT_COLOR_PALETTE.length;
    const color = DEFAULT_COLOR_PALETTE[index];
    this.nextColorIndex++;
    return color;
  }

  /**
   * Creates the highlight icon for selected marker's point.
   * Represents the circular area around the selected marker.
   */
  getSelectedHighlightIcon(): google.maps.Icon {
    const highlightColor = '#808080'; // Gray.
    const highlightOpacity = '0.7';
    const dimensionUnit = 70;
    const populatedIconSvg = this.getEncodedIconUrl({
      svg: SELECTED_HIGHLIGHT_ICON,
      label: '',
      color: highlightColor,
      strokeColor: '',
      strokeWidth: '',
      strokeIsDashed: false,
      opacity: highlightOpacity,
    });
    return {
      url: populatedIconSvg,
      scaledSize: new google.maps.Size(dimensionUnit, dimensionUnit),
      // Align the center of the marker with the geopoint.
      // By default, the anchor is located along the center
      // point of the bottom of the image.
      // https://developers.google.com/maps/documentation/javascript/reference/marker#Icon.anchor
      anchor: new google.maps.Point(dimensionUnit / 2, dimensionUnit / 2),
    };
  }

  private getColor(label: string, colorPaletteState: ColorPaletteState): string {
    const {palette, index, colorByLabel} = colorPaletteState;
    if (colorByLabel.has(label)) {
      return colorByLabel.get(label)!;
    }
    const color = palette[index % palette.length];
    if (label) {
      colorByLabel.set(label, color);
    }
    colorPaletteState.index++;
    return color;
  }

  // TODO(reubenn): Discuss combining populateIconSvg and populateMulitIconSvg
  // with an interface of optional arguments. The issue with this approach is
  // that it could potentially replace values with undefined as there wouldn't
  // be a guarantee that any property had a value.
  /**
   * Replaces <$label> and <$color> with provided label and color. We need to
   * replace these values because there are possibly SVGs coming from the
   * backend.
   */
  // populateIconSvg(svg: string, label: string, color: string, opacity:
  // string):
  private populateIconSvg(iconUrl: IconUrl): string {
    return iconUrl.svg
      .replace(/<\$label>/g, iconUrl.label)
      .replace(/<\$color>/g, iconUrl.color)
      .replace(/<\$opacity>/g, iconUrl.opacity)
      .replace(/<\$strokeColor>/g, iconUrl.strokeColor)
      .replace(/<\$strokeWidth>/g, iconUrl.strokeWidth)
      .replace(
        /<\$dashedStroke>/g,
        iconUrl.strokeIsDashed ? DEFAULT_DASHED_STROKE : WITHOUT_DASHED_STROKE,
      );
  }

  /**
   * Replaces <$label>, <$frontColor>, <$backColor> with provided label and
   * colors.
   */
  private populateMultiIconSvg(
    svg: string,
    label: string,
    frontColor: string,
    backColor: string,
    frontBorderColor: string,
    backBorderColor: string,
  ): string {
    return svg
      .replace(/<\$label>/g, label)
      .replace(/<\$frontColor>/g, frontColor)
      .replace(/<\$backColor>/g, backColor)
      .replace(/<\$frontBorderColor>/g, frontBorderColor)
      .replace(/<\$backBorderColor>/g, backBorderColor);
  }
}

import {Observable, Observer, ReplaySubject, Subject, defer, first, of} from 'rxjs';

import {Injectable} from '@angular/core';

import {StreetViewResponse} from '../typings/street_view';
import {ConfigService} from './config_service';

/**
 * Text that is emitted in the case that a formatted location is not available
 * for a given lat lng.
 */
export const LOCATION_NOT_AVAILABLE_TEXT = 'Not available';

/**
 * Service for details page related tasks.
 */
@Injectable()
export class GoogleMapsService {
  geocoder: google.maps.Geocoder | null = null;

  // A single google map that's reused.
  private map: google.maps.Map | null = null;

  // A single google street view panorama that's reused.
  private panorama: google.maps.StreetViewPanorama | null = null;

  private readonly googleMapsLoaded = new ReplaySubject<boolean>();

  constructor(private readonly configService: ConfigService) {
    defer(() => this.loadMapsLibs())
      .pipe(first())
      .subscribe();
  }

  /**
   * Clean up map and street view resources when no longer in use.
   */
  destroy() {
    this.map = null;
    this.panorama = null;
  }

  /**
   * Renders a satellite map view of current asset location.
   */
  renderSatelliteMap(element: HTMLElement, latLng: google.maps.LatLngLiteral, iconURL?: string) {
    const mapOptions: google.maps.MapOptions = {
      disableDefaultUI: true,
      center: latLng,
      zoom: 16,
    };

    if (!this.map) {
      this.map = new google.maps.Map(element, mapOptions);
    }
    this.map.setOptions(mapOptions);
    this.map.setMapTypeId('satellite');

    if (iconURL) {
      const marker = new google.maps.Marker({
        position: latLng,
        icon: iconURL,
      });
      marker.setMap(this.map);
    }
  }

  /**
   * Renders a map.
   */
  renderMainMap(
    element: HTMLElement,
    center: google.maps.LatLng,
    minZoom: number,
    zoom: number,
    obfuscationStyles: google.maps.MapTypeStyle[],
    onIdle: () => void,
    onClick: ({latLng}: google.maps.MapMouseEvent) => void,
  ): google.maps.Map {
    let styles: google.maps.MapTypeStyle[] = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{visibility: 'off'}],
      },
    ];
    if (this.configService.debugEnabled) {
      styles = styles.concat(obfuscationStyles);
    }
    const mapOptions: google.maps.MapOptions = {
      center,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      minZoom,
      styles,
      zoom,
    };
    const map = new google.maps.Map(element, mapOptions);
    map.get('streetView').setOptions({
      addressControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      imageDateControl: true,
    });
    map.addListener('idle', onIdle);
    map.addListener('click', onClick);
    return map;
  }

  /**
   * Get the human-readable address from lat lng.
   */
  getAddressFromLatLng(location: google.maps.LatLngLiteral): Observable<string> {
    if (this.configService.debugEnabled) {
      return of('100 Mayfield Ave, Mountain View CA 94043');
    }
    return new Observable((observer: Observer<string>) => {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }
      this.geocoder.geocode({location}, (results: google.maps.GeocoderResult[] | null) => {
        const formattedAddress =
          results && results[0] ? results[0].formatted_address : LOCATION_NOT_AVAILABLE_TEXT;
        observer.next(formattedAddress);
        observer.complete();
      });
    });
  }

  /**
   * Get the street view data from the google maps API.
   * @param location: The location to get street view for.
   */
  getStreetViewData(location: google.maps.LatLngLiteral): Observable<StreetViewResponse | null> {
    const streetViewService = new google.maps.StreetViewService();
    const panoramaRequest: google.maps.StreetViewLocationRequest = {
      location,
      radius: 100,
      source: google.maps.StreetViewSource.OUTDOOR,
    };

    return new Observable((observer: Observer<StreetViewResponse | null>) => {
      streetViewService.getPanorama(
        panoramaRequest,
        (data: google.maps.StreetViewPanoramaData | null, status: google.maps.StreetViewStatus) => {
          observer.next(data ? {data, status, location} : null);
          observer.complete();
        },
      );
    });
  }

  /**
   * Based on the streetViewResponse, decide whether or not street view should
   * render.
   * @param streetViewResponse: The response from streetViewService.getPanorama.
   */
  shouldRenderStreetView(streetViewResponse: StreetViewResponse): boolean {
    const {data, status} = streetViewResponse;
    return !!(
      status === google.maps.StreetViewStatus.OK &&
      data &&
      data.location &&
      data.location.pano &&
      data.location.latLng
    );
  }

  /**
   * Render street view in the DOM.
   * @param container: The container to render street view inside of.
   * @param data: The data required to make the panorama image.
   * @param position: The lat lng where the marker should be placed.
   * @param iconURL: The tagged or untagged asset URL.
   */
  renderStreetView(
    container: HTMLElement,
    data: google.maps.StreetViewPanoramaData,
    position: google.maps.LatLngLiteral,
    iconURL: string,
  ) {
    const heading = google.maps.geometry.spherical.computeHeading(
      data.location!.latLng!,
      new google.maps.LatLng(position.lat, position.lng),
    );

    const options = {
      disableDefaultUI: true,
      fullscreenControl: true,
      imageDateControl: true,
      motionTracking: false,
      pano: data.location!.pano,
      pov: {heading, pitch: 0},
    };

    if (!this.panorama) {
      this.panorama = new google.maps.StreetViewPanorama(container, options);
    }
    this.panorama.setOptions(options);

    // The call that makes the new marker will place the marker on the map
    // that is passed to the map property, ie, the panorama.
    // tslint:disable-next-line:no-unused-expression map:
    new google.maps.Marker({
      position,
      icon: iconURL,
      map: this.panorama,
    });
  }

  onGoogleMapsLoaded(): Subject<boolean> {
    return this.googleMapsLoaded;
  }

  private async loadMapsLibs() {
    try {
      (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
      (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
      (await google.maps.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
      (await google.maps.importLibrary('visualization')) as google.maps.VisualizationLibrary;
      this.googleMapsLoaded.next(true);
    } catch {
      this.googleMapsLoaded.next(false);
    }
  }
}

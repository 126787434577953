import {ImageAssociationStatus} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';

/**
 * Assets can be filtered based on one of these options. Filtering behaves as OR.
 * When no options are set then all assets are shown since there's no filters.
 */
export enum AssetImageSourceOption {
  NO_IMAGES = 'no_images',
  // Matches a feature if all of its associated images have non-empty user
  // property.
  USER_IMAGES_ONLY = 'user_only',
  // Matches a feature if all of its associated images have empty user
  // property.
  TAPESTRY_IMAGES_ONLY = 'tapestry_images_only',
  // Matches a feature if at least one of its associated images has empty user
  // property and if at least one of its images has non-empty user property.
  USER_AND_TAPESTRY_IMAGES = 'user_and_tapestry_images',
}

/**
 * Map AssetImageSourceOption enum values to proto ImageAssociationStatus.
 */
export const ASSET_IMAGE_SOURCE_MAP = new Map<string, ImageAssociationStatus>([
  [AssetImageSourceOption.NO_IMAGES.toString(), ImageAssociationStatus.NO_IMAGES],
  [AssetImageSourceOption.USER_IMAGES_ONLY.toString(), ImageAssociationStatus.USER_IMAGES_ONLY],
  [
    AssetImageSourceOption.TAPESTRY_IMAGES_ONLY.toString(),
    ImageAssociationStatus.TAPESTRY_IMAGES_ONLY,
  ],
  [
    AssetImageSourceOption.USER_AND_TAPESTRY_IMAGES.toString(),
    ImageAssociationStatus.USER_AND_TAPESTRY_IMAGES,
  ],
]);

/**
 * Feeder ID that exists as a property on asset Feature.
 */
export const FEEDER_ID = 'Feeder ID';

/**
 * Feeder name that exists as a property on asset Feature.
 */
export const FEEDER_NAME = 'Feeder Name';

/**
 * Equipment ID that exists as a property on asset Feature.
 */
export const EQUIPMENT_ID = 'EquipmentID';

/**
 * Asset Type that exists as a property on asset Feature.
 */
export const ASSET_TYPE = 'Asset type';

/**
 * The tag number that exists as a property on a CMS support structure.
 */
// LINT.IfChange(ce_tag_number)
export const CE_TAG_NUMBER = 'CE Tag Number';
// LINT.ThenChange(//depot/google3/googlex/refinery/viaduct/ingestion/cms/assets/supportstructurecsvheaders.go)

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {MatchingResult} from './matching_result';

@NgModule({
  declarations: [MatchingResult],
  exports: [MatchingResult],
  imports: [CommonModule],
})
export class MatchingResultModule {}

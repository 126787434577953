<div class="container" [ngClass]="{'asset-timeline-enabled': assetTimelineEnabled}">
  <div class="info-container dark-theme" [ngClass]="{'show-filters': 'studioFilteringEnabled'}">
    @if (!studioFilteringEnabled || filters.size === 0) {
      <div class="count">{{ images.length }} Images</div>
    } @else if (studioFilteringEnabled) {
      <div class="filter-container">
        <mat-chip-set class="mat-chip-set">
          <mat-chip color="primary" [highlighted]="showAllImages" (click)="toggleShowAllImages()">
            All Images ({{ images.length }})
          </mat-chip>
          @if (filters.size > 0) {
            <mat-divider class="chip-divider" [vertical]="true"></mat-divider>
          }
          @for (filter of filters | keyvalue; track filter) {
            <mat-chip
              color="primary"
              [highlighted]="filters.get(filter.key)?.selected"
              (click)="toggleSelection(filter.key)">
              {{ filter.key }} ({{ filters.get(filter.key)?.count }})
            </mat-chip>
          }
        </mat-chip-set>
      </div>
    }
    @if (showAssetTimeline) {
      <div class="sort-by-options-container">
        <span>Sort by: </span>
        <button mat-button class="sort-by-options-menu-button" [matMenuTriggerFor]="menu">
          <div class="sort-by-options-menu-button-label">
            <span>{{ getSelectedSortByOption() }}</span>
            @if (updateIsInProgress) {
              <mat-spinner class="timeline-update-spinner" diameter="16"> </mat-spinner>
            } @else {
              <mat-icon> arrow_drop_down </mat-icon>
            }
          </div>
        </button>
        <mat-menu #menu="matMenu" class="sort-by-options-menu" xPosition="before">
          @for (option of sortByOptionsValues; track option) {
            <button mat-menu-item (click)="onSortByOptionClicked(option)">
              <div class="sort-by-option">
                <span>{{ option }}</span>
                @if (isCurrentSortByOptionSelected(option)) {
                  <mat-icon class="sort-by-option-selected-icon">check</mat-icon>
                }
              </div>
            </button>
          }
        </mat-menu>
      </div>
    }
  </div>
  <div class="carousel" [ngClass]="{'show-filters': 'studioFilteringEnabled'}">
    <button
      class="arrow left"
      mat-icon-button
      aria-label="Previous image"
      i18n-aria-label
      (click)="previousImage()"
      matTooltip="← Previous Image">
      <mat-icon>expand_more</mat-icon>
    </button>
    @if (!assetTimelineEnabled) {
      <cdk-virtual-scroll-viewport
        #viewport
        class="slider-container"
        [itemSize]="itemSize"
        orientation="horizontal">
        <button
          *cdkVirtualFor="let image of filteredImages; index as i"
          class="button-image"
          [matTooltip]="image?.name"
          [matTooltipDisabled]="isMobile"
          [ngClass]="{selected: i === selectedImageIndex}"
          (click)="selectImage(i)">
          <img
            [src]="imageUrlsById.get(image.id)"
            [alt]="image?.name || 'Image'"
            [height]="heightPx" />
          @if (defects.has(image.id)) {
            <div matTooltip="Image contains a defect" class="defect"></div>
          }
        </button>
      </cdk-virtual-scroll-viewport>
    } @else {
      <cdk-virtual-scroll-viewport
        #viewport
        [appendOnly]="showAssetTimeline"
        class="slider-container"
        [itemSize]="itemSize"
        orientation="horizontal">
        @if (showAssetTimeline) {
          <div *cdkVirtualFor="let imageGroup of imageGroups" class="image-group">
            <div class="images-section">
              @for (image of imageGroup.images; track image; let i = $index) {
                <div
                  class="button-image-container"
                  [ngClass]="{selected: i + imageGroup.groupStartIndex === selectedImageIndex}">
                  <button
                    class="button-image"
                    [matTooltip]="image?.name"
                    [matTooltipDisabled]="isMobile"
                    (click)="selectImage(i + imageGroup.groupStartIndex)">
                    <img
                      [src]="imageUrlsById.get(image.id)"
                      [alt]="image?.name || 'Image'"
                      [height]="heightPx" />
                    @if (defects.has(image.id)) {
                      <div matTooltip="Image contains a defect" class="defect"></div>
                    }
                  </button>
                </div>
              }
            </div>
            <mat-chip
              class="group-label-chip"
              [ngClass]="{
                highlighted:
                  imageGroup.groupStartIndex <= selectedImageIndex &&
                  selectedImageIndex <= imageGroup.groupEndIndex
              }">
              <span class="group-label">{{ imageGroup.displayLabel }}</span>
            </mat-chip>
          </div>
        } @else {
          <div class="images">
            <div
              *cdkVirtualFor="let image of filteredImages; index as i"
              class="button-image-container"
              [ngClass]="{selected: i === selectedImageIndex}">
              <button
                class="button-image"
                [matTooltip]="image?.name"
                [matTooltipDisabled]="isMobile"
                (click)="selectImage(i)">
                <img
                  [src]="imageUrlsById.get(image.id)"
                  [alt]="image?.name || 'Image'"
                  [height]="heightPx" />
                @if (defects.has(image.id)) {
                  <div matTooltip="Image contains a defect" class="defect"></div>
                }
              </button>
            </div>
          </div>
        }
      </cdk-virtual-scroll-viewport>
    }
    <button
      class="arrow right"
      mat-icon-button
      aria-label="Next image"
      i18n-aria-label
      (click)="nextImage()"
      matTooltip="Next Image →">
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
</div>

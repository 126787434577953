// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/filter_view.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";

/**
 * GridAware filter view that can be stored as user view and reconstructed on
 * UI.
 *
 * @generated from message tapestry.gridaware.api.v1.FilterView
 */
export const FilterView = proto3.makeMessageType("tapestry.gridaware.api.v1.FilterView", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "display_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "user",
  kind: "message",
  T: User
}, {
  no: 4,
  name: "filters_by_layer_id",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: LayerFilterView
  }
}]);

/**
 * Layer-specific filter view that can be stored as user view and
 * reconstructed on UI.
 *
 * @generated from message tapestry.gridaware.api.v1.LayerFilterView
 */
export const LayerFilterView = proto3.makeMessageType("tapestry.gridaware.api.v1.LayerFilterView", () => [{
  no: 1,
  name: "property_filters",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "message",
    T: PropertyFilter
  }
}, {
  no: 2,
  name: "active_only",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * Filter defined on one of layer's metadata values.
 * Value depends on the type of the metadata.
 *
 * @generated from message tapestry.gridaware.api.v1.PropertyFilter
 */
export const PropertyFilter = proto3.makeMessageType("tapestry.gridaware.api.v1.PropertyFilter", () => [{
  no: 1,
  name: "date_filter",
  kind: "message",
  T: DateFilter,
  oneof: "filter"
}, {
  no: 2,
  name: "string_filter",
  kind: "message",
  T: StringFilter,
  oneof: "filter"
}]);

/**
 * Filter defined on one of layer's date-typed metadata values.
 * Defines the standard from/to range.
 *
 * @generated from message tapestry.gridaware.api.v1.DateFilter
 */
export const DateFilter = proto3.makeMessageType("tapestry.gridaware.api.v1.DateFilter", () => [{
  no: 1,
  name: "from_time",
  kind: "message",
  T: Timestamp
}, {
  no: 2,
  name: "to_time",
  kind: "message",
  T: Timestamp
}]);

/**
 * Filter defined on one of layer's string-typed metadata values.
 * Defines the set of desired values.
 *
 * @generated from message tapestry.gridaware.api.v1.StringFilter
 */
export const StringFilter = proto3.makeMessageType("tapestry.gridaware.api.v1.StringFilter", () => [{
  no: 1,
  name: "values",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}]);
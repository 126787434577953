import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {FilterField} from '../constants/filters';
import {Filter, type FilterMap} from '../typings/filter';

/**
 * Component for rendering filter chip list.
 */
@Component({
  selector: 'filter-chips',
  templateUrl: './filter_chips.ng.html',
  styleUrls: ['./filter_chips.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterChips {
  @Input() filters: FilterMap | null = {};

  /**
   * Filters that are hidden as they may be displayed elsewhere in the UI.
   */
  @Input()
  hiddenFilters: string[] = [
    FilterField.ANNOTATION_EXCLUDE,
    FilterField.ANNOTATION_INCLUDE,
    FilterField.IMAGE_ASSOCIATION_FILTER,
    FilterField.IMAGE_GROUPS,
  ];

  @Output() readonly editingChange = new EventEmitter<Filter>();
  @Output() readonly removeChip = new EventEmitter<string>();

  changeFilter(filterName: string, selectedOptions: Set<string>) {
    this.editingChange.emit({
      name: filterName,
      selectedValues: selectedOptions,
    });
  }

  removeFilter(filterName: string) {
    this.removeChip.emit(filterName);
  }
}

import {ImageAnnotationLabel} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';

import {
  AnnotationFilterInfo,
  AnnotationFilterType,
  AnnotationType,
  LabelInfo,
} from '../typings/annotations';

/**
 * Labels that can be applied to image annotation.
 */
export const LABELS: LabelInfo[] = [
  {
    value: ImageAnnotationLabel.AERIAL_SPACER_CABLE_LABEL,
    label: 'Aerial Spacer Cable',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.BARE_PRIMARY_WIRE_LABEL,
    label: 'Primary Wire',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.CROSSARM_LABEL,
    label: 'Crossarm',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.CUT_OUT_LABEL,
    label: 'Cutout',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.CUT_OUT_POLYMER_LABEL,
    label: 'Cutout (Polymer)',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.CUT_OUT_PORCELAIN_LABEL,
    label: 'Cutout (Porcelain)',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.DEAD_END_TYPE_INSULATOR_LABEL,
    label: 'Dead End Insulator',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.DISCONNECT_SWITCH_GANG_LABEL,
    label: 'Three Phase Gang Operated Switch',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.DOWN_GUY_LABEL,
    label: 'Guy Wire',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.ISOLATOR_LABEL,
    label: 'Isolating Transformer',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.LIGHTING_ARRESTOR_LABEL,
    label: 'Surge Arrestor',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.LINE_SENSOR_LABEL,
    label: 'Line Sensor',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.PIN_TYPE_INSULATOR_LABEL,
    label: 'Pin Insulator',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.PRIMARY_METER_LABEL,
    label: 'Primary Meter',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.PRIMARY_RISER_LABEL,
    label: 'Primary Riser',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.RECLOSER_LABEL,
    label: 'Recloser',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.REGULATOR_LABEL,
    label: 'Regulator',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.SECONDARY_OPEN_WIRE_LABEL,
    label: 'Secondary Wire',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.SECTIONALIZER_LABEL,
    label: 'Sectionalizer',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.STREETLIGHT_LABEL,
    label: 'Streetlight',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.TRANSFORMER_LABEL,
    label: 'Transformer',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.SWITCH_LABEL,
    label: 'Switch',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.POLE_LABEL,
    label: 'Pole',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.CAPACITOR_LABEL,
    label: 'Capacitor',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.VEGETATION_OVERGROWTH_LABEL,
    label: 'Vegetation Overgrowth',
    type: AnnotationType.DEFECT,
  },
  {
    value: ImageAnnotationLabel.PIN_INSULATOR_DEFECT_LABEL,
    label: 'Pin Insulator with Defect',
    type: AnnotationType.DEFECT,
  },
  {
    value: ImageAnnotationLabel.CONTROL_BOX_LABEL,
    label: 'Control Box',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.CROSSARM_DEFECT_LABEL,
    label: 'Crossarm with Defect',
    type: AnnotationType.DEFECT,
  },
  {
    value: ImageAnnotationLabel.POLE_DEFECT_LABEL,
    label: 'Pole Defect',
    type: AnnotationType.DEFECT,
  },
  {
    value: ImageAnnotationLabel.CROSSARM_WITH_BRACE_DEFECT_LABEL,
    label: 'Crossarm with Brace Defect',
    type: AnnotationType.DEFECT,
  },
  {
    value: ImageAnnotationLabel.RECLOSER_OIL_CIRCUIT_LABEL,
    label: 'Oil Circuit Recloser',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.RECLOSER_VACUUM_CIRCUIT_LABEL,
    label: 'Vacuum Circuit Recloser',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.RECLOSER_AUTOMATIC_TRANSFER_LABEL,
    label: 'Automatic Transfer Recloser',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.SECONDARY_POWER_SOURCE_LABEL,
    label: 'Secondary Power Source',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.TREE_LABEL,
    label: 'Tree',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.COMMUNICATION_POLE_LABEL,
    label: 'Communication Pole',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.SWITCH_OPEN_LABEL,
    label: 'Switch (Open)',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.GROUND_TRANSFORMER_LABEL,
    label: 'Ground Transformer',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.GROUND_LV_CABINET,
    label: 'Ground LV Cabinet',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.GROUND_ASSET_LABEL,
    label: 'Ground Asset',
    type: AnnotationType.ASSET,
  },
  {
    value: ImageAnnotationLabel.OTHER_LABEL,
    label: 'Other',
    type: AnnotationType.ASSET,
  },
];

/**
 * Annotation name by its label.
 */
export const ANNOTATION_NAME_BY_LABEL = new Map<ImageAnnotationLabel, string>(
  LABELS.map((label: LabelInfo) => [label.value, label.label]),
);

/**
 * Types of annotation filters.
 * TODO(b/281585966) activate more types once they are available.
 */
export const ANNOTATION_FILTER_TYPES: AnnotationFilterInfo[] = [
  {type: AnnotationFilterType.AUTHOR},
  {type: AnnotationFilterType.CREATED_DATE},
  {type: AnnotationFilterType.LABEL, activated: true},
  {type: AnnotationFilterType.MODIFIED_DATE},
  {type: AnnotationFilterType.SOURCE},
];

/**
 * Possible sources of annotations.
 */
export enum AnnotationSource {
  ML = 'ml',
  USER = 'user',
}

import { _LayersPass as LayersPass } from '@deck.gl/core';
class CollisionFilterPass extends LayersPass {
  renderCollisionMap(target, options) {
    const padding = 1;
    const clearColor = [0, 0, 0, 0];
    const scissorRect = [padding, padding, target.width - 2 * padding, target.height - 2 * padding];
    this.render({
      ...options,
      clearColor,
      scissorRect,
      target,
      pass: 'collision'
    });
  }
  getLayerParameters(layer, layerIndex, viewport) {
    return {
      ...layer.props.parameters,
      blend: false,
      depthRange: [0, 1],
      depthTest: true
    };
  }
  getModuleParameters() {
    // Draw picking colors into collision FBO
    return {
      drawToCollisionMap: true,
      picking: {
        isActive: 1,
        isAttribute: false
      },
      lightSources: {}
    };
  }
}
export { CollisionFilterPass as default };
// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/config/v1/gridaware_feature_config.proto (package tapestry.gridaware.config.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Protocol message type used to configure feature flags for Gridaware app.
 * Feature flags can be overwritten by using the parameters in the URL.
 * Next ID: 42
 *
 * @generated from message tapestry.gridaware.config.v1.GridawareFeatureConfig
 */
export const GridawareFeatureConfig = proto3.makeMessageType("tapestry.gridaware.config.v1.GridawareFeatureConfig", () => [{
  no: 1,
  name: "google_login_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 2,
  name: "upload_view_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 3,
  name: "user_types_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 7,
  name: "new_forms_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 8,
  name: "fife_on_demand_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 10,
  name: "offline_assets_by_feeder_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 11,
  name: "zoom_in_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 12,
  name: "annotation_filtering_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 13,
  name: "thumbnail_carousel_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 14,
  name: "async_upload_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 15,
  name: "edit_pending_upload_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 18,
  name: "service_worker_firebase_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 19,
  name: "breadcrumbs_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 20,
  name: "studio_carousel_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 21,
  name: "lidar_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 22,
  name: "solar_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 23,
  name: "image_orientation_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 25,
  name: "annotation_filtering_assets_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 26,
  name: "new_map_table_view_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 27,
  name: "studio_filtering_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 28,
  name: "upload_form_improvements_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 29,
  name: "flagging_ml_annotations_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 30,
  name: "solar2_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 31,
  name: "image_with_related_assets_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 32,
  name: "image_source_filter_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 33,
  name: "map_pagination_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 34,
  name: "zoom_to_results_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 35,
  name: "vector_maps_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 36,
  name: "streetview_recency_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 38,
  name: "theming_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 39,
  name: "gcp_upload_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 40,
  name: "asset_timeline_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}, {
  no: 41,
  name: "filter_deeplinks_enabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);
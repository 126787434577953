import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';

import {BannerModule} from '../banner/banner_module';
import {HeaderModule} from '../header/header_module';
import {SidepanelLayout} from './sidepanel_layout';

@NgModule({
  declarations: [SidepanelLayout],
  imports: [
    BannerModule,
    CommonModule,
    HeaderModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTooltipModule,
  ],
  exports: [SidepanelLayout],
})
export class SidepanelLayoutModule {}

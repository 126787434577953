@if (!editing) {
  @if (tags.size < 1) {
    <div class="tags-list">No tags</div>
  }
  @if (tags.size > 0) {
    <div class="tags-list">
      <mat-chip-listbox [selectable]="false">
        @for (tag of tags; track tag) {
          <div class="chip-wrapper">
            <mat-chip-option class="chip" disableRipple [removable]="false">
              <div class="input-name-value">{{ tag }}</div>
            </mat-chip-option>
          </div>
        }
      </mat-chip-listbox>
    </div>
  }
  @if (allowEditing) {
    <button mat-flat-button color="primary" type="button" (click)="startEditing()">
      Edit tags
    </button>
  }
}
@if (editing) {
  <multiselect-autocomplete
    [placeholderText]="'Search tags'"
    [options]="allTags"
    [initialSelectedOptions]="getTags()"
    [applyActionTitle]="'Save'"
    [allowEmpty]="true"
    [allowNewElements]="true"
    [selectAllOptionName]="'Tags'"
    (apply)="onApplied()"
    (cancel)="onCancel()"
    (selectedOptionsChanged)="onSelectedOptionsChanged($event)">
  </multiselect-autocomplete>
}

import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

import {Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {
  FilterView,
  LayerFilterView,
  PropertyFilter,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filter_view_pb';

import {DISPLAY_NAME_BY_TOGO} from '../constants/image';
import {FilterViewsService} from '../services/filter_views_service';
import {dateToDateString} from '../utils/date';
import {removeFilterFromLayer} from '../utils/filter_views';

/**
 * Data that is passed to the create view dialog.
 */
export interface CreateViewDialogData {
  layerNamesById: Map<string, string>;
  view: FilterView;
}

/**
 * Data that is returned by the create view dialog on close.
 */
export interface CreateViewDialogResult {
  viewToCreate: FilterView;
}

/**
 * Filter values formatted for display.
 */
export interface FilterDisplayFormat {
  name: string;
  value: string;
}

/** Filter view name characters max length */
export const NAME_MAX_CHARACTERS = 30;

/** Form control error code on missing input */
const ERROR_CODE_REQUIRED = 'required';

/** Form control error code on max length exceeded */
const ERROR_CODE_MAX_LENGTH = 'maxlength';

/** Form control error code on non-unique view name */
const ERROR_CODE_UNIQUE_NAME = 'non-uniquename';

/**
 * Component for rendering the dialog displayed on view creation.
 */
@Component({
  selector: 'create-view-dialog',
  templateUrl: './create_view_dialog.ng.html',
  styleUrls: ['./create_view_dialog.scss'],
})
export class CreateViewDialog implements OnDestroy {
  readonly viewName = new UntypedFormControl('', {
    validators: [Validators.required, Validators.maxLength(NAME_MAX_CHARACTERS)],
  });

  filterView: FilterView;
  filtersByLayer: Map<string, FilterDisplayFormat[]>;
  totalLayersCount = 0;
  totalFiltersCount = 0;
  viewNameHintMessage = this.getViewNameHintMessage('');

  validationIsInProgress = false;
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<CreateViewDialog>,
    private readonly filterViewsService: FilterViewsService,
    @Inject(MAT_DIALOG_DATA) public data: CreateViewDialogData,
  ) {
    this.filterView = data.view.clone();
    this.filtersByLayer = this.getFiltersByLayer();
    this.totalLayersCount = this.getLayersCount();
    this.totalFiltersCount = this.getFiltersCount();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  // Gets the error to be displayed on invalid user input.
  getViewNameErrorMessage(): string {
    if (this.viewName.hasError(ERROR_CODE_REQUIRED)) {
      return 'Please enter a name for the filter view';
    }
    if (this.viewName.hasError(ERROR_CODE_UNIQUE_NAME)) {
      return 'There is already a filter view with this name';
    }
    return this.viewName.hasError(ERROR_CODE_MAX_LENGTH)
      ? `Filter view name must be at most ${NAME_MAX_CHARACTERS} characters`
      : '';
  }

  // The reason for an explicit input handler is the on 'blur' form validation,
  // needed to improve the server side validation performance, but preventing
  // the form control value from being updated on keypress - needed for hint
  // message to be updated as user types.
  handleViewNameInput(event: Event) {
    this.viewNameHintMessage = this.getViewNameHintMessage(
      (event.target as HTMLInputElement)?.value,
    );
  }

  // Returns the message with number of characters left according
  // to the character limit.
  getViewNameHintMessage(input: string): string {
    const usedChars = input?.length || 0;
    const remainingChars = NAME_MAX_CHARACTERS - usedChars;
    if (remainingChars === 1) {
      return `${remainingChars} character left`;
    }
    return `${remainingChars} characters left`;
  }

  // Gets applied layers count.
  getLayersCount(): number {
    return Object.keys(this.filterView.filtersByLayerId).length;
  }

  // Gets applied filters count.
  getFiltersCount(): number {
    return Object.values(this.filterView.filtersByLayerId).reduce(
      (currentCount, layerFilters) =>
        currentCount + Object.values(layerFilters.propertyFilters).length,
      0,
    );
  }

  // Gets layer display name by ID.
  getLayerName(layerId: string): string {
    return this.data.layerNamesById.get(layerId) || layerId;
  }

  // Removes the filter from the layer.
  removeFilter(layerId: string, filterName: string) {
    if (!this.filtersByLayer.has(layerId)) {
      return;
    }
    const layerFilters = this.filtersByLayer.get(layerId)!;
    const index = layerFilters.findIndex((filter) => filter.name === filterName);
    if (index >= 0) {
      layerFilters.splice(index, 1);
    }
    this.totalFiltersCount--;
    this.filterView = removeFilterFromLayer(this.filterView, layerId, filterName);
  }

  // Checks that the entered view name is unique and passes the configured
  // object over for creation and closes the dialog. Otherwise, the dialog
  // is not closed and the ERROR_CODE_UNIQUE_NAME validation error is shown
  // under the view name's input field.
  onCreate() {
    this.validationIsInProgress = true;
    this.filterView.displayName = this.viewName.value;
    this.filterViewsService
      .searchFilterViewsByName(this.viewName.value)
      .pipe(
        finalize(() => {
          this.validationIsInProgress = false;
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((views: FilterView[]) => {
        if (views.length > 0) {
          this.viewName.setErrors({'non-uniquename': true});
          return;
        }
        this.dialogRef.close({
          viewToCreate: this.filterView,
        });
      });
  }

  private getFiltersByLayer(): Map<string, FilterDisplayFormat[]> {
    const filtersByLayer = new Map<string, FilterDisplayFormat[]>();
    for (const [layerId, layerFilters] of Object.entries(this.filterView.filtersByLayerId)) {
      filtersByLayer.set(layerId, this.getFormattedFiltersForLayer(layerFilters));
    }
    return filtersByLayer;
  }

  private getFormattedFiltersForLayer(layerFilters: LayerFilterView): FilterDisplayFormat[] {
    const filtersInDisplayFormat = [];
    for (const [propertyName, propertyFilter] of Object.entries(layerFilters.propertyFilters)) {
      filtersInDisplayFormat.push({
        name: propertyName,
        value: this.formatFilterPropertyForDisplay(propertyFilter),
      });
    }
    return filtersInDisplayFormat;
  }

  formatFilterNameForDisplay(filterName: string): string {
    return DISPLAY_NAME_BY_TOGO.get(filterName) || filterName;
  }

  private formatFilterPropertyForDisplay(filter: PropertyFilter): string {
    switch (filter.filter.case) {
      case 'stringFilter': {
        const filterValues = filter.filter.value.values;
        return filterValues.join(', ');
      }

      case 'dateFilter': {
        const filterValue = filter.filter.value;
        return this.formatDateRange(
          dateToDateString(filterValue.fromTime!.toDate()),
          dateToDateString(filterValue.toTime!.toDate()),
        );
      }
      default:
        return '';
    }
  }

  private formatDateRange(from: string, to: string): string {
    return `${from} to ${to}`;
  }
}

import {Subject} from 'rxjs';

import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';

import {FileViewerService} from '../file_viewer/file_viewer_service';
import {MapService} from '../services/map_service';
import {ServiceWorkerService} from '../services/service_worker_service';

/**
 * The root component of the app.
 */
@Component({
  selector: 'app',
  templateUrl: './app.component.html',
})
export class App implements OnInit, OnDestroy {
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly fileViewerService: FileViewerService,
    private readonly mapService: MapService,
    private readonly serviceWorkerService: ServiceWorkerService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.fileViewerService.setRoot(this.viewContainerRef);
  }

  ngOnInit() {
    // SW should be available regardless of auth state.
    this.serviceWorkerService.register();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}

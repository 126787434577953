<h1 mat-dialog-title class="dialog-title">Create View</h1>
<mat-dialog-content class="view-content">
  <h2 class="view-name mat-h2">Name</h2>
  <mat-form-field class="name" appearance="fill">
    <mat-label>View name</mat-label>
    <input matInput [formControl]="viewName" required (input)="handleViewNameInput($event)" />
    <mat-hint align="end">{{ viewNameHintMessage }}</mat-hint>
    @if (viewName.invalid) {
      <mat-error>{{ getViewNameErrorMessage() }}</mat-error>
    }
  </mat-form-field>
  <h2 class="view-state mat-h2">
    Active layers ({{ getLayersCount() }}) & filters ({{ getFiltersCount() }})
  </h2>
  <div class="filters-container">
    @for (layerFilters of filtersByLayer | keyvalue; track layerFilters) {
      <h3 class="layer-name mat-h3">
        {{ getLayerName(layerFilters.key) }} ({{ layerFilters.value.length }})
      </h3>
      <mat-chip-listbox multiple>
        @for (filter of layerFilters.value; track filter) {
          <div class="chip-wrapper">
            <mat-chip
              class="chip"
              appearance="hairline-filter"
              matTooltip="{{ formatFilterNameForDisplay(filter.name) }}: {{ filter.value }}"
              [removable]="true"
              (removed)="removeFilter(layerFilters.key, filter.name)">
              <div class="filter-value">
                {{ formatFilterNameForDisplay(filter.name) }}:
                {{ filter.value }}
              </div>
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </div>
        }
      </mat-chip-listbox>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    mat-raised-button
    color="primary"
    [disabled]="!viewName.valid || validationIsInProgress"
    (click)="onCreate()">
    Create
  </button>
</mat-dialog-actions>

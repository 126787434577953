import {JsonObject} from '@bufbuild/protobuf';
import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';

import {Injectable, Optional} from '@angular/core';

import {
  GridawareConfig,
  GridawareConfig_UploadFormType as UploadFormType,
  UrlParam,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/config/v1/gridaware_config_pb';
import {GridawareFeatureConfig} from '@tapestry-energy/npm-prod/tapestry/gridaware/config/v1/gridaware_feature_config_pb';

/**
 * The window object with the extra config-related properties which are
 * added in index.html. This interface is only going to be used within this
 * file. This is required by the TSC.
 */
declare interface GridawareWindow extends Window {
  appConfigObject: object;
  featureConfigObject: object;
}

/**
 * Used to encapsulate configuration values for Firebase.
 */
declare interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
}

/**
 * Used to create an instance of OAuthProvider in the firebase service.
 */
declare interface FirebaseProviderConfig {
  id: string; // The provider id, eg, 'microsoft.com'.
  customParameters: {
    tenant?: string; // Id that identifies the tenant.
  };
  label: string; // The name to be associated with this login option.
}

/**
 * Keys for serverside variables that may be overridden in query params.
 */
export enum ConfigParams {
  DEBUG_ENABLED = 'debug',
  GOOGLE_CORP_ANALYTICS_ENABLED = 'GOOGLE_CORP_ANALYTICS_ENABLED',
  GOOGLE_LOGIN_ENABLED = 'GOOGLE_LOGIN_ENABLED',
  SERVICE_WORKER_ENABLED = 'SERVICE_WORKER_ENABLED',
  UNITS = 'UNITS',
  UPLOAD_VIEW_ENABLED = 'UPLOAD_VIEW_ENABLED',
  USER_TYPES_ENABLED = 'USER_TYPES_ENABLED',
  NEW_FORMS_ENABLED = 'NEW_FORMS_ENABLED',
  FIFE_ON_DEMAND_ENABLED = 'FIFE_ON_DEMAND_ENABLED',
  SCOTTY_FORCE_TRACE = 'SCOTTY_FORCE_TRACE',
  SERVING_DOMAIN_AS_AUTH_DOMAIN_ENABLED = 'SERVING_DOMAIN_AS_AUTH_DOMAIN_ENABLED',
  OFFLINE_ASSETS_BY_FEEDER_ENABLED = 'OFFLINE_ASSETS_BY_FEEDER_ENABLED',
  ZOOM_IN_ENABLED = 'ZOOM_IN_ENABLED',
  ANNOTATION_FILTERING_ENABLED = 'ANNOTATION_FILTERING_ENABLED',
  THUMBNAIL_CAROUSEL_ENABLED = 'THUMBNAIL_CAROUSEL_ENABLED',
  EDIT_PENDING_UPLOAD_ENABLED = 'EDIT_PENDING_UPLOAD_ENABLED',
  STUDIO_CAROUSEL_ENABLED = 'STUDIO_CAROUSEL_ENABLED',
  LIDAR_ENABLED = 'LIDAR_ENABLED',
  SOLAR_ENABLED = 'SOLAR_ENABLED',
  SOLAR2_ENABLED = 'SOLAR2_ENABLED',
  IMAGE_ORIENTATION_ENABLED = 'IMAGE_ORIENTATION_ENABLED',
  ANNOTATION_FILTERING_ASSETS_ENABLED = 'ANNOTATION_FILTERING_ASSETS_ENABLED',
  STUDIO_FILTERING_ENABLED = 'STUDIO_FILTERING_ENABLED',
  UPLOAD_FORM_IMPROVEMENTS_ENABLED = 'UPLOAD_FORM_IMPROVEMENTS_ENABLED',
  FLAGGING_ML_ANNOTATIONS_ENABLED = 'FLAGGING_ML_ANNOTATIONS_ENABLED',
  MAP_PAGINATION_ENABLED = 'MAP_PAGINATION_ENABLED',
  VECTOR_MAPS_ENABLED = 'VECTOR_MAPS_ENABLED',
  STREETVIEW_RECENCY_ENABLED = 'STREETVIEW_RECENCY_ENABLED',
  THEMING_ENABLED = 'THEMING_ENABLED',
  GCP_UPLOAD_ENABLED = 'GCP_UPLOAD_ENABLED',
  ASSET_TIMELINE_ENABLED = 'ASSET_TIMELINE_ENABLED',
  FILTER_DEEPLINKS_ENABLED = 'FILTER_DEEPLINKS_ENABLED',
}

/**
 * The config service houses the configuration settings for the app.
 * Some of the settings are constants that exist within this service while
 * others are retrieved from the window object. The app settings that exist on
 * the window object were created via server-side rendering.
 */
@Injectable()
export class ConfigService {
  /**
   * Non-overridable environment variables that are set serverside.
   */
  readonly authEnabled: boolean = false;
  readonly authOverrideEnabled: boolean = false;
  readonly feedbackBucket: string;
  readonly feedbackId: string;
  readonly gaTrackerId: string;
  readonly mapCenter: LatLng;
  readonly mapId: string;
  readonly onePlatformApiKey: string;
  readonly onePlatformUrl: string;
  readonly releaseNumber: number;
  readonly scottyUrl: string;
  readonly gridawareBackendUrl: string;

  /**
   * Configuration object for initializing the Firebase app.
   *
   * Object property names must be strings in order to avoid them being
   * obfuscated by the JS compiler.
   *
   * @see https://firebase.google.com/docs/web/setup#config-object.
   */
  readonly firebaseConfig: FirebaseConfig;
  /**
   * Configuration for setting up an OAuthProvider.
   *
   * This is being used to integrate with OAuth providers like
   * Microsoft and Okta.
   * @see https://firebase.google.com/docs/auth/web/microsoft-oauth.
   * @see https://cloud.google.com/identity-platform/docs/how-to-enable-application-for-oidc
   */
  readonly firebaseProviderConfig: FirebaseProviderConfig[] = [];
  readonly availableUploadFormTypes: UploadFormType[] = [];

  /**
   * Feature flags. These may be overridden in query params.
   */
  readonly debugEnabled: boolean;
  readonly googleCorpAnalyticsEnabled: boolean;
  readonly googleLoginEnabled: boolean;
  readonly serviceWorkerEnabled: boolean;
  readonly units: string;
  readonly uploadViewEnabled: boolean;
  readonly userTypesEnabled: boolean;
  readonly newFormsEnabled: boolean;
  readonly fifeOnDemandEnabled: boolean;
  readonly offlineAssetsByFeederEnabled: boolean;
  readonly zoomInEnabled: boolean;
  readonly annotationFilteringEnabled: boolean;
  readonly thumbnailCarouselEnabled: boolean;
  readonly editPendingUploadEnabled: boolean;
  readonly studioCarouselEnabled: boolean;
  readonly lidarEnabled: boolean;
  readonly solarEnabled: boolean;
  readonly solar2Enabled: boolean;
  readonly imageOrientationEnabled: boolean;
  readonly annotationFilteringAssetsEnabled: boolean;
  readonly studioFilteringEnabled: boolean;
  readonly uploadFormImprovementsEnabled: boolean;
  readonly flaggingMlAnnotationsEnabled: boolean;
  readonly mapPaginationEnabled: boolean;
  readonly vectorMapsEnabled: boolean;
  readonly streetviewRecencyEnabled: boolean;
  readonly themingEnabled: boolean;
  readonly gcpUploadEnabled: boolean;
  readonly assetTimelineEnabled: boolean;
  readonly filterDeeplinksEnabled: boolean;

  constructor(@Optional() win?: Window) {
    if (!win) {
      win = window;
    }

    // window.featureConfigObject and window.appConfigObject are set in env.js.
    const gridawareWindow = win as GridawareWindow;

    const appConfig = GridawareConfig.fromJson(
      (gridawareWindow?.appConfigObject || {}) as JsonObject,
    );
    const featureConfig = GridawareFeatureConfig.fromJson(
      (gridawareWindow?.featureConfigObject || {}) as JsonObject,
    );

    this.feedbackBucket = appConfig.feedbackBucket || '';
    this.feedbackId = appConfig.feedbackId || '';
    this.gaTrackerId = appConfig.gaTrackerId || '';
    this.mapCenter = appConfig.mapCenter || new LatLng();
    this.mapId = appConfig.mapId;
    this.onePlatformApiKey = appConfig.onePlatformApiKey || '';
    this.onePlatformUrl = appConfig.onePlatformUrl || '';
    this.releaseNumber = appConfig.releaseNumber || 0;
    this.scottyUrl = appConfig.scottyUrl || '';
    this.authOverrideEnabled = appConfig.authOverrideEnabled || false;

    const authConfig = appConfig.gcipConfig || [];
    if (authConfig.length > 0) {
      for (const authConfigEntry of authConfig) {
        const tenantId = authConfigEntry.gcipTenantId;
        this.firebaseProviderConfig.push({
          id: authConfigEntry.gcipProviderId,
          customParameters: tenantId ? {tenant: tenantId} : {},
          label: authConfigEntry.displayName,
        });
      }
      this.authEnabled =
        !this.authOverrideEnabled &&
        !!appConfig.gcipApiKey &&
        !!appConfig.gcpProjectId &&
        !!this.firebaseProviderConfig[0].id;
    }

    // For localhost, use proxy.conf.json to proxy requests.
    const authDomain = win.location.host.includes('localhost')
      ? win.location.host
      : `${appConfig.gcpProjectId}.firebaseapp.com`;

    this.firebaseConfig = {
      apiKey: appConfig.gcipApiKey,
      authDomain,
      projectId: appConfig.gcpProjectId,
      storageBucket: `gs://${appConfig.gcpProjectId}.appspot.com`,
    };
    this.availableUploadFormTypes = appConfig.availableUploadFormTypes || [];

    /**
     * Feature flags. These may be overridden in query params.
     */
    this.debugEnabled = false;
    this.googleCorpAnalyticsEnabled = false;
    this.googleLoginEnabled = featureConfig.googleLoginEnabled;
    this.serviceWorkerEnabled = appConfig.serviceWorkerEnabled || false;
    this.units = appConfig.units || '';
    this.uploadViewEnabled = featureConfig.uploadViewEnabled || false;
    this.userTypesEnabled = featureConfig.userTypesEnabled || false;
    this.newFormsEnabled = featureConfig.newFormsEnabled || false;
    this.fifeOnDemandEnabled = featureConfig.fifeOnDemandEnabled || false;
    this.zoomInEnabled = featureConfig.zoomInEnabled || false;
    this.annotationFilteringEnabled = featureConfig.annotationFilteringEnabled || false;
    this.thumbnailCarouselEnabled = featureConfig.thumbnailCarouselEnabled || false;
    this.offlineAssetsByFeederEnabled = featureConfig.offlineAssetsByFeederEnabled || false;
    this.editPendingUploadEnabled = featureConfig.editPendingUploadEnabled || false;
    this.studioCarouselEnabled = featureConfig.studioCarouselEnabled || false;
    this.lidarEnabled = featureConfig.lidarEnabled || false;
    this.solarEnabled = featureConfig.solarEnabled || false;
    this.solar2Enabled = featureConfig.solar2Enabled || false;
    this.imageOrientationEnabled = featureConfig.imageOrientationEnabled || false;
    this.annotationFilteringAssetsEnabled = featureConfig.annotationFilteringAssetsEnabled || false;
    this.studioFilteringEnabled = featureConfig.studioFilteringEnabled || false;
    this.uploadFormImprovementsEnabled = featureConfig.uploadFormImprovementsEnabled || false;
    this.flaggingMlAnnotationsEnabled = featureConfig.flaggingMlAnnotationsEnabled || false;
    this.mapPaginationEnabled = featureConfig.mapPaginationEnabled || false;
    this.vectorMapsEnabled = featureConfig.vectorMapsEnabled || false;
    this.streetviewRecencyEnabled = featureConfig.streetviewRecencyEnabled || false;
    this.themingEnabled = featureConfig.themingEnabled || false;
    this.gcpUploadEnabled = featureConfig.gcpUploadEnabled || false;
    this.assetTimelineEnabled = featureConfig.assetTimelineEnabled || false;
    this.filterDeeplinksEnabled = featureConfig.filterDeeplinksEnabled || false;

    // Override feature flags parsed from the URL.
    const url = new URL(win.location.href.replace(/\/#/, ''));
    const urlParams = url.searchParams;
    if (urlParams.has(ConfigParams.GOOGLE_CORP_ANALYTICS_ENABLED)) {
      this.googleCorpAnalyticsEnabled = bool(
        urlParams.get(ConfigParams.GOOGLE_CORP_ANALYTICS_ENABLED),
      );
    }
    if (urlParams.has(ConfigParams.GOOGLE_LOGIN_ENABLED)) {
      this.googleLoginEnabled = bool(urlParams.get(ConfigParams.GOOGLE_LOGIN_ENABLED));
    }
    if (urlParams.has(ConfigParams.UNITS)) {
      this.units = `${urlParams.get(ConfigParams.UNITS)}`;
    }
    if (urlParams.has(ConfigParams.UPLOAD_VIEW_ENABLED)) {
      this.uploadViewEnabled = bool(urlParams.get(ConfigParams.UPLOAD_VIEW_ENABLED));
    }
    if (urlParams.has(ConfigParams.USER_TYPES_ENABLED)) {
      this.userTypesEnabled = bool(urlParams.get(ConfigParams.USER_TYPES_ENABLED));
    }
    if (urlParams.has(ConfigParams.DEBUG_ENABLED)) {
      this.debugEnabled = bool(urlParams.get(ConfigParams.DEBUG_ENABLED));
    }
    if (urlParams.has(ConfigParams.SERVICE_WORKER_ENABLED)) {
      this.serviceWorkerEnabled = bool(urlParams.get(ConfigParams.SERVICE_WORKER_ENABLED));
    }
    if (urlParams.has(ConfigParams.NEW_FORMS_ENABLED)) {
      this.newFormsEnabled = bool(urlParams.get(ConfigParams.NEW_FORMS_ENABLED));
    }
    if (urlParams.has(ConfigParams.FIFE_ON_DEMAND_ENABLED)) {
      this.fifeOnDemandEnabled = bool(urlParams.get(ConfigParams.FIFE_ON_DEMAND_ENABLED));
    }
    if (urlParams.has(ConfigParams.OFFLINE_ASSETS_BY_FEEDER_ENABLED)) {
      this.offlineAssetsByFeederEnabled = bool(
        urlParams.get(ConfigParams.OFFLINE_ASSETS_BY_FEEDER_ENABLED),
      );
    }
    if (bool(urlParams.get(ConfigParams.SCOTTY_FORCE_TRACE))) {
      this.scottyUrl = appendParams(this.scottyUrl, appConfig.scottyForceTraceUrlParams);
    }
    if (urlParams.has(ConfigParams.ZOOM_IN_ENABLED)) {
      this.zoomInEnabled = bool(urlParams.get(ConfigParams.ZOOM_IN_ENABLED));
    }
    if (urlParams.has(ConfigParams.ANNOTATION_FILTERING_ENABLED)) {
      this.annotationFilteringEnabled = bool(
        urlParams.get(ConfigParams.ANNOTATION_FILTERING_ENABLED),
      );
    }
    if (urlParams.has(ConfigParams.THUMBNAIL_CAROUSEL_ENABLED)) {
      this.thumbnailCarouselEnabled = bool(urlParams.get(ConfigParams.THUMBNAIL_CAROUSEL_ENABLED));
    }
    if (urlParams.has(ConfigParams.EDIT_PENDING_UPLOAD_ENABLED)) {
      this.editPendingUploadEnabled = bool(urlParams.get(ConfigParams.EDIT_PENDING_UPLOAD_ENABLED));
    }
    if (urlParams.has(ConfigParams.STUDIO_CAROUSEL_ENABLED)) {
      this.studioCarouselEnabled = bool(urlParams.get(ConfigParams.STUDIO_CAROUSEL_ENABLED));
    }
    if (urlParams.has(ConfigParams.LIDAR_ENABLED)) {
      this.lidarEnabled = bool(urlParams.get(ConfigParams.LIDAR_ENABLED));
    }
    if (urlParams.has(ConfigParams.SOLAR_ENABLED)) {
      this.solarEnabled = bool(urlParams.get(ConfigParams.SOLAR_ENABLED));
    }
    if (urlParams.has(ConfigParams.SOLAR2_ENABLED)) {
      this.solar2Enabled = bool(urlParams.get(ConfigParams.SOLAR2_ENABLED));
    }
    if (urlParams.has(ConfigParams.IMAGE_ORIENTATION_ENABLED)) {
      this.imageOrientationEnabled = bool(urlParams.get(ConfigParams.IMAGE_ORIENTATION_ENABLED));
    }
    if (urlParams.has(ConfigParams.ANNOTATION_FILTERING_ASSETS_ENABLED)) {
      this.annotationFilteringAssetsEnabled = bool(
        urlParams.get(ConfigParams.ANNOTATION_FILTERING_ASSETS_ENABLED),
      );
    }
    if (urlParams.has(ConfigParams.STUDIO_FILTERING_ENABLED)) {
      this.studioFilteringEnabled = bool(urlParams.get(ConfigParams.STUDIO_FILTERING_ENABLED));
    }
    if (urlParams.has(ConfigParams.UPLOAD_FORM_IMPROVEMENTS_ENABLED)) {
      this.uploadFormImprovementsEnabled = bool(
        urlParams.get(ConfigParams.UPLOAD_FORM_IMPROVEMENTS_ENABLED),
      );
    }
    if (urlParams.has(ConfigParams.FLAGGING_ML_ANNOTATIONS_ENABLED)) {
      this.flaggingMlAnnotationsEnabled = bool(
        urlParams.get(ConfigParams.FLAGGING_ML_ANNOTATIONS_ENABLED),
      );
    }
    if (urlParams.has(ConfigParams.MAP_PAGINATION_ENABLED)) {
      this.mapPaginationEnabled = bool(urlParams.get(ConfigParams.MAP_PAGINATION_ENABLED));
    }
    if (urlParams.has(ConfigParams.VECTOR_MAPS_ENABLED)) {
      this.vectorMapsEnabled = bool(urlParams.get(ConfigParams.VECTOR_MAPS_ENABLED));
    }
    if (urlParams.has(ConfigParams.STREETVIEW_RECENCY_ENABLED)) {
      this.streetviewRecencyEnabled = bool(urlParams.get(ConfigParams.STREETVIEW_RECENCY_ENABLED));
    }
    if (urlParams.has(ConfigParams.THEMING_ENABLED)) {
      this.themingEnabled = bool(urlParams.get(ConfigParams.THEMING_ENABLED));
    }
    if (urlParams.has(ConfigParams.GCP_UPLOAD_ENABLED)) {
      this.gcpUploadEnabled = bool(urlParams.get(ConfigParams.GCP_UPLOAD_ENABLED));
    }
    if (urlParams.has(ConfigParams.ASSET_TIMELINE_ENABLED)) {
      this.assetTimelineEnabled = bool(urlParams.get(ConfigParams.ASSET_TIMELINE_ENABLED));
    }
    if (urlParams.has(ConfigParams.FILTER_DEEPLINKS_ENABLED)) {
      this.filterDeeplinksEnabled = bool(urlParams.get(ConfigParams.FILTER_DEEPLINKS_ENABLED));
    }

    let useServingDomainAsAuthDomain = appConfig.useServingDomainAsAuthDomain;
    // If the URL parameter is provided, it takes precedence (i.e. if the
    // feature is enabled in the config file, we can still disable via the URL).
    if (urlParams.has(ConfigParams.SERVING_DOMAIN_AS_AUTH_DOMAIN_ENABLED)) {
      useServingDomainAsAuthDomain = bool(
        urlParams.get(ConfigParams.SERVING_DOMAIN_AS_AUTH_DOMAIN_ENABLED),
      );
    }
    if (useServingDomainAsAuthDomain) {
      this.firebaseConfig.authDomain = url.host;
    }

    this.gridawareBackendUrl = appConfig.gridawareBackendUrl || '';
  }
}

function bool(value: boolean | string | null): boolean {
  return value?.toString()?.toLowerCase() === 'true';
}

/**
 * Returns `baseUrl` with `params` appended to the params segment.
 */
function appendParams(baseUrl: string, params: UrlParam[]): string {
  if (params.length === 0) {
    return baseUrl;
  }
  const url = new URL(baseUrl);
  for (const param of params) {
    url.searchParams.append(param.name, param.value);
  }
  return url.toString();
}

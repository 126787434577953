@if (imageUrls | async; as urls) {
  <ngu-carousel #carousel [dataSource]="urls" [inputs]="carouselConfig">
    <ngu-item *nguCarouselDef="let imageData">
      <div class="carousel-item">
        <img alt="User image" class="carousel-image" [src]="imageData" />
      </div>
    </ngu-item>
    <button
      mat-mini-fab
      NguCarouselPrev
      class="nav-button previous"
      [ngClass]="{'single-slide': carousel.isFirst && carousel.isLast}"
      [disabled]="carousel.isFirst && carousel.isLast">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button
      mat-mini-fab
      NguCarouselNext
      class="nav-button next"
      [ngClass]="{'single-slide': carousel.isFirst && carousel.isLast}"
      [disabled]="carousel.isFirst && carousel.isLast">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <div NguCarouselPoint class="page-marker">
      {{ carousel.activePoint ? carousel.activePoint + 1 : 1 }} of
      {{ carousel.pointNumbers.length }}
    </div>
  </ngu-carousel>
}

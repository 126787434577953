import {Observable} from 'rxjs';

import {Injectable, Type} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

/**
 * Site-wide width for dialogs.
 */
export const DIALOG_WIDTH = '50%';

/**
 * Site-wide maximum width for dialogs.
 */
export const MAX_DIALOG_WIDTH = '540px';

/**
 * Site-wide minimum width for dialogs.
 */
export const MIN_DIALOG_WIDTH = '360px';

const DEFAULT_CONFIG: MatDialogConfig = {
  maxWidth: MAX_DIALOG_WIDTH,
  minWidth: MIN_DIALOG_WIDTH,
  width: DIALOG_WIDTH,
};

/**
 * A service for rendering dialogs.
 */
@Injectable({providedIn: 'root'})
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}

  render<C, R>(componentType: Type<C>, config: MatDialogConfig = {}): Observable<R> {
    const dialogRef = this.dialog.open(componentType, {
      ...DEFAULT_CONFIG,
      ...config,
      panelClass: config.panelClass ?? 'form-dialog',
    });
    return dialogRef.afterClosed();
  }
}

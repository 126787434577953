/**
 * @fileoverview Service for integration with go/feedback -- a module to
 *     report and log user feedback.
 */
import {Injectable} from '@angular/core';

import {AuthService} from '../services/auth_service';
import {ErrorsService} from '../services/errors_service';
import {ConfigService} from './config_service';

// go/feedback-web/api#configuration-objectstring-required
interface FeedbackConfiguration {
  productId: string;
  bucket?: string;
  productVersion: string;

  allowNonLoggedInFeedback: boolean;
}

interface UserFeedback {
  api: {
    startFeedback: (config: FeedbackConfiguration, productData: Object) => void;
  };
}

// Global is imported from gstatic in index.html. This is not recommended but is required because we are running outside of g3
// See https://g3doc.corp.google.com/company/teams/support-content/feedback_web/integration/index.md?cl=head#getting-the-api-script-from-gstatic-not-recommended
declare const userfeedback: UserFeedback;

/**
 * Service to integrate with the go/feedback JS API.
 * Additional data can be submitted with reports, such as customer ID (eg, NBP)
 * when we have several between which to differentiate, or release ID.
 */
@Injectable()
export class FeedbackService {
  constructor(
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly errorsService: ErrorsService,
  ) {}

  /**
   * Begins the feedback flow, launching a dialog where the user can highlight
   * part of the current UI and leave feedback in freetext form. We can also
   * attach additional diagnostic information to the startFeedback method as
   * an additional param.
   */
  start() {
    const errors = this.errorsService.getReport();

    userfeedback.api.startFeedback(
      {
        bucket: this.configService.feedbackBucket,
        productId: this.configService.feedbackId,
        productVersion: this.configService.releaseNumber.toString(),
        // Don't force users to sign into a Google account. (GridAware uses
        // federated SSO.)
        allowNonLoggedInFeedback: true,
      },
      {
        gridAwareSSOEmail: this.authService.currentUserIfPresent()?.email,
        errors: errors.length > 0 ? JSON.stringify(errors) : undefined,
      },
    );
    // TODO: b/284199916 - Do this in a completion callback instead of right
    // after starting feedback.
    this.errorsService.clear();
  }
}

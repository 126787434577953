<ng-container>
  @if (loading) {
    <mat-progress-spinner mode="indeterminate" color="primary" diameter="10">
    </mat-progress-spinner>
  } @else {
    <div class="annotation-summary-container">
      <div class="summary-row summary-border-bottom">
        <div class="asset-header">Assets</div>
        <div class="asset">{{ assets }}</div>
      </div>
      <div class="summary-row">
        <div class="asset-header">Defects</div>
        <div class="asset">{{ defects }}</div>
      </div>
    </div>
  }
</ng-container>

import {BehaviorSubject} from 'rxjs';

import {Injectable} from '@angular/core';

/**
 * Service for specifying global progress. Only determinate or indeterminate
 * progress can be set but not both.
 */
@Injectable({providedIn: 'root'})
export class LoaderService {
  /**
   * Show indeterminate progress. If true then determinateLoadingProgress is
   * ignored.
   */
  readonly showIndeterminateProgress = new BehaviorSubject<boolean>(false);

  /**
   * Loading progress from 0 to 100 with 100 being complete, or null if
   * no known progress.
   */
  readonly determinateLoadingProgress = new BehaviorSubject<number | null>(null);
}

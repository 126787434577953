// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/geometry.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DoubleValue, proto3 } from "@bufbuild/protobuf";
import { LatLng } from "../../../../google/type/latlng_pb.js";

/**
 * A bounding box for a geoquery search. This maps directly with s2.Rect (Go)
 * and S2LatLngRect (C++/Java).
 *
 * @generated from message tapestry.gridaware.api.v1.BoundingBox
 */
export const BoundingBox = proto3.makeMessageType("tapestry.gridaware.api.v1.BoundingBox", () => [{
  no: 1,
  name: "lo",
  kind: "message",
  T: LatLng
}, {
  no: 2,
  name: "hi",
  kind: "message",
  T: LatLng
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.Geometry
 */
export const Geometry = proto3.makeMessageType("tapestry.gridaware.api.v1.Geometry", () => [{
  no: 1,
  name: "point",
  kind: "message",
  T: Point,
  oneof: "geometry"
}, {
  no: 2,
  name: "multi_point",
  kind: "message",
  T: MultiPoint,
  oneof: "geometry"
}, {
  no: 3,
  name: "line_string",
  kind: "message",
  T: Polyline,
  oneof: "geometry"
}, {
  no: 4,
  name: "multi_line_string",
  kind: "message",
  T: MultiLineString,
  oneof: "geometry"
}, {
  no: 5,
  name: "polygon",
  kind: "message",
  T: Polygon,
  oneof: "geometry"
}, {
  no: 6,
  name: "multi_polygon",
  kind: "message",
  T: MultiPolygon,
  oneof: "geometry"
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.Point
 */
export const Point = proto3.makeMessageType("tapestry.gridaware.api.v1.Point", () => [{
  no: 1,
  name: "location",
  kind: "message",
  T: LatLng
}, {
  no: 2,
  name: "height",
  kind: "message",
  T: PointHeight
}]);

/**
 * PointHeight allows the 3D location of a point to be stored. Note that
 * s2.Point does not support a height parameter, so there might be information
 * loss when converting to that type.
 *
 * @generated from message tapestry.gridaware.api.v1.PointHeight
 */
export const PointHeight = proto3.makeMessageType("tapestry.gridaware.api.v1.PointHeight", () => [{
  no: 2,
  name: "altitude_meters",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */,
  oneof: "height"
}, {
  no: 3,
  name: "height_above_grade",
  kind: "message",
  T: HeightAboveGrade,
  oneof: "height"
}]);

/**
 * HeightAboveGrade specifies the height information and the grade with respect
 * to which the height is computed.
 *
 * @generated from message tapestry.gridaware.api.v1.HeightAboveGrade
 */
export const HeightAboveGrade = proto3.makeMessageType("tapestry.gridaware.api.v1.HeightAboveGrade", () => [{
  no: 1,
  name: "height_meters",
  kind: "scalar",
  T: 1 /* ScalarType.DOUBLE */
}, {
  no: 2,
  name: "grade_height_meters",
  kind: "message",
  T: DoubleValue
}, {
  no: 3,
  name: "grade_type",
  kind: "enum",
  T: proto3.getEnumType(HeightAboveGrade_GradeType)
}]);

/**
 *   GradeType specifies the grade such as road or overbridge that the height
 *   is given in reference to. This allows to encode for use cases such as
 *   where there is some equipment on a road and some equipment on a bridge
 *   over a road.
 *
 * @generated from enum tapestry.gridaware.api.v1.HeightAboveGrade.GradeType
 */
export const HeightAboveGrade_GradeType = proto3.makeEnum("tapestry.gridaware.api.v1.HeightAboveGrade.GradeType", [{
  no: 0,
  name: "UNSPECIFIED_GRADE_TYPE"
}, {
  no: 1,
  name: "ROAD"
}, {
  no: 2,
  name: "BRIDGE"
}]);

/**
 * A MultiPoint is a collection of points that are NOT assumed to be connected.
 * See https://tools.ietf.org/html/rfc7946#section-3.1.3 for GeoJSON spec.
 *
 * @generated from message tapestry.gridaware.api.v1.MultiPoint
 */
export const MultiPoint = proto3.makeMessageType("tapestry.gridaware.api.v1.MultiPoint", () => [{
  no: 1,
  name: "points",
  kind: "message",
  T: Point,
  repeated: true
}]);

/**
 * A Polyline is a collection of Points that are assumed to be connected.
 * Maps to geojson LineString.
 *
 * @generated from message tapestry.gridaware.api.v1.Polyline
 */
export const Polyline = proto3.makeMessageType("tapestry.gridaware.api.v1.Polyline", () => [{
  no: 1,
  name: "points",
  kind: "message",
  T: Point,
  repeated: true
}]);

/**
 * A MultiLineString is a collection of lines that may or may not loop back on
 * themselves.
 * See https://tools.ietf.org/html/rfc7946#section-3.1.5 for GeoJSON spec.
 *
 * @generated from message tapestry.gridaware.api.v1.MultiLineString
 */
export const MultiLineString = proto3.makeMessageType("tapestry.gridaware.api.v1.MultiLineString", () => [{
  no: 1,
  name: "polylines",
  kind: "message",
  T: Polyline,
  repeated: true
}]);

/**
 * A Polygon is a sequence of loops, as defined by the Go s2 library. The
 * polygon interior consists of the points contained by an odd number of
 * loops. (Recall that a loop contains the set of points on its left-hand side.)
 *
 * @generated from message tapestry.gridaware.api.v1.Polygon
 */
export const Polygon = proto3.makeMessageType("tapestry.gridaware.api.v1.Polygon", () => [{
  no: 1,
  name: "loops",
  kind: "message",
  T: Loop,
  repeated: true
}]);

/**
 * A Loop consists of a sequence of vertices where the first vertex is
 * implicitly connected to the last. All loops are defined to have a CCW
 * orientation, i.e. the interior of the loop is on the left side of the edges.
 *
 * @generated from message tapestry.gridaware.api.v1.Loop
 */
export const Loop = proto3.makeMessageType("tapestry.gridaware.api.v1.Loop", () => [{
  no: 1,
  name: "points",
  kind: "message",
  T: Point,
  repeated: true
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.MultiPolygon
 */
export const MultiPolygon = proto3.makeMessageType("tapestry.gridaware.api.v1.MultiPolygon", () => [{
  no: 1,
  name: "polygon",
  kind: "message",
  T: Polygon,
  repeated: true
}]);
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {PipesModule} from '../pipes/pipes_module';
import {CommentsForm} from './comment_form';
import {CommentsList} from './comments_list';

@NgModule({
  declarations: [CommentsList, CommentsForm],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    PipesModule,
  ],
  exports: [CommentsList, CommentsForm],
})
export class CommentModule {}

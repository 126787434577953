/**
 * @fileoverview Useful functions for dealing with collections.
 */

/**
 * Returns true if `a` and `b` sets are equal.
 */
export function setsAreEqual<T>(a: Set<T>, b: Set<T>): boolean {
  return a.size === b.size && [...a].every((item) => b.has(item));
}

/**
 * Reverse a maps key-value relationship.
 */
export function reverseMap<K, V>(map: Map<K, V>): Map<V, K> | null {
  if (!valuesAreUnique<K, V>(map)) {
    return null;
  }
  return new Map(Array.from(map.entries()).map(([a, b]) => [b, a]));
}

function valuesAreUnique<K, V>(map: Map<K, V>): boolean {
  const values = Array.from(map.values());
  return values.length === new Set(values).size;
}

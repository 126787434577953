// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/related_feature.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { LifecycleStage } from "./common_pb.js";

/**
 * Related features grouped by a role.
 * Next ID: 17
 *
 * @generated from message tapestry.gridaware.api.v1.RelatedFeaturesGroup
 */
export const RelatedFeaturesGroup = proto3.makeMessageType("tapestry.gridaware.api.v1.RelatedFeaturesGroup", () => [{
  no: 1,
  name: "role",
  kind: "enum",
  T: proto3.getEnumType(RelatedFeaturesGroup_RelatedFeatureRole)
}, {
  no: 2,
  name: "related_features",
  kind: "message",
  T: RelatedFeature,
  repeated: true
}]);

/**
 * @generated from enum tapestry.gridaware.api.v1.RelatedFeaturesGroup.RelatedFeatureRole
 */
export const RelatedFeaturesGroup_RelatedFeatureRole = proto3.makeEnum("tapestry.gridaware.api.v1.RelatedFeaturesGroup.RelatedFeatureRole", [{
  no: 0,
  name: "UNKNOWN"
}, {
  no: 1,
  name: "PARENT_ASSET"
}, {
  no: 2,
  name: "CHILD_ASSET"
}, {
  no: 13,
  name: "AUTOTOPOLOGY_CHILD_ASSET"
}, {
  no: 3,
  name: "PARENT_WORK_ORDER"
}, {
  no: 4,
  name: "CHILD_WORK_ORDER"
}, {
  no: 5,
  name: "PARENT_DEFECT"
}, {
  no: 16,
  name: "CONTEXTUAL_DEFECT"
}, {
  no: 6,
  name: "CHILD_DEFECT"
}, {
  no: 14,
  name: "AUTOTOPOLOGY_CHILD_DEFECT"
}, {
  no: 8,
  name: "CHILD_IMAGE"
}, {
  no: 15,
  name: "AUTOTOPOLOGY_CHILD_IMAGE"
}, {
  no: 9,
  name: "CHILD_INCIDENT"
}, {
  no: 10,
  name: "PARENT_SERVICE_REQUEST"
}, {
  no: 11,
  name: "CHILD_SERVICE_REQUEST_ACTIVITY"
}, {
  no: 12,
  name: "CHILD_SAP_ACTIVITY"
}]);

/**
 * Information about a related feature.
 *
 * @generated from message tapestry.gridaware.api.v1.RelatedFeature
 */
export const RelatedFeature = proto3.makeMessageType("tapestry.gridaware.api.v1.RelatedFeature", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "layer_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "display_name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "updated_at",
  kind: "message",
  T: Timestamp
}, {
  no: 5,
  name: "lifecycle_stage",
  kind: "enum",
  T: proto3.getEnumType(LifecycleStage)
}]);
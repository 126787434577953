// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/feature.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Geometry } from "./geometry_pb.js";
import { Tag } from "./tag_pb.js";
import { RelatedFeaturesGroup } from "./related_feature_pb.js";
import { ImageAssociationStatus, LifecycleStage } from "./common_pb.js";
import { HeaderImage } from "./image_pb.js";

/**
 * A Type assigned to a feature.
 *
 * @generated from enum tapestry.gridaware.api.v1.FeatureType
 */
export const FeatureType = proto3.makeEnum("tapestry.gridaware.api.v1.FeatureType", [{
  no: 0,
  name: "FEATURE_TYPE_UNSPECIFIED"
}, {
  no: 1,
  name: "ASSET"
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.Feature
 */
export const Feature = proto3.makeMessageType("tapestry.gridaware.api.v1.Feature", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 16,
  name: "external_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "geometry",
  kind: "message",
  T: Geometry
}, {
  no: 9,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}, {
  no: 10,
  name: "updated_at",
  kind: "message",
  T: Timestamp
}, {
  no: 12,
  name: "tags",
  kind: "message",
  T: Tag,
  repeated: true
}, {
  no: 13,
  name: "related_features_groups",
  kind: "message",
  T: RelatedFeaturesGroup,
  repeated: true
}, {
  no: 14,
  name: "lifecycle_stage",
  kind: "enum",
  T: proto3.getEnumType(LifecycleStage)
}, {
  no: 15,
  name: "header_image",
  kind: "message",
  T: HeaderImage
}, {
  no: 17,
  name: "created_at",
  kind: "message",
  T: Timestamp
}, {
  no: 18,
  name: "closed_at",
  kind: "message",
  T: Timestamp
}, {
  no: 19,
  name: "feature_type",
  kind: "enum",
  T: proto3.getEnumType(FeatureType)
}, {
  no: 20,
  name: "image_association_status",
  kind: "enum",
  T: proto3.getEnumType(ImageAssociationStatus)
}]);

/**
 * TODO(b/147636120): Feature (Asset, Image, Geojson) relations should be
 * saved on ingestion. This proto can be removed then.
 * Used for generating links on the front end.
 *
 * @generated from message tapestry.gridaware.api.v1.FeatureIdMap
 */
export const FeatureIdMap = proto3.makeMessageType("tapestry.gridaware.api.v1.FeatureIdMap", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "external_id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * A key-value pair of user-generated data associated with a feature.
 *
 * @generated from message tapestry.gridaware.api.v1.Property
 */
export const Property = proto3.makeMessageType("tapestry.gridaware.api.v1.Property", () => [{
  no: 1,
  name: "key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "value",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  oneof: "property_value"
}, {
  no: 3,
  name: "timestamp_value",
  kind: "message",
  T: Timestamp,
  oneof: "property_value"
}]);

/**
 * A repeated list of Property instances so that we can serialize them all as a
 * single proto.
 *
 * @generated from message tapestry.gridaware.api.v1.Properties
 */
export const Properties = proto3.makeMessageType("tapestry.gridaware.api.v1.Properties", () => [{
  no: 1,
  name: "properties",
  kind: "message",
  T: Property,
  repeated: true
}]);
import {Component, EventEmitter, Input, Output} from '@angular/core';

import {Comment} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/comment_pb';

import {CommentsService} from '../services/comments_service';

/**
 * The comments form component.
 */
@Component({
  selector: 'comment-form',
  templateUrl: './comment_form.html',
  styleUrls: ['./comment_form.scss'],
})
export class CommentsForm {
  @Input() featureId = '';
  @Output() commentComplete = new EventEmitter<Comment | null>();

  commentMaxLength = 500;
  commentText = '';
  loading = false;

  constructor(private readonly commentService: CommentsService) {}

  clearComment() {
    this.commentText = '';
    this.commentComplete.emit(null);
  }

  leaveComment() {
    this.loading = true;

    this.commentService
      .createComment(this.commentText, this.featureId)
      .subscribe((comment: Comment | null) => {
        this.commentText = '';
        this.loading = false;
        this.commentComplete.emit(comment);
      });
  }
}

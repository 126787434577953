import {Inject, Pipe, PipeTransform} from '@angular/core';

import {FILTER_NAMES_MAP} from './filter_config';
import {getFilterNameByKey} from './filter_helpers';

/**
 * Pipe for formatting filter name label.
 */
@Pipe({name: 'filterNameLabel'})
export class FilterNameLabelPipe implements PipeTransform {
  constructor(
    @Inject(FILTER_NAMES_MAP)
    private readonly filterNamesMap: Map<string, string>,
  ) {}

  transform(filterName: string): string {
    return getFilterNameByKey(this.filterNamesMap, filterName);
  }
}

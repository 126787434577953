<div class="container">
  <header></header>
  <div class="content">
    <div>
      <div class="pending-uploads-header">Pending Uploads</div>
      <div class="pending-uploads-content">
        You can add images to upload when your connection is restored.
      </div>
      <div class="pending-divider"></div>
      <div
        [hidden]="!failedComponentRefs.size || (networkService.getOffline$() | async)"
        class="section">
        <div class="section-header-action">
          <div class="section-header">Failed {{ getImageCount(failedVCRef) }}</div>
          <button
            mat-button
            class="button"
            (click)="retryAllFailed()"
            i18n-aria-label
            aria-label="Retry all">
            Retry all
          </button>
        </div>
        <ng-template #failed></ng-template>
      </div>
      <div class="section">
        <div class="section-header-action">
          <div class="section-header">Pending {{ getImageCount(pendingVCRef) }}</div>
          <button
            mat-button
            class="button upload-all"
            (click)="uploadAllPending()"
            [disabled]="!pendingComponentRefs.size || (networkService.getOffline$() | async)"
            i18n-aria-label
            aria-label="Upload all">
            Upload all
          </button>
        </div>
        <ng-template #pending></ng-template>
      </div>
      <div [hidden]="!succeededVCRef || succeededVCRef.length === 0" class="section">
        <div class="section-header">Succeeded {{ getImageCount(succeededVCRef) }}</div>
        <ng-template #succeeded></ng-template>
      </div>
      <button mat-flat-button color="primary" class="button" (click)="goToUploadPage()">
        Add images
      </button>
    </div>
  </div>
</div>

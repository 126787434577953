import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';

/**
 * The data that the feature selection dialog responds with.
 */
export interface FeatureSelectionResponse {
  locationOfPin: LatLng | null;
  selectedFeature: Feature | null;
}

/**
 * The data that is passed into the feature selection dialog.
 */
export interface FeatureSelectionMapDialogData {
  layerIds: string[];
}

/**
 * Dialog for selecting an asset on a map.
 */
@Component({
  templateUrl: 'feature_selection_map_dialog.ng.html',
  styleUrls: ['feature_selection_map_dialog.scss'],
})
export class FeatureSelectionMapDialog {
  locationDescriptionVisible = false;
  locationOfPin: LatLng | null = null;
  selectedFeature: Feature | null = null;

  constructor(
    private readonly dialogRef: MatDialogRef<FeatureSelectionMapDialog>,
    @Inject(MAT_DIALOG_DATA) readonly data: FeatureSelectionMapDialogData,
  ) {}

  updateFeatureSelection(selectedFeature: Feature | null) {
    this.selectedFeature = selectedFeature;
    this.locationOfPin = null;
  }

  updateLocationOfPin(locationOfPin: LatLng | null) {
    this.locationOfPin = locationOfPin;
    this.selectedFeature = null;
  }

  uploadAll(): void {
    this.dialogRef.close({
      selectedFeature: this.selectedFeature,
      locationOfPin: this.locationOfPin,
    });
  }
}

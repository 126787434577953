import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {Metadata} from './metadata';

@NgModule({
  declarations: [Metadata],
  exports: [Metadata],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatSnackBarModule],
})
export class MetadataModule {}

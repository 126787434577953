import { Shader } from '@luma.gl/core';
/** Manages a cached pool of Shaders for reuse. */
export class ShaderFactory {
  static defaultProps = {
    ...Shader.defaultProps
  };
  device;
  _cache = {};
  /** Returns the default ShaderFactory for the given {@link Device}, creating one if necessary. */
  static getDefaultShaderFactory(device) {
    device._lumaData.defaultShaderFactory ||= new ShaderFactory(device);
    return device._lumaData.defaultShaderFactory;
  }
  /** @internal */
  constructor(device) {
    this.device = device;
  }
  /** Requests a {@link Shader} from the cache, creating a new Shader only if necessary. */
  createShader(props) {
    const key = this._hashShader(props);
    let cacheEntry = this._cache[key];
    if (!cacheEntry) {
      const shader = this.device.createShader({
        ...props,
        id: props.id ? `${props.id}-cached` : undefined
      });
      this._cache[key] = cacheEntry = {
        shader,
        useCount: 0
      };
    }
    cacheEntry.useCount++;
    return cacheEntry.shader;
  }
  /** Releases a previously-requested {@link Shader}, destroying it if no users remain. */
  release(shader) {
    const key = this._hashShader(shader);
    const cacheEntry = this._cache[key];
    if (cacheEntry) {
      cacheEntry.useCount--;
      if (cacheEntry.useCount === 0) {
        delete this._cache[key];
        cacheEntry.shader.destroy();
      }
    }
  }
  // PRIVATE
  _hashShader(value) {
    return `${value.stage}:${value.source}`;
  }
}
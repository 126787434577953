import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {ROUTE} from '../../constants/paths';

/**
 * Dialog for a first-time-offline user.
 */
@Component({
  templateUrl: 'offline_dialog.ng.html',
  styleUrls: ['offline_dialog.scss'],
})
export class OfflineDialog {
  constructor(
    private readonly dialogRef: MatDialogRef<OfflineDialog>,
    private readonly router: Router,
  ) {}

  goToPendingUploads() {
    this.dialogRef.close();
    this.router.navigateByUrl(ROUTE.PENDING_UPLOAD);
  }
}

import {Observable, Subject, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {QueryFeaturesResponse} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_pb';

import {FeaturesService, QueryFeaturesRequestPayload} from './features_service';
import {PaginationCachingService} from './pagination_caching_service';

/** Page interface. */
export interface Page {
  pageIndex: number;
  pageSize: number;
  length?: number;
}

/** Pagination service for retrieving paginated features data. */
@Injectable()
export class FeaturesPaginationService {
  // Notification channel allowing clients to differentiate between the data
  // retrieved from cache and the data fetch happening, as well as the requested
  // number of records.
  private readonly dataFetchTriggered$ = new Subject<number>();

  constructor(
    private readonly featuresService: FeaturesService,
    private readonly paginationCachingService: PaginationCachingService,
  ) {}

  /**
   * Returns features for the particular page.
   *
   * It gets features from the cache and requests the remaining ones.
   * Requested features saves into the cache for the future usage.
   */
  getFeaturesByPage(page: Page, payload: QueryFeaturesRequestPayload): Observable<Feature[]> {
    const {layerId} = payload;
    const slicedPageData = this.sliceCachedFeaturesByPage(page, layerId);
    const slicedPageDataSize = slicedPageData.length;

    if (slicedPageDataSize === this.getFeaturesCountPerPage(page)) {
      return of(slicedPageData);
    }

    return this.getFeatures({
      ...payload,
      pagination: {
        pageSize: this.getRemainingPageSize(slicedPageDataSize, page.pageSize),
        pageToken: this.paginationCachingService.getNextPageTokenForLayer(layerId),
      },
    }).pipe(
      map((features: Feature[]): Feature[] =>
        slicedPageDataSize ? [...slicedPageData, ...features] : features,
      ),
    );
  }

  /**
   * Returns features for the first page.
   *
   * It requests features for the first page and restore the cache.
   */
  getFeaturesFirstPage(payload: QueryFeaturesRequestPayload): Observable<Feature[]> {
    return this.getFeatures(
      {
        ...payload,
        pagination: {...payload.pagination!, pageToken: ''},
      },
      true,
    );
  }

  /**
   * Returns a list of requested features.
   *
   * Requests features and saves them into the cache for the future usage. To
   * support backward pagination, a larger set is retrieved, a slice is cut for
   * immediate use and the entire set is cached.
   * Along with features receives a
   * token for the next page. An empty token means no more pages available.
   */
  getFeatures(payload: QueryFeaturesRequestPayload, restoreCache = false): Observable<Feature[]> {
    this.dataFetchTriggered$.next(payload.maxResults || 0);
    const {layerId} = payload;
    return this.featuresService.queryFeatures(payload).pipe(
      map((response: QueryFeaturesResponse) => {
        const features = response.features;
        const token = response.nextPageToken;
        this.paginationCachingService.setNextPageTokenForLayer(layerId, token);

        if (restoreCache) {
          this.paginationCachingService.setFeaturesForLayer(layerId, features);
          return features;
        }

        if (payload.pagination && features.length > payload.pagination.pageSize) {
          this.paginationCachingService.setFeaturesForLayer(layerId, features);
          return features.slice(features.length - payload.pagination.pageSize, features.length);
        }

        this.paginationCachingService.addFeaturesForLayer(layerId, features);
        return features;
      }),
    );
  }

  /** Returns features count for the particular page. */
  getFeaturesCountPerPage({pageSize, pageIndex, length}: Page): number {
    if (!length) {
      return pageSize;
    }

    const remainingFeaturesCount = length - pageSize * pageIndex;
    const isLastPage = remainingFeaturesCount <= pageSize;

    return isLastPage ? remainingFeaturesCount : pageSize;
  }

  /** Returns features from the cache for the specified page. */
  sliceCachedFeaturesByPage(page: Page, layerId: string): Feature[] {
    const {pageIndex, pageSize} = page;
    const start = pageIndex === 0 ? 0 : pageSize * pageIndex;
    const end = start + pageSize;

    const features = this.paginationCachingService.getFeaturesForLayer(layerId);
    return features.slice(start, end);
  }

  /**
   * Returns remaining features count for the provided page size.
   * @param availableSize Size of the cached features list or its slice.
   * @param desiredSize Desired page size.
   */
  getRemainingPageSize(availableSize: number, desiredSize: number): number {
    return availableSize ? desiredSize - availableSize : desiredSize;
  }

  /**
   * Provides a way for clients to get notified on data fetch event (vs
   * retrieving the data from the cached subset).
   */
  onDataFetchTriggered(): Observable<number> {
    return this.dataFetchTriggered$.asObservable();
  }
}

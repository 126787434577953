import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {AnnotationFiltersModule} from '../annotation_filters/annotation_filters_module';
import {HeaderModule} from '../header/header_module';
import {ImageThumbnailModule} from '../image_thumbnail/image_thumbnail_module';
import {LayerFiltersModule} from '../layer_filters/layer_filters_module';
import {MapTableToggleModule} from '../map_table_toggle/map_table_toggle_module';
import {SidepanelLayoutModule} from '../sidepanel_layout/sidepanel_layout_module';
import {TagFiltersModule} from '../tag_filters/tag_filters_module';
import {SolarDataTable} from './solar_data_table';
import {SolarTablePage} from './solar_table';

@NgModule({
  declarations: [SolarDataTable, SolarTablePage],
  imports: [
    AnnotationFiltersModule,
    CommonModule,
    FormsModule,
    HeaderModule,
    ImageThumbnailModule,
    LayerFiltersModule,
    MapTableToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule,
    SidepanelLayoutModule,
    TagFiltersModule,
  ],
  exports: [SolarDataTable],
})
export class SolarTableModule {}

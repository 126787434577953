/**
 *  @fileoverview Error page component.
 */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

interface ErrorDescriptions {
  [code: string]: {
    action: {label: string; href: string};
    codeMessage: string;
    description: string;
  };
}

/**
 * Shows a generic 404/500 or other error page.
 */
@Component({
  selector: 'page-error',
  templateUrl: './page_errors_component.ng.html',
  styleUrls: ['./page_errors_component.scss'],
})
export class PageErrorsComponent implements OnInit {
  code = '';
  errorDescriptions: ErrorDescriptions = {
    '404': {
      action: {label: 'GO TO MAP', href: '/map'},
      codeMessage: '404 – Page Not Found',
      description: 'The page you are looking for was not found or does not exist.',
    },
    '500': {
      action: {label: 'GO TO MAP', href: '/map'},
      codeMessage: '500 – Server Error',
      description: 'A server error occurred and the page can not be shown.',
    },
    unknownError: {
      action: {label: 'GO TO MAP', href: '/map'},
      codeMessage: 'Unknown Error',
      description: 'An error occurred and the page can not be shown.',
    },
  };

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params) {
        if (Object.prototype.hasOwnProperty.call(this.errorDescriptions, params['code'])) {
          this.code = params['code'];
        } else {
          this.code = 'unknownError';
        }
      }
    });
  }
}

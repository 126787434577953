<ng-container>
  <div class="annotation-filters-header">
    <div class="filters-title">Annotation Filters</div>
  </div>
  <div class="annotation-filter-chips">
    <annotation-filter-chips [layerId]="layerId"></annotation-filter-chips>
  </div>
  @if (included.length > 0 || excluded.length > 0) {
    <mat-chip-listbox class="selected-options-listbox">
      @for (option of included; track option) {
        <div class="chip-wrapper">
          <mat-chip class="chip" (removed)="removeIncludeOption(option)">
            <div class="chip-name">
              <mat-icon fontSet="material-icons-outlined">label</mat-icon>
              <span>{{ option }}</span>
            </div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </div>
      }
      @for (option of excluded; track option) {
        <div class="chip-wrapper">
          <mat-chip class="chip excluded-chip" (removed)="removeExcludeOption(option)">
            <div class="chip-name">
              <mat-icon fontSet="material-icons-outlined">label_off</mat-icon>
              <span>{{ option }}</span>
            </div>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </div>
      }
    </mat-chip-listbox>
  }
  @if (editing) {
    <form class="filter-form">
      <div class="form-container">
        <multiselect-autocomplete
          #include
          placeholderText="Include label name"
          [panelWidth]="autocompletePanelWidth"
          [initialSelectedOptions]="included"
          [options]="allLabels"
          [autoFocus]="false"
          [selectAllOptionName]="'Label names'"
          (selectedOptionsChanged)="updateIncluded($event)">
        </multiselect-autocomplete>
      </div>
      <div class="form-container">
        <multiselect-autocomplete
          #exclude
          placeholderText="Exclude label name"
          [panelWidth]="autocompletePanelWidth"
          [initialSelectedOptions]="excluded"
          [options]="allLabels"
          [autoFocus]="false"
          [selectAllOptionName]="'Label names'"
          (selectedOptionsChanged)="updateExcluded($event)">
        </multiselect-autocomplete>
      </div>
    </form>
    <div class="actions-container">
      <button mat-flat-button (click)="onCancelEdit()">Cancel</button>
      <button mat-flat-button color="primary" (click)="onApplyEdit()">Apply</button>
    </div>
  } @else {
    <button
      class="add-filter-button"
      mat-flat-button
      color="primary"
      (click)="onAddFilterButtonClicked()">
      Add label filter
    </button>
  }
</ng-container>

import {Injectable} from '@angular/core';

interface ErrorMessage {
  datetime: Date;
  message: string;
}

const MAXIMUM_MESSAGES_TO_KEEP = 20;

/**
 * Central service for handling/storing error which can be shown to the user or
 * be included into the feedback.
 */
@Injectable({providedIn: 'root'})
export class ErrorsService {
  private readonly errors: ErrorMessage[] = [];

  addError(message: string) {
    if (this.errors.length >= MAXIMUM_MESSAGES_TO_KEEP) {
      this.errors.splice(0, 1);
    }

    this.errors.push({
      datetime: new Date(),
      message,
    });
  }

  clear() {
    this.errors.length = 0;
  }

  getReport(): ErrorMessage[] {
    return this.errors.map((error: ErrorMessage) => ({...error}));
  }
}

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/layer.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message tapestry.gridaware.api.v1.Layer
 */
export const Layer = proto3.makeMessageType("tapestry.gridaware.api.v1.Layer", () => [{
  no: 1,
  name: "id",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "name",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "layer_type",
  kind: "enum",
  T: proto3.getEnumType(Layer_LayerType)
}, {
  no: 4,
  name: "position",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "layer_style",
  kind: "message",
  T: LayerStyle
}]);

/**
 * Layer type indicator.
 *
 * @generated from enum tapestry.gridaware.api.v1.Layer.LayerType
 */
export const Layer_LayerType = proto3.makeEnum("tapestry.gridaware.api.v1.Layer.LayerType", [{
  no: 0,
  name: "TYPE_UNSPECIFIED"
}, {
  no: 1,
  name: "NATIVE"
}, {
  no: 2,
  name: "IMAGE_TILE"
}, {
  no: 3,
  name: "IMAGES"
}, {
  no: 4,
  name: "ASSETS"
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.LayerStyle
 */
export const LayerStyle = proto3.makeMessageType("tapestry.gridaware.api.v1.LayerStyle", () => [{
  no: 1,
  name: "point_marker",
  kind: "message",
  T: PointMarker
}, {
  no: 5,
  name: "polyline",
  kind: "message",
  T: PolylineStyle
}, {
  no: 4,
  name: "priority_property_keys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 2,
  name: "ignore_property_keys",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 3,
  name: "include_inactives_disabled",
  kind: "scalar",
  T: 8 /* ScalarType.BOOL */
}]);

/**
 * @generated from message tapestry.gridaware.api.v1.PointMarker
 */
export const PointMarker = proto3.makeMessageType("tapestry.gridaware.api.v1.PointMarker", () => [{
  no: 2,
  name: "unselected_icon_svg",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 3,
  name: "selected_icon_svg",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 11,
  name: "unselected_icon_svg_with_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 12,
  name: "selected_icon_svg_with_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 4,
  name: "color_schema",
  kind: "map",
  K: 9 /* ScalarType.STRING */,
  V: {
    kind: "scalar",
    T: 9 /* ScalarType.STRING */
  }
}, {
  no: 5,
  name: "marker_property_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 6,
  name: "color_palette",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */,
  repeated: true
}, {
  no: 7,
  name: "label_property_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 8,
  name: "color_property_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 9,
  name: "color_description_property_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 10,
  name: "hover_property_key",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 13,
  name: "unselected_icon_svg_no_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 14,
  name: "selected_icon_svg_no_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 15,
  name: "unselected_icon_svg_with_user_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 16,
  name: "selected_icon_svg_with_user_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 17,
  name: "unselected_icon_svg_with_tapestry_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 18,
  name: "selected_icon_svg_with_tapestry_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 19,
  name: "unselected_icon_svg_with_user_tapestry_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 20,
  name: "selected_icon_svg_with_user_tapestry_image",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);

/**
 * Styles for a feature which has a polyline geometry.
 * Note. This message has been renamed from Polyline to PolylineStyle
 * during migrating to GCP to resolve a conflict with the Polyline in the
 * geometry.proto
 *
 * @generated from message tapestry.gridaware.api.v1.PolylineStyle
 */
export const PolylineStyle = proto3.makeMessageType("tapestry.gridaware.api.v1.PolylineStyle", () => [{
  no: 1,
  name: "stroke_color",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}]);
<ng-container>
  @if (canIncludeInactives) {
    <div class="filter-container include-inactive">
      <div>
        Include inactive
        {{ queryFeatureCount?.inactive === 1 ? 'result' : 'results' }}
      </div>
      <mat-checkbox
        class="include-inactive-checkbox"
        [disabled]="!queryFeatureCount || queryFeatureCount.inactive === 0"
        [checked]="includeInactiveResults"
        (change)="setIncludeInactive($event.checked)">
      </mat-checkbox>
    </div>
  }
  <div class="header">
    <div class="layer-filters-title">Property filters</div>
    <div class="right-container">
      <button mat-stroked-button color="accent" class="reset-button" (click)="resetFilters()">
        Reset
      </button>
      @if (!editing && (totalFeatureCount | async) !== null) {
        <matching-result [quantity]="(totalFeatureCount | async) || 0"> </matching-result>
      }
    </div>
  </div>
  <property-filters
    [layerId]="layerId"
    [reset]="resetFiltersRequest"
    (editingChange)="onEditingChanged($event)">
  </property-filters>
</ng-container>

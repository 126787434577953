/**
 * Site-wide width for dialogs.
 */
export const DIALOG_WIDTH = '50%';

/**
 * Site-wide maximum width for dialogs.
 */
export const MAX_DIALOG_WIDTH = '540px';

/**
 * Site-wide width for form dialogs. Note that this is wider than the
 * max width for other types of dialogs set above.
 */
export const FORM_DIALOG_WIDTH = '682px';

/**
 * Site-wide minimum width for dialogs.
 */
export const MIN_DIALOG_WIDTH = '360px';

/**
 * Site-wide maximum height for dialogs.
 */
export const MAX_DIALOG_HEIGHT = '95vh';

/**
 * Color to use for rendering defect-related image annotations.
 */
export const DEFECT_ANNOTATION_COLOR = '#b3261e';

/**
 * Default color to use for rendering image annotations.
 */
export const DEFAULT_ANNOTATION_COLOR = '#f6e54c';

/**
 * Color to use for rendering of label text in
 * defect-related image annotations.
 */
export const DEFECT_TEXT_ANNOTATION_COLOR = '#ffffff';

/**
 * Default color to use for rendering of label text in
 * image annotations.
 */
export const DEFAULT_TEXT_ANNOTATION_COLOR = '#000000';

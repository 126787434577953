/**
 * Fixed names for the filters to be used inside FilterMap and when saved to the
 * local storage.
 */
export enum FilterField {
  ANNOTATION_INCLUDE = 'annotations_include',
  ANNOTATION_EXCLUDE = 'annotations_exclude',
  IMAGE_ASSOCIATION_FILTER = 'image_association_filter',
  IMAGE_GROUPS = 'image_groups',
  TAG_NAMES = 'tag_names',
  TYPES = 'types',
  LINE_NUMBERS = 'line_numbers',
}

// @generated by protoc-gen-connect-es v1.3.0
// @generated from file tapestry/gridaware/api/v1/tagservice.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetTagsByLayerIdRequest, GetTagsByLayerIdResponse } from "./tagservice_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * See http://google3/google/internal/viaduct/gridaware/v1/tag.proto
 *
 * @generated from service tapestry.gridaware.api.v1.TagService
 */
export const TagService = {
  typeName: "tapestry.gridaware.api.v1.TagService",
  methods: {
    /**
     * GetTagsByLayerId gets a list of unique Tags ordered by Name. Only Tags used
     * by Features from the specified LayerID will be returned.
     *
     * @generated from rpc tapestry.gridaware.api.v1.TagService.GetTagsByLayerId
     */
    getTagsByLayerId: {
      name: "GetTagsByLayerId",
      I: GetTagsByLayerIdRequest,
      O: GetTagsByLayerIdResponse,
      kind: MethodKind.Unary
    }
  }
};
// TODO(b/331674733): Ensure data storage compiles with Solar API.

// @generated by protoc-gen-es v1.7.1
// @generated from file tapestry/gridaware/api/v1/solar_insight.proto (package tapestry.gridaware.api.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Aggregated Solar Insights.
 *
 * @generated from message tapestry.gridaware.api.v1.SolarInsights
 */
export const SolarInsights = proto3.makeMessageType("tapestry.gridaware.api.v1.SolarInsights", () => [{
  no: 1,
  name: "solar_insights",
  kind: "message",
  T: SolarInsight,
  repeated: true
}]);

/**
 * Data was aggregated for all installation control points (ICPs) within each
 * feeder.
 *
 * @generated from message tapestry.gridaware.api.v1.AggregationByFeeder
 */
export const AggregationByFeeder = proto3.makeMessageType("tapestry.gridaware.api.v1.AggregationByFeeder", () => [{
  no: 1,
  name: "feeder_code",
  kind: "scalar",
  T: 9 /* ScalarType.STRING */
}, {
  no: 2,
  name: "icp_count",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);

/**
 * Aggregated solar metrics by a type like a feeder.
 * TODO(b/325104585): Update comments in this file.
 *
 * @generated from message tapestry.gridaware.api.v1.SolarInsight
 */
export const SolarInsight = proto3.makeMessageType("tapestry.gridaware.api.v1.SolarInsight", () => [{
  no: 1,
  name: "aggregation_by_feeder",
  kind: "message",
  T: AggregationByFeeder,
  oneof: "aggregation_type"
}, {
  no: 2,
  name: "percent_match",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 3,
  name: "percent_residential",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 4,
  name: "percent_commercial",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 5,
  name: "percent_other",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 6,
  name: "max_kw_connected_capacity",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 7,
  name: "avg_max_kw_residential",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 8,
  name: "avg_max_kw_commercial",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 9,
  name: "max_yearly_kwh_potential_ac",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 10,
  name: "max_m2_panel_area",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 11,
  name: "total_m2_roof_area",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 12,
  name: "avg_roof_max_sunshine_hrs_per_m2",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 13,
  name: "ne_only_kw_connected_capacity",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 14,
  name: "ne_only_avg_kw_residential",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 15,
  name: "ne_only_avg_kw_commercial",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 16,
  name: "ne_only_yearly_kwh_potential_ac",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 17,
  name: "we_only_kw_connected_capacity",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 18,
  name: "we_only_avg_kw_residential",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 19,
  name: "we_only_avg_kw_commercial",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 20,
  name: "we_only_yearly_kwh_potential_ac",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 21,
  name: "imagery_average_age",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 22,
  name: "imagery_one_year",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 23,
  name: "imagery_two_year",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 24,
  name: "imagery_three_year",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 25,
  name: "imagery_five_year",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 26,
  name: "imagery_ten_year",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 27,
  name: "imagery_fifteen_year",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 28,
  name: "percent_high_quality_imagery",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 29,
  name: "percent_medium_quality_imagery",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 30,
  name: "carbon_offset_factor_per_mwh",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}, {
  no: 31,
  name: "percent_unavailable_imagery",
  kind: "scalar",
  T: 5 /* ScalarType.INT32 */
}]);
import {Feature} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  Polyline,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';

/**
 * Returns true if any feature is located within a bounds.
 */
export function boundsContainsAnyFeature(
  features: Feature[],
  bounds: google.maps.LatLngBounds,
): boolean {
  for (const feature of features) {
    const geometry = feature.geometry!;
    if (
      geometry.geometry.case === 'point' &&
      boundsContainsPoint(geometry.geometry.value, bounds)
    ) {
      return true;
    }
    if (
      geometry.geometry.case === 'multiPoint' &&
      boundsContainsMultiPoint(geometry.geometry.value, bounds)
    ) {
      return true;
    }
    if (
      geometry.geometry.case === 'lineString' &&
      boundsContainsLineString(geometry.geometry.value, bounds)
    ) {
      return true;
    }
    if (
      geometry.geometry.case === 'multiLineString' &&
      boundsContainsMultiLineString(geometry.geometry.value, bounds)
    ) {
      return true;
    }
    if (
      geometry.geometry.case === 'polygon' &&
      boundsContainsPolygon(geometry.geometry.value, bounds)
    ) {
      return true;
    }
    if (
      geometry.geometry.case === 'multiPolygon' &&
      boundsContainsMultiPolygon(geometry.geometry.value, bounds)
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if a point is located within a map's bounds.
 */
function boundsContainsPoint(point: Point, bounds: google.maps.LatLngBounds): boolean {
  return bounds.contains(
    new google.maps.LatLng(point.location?.latitude || 0, point.location?.longitude || 0),
  );
}

/**
 * Returns true if any point is located within a map's bounds.
 */
function boundsContainsAnyPoint(points: Point[], bounds: google.maps.LatLngBounds): boolean {
  for (const point of points) {
    if (boundsContainsPoint(point, bounds)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if a multi point is located within a map's bounds.
 */
function boundsContainsMultiPoint(
  multiPoint: MultiPoint,
  bounds: google.maps.LatLngBounds,
): boolean {
  return boundsContainsAnyPoint(multiPoint.points.slice(), bounds);
}

/**
 * Returns true if a line string is located within a map's bounds.
 */
function boundsContainsLineString(lineString: Polyline, bounds: google.maps.LatLngBounds): boolean {
  return boundsContainsAnyPoint(lineString.points.slice(), bounds);
}

/**
 * Returns true if a multi-line string is located within a map's bounds.
 */
function boundsContainsMultiLineString(
  multiLineString: MultiLineString,
  bounds: google.maps.LatLngBounds,
): boolean {
  for (const lineString of multiLineString.polylines) {
    if (boundsContainsLineString(lineString.clone(), bounds)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if a polygon is located within a map's bounds.
 */
function boundsContainsPolygon(polygon: Polygon, bounds: google.maps.LatLngBounds): boolean {
  for (const loop of polygon.loops) {
    if (boundsContainsAnyPoint(loop.points.slice(), bounds)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if a multi polygon is located within a map's bounds.
 */
function boundsContainsMultiPolygon(
  multiPolygon: MultiPolygon,
  bounds: google.maps.LatLngBounds,
): boolean {
  for (const polygon of multiPolygon.polygon) {
    if (boundsContainsPolygon(polygon.clone(), bounds)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns the nearest markers by position comparing to the center point of the
 * bounds.
 */
export function filterNearestMarkersByPosition(
  featurePositions: (google.maps.LatLng | null | undefined)[],
  bounds: google.maps.LatLngBounds,
): google.maps.LatLng[] {
  let minDistance = Number.MAX_SAFE_INTEGER;
  // Group an array of positions by distance from the center of the bounds.
  const positionsByDistance = new Map<number, google.maps.LatLng[]>();
  const centerPoint: google.maps.LatLng = bounds.getCenter();
  for (const featurePosition of featurePositions) {
    // Distance between the feature position and the center of the bounds in
    // meters using Haversine formula.
    if (featurePosition) {
      const distance = google.maps.geometry.spherical.computeDistanceBetween(
        featurePosition,
        centerPoint,
      );
      // Set the minimum distance from the center of the bounds to 'minDistance'.
      minDistance = Math.min(distance, minDistance);
      const positions = positionsByDistance.get(distance) || [];
      positions.push(featurePosition);
      positionsByDistance.set(distance, positions);
    }
  }

  // This is an arbitrary number in meters that is used to determine if a marker
  // is too far from the center of the bounds.
  const maxDistanceFromBoundsCenter = 150000;
  // Compose an array of positions from the map, excluding markers that are too
  // far from the center of the bounds.
  const nearestMarkerPositions = [];
  for (const [distance, positions] of positionsByDistance) {
    if (distance - minDistance < maxDistanceFromBoundsCenter) {
      nearestMarkerPositions.push(...positions);
    }
  }
  return nearestMarkerPositions;
}

/**
 *  @fileoverview Map Legend Component
 */
import {Component, Input} from '@angular/core';

import {Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

/**
 * The map layers legend component.
 */
@Component({
  selector: 'map-layers-legend',
  templateUrl: './map_layers_legend_component.ng.html',
  styleUrls: ['./map_layers_legend_component.scss'],
})
export class MapLayersLegendComponent {
  @Input() layerName!: string;
  @Input() layerType!: Layer_LayerType;

  layerTypes = Layer_LayerType;
}

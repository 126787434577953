<sidepanel-layout>
  <div header></div>
  <div sidepanel>
    <router-outlet></router-outlet>
  </div>
  <div main class="image-studio-container">
    <image-studio
      [totalImageCount]="totalImageCount"
      (onPrevious)="selectPreviousImage()"
      (onNext)="selectNextImage()">
    </image-studio>
  </div>
</sidepanel-layout>
